import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import {
  ErrorSnackbar,
  SuccessSnackbar
} from "../../components/CustomSnackBar";
import { fetchAdminTotalProjections } from "../../redux/reducers/TotalProjections";
import useAppContext from "../../utils/useAppContext";
import Deposits from "./Deposits";

export const cardProperties = {
  p: 2,
  display: "flex",
  flexDirection: "column",
  height: 100,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: "#92ffd2",
  },
};

function TransactionSection() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { freqValue, productType, connectorId, bankName } = useAppContext();

  const { totalAdminProjectionsData, isLoading, successMsg, errorMsg } =
    useSelector(({ totalProjections }) => totalProjections);


  useEffect(() => {
    dispatch(fetchAdminTotalProjections(productType, bankName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productType, bankName]);

  const {
    totalLeadsEntered = 0,
    totalLeadsAmount = 0,

    totalDisbursedApplications = 0,
    totalDisbursedAmount = 0,

    totalBankPayoutCount = 0,
    totalBankPayoutReceived = 0,

    totalConnectorSpotCount = 0,
    totalConnectorSpotPaid = 0,

    totalConnectorMonthlyCount = 0,
    totalConnectorMonthlyPaid = 0,

    totalConnectorPendingCount = 0,
    totalConnectorPendingPayment = 0,

    totalConnectorApplications = 0,
    totalConnectorAmount = 0,

    totalUnmappedLeads = 0,
    totalUnmappedAmount = 0,

  } = totalAdminProjectionsData;

  const navigate = (type) => {
    const queryParam = bankName && productType ?
      `type=${type}&productType=${productType}&bank=${bankName}`
      : bankName ?
        `type=${type}&bank=${bankName}`
        : productType ?
          `type=${type}&productType=${productType}`
          : `type=${type}`;
    history.push(`/admin/admin-leads?${queryParam}`);
  }

  return (
    <>
      <Grid container spacing={2}>
        {/* First row */}
        <Grid item xs={12} md={3} lg={4} className="deposits-card">
          <Paper
            onClick={() => navigate('leadListing')}
            sx={cardProperties}
          >
            <div style={{ borderRadius: "100px", height: "4rem" }}>
              <Deposits
                noOfLeads={totalLeadsEntered}
                name="Leads Listing"
                amount={totalLeadsAmount}
              />
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3} lg={4} className=" deposits-card">
          <Paper
            onClick={() => navigate('disbursementListing')}
            sx={cardProperties}
          >
            <Deposits
              name="Disbursement Listing"
              noOfLeads={totalDisbursedApplications}
              amount={totalDisbursedAmount}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={3} lg={4} className="deposits-card">
          <Paper
            onClick={() => navigate('unmappedPayout')}
            sx={cardProperties}
          >
            <Deposits
              noOfLeads={totalUnmappedLeads}
              name="Un Mapped Payouts"
              amount={totalUnmappedAmount}
              type="currency"
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={3} lg={4} className="deposits-card">
          <Paper
            onClick={() => navigate('pendingPayout')}
            sx={cardProperties}
          >
            <Deposits
              noOfLeads={totalConnectorPendingCount}
              name="Total Pending Payouts"
              amount={totalConnectorPendingPayment}
              type="currency"
            />
          </Paper>
        </Grid>

        {/* <Grid item xs={12} md={3} lg={3} className="deposits-card">
          <Paper
            onClick={() => navigate('bankPayout')}
            sx={cardProperties}
          >
            <Deposits
              noOfLeads={totalBankPayoutCount}
              name="Bank Payouts"
              amount={totalBankPayoutReceived}
              type="currency"
            />
          </Paper>
        </Grid> */}

        {/* <Grid item xs={12} md={3} lg={3} className="deposits-card">
          <Paper
            onClick={() => navigate('connectorPayout')}
            sx={cardProperties}
          >
            <Deposits
              noOfLeads={totalConnectorApplications}
              name="Connector Payouts"
              amount={totalConnectorAmount}
              type="currency"
            />
          </Paper>
        </Grid> */}
        {/* Second row */}

        <Grid item xs={12} md={3} lg={4} className="deposits-card">
          <Paper
            onClick={() => navigate('spotPayout')}
            sx={cardProperties}
          >
            <div style={{ borderRadius: "100px", height: "4rem" }}>
              <Deposits
                noOfLeads={totalConnectorSpotCount}
                name="Pending Spot Payouts"
                amount={totalConnectorSpotPaid}
              />
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3} lg={4} className=" deposits-card">
          <Paper
            onClick={() => navigate('monthlyPayout')}
            sx={cardProperties}
          >
            <Deposits
              noOfLeads={totalConnectorMonthlyCount}
              name="Pending Monthly Payouts"
              amount={totalConnectorMonthlyPaid}
            />
          </Paper>
        </Grid>

      </Grid>
      {errorMsg && <ErrorSnackbar errorMsg={errorMsg} />}
      {successMsg && <SuccessSnackbar successMsg={successMsg} />}
    </>
  );
}

export default TransactionSection;
