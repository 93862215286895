import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from "@mui/icons-material/Edit";
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Chip, Tooltip, Zoom } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { GridActionsCellItem } from "@mui/x-data-grid";
import axios from 'axios';
import * as React from 'react';
import { monthNumberToLabelMap } from "../../constants/appconstants";
import { ApiUrl } from '../../helpers/getUrl';
import EditExpense from './EditExpense';
import moment from 'moment';

function Row(props) {
    const { row, loadData, setLoadData } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {monthNumberToLabelMap[row.month + 1]}
                </TableCell>
                <TableCell>{row.totalGrossAmount}</TableCell>
                <TableCell>{row.totalTds}</TableCell>
                <TableCell>{row.totalNetAmount}</TableCell>
                <TableCell>
                    {row.status === "APPROVED" && <Chip label="Approved" size="small" variant='outlined' color="success" />}
                    {row.status === "REJECTED" && <Chip label="Rejected" size="small" variant='outlined' color="error" />}
                    {row.status === "PENDING" && <Chip label="Pending" size="small" variant='outlined' color="secondary" />}
                </TableCell>
                <TableCell>
                    <Tooltip TransitionComponent={Zoom} title={row.comments}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                        >
                            <InfoTwoToneIcon color='primary' />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#f0f8ff' }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <SubRow row={row} loadData={loadData} setLoadData={setLoadData} />
                    </Collapse>
                </TableCell >
            </TableRow >
        </React.Fragment >
    );
}



function SubRow({ row, loadData, setLoadData }) {


    const [isEdit, setisEdit] = React.useState({ data: {}, display: false })
    const [data, setData] = React.useState([])

    const handleEdit = (data, idx) => {
        setisEdit({
            data,
            display: true
        })
    }

    React.useEffect(() => {
        axios.get(ApiUrl() + `expense?month=${row.month}&year=${row.year}`).then((res) => {
            setData(res.data);
        }).catch((error) => {
            console.error(error)
        })
    }, [row]);

    React.useEffect(() => {
        setLoadData(!loadData)
    }, [isEdit])

    return (
        <React.Fragment>
            <Box sx={{ margin: 3 }}>
                <TableContainer >
                    <Table size="small" stickyHeader aria-label="data table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Category</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>TDS</TableCell>
                                <TableCell>IGST</TableCell>
                                <TableCell>CGST</TableCell>
                                <TableCell>SGST</TableCell>
                                <TableCell>TDS Payment Status</TableCell>
                                <TableCell>Paid On</TableCell>
                                <TableCell>Net Amount</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Remarks</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ backgroundColor: "#bbbbbb4f" }}>
                            {data?.map((data, idx) => (
                                <TableRow hover tabIndex={-1} key={idx}>
                                    <TableCell>{data.category}</TableCell>
                                    <TableCell>{data.grossAmount}</TableCell>
                                    <TableCell>{data.tds}</TableCell>
                                    <TableCell>{data.igst}</TableCell>
                                    <TableCell>{data.cgst}</TableCell>
                                    <TableCell>{data.sgst}</TableCell>
                                    <TableCell>{data.isTdsPaid ? "Yes" : "No"}</TableCell>
                                    <TableCell>{moment(data?.paidOn)?.format("DD/MM/YYYY")}</TableCell>
                                    <TableCell>{data.netAmount}</TableCell>
                                    <TableCell style={{ textTransform: 'capitalize' }}>{data.status?.toLowerCase()}</TableCell>
                                    <TableCell>{data.remark}</TableCell>
                                    <TableCell><GridActionsCellItem
                                        icon={<EditIcon />}
                                        label="Edit"
                                        disabled={row.status === "APPROVED"}
                                        onClick={() => { handleEdit(data, idx) }}
                                        color="inherit"
                                    /></TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <EditExpense setisEdit={setisEdit} isEdit={isEdit} />
        </React.Fragment >
    );
}


export default function DataTable({ yearWiseData, loadData, setLoadData }) {

    return (
        <div style={{ marginTop: '20px' }}>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow style={{ backgroundColor: '#cecece' }}>
                            <TableCell />
                            <TableCell>Month</TableCell>
                            <TableCell>Total Amount</TableCell>
                            <TableCell>Total TDS</TableCell>
                            <TableCell>Total Net Amount</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Comments</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {yearWiseData?.map((row) => (
                            <Row key={row.month} row={row} loadData={loadData} setLoadData={setLoadData} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
