import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { ErrorSnackbar, SuccessSnackbar } from "../../../../components/CustomSnackBar";
import { bankNameConstantsForAccount } from "../../../../constants/appconstants";
import { errorHandler } from "../../../../helpers/errorHandler";
import { ApiUrl } from "../../../../helpers/getUrl";
import { BootstrapButton } from "../../BootstrapButton";

const useStyles = makeStyles(() => ({
    textfield: {
        border: "1px solid #a3a3a3 !important",
        borderRadius: 5,
        borderBottom: "none"
    },
    inputRoot: {
        "&.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root": {
            border: "1px solid #a3a3a3 !important"
        },
    }
}));

const useYupValidationResolver = (validationSchema) =>
    useCallback(
        async (data) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors, currentError) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );
const validationSchema = yup.object({
    accountNumber: yup.string().matches(/^([0-9]{10})|([0-9]{2}-[0-9]{3}-[0-9]{6})$/, { message: 'Please enter valid account no', excludeEmptyString: true }).required("Account Number is Required"),
    verifyAccountNumber: yup.string().when("transferTo", {
        is: (transferTo) => {
            if (transferTo === 'EXISTING') {
                return false;
            } else {
                return true;
            }
        },
        then: yup.string().required('Account Number is Required').oneOf([yup.ref('accountNumber'), null], "Account Number don't match"),
        otherwise: yup.string().notRequired()
    }),
    requestedAmount: yup.string().matches(/^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, { message: 'Please enter valid amount', excludeEmptyString: true }).required("Amount  is Required"),
    bankName: yup.string().required("Bank Name is Required"),
    ifsc: yup.string().matches(/[A-Za-z]{4}0[A-Z0-9a-z]{6}$/, { message: 'Please enter valid Ifsc Code.', excludeEmptyString: true }).required("IFSC Code is Required"),
    //PAN regex: .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, { message: 'Please enter valid Pan no.', excludeEmptyString: true })
    pan: yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}/, { message: 'Please enter valid Pan no.', excludeEmptyString: true }).required("PAN Number is Required"),
    gstin: yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, { message: 'Please enter valid GST No.', excludeEmptyString: true }),
    accountHolderName: yup.string().required(" Account Holder Name is Required"),
});
export const RequestAdvanceForm = ({ display, handleModal }) => {
    const classes = useStyles();
    const [reEnter, setReEnter] = useState(true);
    const [bankNameDefaultValue, setBankNameDefaultValue] = useState(false);
    const [bankNameDyn, setBankNameDyn] = useState('');
    const [successMsg, setSuccessMsg] = useState({
        status: false,
        msg: '',
    });
    const [errorMsg, setErrorMsg] = useState({ state: false, msg: null });
    const [errorMsg1, setErrorMsg1] = useState({ state: false, msg: null });

    const bankNameRef = useRef();

    // const resolver = useYupValidationResolver(reEnter ? validationSchema : validationSchemaWithoutVerify);
    const resolver = useYupValidationResolver(validationSchema);
    const { connectorId } = JSON.parse(sessionStorage.getItem('data'));

    const {
        register,
        reset,
        handleSubmit,
        watch,
        setValue,
        formState: { errors }
    } = useForm({
        resolver,
    });
    var watchTransferTo = watch("transferTo", "EXISTING");

    const [accountDetails, setAccountDetails] = useState({
        data: []
    })

    useEffect(() => {
        // get account details only once when component is mounted
        getExistingAccountDetails()
    }, [])

    useEffect(() => {
        // auto-fill account details input fields from state object when "transferTo" dropdown changes
        if (watchTransferTo === "EXISTING") {
            setValue("accountHolderName", accountDetails.data.accountHolderName)
            setValue("pan", accountDetails.data.pan)
            setValue("ifsc", accountDetails.data.ifsc)
            setValue("accountNumber", accountDetails.data.accountNumber)
            setValue("bankName", accountDetails.data.bankName)
            // 
            setBankNameDyn(accountDetails.data.bankName)
            setBankNameDefaultValue(true)
            setReEnter(false)
        }
        if (watchTransferTo === "NEW") {
            setValue("accountHolderName", '')
            setValue("pan", '')
            setValue("ifsc", '')
            setValue("accountNumber", '')
            setValue("bankName", '')
            setValue('verifyAccountNumber', '')
            // 
            setBankNameDyn('')
            setBankNameDefaultValue(false)
            setReEnter(true)
        }
    }, [watchTransferTo])

    const getExistingAccountDetails = () => {
        axios.get(`${ApiUrl()}accounts/${connectorId}`).then((res) => {
            console.log("GET accounts: ", res.data.accounts[0])
            if (res.status === 200) {
                // after a successful response, set the response in the state object
                setAccountDetails({
                    ...accountDetails, data: res.data.accounts[0]
                })
                setValue("accountHolderName", res.data.accounts[0].accountHolderName)
                setValue("pan", res.data.accounts[0].pan)
                setValue("ifsc", res.data.accounts[0].ifsc)
                setValue("accountNumber", res.data.accounts[0].accountNumber)
                setValue("bankName", res.data.accounts[0].bankName)
                // 
                setBankNameDyn(res.data.accounts[0].bankName)
                setBankNameDefaultValue(true)
                setReEnter(false)
            }
        })
            .catch(error => {
                let errMsg = errorHandler(error);
                setErrorMsg({ state: true, msg: errMsg })
            });
    }
    const resetTransferTo = () => {
        setValue("transferTo", 'EXISTING')
    }

    const onSubmitForm = (data) => {
        console.log("request form data in connector: ", data)
        let formData = {
            "connectorId": connectorId,
            "requestedAmount": parseInt(data.requestedAmount),
            "accountHolderName": data.accountHolderName,
            "accountNumber": data.accountNumber,
            "ifsc": data.ifsc,
            "pan": data.pan,
            "gstin": data.gstin,
            "connectorRemarks": data.connectorRemarks,
        };
        if (bankNameDyn.length > 0) {
            formData.bankName = bankNameDyn;
        } else {
            formData.bankName = data.bankName;
        }

        // console.log("formData: ", formData);

        axios.post(`${ApiUrl()}advance/request/`, formData).then((res) => {
            reset();
            handleModal();
            setSuccessMsg({
                status: true,
                msg: 'Request Advance Created Successfully',
            })
        }).catch(error => {
            let errMsg = errorHandler(error);
            setErrorMsg1({ state: true, msg: errMsg })
        });
    }
    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={display}
                onClose={(e, r) => { handleModal(e, r) }}
                disableEscapeKeyDown
            // onBackdropClick="false"
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={(e) => {
                            reset(formValues => ({
                                ...formValues,
                                lastName: 'test',
                            }));
                            handleModal(e);
                            resetTransferTo()
                        }}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div className="text-center mt-3 dialog_header">Request Advance</div>
                </DialogTitle>
                <DialogContent sx={{ paddingTop: 0, marginTop: "10px" }}>
                    <form onSubmit={handleSubmit(onSubmitForm)}>
                        <div className="row form-fields mt-md-n5">
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <InputLabel >Requested Amount </InputLabel>
                                    <OutlinedInput
                                        className={classes.textfield}
                                        autoFocus
                                        margin="dense"
                                        id="requestedAmount"
                                        {...register("requestedAmount")}
                                        fullWidth
                                        variant="outlined"
                                    />
                                    {errors?.requestedAmount && <p className="error">{errors?.requestedAmount?.message}</p>}
                                </div>
                                <div className="col-md-6 mb-2">
                                    <InputLabel id="transferTo_label">Transfer to</InputLabel>
                                    <Select
                                        labelId="transferTo_label"
                                        className="outlined-input w-100"
                                        defaultValue="EXISTING"
                                        id="transferTo"
                                        {...register("transferTo", "NEW")}
                                    >
                                        <MenuItem value="NEW">New</MenuItem>
                                        <MenuItem value="EXISTING">Existing</MenuItem>
                                    </Select>
                                </div>


                                <div className="col-md-6 mb-2">
                                    <InputLabel>Account Holder Name</InputLabel>
                                    <OutlinedInput
                                        className={classes.textfield}
                                        autoFocus
                                        margin="dense"
                                        id="accountHolderName"
                                        {...register("accountHolderName")}
                                        // value={accountName && accountName}
                                        // onChange={(e) => setValue("accountHolderName", e.target.value)}
                                        fullWidth
                                        variant="outlined"
                                        disabled={!reEnter}
                                    />
                                    <p className="error">{errors?.accountHolderName?.message}</p>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <InputLabel>PAN Number</InputLabel>
                                    <OutlinedInput
                                        className={classes.textfield}
                                        autoFocus
                                        margin="dense"
                                        id="pan"
                                        {...register("pan")}
                                        fullWidth
                                        variant="outlined"
                                        disabled={!reEnter}
                                        inputProps={{ maxLength: 10 }}
                                    />
                                    {errors?.pan && <p className="error">{errors?.pan?.message}</p>}
                                </div>
                                <div className="col-md-6 mb-2">
                                    <InputLabel>Bank Account Number</InputLabel>
                                    <OutlinedInput
                                        className={classes.textfield}
                                        autoFocus
                                        margin="dense"
                                        id="accountNumber"
                                        {...register("accountNumber")}
                                        fullWidth
                                        variant="outlined"
                                        disabled={!reEnter}
                                    />
                                    <p className="error">{errors?.accountNumber?.message}</p>
                                </div>
                                {reEnter &&
                                    <div className="col-md-6 mb-2">
                                        <InputLabel>Re-enter Bank Account Number</InputLabel>
                                        <OutlinedInput
                                            className={classes.textfield}
                                            autoFocus
                                            margin="dense"
                                            // disabled={!reEnter}
                                            id="verifyAccountNumber"
                                            {...register("verifyAccountNumber")}
                                            fullWidth
                                            variant="outlined"
                                        />
                                        <p className="error">{errors?.verifyAccountNumber?.message}</p>
                                    </div>
                                }

                                <div className="col-md-6 mb-2">
                                    <InputLabel>GSTIN</InputLabel>
                                    <OutlinedInput
                                        className={classes.textfield}
                                        // defaultValue={formDataInEditMode?.gstin}
                                        autoFocus
                                        margin="dense"
                                        id="gstin"

                                        {...register("gstin")}
                                        fullWidth
                                        variant="outlined"
                                    />
                                    <p className="error">{errors?.gstin?.message}</p>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <InputLabel>IFSC Code</InputLabel>
                                    <OutlinedInput
                                        className={classes.textfield}
                                        // defaultValue={formDataInEditMode?.ifsc}
                                        autoFocus
                                        margin="dense"
                                        id="ifsc"
                                        {...register("ifsc")}
                                        fullWidth
                                        variant="outlined"
                                        disabled={!reEnter}
                                    />
                                    <p className="error">{errors?.ifsc?.message}</p>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <InputLabel >Bank Name</InputLabel>
                                    {bankNameDefaultValue ?
                                        <OutlinedInput
                                            className={classes.textfield}
                                            autoFocus
                                            margin="dense"
                                            id="bankNameDefaultValue"
                                            value={bankNameDyn}
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                        : <Autocomplete
                                            classes={classes}
                                            options={Object.keys(bankNameConstantsForAccount)}
                                            id="bankNameAccounts"
                                            disabled={!reEnter}
                                            renderInput={(params) =>
                                                <TextField
                                                    ref={bankNameRef}
                                                    {...register("bankName")}
                                                    {...params}
                                                    disabled={!reEnter}
                                                    error={errors?.bankName?.message.length > 0}
                                                    helperText={errors?.bankName?.message}
                                                />
                                            }
                                        />
                                    }
                                </div>
                                <div className="col-md-6 mb-2">
                                    <InputLabel>Remarks</InputLabel>
                                    <OutlinedInput
                                        className={classes.textfield}
                                        autoFocus
                                        margin="dense"
                                        id="connectorRemarks"
                                        {...register("connectorRemarks")}
                                        fullWidth
                                        variant="outlined"
                                    />
                                    <p className="error">{errors?.connectorRemarks?.message}</p>
                                </div>
                            </div>
                        </div>
                        <DialogActions className="justify-content-center">
                            <BootstrapButton aria-selected="true" className="w-auto px-4 pt-2" autoFocus type="submit">
                                Submit
                            </BootstrapButton>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
            {successMsg && <SuccessSnackbar successMsg={successMsg.msg} />}
            {errorMsg.state && <ErrorSnackbar errorMsg={errorMsg.msg} />}
            {errorMsg1.state && <ErrorSnackbar errorMsg={errorMsg1.msg} />}
        </>
    )
}