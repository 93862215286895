import { makeStyles } from "@material-ui/core/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useSelector } from "react-redux";
import Title from "./Title";
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
const useStyles = makeStyles((theme) => ({

  root: {
    // padding:'8px',
    [theme.breakpoints.only('xs')]: {
      padding: '8px',
    },
  }

}));

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders(props) {
  // const dispatch = useDispatch();

  const classes = useStyles();

  const { periodicProjectionsData, isLoading, successMsg, errorMsg } =
    useSelector(({ periodicProjectionService }) => periodicProjectionService);

  return (
    <React.Fragment>
      <Title>{props?.title}</Title>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 350 }}>
          <Table stickyHeader>
            <TableHead >
              <TableRow>
                {props?.data.map((x, idx) => (
                  <TableCell className={classes.root} key={idx}>
                    <b>{x}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {periodicProjectionsData?.map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell>{row.label}</TableCell>
                  <TableCell>{row.totalDisbursedAmount.cur()}</TableCell>
                  <TableCell>{row.totalConnectorPayout.cur()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
}
