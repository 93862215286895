import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { hslToRgb } from "@mui/material";
import { useSelector } from "react-redux";
import PageLoader from "../../components/PageLoader";

export default function Deposits({ name, amount, noOfLeads }) {
  const { isLoading } = useSelector(({ totalProjections }) => totalProjections);
  return (
    <>
      <div style={{ display: "flex" }}>
        <Typography component="p" variant="h3">
          {name}
        </Typography>
      </div>
      <div>
        <label>
          No. Leads  -
          <span style={{ marginLeft: 10 }}>{noOfLeads}</span>
        </label>
      </div>
      <div>
        <label>
          Amount  - 
          <span style={{ marginLeft: 10 }}>
            {amount.cur()}
          </span>
        </label>
      </div>

      {isLoading && <PageLoader />}
    </>
  );
}
