Array.prototype.chunk = function (size) {
    let len = this.length;
    let offset = 0, arr = [];
    while (offset < len) {
        console.log("offset", offset, "size", size);
        arr.push(this.slice(offset, offset+size));
        offset = offset + size ;
    }
    return arr;
}

Number.prototype.cur = function () {
    let amt = this;
    let cr = 10000000;
    let crd = amt >= cr;
    if (crd) {
        amt = amt / cr;
    }
    return new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
    }).format(amt) + (crd?" Cr":"");
}

String.prototype.cur = function () {
    return parseFloat(this).cur();
}