import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputLabel, OutlinedInput, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { ErrorSnackbar, SuccessSnackbar } from "../../components/CustomSnackBar";
import { userRoleConstants } from "../../constants/appconstants";
import { errorHandler } from "../../helpers/errorHandler";
import { ApiUrl } from "../../helpers/getUrl";
import { BootstrapButton } from "./BootstrapButton";

export const ConfirmationDialog = ({ open, setOpen, reqAmt, userRowObj }) => {
    const [successMsg, setSuccessMsg] = useState({
        status: false,
        msg: '',
    });
    const [approvedAmount, setApprovedAmount] = useState(null)
    const [errorAmount, setErrorAmount] = useState({ err: false, msg: "" })
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [remarks, setRemarks] = useState('')
    const userRole = JSON.parse(sessionStorage.getItem('userRole'));
    const { CONNECTOR } = userRoleConstants;
    let email = (JSON.parse(sessionStorage?.user))?.userEmail;
    const [errorMsg, setErrorMsg] = useState({ state: false, msg: null });
    const [errorMsg1, setErrorMsg1] = useState({ state: false, msg: null });
    let POST_URL = (userRole === CONNECTOR) ? 'advance/connector/decision' : 'advance/ceo/decision';

    useEffect(() => {
        if (userRole === CONNECTOR) {
            setButtonDisabled(false)
        }
    }, [userRole])

    const handleClose = () => {
        setOpen({ show: false, reason: null })
        setRemarks('')
        setApprovedAmount("")
        setErrorAmount({ err: false, msg: "" })
        setButtonDisabled(true)
    }
    const handleSetApprovedAmount = (e) => {
        setApprovedAmount(e.target.value)
        if (e.target.value === "") {
            setErrorAmount({ err: true, msg: "Approved Amount is required" })
            setButtonDisabled(true)
        } else if (e.target.value > reqAmt) {
            setErrorAmount({ err: true, msg: "Approved Amount Should not be greater than Request Amount" })
            setButtonDisabled(true)
        } else {
            setErrorAmount({ err: false, msg: "" })
            setButtonDisabled(false)
        }
    }

    const handleApproveReq = () => {
        // console.log("approved");
        let formData = {
            status: "APPROVED",
            transactionId: userRowObj.transactionId,
            createdBy: email
        }
        // set remarks field to formData
        if (userRole === CONNECTOR) {
            formData.connectorRemarks = remarks
        } else {
            formData.ceoRemarks = remarks;
            formData.approvedAmount = parseInt(approvedAmount);
        }

        // POST API FOR APPROVED
        axios.post(ApiUrl() + POST_URL, formData).then((res) => {
            // console.log("approved POST data: ", res);
            if (res.status === 200) {
                setApprovedAmount(0)
                handleClose()
                setSuccessMsg({
                    status: true,
                    msg: 'Acknowledged',
                })
            }
        }).catch(error => {
            let errMsg = errorHandler(error);
            setErrorMsg({ state: true, msg: errMsg })
        });
    }
    const handleRejectReq = () => {
        // console.log("rejected");
        let formData = {
            status: "REJECTED",
            transactionId: userRowObj.transactionId,
            createdBy: email
        }

        // set remarks field to formData
        if (userRole === CONNECTOR) {
            formData.connectorRemarks = remarks
        } else {
            formData.ceoRemarks = remarks
        }

        console.log(formData);
        // POST API FOR REJECTED
        axios.post(ApiUrl() + POST_URL, formData).then((res) => {
            // console.log("approved POST data: ", res);
            if (res.status === 200) {
                setRemarks("")
                handleClose()
            }
        }).catch(error => {
            let errMsg = errorHandler(error);
            setErrorMsg1({ state: true, msg: errMsg })
        });
    }
    return (
        <>
            {/* <Alert severity="error">This is an error alert — check it out!</Alert> */}
            <Dialog
                open={open.show}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div className="text-center mt-3 dialog_header">Acknowledgement</div>
                </DialogTitle>
                <DialogContent className="text-center mt-3">
                    {open.reason === "Accept" ?
                        <>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div className="row form-fields mx-0 px-0">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <TextField
                                                id="requestedAmount"
                                                label="Requested Amount"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="standard"
                                                defaultValue={reqAmt || ""}
                                                className="w-100 outlined-input mt-2"
                                                readOnly
                                                disabled />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                id="approvedAmount"
                                                label="Approved Amount"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="standard"
                                                className="w-100 outlined-input mt-2"
                                                defaultValue={userRowObj.approvedAmount || approvedAmount}
                                                readOnly
                                                disabled={userRole === CONNECTOR}
                                                // value={approvedAmount}
                                                onChange={(e) => handleSetApprovedAmount(e)}
                                                error={errorAmount.err}
                                                helperText={errorAmount.msg}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Box>
                            <p className="mt-3 mb-0">
                                Are you sure you want to Accept the advance?
                            </p>
                        </>
                        : <div>
                            Are you sure you want to <span className="text-danger">Reject</span> the advance?<br />
                            <InputLabel htmlFor="component-disabled" className="mt-3" style={{ textAlign: 'left' }}>Remarks</InputLabel>
                            <OutlinedInput
                                id="outlined-basic"
                                multiline
                                maxRows={4}
                                label="Outlined"
                                variant="standard"
                                className="w-100 outlined-input mt-2"
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                            />
                        </div>}
                </DialogContent>
                <DialogActions className="justify-content-center mb-5">

                    <BootstrapButton
                        aria-selected="true"
                        className="w-auto px-5 py-2 border-0"
                        autoFocus
                        variant="contained"
                        disabled={open.reason === "Accept" ? buttonDisabled : false}
                        onClick={open.reason === "Accept" ? handleApproveReq : handleRejectReq}>
                        Yes
                    </BootstrapButton>
                    <BootstrapButton aria-selected="false" className="w-auto px-5 py-2" onClick={handleClose}>
                        No
                    </BootstrapButton>
                </DialogActions>
            </Dialog>
            {successMsg && <SuccessSnackbar successMsg={successMsg.msg} />}
            {errorMsg.state && <ErrorSnackbar errorMsg={errorMsg.msg} />}
            {errorMsg1.state && <ErrorSnackbar errorMsg={errorMsg1.msg} />}
        </>


    )
}