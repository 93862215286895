import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import * as React from "react";
// import Chart from './Chart';
import ChartsSection from "./chartsSection";
import TopButtons from "./topButtons.js";
import TransactionSection from "./TransactionSections";


function FinAdmin() {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} className="sidenav-content">
      <Grid container spacing={3} className="row m-0 sidenav-info">
        {/* Buttons */}
        <TopButtons />
        <TransactionSection />
        <ChartsSection />
      </Grid>
    </Container>
  );
}

export default FinAdmin;
