import AdjustIcon from '@mui/icons-material/Adjust';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Chip, CircularProgress, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import axios from 'axios';
// import moment from 'moment';
import { useEffect, useState } from 'react';
import { ErrorSnackbar } from '../../../../components/CustomSnackBar';
import { bankCodeConstantsForAccount, productConstants } from '../../../../constants/appconstants';
import { spotPaymentStatus, statusColors } from '../../../../helpers/constants';
import { errorHandler } from '../../../../helpers/errorHandler';
import { ApiUrl } from '../../../../helpers/getUrl';
import { RequestSpotPayoutForm } from './RequestSpotPayoutForm';

export const SpotPaymentGrid = ({ showProcessedPayoutStatus, showOnlySpot }) => {
    let localData = JSON.parse(sessionStorage.getItem("data"));
    const { connectorId } = localData || {};
    // const today = moment(new Date()).format("YYYY-MM-DD");
    const [display, setDisplay] = useState({
        show: false,
        data: [],
    });
    const [data, setData] = useState(null);
    // const [startDate, setStartDate] = useState(today);
    // const [endDate, setEndDate] = useState(today);
    const [searchAmount, setSearchAmount] = useState("");
    // const [transactionType, setTransactionType] = useState("RECEIPT");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [count, setCount] = useState(0);
    const [errorMsg, setErrorMsg] = useState({ state: false, msg: null });
    const { SUCCESS, PENDING, ERROR } = statusColors;

    const handleModal = () => {
        setDisplay({
            show: false,
            data: [],
        });
    };

    useEffect(() => {
        getData();
    }, [rowsPerPage, page]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    function getData() {
        const formData = {
            limit: rowsPerPage,
            orderBy: "createdOn",
            orderDirection: "DESC",
            page: page + 1,
            search: {
                // dateRange: {
                //     startDate: startDate + "T00:00:00.000Z",
                //     endDate: endDate + "T23:59:59.000Z",
                // },
                // connectorPayoutOption: "MONTHLY"
            },
        };
        if (showProcessedPayoutStatus === true) formData.search.connectorPayoutStatus = "completed";
        if (showOnlySpot === true) formData.search.connectorPayoutOption = "SPOT";
        if (searchAmount !== null && searchAmount !== '') formData.search.approvedAmount = parseInt(searchAmount)
        axios
            .post(ApiUrl() + `leads/${connectorId}`, formData)
            .then((res) => {
                // console.log("spot list in connector: ", res.data.data);
                if (res.status === 200) {
                    setData(res.data.data);
                    setCount(res.data.count)
                }
            }).catch(error => {
                let errMsg = errorHandler(error);
                setErrorMsg({ state: true, msg: errMsg })
            });
    }
    const getPaymentStatus = (status) => {
        var result = spotPaymentStatus.filter(obj => {
            return obj.status === status
        })
        // console.log(result);
        return (
            <Chip
                label={result[0].status && result[0].label}
                variant="outlined"
                icon=
                {<AdjustIcon
                    style={result[0].color === 'success' ? SUCCESS
                        : (result[0].color === 'pending') ? PENDING : ERROR} />}
            />
        );
    }
    return (
        <>
            {/* <Grid pt={3} container spacing={2}>
                <Grid item xs={12} lg={3}>
                    <InputLabel htmlFor="component-disabled">Transaction type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="w-100 outlined-input"
                        value={transactionType}
                        onChange={(e) => setTransactionType(e.target.value)}
                    >
                        <MenuItem value="RECEIPT">Receipt</MenuItem>
                        <MenuItem value="PAYMENT">Payment</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <InputLabel htmlFor="component-disabled">Amount</InputLabel>
                    <OutlinedInput
                        id="outlined-basic"
                        label="Outlined"
                        variant="standard"
                        className="w-100 outlined-input"
                        value={searchAmount}
                        onChange={(e) => setSearchAmount(e.target.value)}
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <InputLabel htmlFor="component-disabled">From</InputLabel>
                    <OutlinedInput
                        id="outlined-basic"
                        label="Outlined"
                        type="date"
                        variant="standard"
                        aria-label="From"
                        className="outlined-input w-100"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <InputLabel htmlFor="component-disabled">To</InputLabel>
                    <OutlinedInput
                        id="outlined-basic"
                        label="Outlined"
                        type="date"
                        variant="standard"
                        className="outlined-input w-100"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} lg={2} container style={{ maxWidth: "100%" }}>
                    <BootstrapButton
                        mr={2}
                        aria-selected={"true"}
                        style={{ borderRadius: "12px", marginTop: "16px", width: "100%" }}
                        onClick={getData}
                    >
                        Search
                    </BootstrapButton>
                </Grid>
            </Grid> */}
            <Grid container paddingBottom={4}>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Customer Name</TableCell>
                                    <TableCell align="center">Product type</TableCell>
                                    <TableCell align="center">Application Number</TableCell>
                                    <TableCell align="center">Bank Name</TableCell>
                                    <TableCell align="center">Remarks</TableCell>
                                    <TableCell align="center">Payout  Amt</TableCell>
                                    <TableCell align="center">Loan Amt</TableCell>
                                    <TableCell align="center">Payout Status</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!data ?
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <CircularProgress />
                                            </Box>
                                        </TableCell>
                                    </TableRow> : null}
                                {data?.map((row, idx) => (
                                    <TableRow
                                        key={idx}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.customerName || "-"}
                                        </TableCell>
                                        <TableCell align="center">{productConstants[row.productType || "-"]}</TableCell>
                                        <TableCell align="center">{row.applicationNumber || "-"}</TableCell>
                                        <TableCell align="center">{bankCodeConstantsForAccount[row.loanBank] || "-"}</TableCell>
                                        <TableCell align="center">{row.remarks || "-"}</TableCell>
                                        <TableCell align="center">{row.connectorPayoutAmount.cur()}</TableCell>
                                        <TableCell align="center">{row.loanAmount.cur()}</TableCell>
                                        <TableCell align="center">
                                            {getPaymentStatus(row.connectorPayoutStatus)}
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() =>
                                                    setDisplay({
                                                        show: true,
                                                        data: row,
                                                    })
                                                }
                                            >
                                                <VisibilityOutlinedIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {data && data.length === 0 && (
                                    <TableRow>
                                        <TableCell align="center" colSpan={8}>
                                            No data found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        component="div"
                        className='custom_pagination'
                        count={count || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>
            <RequestSpotPayoutForm display={display} handleModal={handleModal} />
            {errorMsg.state && <ErrorSnackbar errorMsg={errorMsg.msg} />}
        </>
    )
}