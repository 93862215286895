import AddIcon from "@mui/icons-material/Add";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  ErrorSnackbar,
  SuccessSnackbar,
} from "../../components/CustomSnackBar";
import { ApiUrl } from "../../helpers/getUrl";
import AddExpense from "./AddExpense";
import DataTable from "./DataTable";
import BulkUpload from "./bulkUpload";

const ExpenseIndex = () => {
  const handleAddExpense = () => {
    setAddExpense(!addExpense);
  };

  const [value, setValue] = useState(moment(new Date()));
  const [addExpense, setAddExpense] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [yearWiseData, setYearWiseData] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  useEffect(() => {
    getData();
  }, [value, addExpense, loadData]);

  const getData = () => {
    const year = value.year();
    axios
      .get(ApiUrl() + `expense/calendar?year=${year}`)
      .then((response) => {
        setYearWiseData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (successMsg || errorMsg) {
      setTimeout(() => {
        setSuccessMsg(null);
        setErrorMsg(null);
      }, 3000);
    }
  }, [successMsg, errorMsg]);

  return (
    <>
      {errorMsg && <ErrorSnackbar errorMsg={errorMsg} />}
      {successMsg && <SuccessSnackbar successMsg={successMsg} />}
      <div className="sidenav-content">
        <div className="row my-2">
          <div className="col-md-4">
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              className="leads-heading"
            >
              Expense Data
            </Typography>
          </div>
          <div className="col-md-8">
            <Stack spacing={4} direction="row-reverse" alignItems="flex-start">
              <BulkUpload refreshTable={getData} />
              <a
                href="/TemplateExcel/StarPowersExpenseTemplate.xlsx"
                style={{ color: "#dadbde" }}
                className="mx-2"
                download
              >
                <Button
                  variant="contained"
                  startIcon={<GetAppIcon />}
                  // className={classes.leadButton}
                >
                  Download Template
                </Button>
              </a>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddExpense}
                className="mx-2"
              >
                Add Expense
              </Button>

              <FormControl>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    views={["year"]}
                    label=""
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        style={{ marginTop: "-20px" }}
                        className="mx-0 mr-0"
                        {...params}
                        helperText={null}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </Stack>
          </div>
        </div>
        <DataTable
          yearWiseData={yearWiseData}
          loadData={loadData}
          setLoadData={setLoadData}
        />
        <AddExpense
          addExpense={addExpense}
          setAddExpense={setAddExpense}
          setSuccessMsg={setSuccessMsg}
          setErrorMsg={setErrorMsg}
        />
      </div>
    </>
  );
};
export default ExpenseIndex;
