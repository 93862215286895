import { useState } from "react";
import { KPI } from "./Kpis";
import { PayoutGrid } from "./PayoutGrid";



export const PayoutDetails = () => {

    const [addAdvance, setAddAdvance] = useState(false);

    const handleModal = () => {
        setAddAdvance(!addAdvance)
    }

    return (
        <div className="mt-4 px-4">
            <h4>Payout Details</h4>
            <KPI />
            <PayoutGrid />
        </div>
    )
}