 import React from "react";
import {
  Redirect, Route, Switch
} from "react-router-dom";
// import Dashboard from "./dashboard/Dashboard.js";
import AppWrapper from "./components/AppWrapper";
import NotFound from "./components/NotFoundPage/404-page";
import { userRoleConstants } from "./constants/appconstants";
import AdminHome from "./features/AdminHome";
import CEOHome from './features/CEOHome';
import Connector from "./features/connector";
// Pages
import Dashboard from "./features/dashboard";
import FinAdmin from './features/finManager/finManagerIndex';
import Leads from "./features/leads";
import AdminLeads from "./features/leads/adminIndex";
import LoanEnquiry from "./features/LoanEnquiry";
import PaymentApproval from './features/paymentApproval/index';
import RedemptionMangement from './features/RedemptionManagement';
import Reports from "./features/Reports";
import Settings from "./features/settings";
import TestPage from "./features/TestPage";
import useAppContext from "./utils/useAppContext";
import Notification from "./features/notifications";
import CompletedLeads from "./features/leads/CompletedLeads";
import ApproveConnectorIndex from "./features/leads/ApproveConnectorIndex";
import HelpIndex from "./features/Help";
import ExpenseIndex from "./features/ExpenseManagement/ExpenseIndex";
import ExpenseApproval from "./features/ExpenseManagement/ExpenseApproval";
import BankWiseOffersIndex from "./features/BankWiseOffers/BankWiseOffersIndex";
import PLReport from "./features/Reports/PLReport";
import RoboReport from "./features/Reports/RoboReport";
import ReportIndex from "./features/Reports/RoboReport/ReportIndex";
import { AdvanceDashboard } from "./features/Advances/Connector";
import { AdvanceApprovalDashboard } from "./features/Advances/Ceo";
import { AdvancePaymentApproval } from "./features/Advances/Admin";
import { AdvanceHistory } from "./features/Advances/Admin/AdvanceHistory";
import { PayoutDetails } from "./features/Advances/Admin/PayoutDetails";
import ConnectorManagerLeads from "./features/leads/ConnectorManagerLead";
import MasterReport from "./features/Reports/MasterReport";
import ConnectorReport from "./features/Reports/ConnectorReport";
import DigitalApplDashboard from "./features/connector/DigitalApplications";
import DigitalApplDashboardAdmin from "./features/DigitalApplications";



export default function Routes({ match }) {
  let authRoutes = null;
  let redirectUrl = null;
  const { user } = useAppContext();
  // const user = 'FIN_MANAGER';
  const { CONNECTOR, CEO, FIN_ADMIN, FIN_MANAGER, CONN_MANAGER, BRANCH_MANAGER } = userRoleConstants;
  console.log(user);

  if (user === CONNECTOR) {
    authRoutes = (
      <>
        <Route path={`${match.url}/home`} component={Dashboard} />
        <Route path={`${match.url}/leads`} component={Leads} />
        <Route path={`${match.url}/test`} component={TestPage} />
        <Route path={`${match.url}/help`} component={HelpIndex} />
        <Route path={`${match.url}/digital-applications`} component={DigitalApplDashboard} />
        <Route path={`${match.url}/redemption-management`} component={RedemptionMangement} />
        <Route path={`${match.url}/loan-enquiry`} component={LoanEnquiry} />
        <Route path={`${match.url}/bank-wise-offer`} component={BankWiseOffersIndex} />
        <Route path={`${match.url}/advance`} component={AdvanceDashboard} />
      </>
    );
  } else if (user === FIN_ADMIN) {
    authRoutes = (
      <>
        <Route path={`${match.url}/admin-home`} component={AdminHome} />
        <Route path={`${match.url}/admin-leads`} component={AdminLeads} />
        <Route
          path={`${match.url}/admin-loan-enquiry`}
          component={LoanEnquiry}
        />
        <Route path={`${match.url}/test`} component={TestPage} />
        <Route path={`${match.url}/admin-add-DigiRep`} component={Connector} />
        <Route path={`${match.url}/admin-settings`} component={Settings} />
        <Route path={`${match.url}/notification`} component={Notification} />
        <Route
          path={`${match.url}/approve-DigiRep`}
          component={ApproveConnectorIndex}
        />
        <Route path={`${match.url}/help`} component={HelpIndex} />
        <Route path={`${match.url}/manage-expense`} component={ExpenseIndex} />
        <Route
          path={`${match.url}/bank-wise-offer`}
          component={BankWiseOffersIndex}
        />
        <Route path={`${match.url}/hdfc-report`} component={RoboReport} />
        {/* <Route
          path={`${match.url}/advance-payment-approval`}
          component={AdvancePaymentApproval}
        /> */}
        <Route
          path={`${match.url}/advance-payment-history`}
          component={AdvanceHistory}
        />
        <Route path={`${match.url}/payout-details`} component={PayoutDetails} />
        <Route
          path={`${match.url}/digital-applications`}
          component={DigitalApplDashboardAdmin}
        />
      </>
    );
  } else if (user === "ADMINCEO") {
    authRoutes = (
      <>
        <Route path={`${match.url}/admin-home`} component={AdminHome} />
        <Route path={`${match.url}/help`} component={HelpIndex} />
      </>
    );
  } else if (user === 'CEO') {
    authRoutes = (
      <>
        <Route path={`${match.url}/ceo-home`} component={CEOHome} />
        <Route
          path={`${match.url}/ceo-masterReports`}
          component={MasterReport}
        />
        <Route
          path={`${match.url}/ceo-digiRepReports`}
          component={ConnectorReport}
        />
        <Route path={`${match.url}/ceo-expenses`} component={ExpenseApproval} />
        <Route
          path={`${match.url}/bank-wise-offer`}
          component={BankWiseOffersIndex}
        />
        <Route path={`${match.url}/notification`} component={Notification} />
        <Route path={`${match.url}/help`} component={HelpIndex} />
        <Route path={`${match.url}/pl-report`} component={PLReport} />
        <Route path={`${match.url}/hdfc-report`} component={ReportIndex} />
        {/* <Route
          path={`${match.url}/advance-approval`}
          component={AdvanceApprovalDashboard}
        /> */}
        <Route
          path={`${match.url}/digital-applications`}
          component={DigitalApplDashboardAdmin}
        />
      </>
    );
  } else if (user === 'FIN_MANAGER') {
    authRoutes = (
      <>
        <Route path={`${match.url}/home`} component={FinAdmin} />
        <Route path={`${match.url}/leads`} component={AdminLeads} />
        <Route path={`${match.url}/expenses`} component={ExpenseApproval} />
        <Route
          path={`${match.url}/completed-payments`}
          component={CompletedLeads}
        />
        <Route
          path={`${match.url}/payment-approval`}
          component={PaymentApproval}
        />
        <Route
          path={`${match.url}/admin-loan-enquiry`}
          component={LoanEnquiry}
        />
        <Route path={`${match.url}/admin-add-DigiRep`} component={Connector} />
        <Route
          path={`${match.url}/bank-wise-offer`}
          component={BankWiseOffersIndex}
        />
        <Route path={`${match.url}/admin-settings`} component={Settings} />
        <Route path={`${match.url}/notification`} component={Notification} />
        <Route path={`${match.url}/help`} component={HelpIndex} />
        <Route path={`${match.url}/pl-report`} component={PLReport} />
        <Route path={`${match.url}/hdfc-report`} component={ReportIndex} />
        <Route
          path={`${match.url}/digital-applications`}
          component={DigitalApplDashboardAdmin}
        />
      </>
    );
  } else if (user === CONN_MANAGER || user === BRANCH_MANAGER) {
    authRoutes = (
      <>
        <Route path={`${match.url}/leads`} component={ConnectorManagerLeads} />
        <Route path={`${match.url}/add-DigiRep`} component={Connector} />
      </>
    );
  }

  if (user === FIN_ADMIN) {
    redirectUrl = (
      <>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/admin-home`} />
      </>
    );
  } else if (user === CONNECTOR) {
    redirectUrl = (
      <>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/home`} />
      </>
    );
  }

  return (
    <AppWrapper>
      <Switch>
        {/* {redirectUrl} */}
        {authRoutes}
        <Route path="*" component={NotFound} />
      </Switch>
    </AppWrapper>
  );
}
