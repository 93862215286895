import { createSlice } from "@reduxjs/toolkit";
import { adminAdvanceRequest } from "../AsyncThunk/admin.asyncThunk";

const initialState = {
    adminRequestData: [],
    status: null,
    error: null,
};

export const AdminRequestSlice = createSlice({
    name: "adminRequest",
    initialState,
    reducers: {
        requestPost: (state, action) => {
            state.adminRequestData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(adminAdvanceRequest.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(adminAdvanceRequest.fulfilled, (state, action) => {
            state.status = 'success';
            state.adminRequestData = [action.payload];
        });
        builder.addCase(adminAdvanceRequest.rejected, (state, action) => {
            state.error = action.payload;
        });
    },
});

export const { requestPost } = AdminRequestSlice.actions;

export default AdminRequestSlice.reducer;
