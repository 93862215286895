import axios from "axios";
import React, { useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ApiUrl } from "../../../helpers/getUrl";
import useAppContext from "../../../utils/useAppContext";

function OverViewChart() {
    const { freqValue, productType, connectorId } = useAppContext();
    
    const [loansDisbursed, setLoansDisbursed] = useState(0);
    const [paymentsProcessed, setPaymentsProcessed] = useState(0);
    const [activeConnectors, setActiveConnectors] = useState(0);

    useEffect(() => {
        getCeoCount();
    }, [productType, freqValue]);

    const getCeoCount = () => {
        axios.get(`${ApiUrl()}projection/percentage/ceo?product=${productType}&timeframe=${freqValue}`)
            .then(res => {
                const result = res.data;
                setLoansDisbursed(Math.round((result.totalDisbursedLeads / result.totalLeads) * 100));
                setPaymentsProcessed(Math.round((result.totalPayoutProcessed / (result.totalPayoutProcessed + result.totalPayoutNotProcessed)) * 100));
                setActiveConnectors(Math.round((result.totalConnectorsWithLeads / result.totalConnectors) * 100));
            }).catch(error => {
                console.error('There was an error!', error);
            });
    }
    return (
        <div className="over-charts row no-gutters">
            <div className="col-4">
                <h5>Lead Status</h5>
                <CircularProgressbar value={loansDisbursed} text={`${loansDisbursed}%`} styles={buildStyles({
                    pathColor: `#5FC9A5`,
                    textColor: '#000',
                })} />
                <h6><span style={{ height: '10px', width: '10px', backgroundColor: 'black' }}></span>Loans Disbursed</h6>
            </div>
            <div className="col-4">
                <h5>Payment Status</h5>
                <CircularProgressbar value={paymentsProcessed} text={`${paymentsProcessed}%`} styles={buildStyles({
                    pathColor: `#6AC8FE`,
                    textColor: '#000',
                })} />
                <h6>Payments Processed</h6>
            </div>
            <div className="col-4">
                <h5>Connector Status</h5>
                <CircularProgressbar value={activeConnectors} text={`${activeConnectors}%`} styles={buildStyles({
                    pathColor: `#ED8CFF`,
                    textColor: '#000',
                })} />
                <h6>Active Connectors</h6>
            </div>
        </div>
    );
}
export default OverViewChart;
