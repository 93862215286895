import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import NotificationAddOutlinedIcon from "@mui/icons-material/NotificationAddOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import { FileCopyOutlined, KeyboardArrowDown } from "@mui/icons-material";
import SubCategoryList from "./SubCategoryList";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

const active = {
  background: "white",
  borderRadius: "5px",
  borderRight: 6,
  borderColor: "#40c4ff",
  "&:hover": {
    background: "white",
  },
};
const nonActive = { borderRadius: "5px", color: "white" };

export default function AdminCEOList({ handleDrawerToggle }) {
  let location = useLocation();
  const { pathname } = location;
  const digirepCategory = [
    {
      icon: <PersonAddOutlinedIcon />,
      label: "Add DigiRep",
      path: "admin-add-DigiRep",
    },
    {
      icon: <PaymentOutlinedIcon />,
      label: "Completed Payments",
      path: "completed-payments",
    },
    {
      icon: <AccountBalanceWalletOutlinedIcon />,
      label: "Payment Approval",
      path: "payment-approval",
    },
  ];
  const accountCategory = [
    { icon: <CreditScoreIcon />, label: "Manage Expenses", path: "expenses" },
    {
      icon: <AssessmentOutlinedIcon />,
      label: "P & L Report",
      path: "pl-report",
    },
  ];

  const enquiryCategory = [
    {
      icon: <FactCheckOutlinedIcon />,
      label: "Loan Enquiry",
      path: "admin-loan-enquiry",
    },
    {
      icon: <AccountBalanceOutlinedIcon />,
      label: "Bank Wise Offer",
      path: "bank-wise-offer",
    },
    { icon: <FileCopyOutlined />, label: "HDFC Report", path: "hdfc-report" },
    { icon: <HelpOutlineIcon />, label: "Help", path: "help" },
  ];
  const categoryList = [
    {
      label: "DigiRep",
      icon: <SupervisorAccountIcon />,
      category: digirepCategory,
    },
    {
      label: "Accounts",
      icon: <AccountBalanceOutlinedIcon />,
      category: accountCategory,
    },
    {
      label: "Enquiry",
      icon: <DriveFileRenameOutlineIcon />,
      category: enquiryCategory,
    },
  ];

  return (
    <div>
      <List sx={{ paddingLeft: "0px" }}>
        <ListItem
          component={({ innerRef, ...props }) => <Link {...props} to="home" />}
        >
          <ListItemButton
            sx={
              pathname === "/finmanager/home" || pathname === "/finmanager/"
                ? active
                : nonActive
            }
          >
            <ListItemIcon
              sx={
                pathname === "/finmanager/home" || pathname === "/finmanager/"
                  ? {}
                  : { color: "white" }
              }
              onClick={handleDrawerToggle}
            >
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>

        <ListItem
          component={({ innerRef, ...props }) => <Link {...props} to="leads" />}
        >
          <ListItemButton
            sx={pathname === "/finmanager/leads" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={pathname === "/finmanager/leads" ? {} : { color: "white" }}
            >
              <PersonOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Leads" />
          </ListItemButton>
        </ListItem>
        {categoryList.map((item) => (
          <SubCategoryList
            item={item}
            innerPath="finmanager"
            handleDrawerToggle={handleDrawerToggle}
          />
        ))}

        <ListItem
          component={({ innerRef, ...props }) => (
            <>
              <Link {...props} to="digital-applications" />
            </>
          )}
        >
          <ListItemButton
            sx={
              pathname === "/finmanager/digital-applications"
                ? active
                : nonActive
            }
          >
            <ListItemIcon
              sx={
                pathname === "/finmanager/digital-applications"
                  ? {}
                  : { color: "white" }
              }
            >
              <SwapHorizIcon />
            </ListItemIcon>
            <ListItemText primary="Digital Applications" />
          </ListItemButton>
        </ListItem>

        {/* <ListItem
        component={({ innerRef, ...props }) => (
          <Link {...props} to="admin-settings" />
        )}
      >
        <ListItemButton
          sx={pathname === "/finmanager/admin-settings" ? active : nonActive}
        >
          <ListItemIcon
            sx={
              pathname === "/finmanager/admin-settings" ? {} : { color: "white" }
            }
          >
            <SettingsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItemButton>
      </ListItem> */}

        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="notification" />
          )}
        >
          <ListItemButton
            sx={pathname === "/finmanager/notification" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={
                pathname === "/finmanager/notification"
                  ? {}
                  : { color: "white" }
              }
            >
              <NotificationAddOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
}
