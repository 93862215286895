import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import AppContext from "../../../components/contextProvider/AppContextProvider/AppContext";
import { productOrder } from "../../../constants/appconstants";
import { fetchProductProjections } from "../../../redux/reducers/ProductProjection";

function Chart2() {
  const dispatch = useDispatch();

  const { freqValue, productType } = useContext(AppContext);
  const data = JSON.parse(sessionStorage.getItem('data'));
  const connectorId = data?.connectorId;

  const { productProjectionsData, isLoading, successMsg, errorMsg } =
    useSelector(({ productProjectionService }) => productProjectionService);

  useEffect(() => {
    dispatch(fetchProductProjections(connectorId, productType, freqValue)); //Invoke Action
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freqValue, productType, connectorId]);

  const data2 = [];
  // { name: "CC", submitted: 4000, processed: 2400 },

  const dummy = productProjectionsData.map((x) => {
    let temp = {};
    temp.name = x.label
    temp.Submitted = x.totalApplications
    temp.Processed = x.totalDisbursedApplications
    temp.index = productOrder[x.label]
    data2.push(temp);
  })

  return (
    <ResponsiveContainer>
      <BarChart
        data={data2.sort((a, b) => (a.index > b.index) ? 1 : -1)}
        margin={{ top: 30, bottom: 30, left: 15, right: 15 }}
      >
        <CartesianGrid />
        <XAxis dataKey="name" allowDecimals={false} domain={['', '']} />
        <YAxis allowDecimals={false} />
        <Tooltip />
        <Legend />
        <Bar barSize={15} dataKey="Submitted" fill="#EF96B4" />
        <Bar barSize={15} dataKey="Processed" fill="#68CAFB" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default Chart2;
