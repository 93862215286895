import CloseIcon from "@mui/icons-material/Close";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { FormLabel } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import axios from "axios";
import PropTypes from "prop-types";
import { default as React, useState } from "react";
import {
  ErrorSnackbar,
  SuccessSnackbar,
} from "../../components/CustomSnackBar";
import PageLoader from "../../components/PageLoader";
import { ApiUrl } from "../../helpers/getUrl";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function BulkUpload({ type, disabled, refreshTable }) {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
    setIsFilePicked(false);
  };

  const changeHandler = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setIsFilePicked(true);
  };

  const handleSubmission = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    const { userId } = JSON.parse(sessionStorage.getItem("user"));

    axios
      .post(`${ApiUrl()}expense/bulk/${userId}`, formData)
      .then((res) => {
        setSuccessMessage("Expense data updated successfully");
        refreshTable();
        setSelectedFile(null);
        setIsFilePicked(false);
        setLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        let errorMessage =
          error.response.data.validationMessage || "Something went wrong";
        setOpen(false);
        setSelectedFile(null);
        setIsFilePicked(false);
        setLoading(false);
        setErrorMsg(errorMessage);
      });
    setTimeout(() => {
      setSuccessMessage(null);
    }, 5000);
  };

  return (
    <div>
      <Button
        sx={{ boxShadow: 2 }}
        variant="text"
        endIcon={<FileUploadOutlinedIcon />}
        onClick={handleClickOpen}
      >
        Bulk Upload
      </Button>
      <BootstrapDialog
        fullWidth={true}
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Bulk Upload
        </BootstrapDialogTitle>
        <form>
          <DialogContent dividers>
            <FormControl>
              <FormLabel>Bulk Upload For Expense Details</FormLabel>
              {isFilePicked && (
                <Typography variant="h6" gutterBottom component="div">
                  {selectedFile?.name}
                </Typography>
              )}
              <label htmlFor="contained-button-file">
                <input
                  style={{ display: "none" }}
                  id="contained-button-file"
                  type="file"
                  onChange={changeHandler}
                />
                <Button variant="outlined" component="span">
                  choose file here
                </Button>
              </label>
            </FormControl>
          </DialogContent>
        </form>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleSubmission}
            disabled={!isFilePicked || loading}
          >
            <FileUploadOutlinedIcon />
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {loading && <PageLoader />}
      {errorMsg && <ErrorSnackbar errorMsg={errorMsg} />}
      {successMessage && <SuccessSnackbar successMsg={successMessage} />}
    </div>
  );
}
