import Delete from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppContextProvider from "../../components/contextProvider/AppContextProvider/AppContext";
import {
  loanStatusConstants,
  userRoleConstants
} from "../../constants/appconstants";
import { getAccountsByConnector } from "../../redux/reducers/GetLeadsDataService";
import useAppContext from "../../utils/useAppContext";
import "../../helpers";

export default function BanksList({ handleEditClick, handleDeleteClick }) {
  let auth = useAppContext();
  const dispatch = useDispatch();
  const { connectorId } = useContext(AppContextProvider);
  const { CONNECTOR } = userRoleConstants;
  const { APPLIED, OPEN } = loanStatusConstants;

  const { accountsData, isLoading } = useSelector(
    ({ getLeadsDataService }) => getLeadsDataService
  );

  const { accounts: data = [], count = 0 } = accountsData;

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);

  useEffect(() => {
    dispatch(getAccountsByConnector(sessionStorage.userId)); // sessionStorage.userId
  }, []);


  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectedIds, setSelectedIds] = React.useState([]);

  const handleCancel = () => {
    setSelectedIds([]);
  };

  let columns = [
    { headerName: "Account Holder", field: "accountHolderName", width: 200 },
    { headerName: "PAN Number", field: "pan", width: 135 },
    { headerName: "Account Number", field: "accountNumber", width: 200 },
    { headerName: "Bank Name", field: "bankName", width: 200 },
    { headerName: "IFSC Code", field: "ifsc", width: 135 },
    { headerName: "Gross Payable", field: "amount", width: 135 },
    { headerName: "Status", field: "accountStatus", width: 100 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            disabled={row?.isDefault === true}
            label="Edit"
            onClick={() => {
              handleEditClick(row);
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Delete />}
            disabled={row?.isDefault === true}
            label="Edit"
            onClick={() => {
              handleDeleteClick(row);
            }}
            color="inherit"
          />
        ];
      },
    }
  ];

  return (
    <div className="row">
      <div
        style={{ height: '70vh', maxWidth: "100%" }}
        className="col-md-12 leads-table"
      >
        <DataGrid
          rows={data}
          getRowId={(e) => e._id}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          rowCount={data.length}
          height
          checkboxSelection={auth.user === CONNECTOR ? false : true}
          // isCellEditable={true}
          // onRowEditStart={()=>{}}
          rowHeight={40}
          isRowSelectable={false}
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = data.filter((row) =>
              selectedIDs.has(row._id)
            );
            setSelectedRows(selectedRowData);
            setSelectedIds([...selectedIDs]);
            console.log("ids", [selectedIDs]);
            console.log("onSelectionModelChange:", selectedRowData);
          }}
          selectionModel={selectedIds}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => {
            setPage(0);
            setPageSize(newPageSize);
          }}
          loading={isLoading}
          disableSelectionOnClick
        />
        {/* {auth.user !== CONNECTOR ? (
          <div className="col-md-12 my-3 pb-3">
            <Stack spacing={2} direction="row-reverse">
              <Button
                variant="contained"
                onClick={handleSubmitAll}
                disabled={selectedRows.length < 1 ? true : false}
              // className={classes.leadButton}
              >
                Submit for Approval
              </Button>

              <Button
                sx={{ boxShadow: 2 }}
                variant="text"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Stack>
          </div>
        ) : null} */}
      </div>
    </div>
  );
}
