import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { BootstrapButton } from "../../BootstrapButton";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import moment from "moment";
import axios from "axios";
import { ApiUrl } from "../../../../helpers/getUrl";
import { bankCodeConstantsForAccount, loginStatusConstants, productConstants } from "../../../../constants/appconstants";
import { ErrorSnackbar, SuccessSnackbar } from "../../../../components/CustomSnackBar";
import { errorHandler } from "../../../../helpers/errorHandler";

const useYupValidationResolver = (validationSchema) =>
    useCallback(
        async (data) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors, currentError) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

const validationSchema = yup.object({
    connectorPayoutStatus: yup.string().required("Payment Status is Required"),
    paymentDate: yup.string().required("Payment Date is Required"),
    disbursementDate: yup.string().required("Disbursement Date is Required"),
    disbursementAmount: yup.string().required("Disbursement Amount is Required"),
});
export const RequestSpotPayoutEditForm = ({ display, handleModal, selectedRow }) => {
    const [successMsg, setSuccessMsg] = useState({
        status: false,
        msg: '',
    });
    const [errorMsg, setErrorMsg] = useState({ state: false, msg: null });
    const today = moment(new Date()).format('YYYY-MM-DD');
    const resolver = useYupValidationResolver(validationSchema);
    const [displayData, setDisplayData] = useState({
        customerName: '',
        productType: '',
        loanBank: '',
        loanAmount: '',
        applicationNumber: null,
        loanStatus: '',
        requestedDate: '',
        requestedAmount: '',
        // disbursementDate: '',
        accountHolderName: '',
        accountNumber: '',
        ifsc: '',
        bankName: '',
        paymentDate: '',
        remarks: '',
        connectorPayoutStatus: selectedRow?.connectorPayoutStatus,
        connectorTdsPercentage: null,
        connectorTdsAmount: null,
    })
    const [bankPayoutAmount, setBankPayoutAmount] = useState(0);
    const [connectorPayoutPercentage, setConnectorPayoutPercentage] = useState(0);
    const [grossPayoutAmount, setGrossPayoutAmount] = useState(0);
    const [connectorPayoutAmount, setConnectorPayoutAmount] = useState(0);
    const [applicationNumber, setApplicationNumber] = useState("");
    const [bankPayoutTDSPercent, setbankPayoutTDSPercent] = useState(0);
    const [bankPayoutTDSAmount, setBankPayoutTDSAmount] = useState(0);
    const [bankPayoutNetAmount, setBankPayoutNetAmount] = useState(0);
    const [bankPayoutDate, setBankPayoutDate] = useState('');
    const [disbursementDate, setDisbursementDate] = useState('');
    const [defaultLoanStatus, setDefaultLoanStatus] = useState('');


    const {
        register,
        reset,
        watch,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: "onChange",
        resolver,
    });

    useEffect(() => {
        if (selectedRow) {
            setDisplayData({
                customerName: selectedRow.customerName,
                productType: selectedRow.productType,
                loanBank: selectedRow.loanBank,
                loanAmount: selectedRow.loanAmount,
                applicationNumber: selectedRow.applicationNumber,
                loanStatus: selectedRow.loanStatus,
                requestedDate: selectedRow.requestedDate,
                requestedAmount: selectedRow.requestedAmount,
                // disbursementDate: selectedRow.disbursementDate,
                accountHolderName: selectedRow.accountHolderName,
                accountNumber: selectedRow.accountNumber,
                ifsc: selectedRow.ifsc,
                bankName: selectedRow.bankName,
                remarks: selectedRow.remarks,
            })
            // console.log("data in edit spot details: ", selectedRow.disbursementAmount);
            setDefaultLoanStatus(selectedRow.loanStatus)
            setDisbursementDate(selectedRow.disbursementDate)

            setValue('disbursementAmount', selectedRow.disbursementAmount || '')
            setValue('bankPayoutPercentage', selectedRow.bankPayoutPercentage)
            setValue('connectorPayoutPercentage', selectedRow.connectorPayoutPercentage)
            setBankPayoutAmount(selectedRow.bankPayoutAmount)
            setGrossPayoutAmount(selectedRow.grossPayoutAmount)
            setConnectorPayoutAmount(selectedRow.connectorPayoutAmount)
            setbankPayoutTDSPercent(selectedRow.bankPayoutTDSPercent)
            setBankPayoutTDSAmount(selectedRow.bankPayoutTDSAmount)
            setBankPayoutDate(selectedRow.bankPayoutDate)

        }

    }, [selectedRow])

    useEffect(() => {
        if (bankPayoutTDSPercent !== undefined) {
            const bankPayoutNetAmount = bankPayoutAmount - (parseInt(bankPayoutTDSPercent / 100 * bankPayoutAmount));
            const bankPayoutTDSAmount = (parseInt(bankPayoutTDSPercent) / 100 * bankPayoutAmount);

            setBankPayoutTDSAmount(bankPayoutTDSAmount)
            setBankPayoutNetAmount(bankPayoutNetAmount)
        }
    }, [bankPayoutTDSPercent, bankPayoutAmount, register]);

    const onSubmitForm = (data) => {
        let bankPayoutPercentageVal = getValues("bankPayoutPercentage")
        let disbursementAmountVal = getValues("disbursementAmount")

        data.grossPayoutAmount = parseInt(grossPayoutAmount) || 0;
        data.connectorPayoutPercentage = parseInt(connectorPayoutPercentage) || 0;
        data.bankPayoutAmount = parseInt(bankPayoutAmount) || 0;
        data.connectorPayoutAmount = parseInt(connectorPayoutAmount) || 0;
        data.bankPayoutTDSAmount = parseInt(bankPayoutTDSAmount) || 0;
        data.bankPayoutNetAmount = parseInt(bankPayoutNetAmount) || 0;
        data.bankPayoutTDSPercent = parseInt(bankPayoutTDSPercent) || 0;
        data.bankPayoutPercentage = parseInt(bankPayoutPercentageVal) || 0;
        data.disbursementAmount = parseInt(disbursementAmountVal) || 0;
        data.paymentDate = moment(data.paymentDate).toISOString();
        data.loanStatus = defaultLoanStatus;
        // console.log("data from useForm: ", data)

        axios.put(ApiUrl() + `leads/${selectedRow.leadId}`, data).then((res) => {
            reset();
            setbankPayoutTDSPercent(0)
            setBankPayoutNetAmount(0)
            setConnectorPayoutAmount(0)
            setBankPayoutNetAmount(0)
            setValue('disbursementAmount', '')
            setDisbursementDate('')
            setDefaultLoanStatus('')
            handleModal();
            setSuccessMsg({
                status: true,
                msg: 'Payment Processed Successfully',
            })
        }).catch(error => {
            let errMsg = errorHandler(error);
            setErrorMsg({ state: true, msg: errMsg })
        });
    }


    const handleInputChange = (event) => {
        const disbursementAmount = document.getElementById('disbursementAmount').value;
        const field = event.target;
        if (field.name === 'bankPayoutPercentage') {
            setBankPayoutAmount((disbursementAmount * field.value / 100).toFixed(3));
        } else if (field.name === 'connectorPayoutPercentage') {
            setConnectorPayoutPercentage(field.value)
            const gpa = disbursementAmount * field.value / 100;
            setGrossPayoutAmount(gpa.toFixed(3));
            setConnectorPayoutAmount((gpa - 0.05 * gpa).toFixed(3));
        } else if (field.name === 'disbursementAmount') {
            const bankPayoutPercentage = document.getElementById('bankPayoutPercentage').value;
            // const connectorPayoutPercentage = document.getElementById('connectorPayoutPercentage').value;
            const gpa = disbursementAmount * connectorPayoutPercentage / 100;
            setGrossPayoutAmount(gpa.toFixed(3));
            setConnectorPayoutAmount((gpa - 0.05 * gpa).toFixed(3));
            setBankPayoutAmount((disbursementAmount * bankPayoutPercentage / 100).toFixed(3));
        } else if (field.name === 'applicationNumber') {
            setApplicationNumber(event.target.value)
        }
    }
    const handledisbursementDate = (e) => {
        setDisbursementDate(e.target.value)
        setDefaultLoanStatus('DISBURSED')
    }
    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };
    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));

        if (pastedData < 0) {
            e.preventDefault();
        }
    };
    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={display} onClose={handleModal}>
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleModal}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div className="text-center mt-3 dialog_header">
                        Spot Payout Details
                    </div>
                </DialogTitle>
                <DialogContent sx={{ paddingTop: 0, marginTop: "10px" }}>
                    <form id="editSpotForm" onSubmit={handleSubmit(onSubmitForm)}>
                        <div className="row form-fields mt-md-n5">
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Customer Name</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.customerName || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Product Type</InputLabel>
                                <OutlinedInput id="outlined-basic" value={productConstants[displayData.productType] || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Bank Name</InputLabel>
                                <OutlinedInput id="outlined-basic" value={bankCodeConstantsForAccount[displayData.loanBank] || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Loan Amount</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.loanAmount?.cur() || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Application Number</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.applicationNumber || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Application Status</InputLabel>
                                <OutlinedInput id="outlined-basic" value={loginStatusConstants[displayData.loanStatus] || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            {/* <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Request Date</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.requestedDate || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Request Amount</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.requestedAmount || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div> */}
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Bank Holder Name</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.accountHolderName || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Bank Account Number</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.accountNumber || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Disbursement Date</InputLabel>
                                <OutlinedInput
                                    id="outlined-basic"
                                    // value={displayData.disbursementDate ? moment(displayData.disbursementDate).format("DD MMM YYYY") : "-"} 
                                    inputProps={{ max: today }}
                                    type="date"
                                    {...register("disbursementDate")}
                                    value={disbursementDate}
                                    onChange={(e) => handledisbursementDate(e)}
                                    label="Outlined" variant="standard" className="w-100 outlined-input mt-2" />
                                <p className="error">{errors?.disbursementDate?.message}</p>
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Disbursement Amount</InputLabel>
                                <OutlinedInput
                                    className="w-100 outlined-input mt-2"
                                    margin="dense"
                                    {...register("disbursementAmount")}
                                    fullWidth
                                    id='disbursementAmount'
                                    onChange={handleInputChange}
                                    variant="standard"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        onWheel: (e) => e.target.blur(),
                                        onPaste: (e) => preventPasteNegative(e),
                                        onKeyPress: (e) => preventMinus(e)
                                    }}
                                />
                                <p className="error">{errors?.disbursementAmount?.message}</p>
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Bank Payout %</InputLabel>
                                <OutlinedInput
                                    type='number'
                                    className="w-100 outlined-input mt-2"
                                    inputProps={{
                                        min: 0,
                                        onWheel: (e) => e.target.blur(),
                                        onPaste: (e) => preventPasteNegative(e),
                                        onKeyPress: (e) => preventMinus(e)
                                    }}
                                    margin="dense"
                                    {...register("bankPayoutPercentage")}
                                    id='bankPayoutPercentage'
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Bank Payout Amount</InputLabel>
                                <OutlinedInput
                                    type="number"
                                    className="w-100 outlined-input mt-2"
                                    margin="dense"
                                    {...register("bankPayoutAmount", {
                                        disabled: true
                                    })}
                                    value={bankPayoutAmount}
                                    label="Bank Payout Amount"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">DigiRep Payout Percentage</InputLabel>
                                <OutlinedInput
                                    type="number"
                                    className="w-100 outlined-input mt-2"
                                    margin="dense"
                                    inputProps={{
                                        maxLength: 20,
                                        step: "0.001",
                                        min: 0,
                                        onWheel: (e) => e.target.blur(),
                                        onPaste: (e) => preventPasteNegative(e),
                                        onKeyPress: (e) => preventMinus(e)
                                    }}
                                    {...register("connectorPayoutPercentage")}
                                    id='connectorPayoutPercentage'
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Gross Payout Amount</InputLabel>
                                <OutlinedInput
                                    type="number"
                                    className="w-100 outlined-input mt-2"
                                    margin="dense"
                                    {...register("grossPayoutAmount", {
                                        disabled: true,
                                    })}
                                    value={grossPayoutAmount}
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">DigiRep Payout Amount</InputLabel>
                                <OutlinedInput
                                    type="number"
                                    className="w-100 outlined-input mt-2"
                                    margin="dense"
                                    {...register("connectorPayoutAmount", {
                                        disabled: true,
                                    })}
                                    value={connectorPayoutAmount}
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel>Bank Payout Received Date</InputLabel>
                                <OutlinedInput
                                    type="date"
                                    className="w-100 outlined-input mt-2"
                                    {...register("bankPayoutDate")}
                                    disabled
                                    value={bankPayoutDate}
                                    onChange={e => setBankPayoutDate(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Bank TDS %</InputLabel>
                                <OutlinedInput
                                    type="number"
                                    className="w-100 outlined-input mt-2"
                                    margin="dense"
                                    inputProps={{
                                        maxLength: 20,
                                        step: 0.001,
                                        min: 0,
                                        onWheel: (e) => e.target.blur(),
                                        onPaste: (e) => preventPasteNegative(e),
                                        onKeyPress: (e) => preventMinus(e)
                                    }}
                                    {...register("bankPayoutTDSPercent")}
                                    label="Bank TDS Percentage"
                                    id='bankPayoutTDSPercent'
                                    fullWidth
                                    variant="standard"
                                    value={bankPayoutTDSPercent}
                                    onChange={e => setbankPayoutTDSPercent(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Bank TDS Amt</InputLabel>
                                <OutlinedInput
                                    type="number"
                                    className="w-100 outlined-input mt-2"
                                    margin="dense"
                                    defaultValue={watch("bankPayoutTDSAmount")}
                                    // value={watch("bankPayoutTDSAmount")}
                                    value={bankPayoutTDSAmount}
                                    {...register("bankPayoutTDSAmount", {
                                        disabled: true,
                                    })}
                                    onChange={e => setBankPayoutTDSAmount(e.target.value)}
                                    label="Bank TDS Amount"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Net Payout Amount</InputLabel>
                                <OutlinedInput
                                    type="number"
                                    className="w-100 outlined-input mt-2"
                                    margin="dense"
                                    // value={watch("bankPayoutNetAmount")}
                                    {...register("bankPayoutNetAmount", {
                                        disabled: true,
                                    })}
                                    value={bankPayoutNetAmount}
                                    onChange={e => setBankPayoutNetAmount(e.target.value)}
                                    label="Net Payout Amount"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">IFSC Code</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.ifsc || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel id="demo-simple-select-label">Payment Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    value={displayData.connectorPayoutStatus}
                                    className="outlined-input w-100"
                                    onChange={(e) => setDisplayData({ ...displayData, connectorPayoutStatus: e.target.value })}
                                    // defaultValue={selectedRow?.connectorPayoutStatus}
                                    defaultValue="processed"
                                    id="connectorPayoutStatus"
                                    {...register("connectorPayoutStatus")}
                                >
                                    <MenuItem value="processed">Processed</MenuItem>
                                    <MenuItem value="notProcessed">Rejected</MenuItem>
                                </Select>
                                <p className="error">{errors?.connectorPayoutStatus?.message}</p>
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Payment Date</InputLabel>
                                <OutlinedInput
                                    type="date"
                                    variant="standard"
                                    className="outlined-input w-100"
                                    id="paymentDate"
                                    inputProps={{ max: today }}
                                    {...register("paymentDate")}
                                />
                                <p className="error">{errors?.paymentDate?.message}</p>
                            </div>
                            <div className="col-md-12 mt-3">
                                <InputLabel htmlFor="component-disabled">Approver Remarks</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.remarks || "-"} multiline maxRows={4} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" />
                            </div>
                        </div>
                    </form>
                </DialogContent>
                <DialogActions className="justify-content-center">
                    <BootstrapButton form="editSpotForm" aria-selected="true" className="w-auto px-4 pt-2" autoFocus type="submit">
                        Submit
                    </BootstrapButton>
                </DialogActions>
            </Dialog>
            {successMsg && <SuccessSnackbar successMsg={successMsg.msg} />}
            {errorMsg.state && <ErrorSnackbar errorMsg={errorMsg.msg} />}
        </>
    )
}