import {
    Grid,
    Paper,
    Slide
} from "@mui/material";
// import AddOfferForm from "./AddOfferForm";
// import BankList from "./BankList";
import React from "react";
import { BootstrapButton } from "../../../utils/BootstrapButton";
import lntIcon from "./LndTIcon.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LntFeeCharges = () => {

  return (
    <>
      <Grid item xs={12} md={5} lg={5} className="deposits-card">
        <Paper
          elevation={24}
          sx={{
            p: 1,
            margin: 1,
            marginTop: 3,
            display: "flex",
            //   flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            height: "15vh",
            boxShadow: 3,
            textAlign: "center",
          }}
        >
          <div
            className="row justify-content-center align-content-center w-100"
            style={{ flexDirection: "row", padding: 8 }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <img src={lntIcon} alt="icon" />
              <div className="guideline-card-header">L&T Fees Charges</div>
              <BootstrapButton
                mr={2}
                aria-selected={"true"}
                style={{
                  borderRadius: "4px",
                }}
              >
                <a
                  href="https://www.ltfs.com/our-products/consumer-loan/fees-charges"
                  target="_blank"
                  rel="noreferrer"
                  style={{color:'white'}}
                >
                  Know More
                </a>
              </BootstrapButton>
            </div>
          </div>
        </Paper>
      </Grid>
    </>
  );
};
export default LntFeeCharges;
