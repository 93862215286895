import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import { ApiUrl } from '../../../helpers/getUrl';
import ReportContext from './reportContext';

export default function Datatable({ key1, value }) {

    const [open, setOpen] = React.useState(false);
    return (
        <React.Fragment>
            <TableRow>
                <TableCell key={key1}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ?
                            <KeyboardArrowDownIcon />
                            :
                            <ChevronRightIcon />
                        }
                    </IconButton>
                </TableCell>
                <TableCell>{key1}</TableCell>
                <TableCell />
                <TableCell>
                    ₹{value?.reduce((total, obj) => obj?.startedAmount + total, 0).toLocaleString("hi")} ({value?.reduce((total, obj) => obj?.startedCount + total, 0).toLocaleString("hi")})
                </TableCell>
                <TableCell>
                    ₹{value?.reduce((total, obj) => obj?.pendingAmount + total, 0).toLocaleString("hi")} ({value?.reduce((total, obj) => obj?.pendingCount + total, 0).toLocaleString("hi")})
                </TableCell>
                <TableCell>
                    ₹{value?.reduce((total, obj) => obj?.wipAmount + total, 0).toLocaleString("hi")} ({value?.reduce((total, obj) => obj?.wipCount + total, 0).toLocaleString("hi")})
                </TableCell>
                <TableCell>
                    ₹{value?.reduce((total, obj) => obj?.rejectedAmount + total, 0).toLocaleString("hi")} ({value?.reduce((total, obj) => obj?.rejectedCount + total, 0).toLocaleString("hi")})
                </TableCell>
                <TableCell>
                    ₹{value?.reduce((total, obj) => obj?.approvedAmount + total, 0).toLocaleString("hi")} ({value?.reduce((total, obj) => obj?.approvedCount + total, 0).toLocaleString("hi")})
                </TableCell>
                <TableCell>
                    ₹{value?.reduce((total, obj) => obj?.dedupAmount + total, 0).toLocaleString("hi")} ({value?.reduce((total, obj) => obj?.dedupCount + total, 0).toLocaleString("hi")})
                </TableCell>
                <TableCell>
                    ₹{value?.reduce((total, obj) => obj?.cancelledAmount + total, 0).toLocaleString("hi")} ({value?.reduce((total, obj) => obj?.cancelledCount + total, 0).toLocaleString("hi")})
                </TableCell>
            </TableRow>
            {/* {open && } */}
            {open && value.map(item => <StateLevelRow row={item} />)}
        </React.Fragment >
    );
}

function StateLevelRow({ row }) {

    const [openSubRow, setOpenSubRow] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow key={row.location}>
                <TableCell />
                <TableCell >
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpenSubRow(!openSubRow)}
                    >
                        {openSubRow ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>

                    {row.location}
                </TableCell>
                <TableCell>₹{row.startedAmount.toLocaleString("hi")} ({row.startedCount})</TableCell>
                <TableCell>₹{row.pendingAmount.toLocaleString("hi")} ({row.pendingCount})</TableCell>
                <TableCell>₹{row.wipAmount.toLocaleString("hi")} ({row.wipCount})</TableCell>
                <TableCell>₹{row.rejectedAmount.toLocaleString("hi")} ({row.rejectedCount})</TableCell>
                <TableCell>₹{row.approvedAmount.toLocaleString("hi")} ({row.approvedCount})</TableCell>
                <TableCell>₹{row.dedupAmount.toLocaleString("hi")} ({row.dedupCount})</TableCell>
                <TableCell>₹{row.cancelledAmount.toLocaleString("hi")} ({row.cancelledCount})</TableCell>
            </TableRow>
            {openSubRow && <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: 'antiquewhite' }} colSpan={10}>
                    <Collapse in={openSubRow} timeout="auto" unmountOnExit>
                        <SubRow row={row} />
                    </Collapse>
                </TableCell>
            </TableRow>
            }
        </React.Fragment >
    );
}

function SubRow(props) {
    const { row } = props;

    const { contextData, setContextData } = React.useContext(ReportContext);
    const [rowData, setRowData] = React.useState([]);

    React.useEffect(() => {
        // console.log(contextData, props)
        axios.get(ApiUrl() + `robo/list?startdate=${contextData.start}&enddate=${contextData.end}&location=${row.location}&state=${row.state}`).then((response) => {
            setRowData(response.data)

        }).catch((error) => {
            console.error(error.data)
            // setErrorMsg("Invalid response from server");
        })
    }, [contextData, props])

    return (
        <React.Fragment>
            <Box sx={{ margin: "15px 0 15px 0" }}>
                <TableContainer style={{ height: "250px", overflow: "auto" }} >
                    <Table size="medium" stickyHeader aria-label="data table">
                        <TableHead>
                            <TableRow>
                                <TableCell>LOS#</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Loan Amount</TableCell>
                                <TableCell>Login Date</TableCell>
                                <TableCell>Stage</TableCell>
                                <TableCell>Credit Status</TableCell>
                                <TableCell>Updated Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ backgroundColor: "#bbbbbb4f" }}>
                            {rowData?.map((data, idx) => (
                                <TableRow>
                                    <TableCell>{data.applicationNumber}</TableCell>
                                    <TableCell>{data.customerName}</TableCell>
                                    <TableCell>{data.loanAmount.toLocaleString("hi")}</TableCell>
                                    <TableCell>{data.loginDate && moment(data.loginDate).format("DD/MM/YYYY")}</TableCell>
                                    <TableCell>{data.stage}</TableCell>
                                    <TableCell>{data.creditStatus}</TableCell>
                                    <TableCell>{moment(data.createdOn).format("DD/MM/YYYY")}</TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </React.Fragment >
    );
}
