import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';

function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
}

const rows = [
    createData('Name 1', 159, 6.0, -4.0),
    createData('Name 2', 237, 9.0, 4.3),
    createData('Name 3', 262, 16.0, -6.0),
    createData('Name 4', 305, 3.7, 4.3),
    createData('Name 5', 356, 16.0, 3.9),
];

export default function EnhancedTable() {
    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Last Month Leads</TableCell>
                        <TableCell>This Month Leads</TableCell>
                        <TableCell>% Change</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell>{row.calories}</TableCell>
                            <TableCell>{row.fat}</TableCell>
                            <TableCell style={{ color: row.carbs < 0 ? 'red' : 'green' }}>
                                {row.carbs}&nbsp; {row.carbs < 0 ? <ArrowDownward /> : <ArrowUpward />}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
