import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from "@mui/material/CircularProgress";
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import { ApiUrl } from '../../../helpers/getUrl';

const SearchByLos = ({ openSearch, setOpenSearch }) => {

    const [loading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [data, setData] = React.useState([]);

    const handleDialog = () => {
        setOpenSearch(false)
        setValue('');
        setData([]);
        // setLoading(false)
    }

    const onSearch = () => {
        setLoading(true)
        axios.get(ApiUrl() + `robo/data?applicationNumber=${value}`).then((res) => {
            setData(res.data);
            setLoading(false)
        }).then((err) => {
            console.log(err)
            setLoading(false)
        })
    }


    return (
        <Dialog maxWidth="md" fullWidth open={openSearch} onClose={() => setOpenSearch(false)}>
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => handleDialog()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }} >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Paper
                    sx={{ p: '2px 4px', m: 2, display: 'flex', alignItems: 'center', width: 400 }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search By LOS#"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
                    <IconButton onClick={onSearch} color="primary" type="submit" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Paper>
                <TableContainer style={{ height: "250px", overflow: "auto" }} >
                    <Table size="medium" stickyHeader aria-label="data table">
                        <TableHead>
                            <TableRow>
                                <TableCell>State</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>LOS#</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Loan Amount</TableCell>
                                <TableCell>Login Date</TableCell>
                                <TableCell>Stage</TableCell>
                                <TableCell>Credit Status</TableCell>
                                <TableCell>Updated Date</TableCell>
                            </TableRow>
                        </TableHead>
                        {!loading ?
                            <TableBody >
                                {data?.map((data, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell>{data.state}</TableCell>
                                        <TableCell>{data.location}</TableCell>
                                        <TableCell>{data.applicationNumber}</TableCell>
                                        <TableCell>{data.customerName}</TableCell>
                                        <TableCell>{data.loanAmount.toLocaleString("hi")}</TableCell>
                                        <TableCell>{data.loginDate && moment(data.loginDate).format("DD/MM/YYYY")}</TableCell>
                                        <TableCell>{data.stage}</TableCell>
                                        <TableCell>{data.creditStatus}</TableCell>
                                        <TableCell>{moment(data.createdOn).format("DD/MM/YYYY")}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            : <IconButton sx={{ p: '25px', left: '300px' }}><CircularProgress /></IconButton>}
                    </Table>
                </TableContainer>
            </DialogContent>
        </Dialog>
    )
}
export default SearchByLos;