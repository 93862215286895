import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from '@mui/material/IconButton';

import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function SuccessRecharge({
  successDialog,
  toggleSuccessDialog,
}) {
  return (
    <Dialog
      open={successDialog}
      onClose={toggleSuccessDialog}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={toggleSuccessDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Paper elevation={0} style={{ textAlign: "center" }}>
          <Typography variant="h5" style={{ color: "#2e54da" }}>
            {"Recharge Completed Successfully!!"}
          </Typography>
          <div style={{ margin: "1rem" }}>
            <CheckCircleIcon fontSize="large" color="success" />
          </div>
        </Paper>
      </DialogContent>
    </Dialog>
  );
}
