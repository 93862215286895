import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAdvanceService } from "../services/Admin.services";

export const adminAdvanceRequest = createAsyncThunk('admin/advanceRequest', async (payload, { rejectWithValue }) => {

    try {
        const response = await AdminAdvanceService(payload);
        return response;
    } catch (err) {
        return rejectWithValue(err);
    }
});