import AddIcon from '@mui/icons-material/Add';
import { Button, Stack, Typography } from "@mui/material";
import { useState } from 'react';
import AddOfferForm from './AddOfferForm';
import BankList from "./BankList";


const BankWiseOffersIndex = () => {

    const [showForm, setShowForm] = useState(false);

    const { connectorId } = JSON.parse(sessionStorage.getItem('data'));

    return (
        <div className="sidenav-content">
            <div className="row my-2">
                <div className="col-md-6">
                    <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        className="leads-heading"
                    >
                        Bank Offers
                    </Typography>
                </div>
                <div className="col-md-6">
                    <Stack spacing={4} direction="row-reverse" alignItems='flex-start'>
                        {!connectorId && <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => setShowForm(true)}
                        >
                            Add Offers
                        </Button>}
                    </Stack>
                </div>
                <BankList />
                <AddOfferForm showForm={showForm} setShowForm={setShowForm} />
            </div>
        </div>

    )
}
export default BankWiseOffersIndex;