import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Button, Dialog, DialogTitle, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import { useState } from 'react';
import { ApiUrl } from "../../helpers/getUrl";

const TemplateDownload = () => {

    const [dwnldType, setDwnldType] = useState("ad");
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(!open)
    }

    return (
        <>
            <Button
                variant="contained"
                startIcon={<GetAppIcon />}
                onClick={() => setOpen(!open)}
            >
                Download Template
            </Button>

            <Dialog
                maxWidth="xs"
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>Download Template</DialogTitle>
                <form>
                    <DialogContent dividers>
                        <FormControl>
                            <RadioGroup
                                row
                                value={dwnldType}
                                onChange={(e) => setDwnldType(e.target.value)}
                            >
                                <FormControlLabel value="ad" control={<Radio />} label="Applications Template" />
                                <FormControlLabel value="bpd" control={<Radio />} label="Bank Payout Template" />
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>
                </form>
                <DialogActions>
                    <a href={dwnldType === "ad" ? ApiUrl() + "template/admin/leads" : ApiUrl() + "template/bank-payout"} target="_blank" >
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                        >
                            <FileDownloadOutlinedIcon />
                        </Button>
                    </a>

                </DialogActions>
            </Dialog>
        </>
    )
}
export default TemplateDownload;