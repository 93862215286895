import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputLabel, OutlinedInput } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { advancePaymentPossibleStatus, transactionEnums, transactionTypes } from "../../../../helpers/constants";
import { BootstrapButton } from "../../BootstrapButton";

export const RequestAdvanceDetails = ({ display, handleModal }) => {
    const [showTransactionType, setShowTransactionType] = useState(true);
    const [displayData, setDisplayData] = useState({
        createdOn: '',
        transactionType: '',
        currentOutstandingAmount: '',
        connectorRemarks: '',
        connecterAcknowledgeFlag: null,
        requestedAmount: '',
        paidAmount: '',
        approvedDate: '',
        approvedAmount: '',
        accountHolderName: '',
        accountNumber: '',
        ifsc: '',
        bankName: '',
    })
    const { PENDING_WITH_CEO, PAYMENT_PENDING, PENDING_FOR_CONNECTOR_ACKNOWLEDGEMENT, PAYMENT_PAID, PAYMENT_PROCESSED } = advancePaymentPossibleStatus;
    const { ADVANCE_PAYMENT, ADVANCE_RECEIPT } = transactionTypes;
    const { PAYMENT, RECEIPT } = transactionEnums;
    useEffect(() => {
        // console.log("disaplay in RequestAdvanceDetails: ", display.data)
        setDisplayData({
            createdOn: display.data.createdOn,
            transactionType: display.data.transactionType,
            currentOutstandingAmount: display.data.currentOutstandingAmount,
            connectorRemarks: display.data.connectorRemarks,
            connecterAcknowledgeFlag: display.data.connecterAcknowledgeFlag,
            requestedAmount: display.data.requestedAmount,
            paidAmount: display.data.paidAmount,
            approvedDate: display.data.approvedDate,
            approvedAmount: display.data.approvedAmount,
            accountHolderName: display.data.accountHolderName,
            accountNumber: display.data.accountNumber,
            ifsc: display.data.ifsc,
            bankName: display.data.bankName,
        })
        if (display.data.transactionType === "ADVANCE_RECEIPT") {
            setShowTransactionType(false)
        } else {
            setShowTransactionType(true)
        }
    }, [display])
    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={display.show} onClose={handleModal}>
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleModal}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div className="text-center mt-3 dialog_header">Request Advance</div>
                </DialogTitle>
                <DialogContent sx={{ paddingTop: 0, marginTop: "10px" }}>
                    <div className="row form-fields mt-md-n5">
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Transaction  Date</InputLabel>
                            <OutlinedInput id="outlined-basic" value={moment(displayData.createdOn).format("DD MMM YYYY") || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Transaction  Type</InputLabel>
                            <OutlinedInput
                                id="outlined-basic"
                                value={displayData.transactionType === ADVANCE_PAYMENT ? PAYMENT
                                    : displayData.transactionType === ADVANCE_RECEIPT ? RECEIPT
                                        : "-"}
                                label="Outlined"
                                variant="standard"
                                className="w-100 outlined-input mt-2"
                                disabled />
                        </div>
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Transaction Amount</InputLabel>
                            <OutlinedInput id="outlined-basic" value={displayData.requestedAmount?.cur() || displayData.paidAmount?.cur() || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Outstanding Amount</InputLabel>
                            <OutlinedInput id="outlined-basic" value={displayData?.currentOutstandingAmount?.cur() || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Request Date</InputLabel>
                            <OutlinedInput id="outlined-basic" value={moment(displayData.createdOn).format("DD MMM YYYY") || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                        {showTransactionType &&
                            <>
                                <div className="col-md-6 mt-3">
                                    <InputLabel htmlFor="component-disabled">Request Amount</InputLabel>
                                    <OutlinedInput id="outlined-basic" value={displayData.requestedAmount?.cur()} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                                </div>
                                <div className="col-md-6 mt-3">
                                    <InputLabel htmlFor="component-disabled">Approval Date</InputLabel>
                                    <OutlinedInput id="outlined-basic" value={displayData.approvedDate?.cur() || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                                </div>
                                <div className="col-md-6 mt-3">
                                    <InputLabel htmlFor="component-disabled">Approval Amount</InputLabel>
                                    <OutlinedInput id="outlined-basic" value={displayData.approvedAmount?.cur()} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                                </div>
                                <div className="col-md-6 mt-3">
                                    <InputLabel htmlFor="component-disabled">Status</InputLabel>
                                    <OutlinedInput id="outlined-basic" value={
                                        display.data.paymentStatus === PENDING_WITH_CEO ? 'CEO Decision Pending'
                                            : display.data.paymentStatus === PAYMENT_PENDING ? 'Payment Pending'
                                                : display.data.paymentStatus === PAYMENT_PAID ? 'Payment Paid'
                                                    : display.data.paymentStatus === PENDING_FOR_CONNECTOR_ACKNOWLEDGEMENT ? 'DigiRep Decision Pending'
                                                        : display.data.paymentStatus === PAYMENT_PROCESSED ? 'Payment Processed'
                                                            : 'Rejected'} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                                </div>
                                <div className="col-md-6 mt-3">
                                    <InputLabel htmlFor="component-disabled">Bank Holder Name</InputLabel>
                                    <OutlinedInput id="outlined-basic" value={displayData.accountHolderName || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                                </div>
                                <div className="col-md-6 mt-3">
                                    <InputLabel htmlFor="component-disabled">Bank Account Number</InputLabel>
                                    <OutlinedInput id="outlined-basic" value={displayData.accountNumber || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                                </div>
                                <div className="col-md-6 mt-3">
                                    <InputLabel htmlFor="component-disabled">IFSC Code</InputLabel>
                                    <OutlinedInput id="outlined-basic" value={displayData.ifsc || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                                </div>
                                <div className="col-md-6 mt-3">
                                    <InputLabel htmlFor="component-disabled">Bank Name</InputLabel>
                                    <OutlinedInput id="outlined-basic" value={displayData.bankName || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                                </div>
                            </>
                        }
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Approver Remarks</InputLabel>
                            <OutlinedInput id="outlined-basic" value={displayData.connectorRemarks || "-"} multiline maxRows={4} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className="justify-content-center">
                    <BootstrapButton aria-selected="true" className="w-auto px-4 pt-2" autoFocus onClick={handleModal}>
                        Close
                    </BootstrapButton>
                </DialogActions>
            </Dialog>
        </>
    )
}