import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Grid, Typography } from '@mui/material';
import axios from 'axios';
import { ApiUrl } from '../../../helpers/getUrl';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
}));

export const KPI = () => {

    const { connectorId } = JSON.parse(sessionStorage.getItem("data")) || undefined;
    const [data, setData] = React.useState([
        {
            id: "totalAdvances",
            title: "Total Advances",
            Amount: "5000000"
        },
        {
            id: "totalSpotPayments",
            title: "Total Spot Payments",
            Amount: "200000"
        },
        {
            id: "totalOutstanding",
            title: "Total Outstanding",
            Amount: "560000"
        },
    ]);

    React.useEffect(() => {
        axios.get(ApiUrl() + `advance/kpi/${connectorId}`)
            .then((res) => {
                const result = data.map((item, idx) => {
                    item.Amount = res.data[item.id]
                    return item;
                })
                setData(result)
            })
            .catch((error) => {
                console.error(error);
            });
    }, [connectorId])

    return (
        <Grid container spacing={5}>
            {data.map((item, idx) => (
                <Grid item xs={12} lg={4} key={idx}>
                    <Item>
                        <Typography variant="div" gutterBottom>
                            {item.title}
                        </Typography>
                        <Typography variant="h4" gutterBottom>
                            {item.Amount.cur()}
                        </Typography>
                    </Item>
                </Grid>
            ))}
        </Grid>
    )
}