import { AppBar, Dialog, DialogContent, DialogTitle, Toolbar, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";


const BankStmtAnalyzer = ({ openBSA, toggleBSA }) => {

  let localData = JSON.parse(sessionStorage.getItem('data'));
  const { connectorId, finadminId, finMngrId } = localData || {};

  let userId;
  if (connectorId) {
    userId = connectorId;
  } else if (finadminId) {
    userId = finadminId;
  } else if (finMngrId) {
    userId = finMngrId;
  }
  return (

    <Dialog
      fullScreen
      open={openBSA}
      onClose={toggleBSA}
    >
      <DialogTitle>
        <AppBar sx={{ position: 'absolute' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={toggleBSA}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" component="div">
              Bank Statement Analyzer
            </Typography>
          </Toolbar>
        </AppBar>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '5%' }}>
        <iframe
          src={`https://bsa.impetusaisolutions.com/#/stmt/${userId}`}
          title="Bank Statement Analyzer"
          width="100%"
          height="90%"
        ></iframe>
      </DialogContent>
    </Dialog>
  );
}
export default BankStmtAnalyzer;