import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import axios from "axios";
import moment from 'moment';
import { default as React, useEffect, useState } from "react";
import { numbersToCashFormat } from "../../helpers/functions";
import { ApiUrl } from '../../helpers/getUrl';
function TransactionSection({ startDate, endDate }) {
  const [result, setResult] = useState([]);


  useEffect(() => {
    getCeoCount();
  }, [startDate, endDate]);

  const getCeoCount = () => {
    const start = new Date(startDate).toISOString();
    const end = moment(endDate).format("YYYY-MM-DD") + "T23:59:59.000Z";
    const body = {
      "startDate": start,
      "endDate": end
    }
    axios.post(`${ApiUrl()}reports/projection`, body)
      .then(res => {
        setResult(res.data);
        // console.log(res.data);
      }).catch(error => {
        console.error('There was an error!', error);
      });
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Paper className="reports-card-container">
            <div>
              <p className="m-0">Total Disbursement</p>
              <h2 className="m-0">{numbersToCashFormat(result?.totalDisbursedAmount) || 0}</h2>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3} lg={2}>
          <Paper className="reports-card-container">
            <div>
              <p className="m-0">PL Disbursement</p>
              <h2 className="m-0">{numbersToCashFormat(result?.PERS) || 0}</h2>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3} lg={2}>
          <Paper className="reports-card-container">
            <div>
              <p className="m-0">HL Disbursement</p>
              <h2 className="m-0">{numbersToCashFormat(result?.HOME) || 0}</h2>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3} lg={2}>
          <Paper className="reports-card-container">
            <div>
              <p className="m-0">Auto Disbursement</p>
              <h2 className="m-0">{numbersToCashFormat(result?.AUTO) || 0}</h2>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <Paper className="reports-card-container">
            <div>
              <p className="m-0">Top Business Location</p>
              <h2 className="m-0">{result?.topLocation || "-"}</h2>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <Paper className="reports-card-container">
            <div>
              <p className="m-0">Top Branch Manager</p>
              <h2 className="m-0">{result?.topBranchManagerName || "-"}</h2>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default TransactionSection;
