import * as React from "react";
import Grid from "@mui/material/Grid";
import Enquiry from "./enquiry";
import KYC from "./KYC";


function LoanEnquiry() {

  return (
    <div className="sidenav-content">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Enquiry />
        </Grid>
        <Grid item xs={12} md={6}>
          <KYC />
        </Grid>
      </Grid>
    </div>
  );
}

export default LoanEnquiry;
