import * as React from "react";
import { styled, createTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
// import Chart from './Chart';
import ChartsSection from "./chartsSection";
import TransactionSection from "./TransactionSections";

import TopButtons from "./topButtons.js";

function Settings() {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} className="sidenav-content">
      <Grid container spacing={3} className="row m-0 sidenav-info">
        {/* Buttons */}
        <Grid item >
            
              <TopButtons />
            
          </Grid>

          <Grid item>
              <TransactionSection />
          </Grid>
         

          <Grid item>
            <ChartsSection />
          </Grid>
        
      </Grid>
    </Container>
  );
}

export default Settings;
