import * as React from "react";
import { styled, createTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import NotificationForm from "./notificationFormV1";




function Notification() {

  return (
    <Container maxWidth="lg" sx={{ pt:0, mb: 0 }}  className="sidenav-content">
      <Grid container justifyContent='center'alignItems='center' spacing={1} className="row m-0 sidenav-info">
        
       <Grid item xs={12} sm={12} md={9} lg={6} sx={{mt:0}} > 
        <Grid item  sx={{marginTop:'20px',borderRadius:'5px',}}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <NotificationForm/>
            </Grid>


          </Grid>
        </Grid>

        
        </Grid>
      </Grid>


    </Container>
  );
}

export default Notification;
