import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Button,
  ButtonBase,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import AppWrapper from "../../../components/AppWrapper";
import { ApiUrl } from "../../../helpers/getUrl";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  borderRadius: "20%",
  maxWidth: "100%",
  maxHeight: "100%",
  border: "2px solid #5a5a5a",
  boxShadow: "10px 10px 5px #d8d7d7",
});

const AadharDetails = () => {
  let history = useHistory();

  const { connectorId, finadminId, finMngrId } =
    JSON.parse(sessionStorage.getItem("data")) || {};
  const search = useLocation().search;
  const requestId = new URLSearchParams(search).get("requestId");
  const status = new URLSearchParams(search).get("status");
  const [userDetail, setUserDetail] = useState(null);

  useEffect(() => {
    if (requestId && status === "success" && !userDetail) {
      const endPoint = `${ApiUrl()}kyc/aadhar/details`;
      console.log(connectorId);
      const payload = {
        requestId,
        connectorId,
        amount: 100,
      };

      axios
        .post(endPoint, payload)
        .then((response) => {
          setUserDetail(response.data.aadhar);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [requestId])

  const onClickBack = () => {
    history.push("/DigiRep/loan-enquiry");
    setUserDetail(null);
  };

  return (
    <AppWrapper>
      <Button size="large" sx={{ p: 2 }} onClick={() => onClickBack()}>
        <KeyboardBackspaceIcon style={{ transform: "scale(2.5)" }} />
      </Button>
      <Paper
        sx={{ p: 2, margin: "auto", maxWidth: 800, flexGrow: 1 }}
        elevation={24}
      >
        <Typography
          gutterBottom
          variant="h3"
          style={{ fontFamily: "fantasy" }}
          p={2}
          mb={0}
          align="center"
          component="div"
        >
          Aadhar Details
        </Typography>
        {userDetail ? (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ButtonBase sx={{ width: 250, height: 250, padding: 2 }}>
                <Img alt="img" src={userDetail?.photo} />
              </ButtonBase>
            </div>
            <div>
              {["name", "uid", "gender", "dob", "Address"].map(
                (title, index) => (
                  <>
                    <Grid
                      container
                      style={{ padding: "0.5rem", backgroundColor: "#E0FFFF" }}
                    >
                      <Grid item xs style={{ textTransform: "capitalize" }}>
                        <Typography
                          gutterBottom
                          variant="h6"
                          // fontFamily={"cursive"}
                          fontWeight={500}
                          paddingLeft={'10vw'}
                        >
                          {title != "uid" ? title : "UId"}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography
                          color="text.secondary"
                        // fontFamily={"cursive"}
                        >
                          {title != "Address"
                            ? userDetail[title]
                            : userDetail.splitAddress.addressLine +
                            " - " +
                            userDetail.splitAddress.pincode}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )
              )}
            </div>
          </>
        ) : (
          <>
            <Stack spacing={1}>
              <Skeleton variant="h1" height={100} />
              <Skeleton variant="rectangular" height={200} />
            </Stack>
          </>
        )}
      </Paper>
    </AppWrapper>
  );
};
export default AadharDetails;
