import CloseIcon from "@mui/icons-material/Close";
import { Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import Button from "@mui/material/Button";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ErrorSnackbar, SuccessSnackbar } from "../../../../components/CustomSnackBar";
import { errorHandler } from "../../../../helpers/errorHandler";
import { ApiUrl } from '../../../../helpers/getUrl';
import { BootstrapButton } from '../../BootstrapButton';

const UploadData = ({ display, handleUploadModal }) => {
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [successMsg, setSuccessMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState({ state: false, msg: null });
    const [disableBtn, setDisableBtn] = useState(true);

    const changeHandler = (event) => {
        const file = event.target.files[0];
        if (file) setDisableBtn(false)
        setSelectedFile(file);
        setIsFilePicked(true);
    };
    useEffect(() => {
        handleRemoveFile()
    }, [handleUploadModal])

    const onSubmitForm = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const formData = new FormData();
        formData.append('file', selectedFile);
        // console.log("Upload formData: ", formData)

        axios.post(`${ApiUrl()}leads/admin/upload/save`, formData).then((res) => {
            console.log("upload res: ", res);
            if (res.status === 200) {
                setSelectedFile()
                handleUploadModal();
                setSuccessMsg(true)
            }
        }).catch(error => {
            let errFromServer = error.response.data.validationMessage
            // console.log(errFromServer)

            let errMsg;

            if (errFromServer) {
                errMsg = errFromServer;
            } else {
                errMsg = errorHandler(error);
            }

            setErrorMsg({ state: true, msg: errMsg })
        });
    }
    const handleRemoveFile = () => {
        setDisableBtn(true)
        setIsFilePicked(false);
    }
    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={display} onClose={(e, r) => handleUploadModal(e, r)}>
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleUploadModal}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div className="text-center mt-3 dialog_header">Upload Data</div>
                </DialogTitle>
                <form >
                    <DialogContent sx={{ paddingTop: 0, marginTop: "10px" }}>
                        <div className="row form-fields mt-md-n5">
                            <div className="col-md-6 mt-3 text-center mx-auto">
                                {isFilePicked && <Typography variant="h6" gutterBottom component="div">
                                    <Chip label={selectedFile?.name} variant="outlined" onDelete={handleRemoveFile} />
                                </Typography>}
                                {!isFilePicked &&
                                    <label htmlFor="contained-button-file">
                                        <input
                                            style={{ display: 'none' }}
                                            id="contained-button-file"
                                            type="file"
                                            onChange={(e) => changeHandler(e)}
                                            accept=".xlsx,.xls,.csv" />
                                        <Button variant='outlined' component='span'>
                                            choose file here
                                        </Button>
                                    </label>
                                }
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="justify-content-center mb-5">
                        <BootstrapButton
                            aria-selected="true"
                            className="w-auto px-5 py-2 border-0"
                            autoFocus
                            type="submit"
                            variant="contained"
                            onClick={(e) => onSubmitForm(e)}
                            disabled={disableBtn}
                        >
                            Upload
                        </BootstrapButton>
                    </DialogActions>
                </form>
            </Dialog>
            {errorMsg.state && <ErrorSnackbar errorMsg={errorMsg.msg} />}
            {successMsg && <SuccessSnackbar successMsg="Data Uploaded Successfully" />}
        </>
    )
}

export default UploadData
