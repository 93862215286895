import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputLabel, OutlinedInput, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { bankCodeConstantsForAccount, productConstants } from "../../../../constants/appconstants";
import { BootstrapButton } from "../../BootstrapButton";

export const RequestSpotPayoutForm = ({ display, handleModal }) => {
    const [displayData, setDisplayData] = useState({
        customerName: '',
        productType: '',
        loanBank: '',
        loanAmount: '',
        applicationNumber: null,
        loanStatus: '',
        requestedDate: '',
        requestedAmount: '',
        approvalDate: '',
        approvalAmount: '',
        accountHolderName: '',
        accountNumber: '',
        ifsc: '',
        bankName: '',
        remarks: '',
    })
    useEffect(() => {
        // console.log("display in RequestAdvanceDetails: ", display.data)
        setDisplayData({
            customerName: display.data.customerName,
            productType: display.data.productType,
            loanBank: display.data.loanBank,
            loanAmount: display.data.loanAmount,
            applicationNumber: display.data.applicationNumber,
            loanStatus: display.data.loanStatus,
            requestedDate: display.data.requestedDate,
            requestedAmount: display.data.requestedAmount,
            accountHolderName: display.data.accountHolderName,
            accountNumber: display.data.accountNumber,
            ifsc: display.data.ifsc,
            bankName: display.data.bankName,
            remarks: display.data.remarks,
        })

    }, [display])

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={display.show} onClose={handleModal}>
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleModal}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h4" component="div" className="text-center">Request Spot Payment</Typography>
                </DialogTitle>
                <DialogContent sx={{ paddingTop: 0, marginTop: "10px" }}>
                    <div className="row form-fields mt-md-n5">
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Customer Name</InputLabel>
                            <OutlinedInput id="outlined-basic" value={displayData.customerName || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Product Type</InputLabel>
                            <OutlinedInput id="outlined-basic" value={productConstants[displayData.productType] || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Bank Name</InputLabel>
                            <OutlinedInput id="outlined-basic" value={bankCodeConstantsForAccount[displayData.loanBank] || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Loan Amount</InputLabel>
                            <OutlinedInput id="outlined-basic" value={displayData?.loanAmount?.cur() || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Application Number</InputLabel>
                            <OutlinedInput id="outlined-basic" value={displayData.applicationNumber || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Application Status</InputLabel>
                            <OutlinedInput id="outlined-basic" value={displayData.loanStatus || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Request Date</InputLabel>
                            <OutlinedInput id="outlined-basic" value={displayData.requestedDate || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Request Amount</InputLabel>
                            <OutlinedInput id="outlined-basic" value={displayData.requestedAmount || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Approval Date</InputLabel>
                            <OutlinedInput id="outlined-basic" value={displayData.approvalDate || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Approval Amount</InputLabel>
                            <OutlinedInput id="outlined-basic" value={displayData.approvalAmount || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Bank Holder Name</InputLabel>
                            <OutlinedInput id="outlined-basic" value={displayData.accountHolderName || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Bank Account Number</InputLabel>
                            <OutlinedInput id="outlined-basic" value={displayData.accountNumber || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">IFSC Code</InputLabel>
                            <OutlinedInput id="outlined-basic" value={displayData.ifsc || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                        <div className="col-md-6 mt-3">
                            <InputLabel htmlFor="component-disabled">Bank Name</InputLabel>
                            <OutlinedInput id="outlined-basic" value={bankCodeConstantsForAccount[displayData.loanBank] || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                        <div className="col-md-12 mt-3">
                            <InputLabel htmlFor="component-disabled">Approver Remarks</InputLabel>
                            <OutlinedInput id="outlined-basic" value={displayData.remarks || "-"} multiline maxRows={4} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className="justify-content-center">
                    <BootstrapButton aria-selected="true" className="w-auto px-4 py-2" autoFocus onClick={handleModal}>
                        Close
                    </BootstrapButton>
                </DialogActions>
            </Dialog>
        </>
    )
}