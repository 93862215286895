import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { expenseCategory } from "../../constants/appconstants";
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddIcon from '@mui/icons-material/Add';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

const AddExpenseForm = ({ data, isEdit, setisEdit, setShowForm, setData }) => {

    const [month, setMonth] = useState(isEdit?.month);
    const [date, setDate] = useState(null);

    const useStyles = makeStyles(() => ({
        tableCell: { textAlign: "center" },
        formControl: {
            padding: "5px",
            width: "250px",
        },
    }));

    const classes = useStyles();

    const {
        handleSubmit,
        register,
        watch,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: isEdit,
    });
    const watchFields = watch(["grossAmount", "tds", "sgst", "cgst", "igst"]);


    useEffect(() => {
        if (isEdit?.paidOn) {
            const paidDate = isEdit.paidOn.substring(0, 10).split("/").reverse().join("-");
            setDate(paidDate);
        }
    }, [isEdit])

    useEffect(() => {
        if (watchFields[0] !== undefined) {
            const amount = parseInt(watchFields[0]) || 0;
            const tds = parseInt(watchFields[1]) || 0;
            const sgst = parseInt(watchFields[2]) || 0;
            const cgst = parseInt(watchFields[3]) || 0;
            const igst = parseInt(watchFields[4]) || 0;
            const netAmt = (amount + sgst + cgst + igst) - tds;
            setValue('netAmount', netAmt)
        }
    }, [watchFields]);

    const onSubmit = (data1) => {
        if (isEdit?.id !== undefined) {
            const editedData = data.map((item, id) => {
                if (id === isEdit.id) {
                    console.log(item)
                    return data1;
                } else {
                    return item;
                }
            })
            console.log('$$edited Data', editedData);
            setData(editedData);
            setisEdit({});
        } else {
            setData([...data, data1]);
        }
        setShowForm(false);
    }

    return (

        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row form-fields mt-md-n5">
                <div className="col-md-4">
                    <FormControl variant="standard" className={classes.formControl}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                views={['month', 'year']}
                                value={month}
                                {...register("month")}
                                label="Month"
                                onChange={(newValue) => {
                                    const x = moment(newValue).format('MMMM YYYY');
                                    setValue('month', x)
                                    setMonth(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} helperText={null} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl variant="standard" className={classes.formControl}>
                        <Select
                            labelId="category"
                            id="category"
                            sx={{ marginTop: "20px" }}
                            {...register("category", { required: "This field is required" })}
                            defaultValue={isEdit?.category}
                            label="category"
                            fullWidth
                        >
                            {Object.values(expenseCategory).map((x) => (
                                <MenuItem value={x}>{x}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText
                            error={errors?.category && errors?.category}
                        >
                            {errors?.category?.message}
                        </FormHelperText>
                        <InputLabel required>Expense Category</InputLabel>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl variant="standard" className={classes.formControl}>
                        <TextField
                            className={classes.textField}
                            autoFocus
                            type='number'
                            margin="dense"
                            {...register("grossAmount", { required: "This field is required" })}
                            label="Amount *"
                            fullWidth
                            variant="standard"
                        />
                        <FormHelperText
                            error={errors?.grossAmount && errors?.grossAmount}
                        >
                            {errors?.grossAmount?.message}
                        </FormHelperText>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl variant="standard" className={classes.formControl}>
                        <TextField
                            className={classes.textField}
                            autoFocus
                            type='number'
                            margin="dense"
                            {...register("tds")}
                            defaultValue={isEdit?.tds || 0}
                            label="TDS"
                            fullWidth
                            variant="standard"
                        />
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl variant="standard" className={classes.formControl}>
                        <TextField
                            className={classes.textField}
                            autoFocus
                            type='number'
                            margin="dense"
                            {...register("sgst")}
                            defaultValue={isEdit?.sgst || 0}
                            label="SGST"
                            fullWidth
                            variant="standard"
                        />
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl variant="standard" className={classes.formControl}>
                        <TextField
                            className={classes.textField}
                            autoFocus
                            type='number'
                            margin="dense"
                            {...register("igst")}
                            defaultValue={isEdit?.igst || 0}
                            label="IGST"
                            fullWidth
                            variant="standard"
                        />
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl variant="standard" className={classes.formControl}>
                        <TextField
                            className={classes.textField}
                            autoFocus
                            type='number'
                            margin="dense"
                            {...register("cgst")}
                            defaultValue={isEdit?.cgst || 0}
                            label="CGST"
                            fullWidth
                            variant="standard"
                        />
                    </FormControl>
                </div>

                <div className="col-md-4">
                    <FormControl variant="standard" className={classes.formControl}>
                        <Select
                            labelId="isTdsPaid"
                            id="isTdsPaid"
                            sx={{ marginTop: "20px" }}
                            {...register("isTdsPaid")}
                            defaultValue={isEdit?.isTdsPaid || 'No'}
                            label="isTdsPaid"
                            fullWidth
                        >
                            {['Yes', 'No'].map((x) => (
                                <MenuItem value={x}>{x}</MenuItem>
                            ))}
                        </Select>
                        <InputLabel>TDS Payment Status</InputLabel>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <InputLabel>Paid On</InputLabel>
                    <FormControl variant="standard" className={classes.formControl}>

                        <FormControl variant="standard" >

                            <input id="fd"
                                className="date-picker"
                                placeholder="Paid On"
                                type="date"
                                value={date}
                                onChange={(e) => {
                                    const x = new Date(e.target.value)?.toISOString();
                                    const paidDate = x.substring(0, 10).split("/").reverse().join("-");
                                    setValue('paidOn', x);
                                    setDate(paidDate);
                                }}
                            />

                        </FormControl>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl variant="standard" className={classes.formControl}>
                        <TextField
                            className={classes.textField}
                            autoFocus
                            margin="dense"
                            {...register("netAmount")}
                            label="Net Amount"
                            defaultValue={0}
                            disabled
                            fullWidth
                            variant="standard"
                        />
                    </FormControl>
                </div>
                <div className="col-md-8">
                    <FormControl variant="standard" className={classes.formControl}>
                        <TextField
                            className={classes.textField}
                            autoFocus
                            margin="dense"
                            {...register("remark")}
                            label="Remarks"
                            fullWidth
                            variant="standard"
                        />
                    </FormControl>
                </div>
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className="col-md-4">
                </div>
                <div className="col-md-4">
                    <Stack spacing={2} direction="row-reverse">
                        <Button
                            variant="contained"
                            type="submit"
                            style={{ float: 'right', marginRight: '25px' }}
                        >
                            Save
                        </Button>

                        <Button variant="text" sx={{ boxShadow: 2 }} onClick={() => { setShowForm(false); setisEdit({}) }}>
                            Cancel
                        </Button>

                    </Stack>
                </div>

            </div>
        </form >
    )
}
export default AddExpenseForm;