import { Grid, Paper } from "@mui/material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@mui/material";
import { useState } from "react";
import { BootstrapButton } from "../../../utils/BootstrapButton";
import rbiIcon from "./RBI_Icon.svg";
import rbiGuidelineIcon from "./RBI_guidelines.svg";
import React from "react";


const RBIGuideline = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Grid item xs={12} md={5} lg={5} className="deposits-card">
        <Paper
          elevation={24}
          sx={{
            p: 1,
            margin: 1,
            marginTop: 3,
            display: "flex",
            //   flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            height: "15vh",
            boxShadow: 3,
            textAlign: "center",
          }}
          //   onClick={() => setShowGrid(true)}
        >
          <div
            className="row justify-content-center align-content-center w-100"
            style={{ flexDirection: "row", padding: 8 }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <img src={rbiIcon} alt="icon" />
              <div className="guideline-card-header">
                RBI Digital Guidelines
              </div>
              <BootstrapButton
                mr={2}
                aria-selected={"true"}
                style={{
                  borderRadius: "4px",
                }}
                onClick={() => setOpen(true)}
              >
                Know More
              </BootstrapButton>
            </div>
          </div>
        </Paper>
      </Grid>
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
        // fullWidth
      >
        <DialogTitle>{"RBI Digital Guidelines"}</DialogTitle>
        <DialogContent>
          <img src={rbiGuidelineIcon} alt="rbi_doc"/>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default RBIGuideline;
