export const timeIntervalConstants = {
  LTD: "LTD",
  YTD: "YTD",
  HTD: "HTD",
  QTD: "QTD",
  MTD: "MTD",
};

export const timeIntervalLabels = {
  MTD: "Current Month",
  QTD: "Last 3 Months",
  HTD: "Last 6 Months",
  YTD: "Year To Date",
  LTD: "LTD",
};

export const productConstants = {
  // ALL: "",
  PERS: "Personal Loan",
  AUTO: "Auto Loan",
  HOME: "Home Loan",
  BUSI: "Business Loan",
  GOLD: "Gold Loan",
  INSR: "Insurance",
  CRED: "Credit Card",
  EDUL: "Education Loan",
};

export const stateConstants = {
  AN: "Andaman and Nicobar Islands",
  AP: "Andhra Pradesh",
  AR: "Arunachal Pradesh",
  AS: "Assam",
  BR: "Bihar",
  CG: "Chandigarh",
  CH: "Chhattisgarh",
  DN: "Dadra and Nagar Haveli",
  DD: "Daman and Diu",
  DL: "Delhi",
  GA: "Goa",
  GJ: "Gujarat",
  HR: "Haryana",
  HP: "Himachal Pradesh",
  JK: "Jammu and Kashmir",
  JH: "Jharkhand",
  KA: "Karnataka",
  KL: "Kerala",
  LA: "Ladakh",
  LD: "Lakshadweep",
  MP: "Madhya Pradesh",
  MH: "Maharashtra",
  MN: "Manipur",
  ML: "Meghalaya",
  MZ: "Mizoram",
  NL: "Nagaland",
  OR: "Odisha",
  PY: "Puducherry",
  PB: "Punjab",
  RJ: "Rajasthan",
  SK: "Sikkim",
  TN: "Tamil Nadu",
  TS: "Telangana",
  TR: "Tripura",
  UP: "Uttar Pradesh",
  UK: "Uttarakhand",
  WB: "West Bengal",
};

export const stateNameToCodeMapping = {
  "Andaman and Nicobar Islands": "AN",
  "Andhra Pradesh": "AP",
  "Arunachal Pradesh": "AR",
  "Assam": "AS",
  "Bihar": "BR",
  "Chandigarh": "CG",
  "Chhattisgarh": "CH",
  "Dadra and Nagar Haveli": "DN",
  "Daman and Diu": "DD",
  "Delhi": "DL",
  "Goa": "GA",
  "Gujarat": "GJ",
  "Haryana": "HR",
  "Himachal Pradesh": "HP",
  "Jammu and Kashmir": "JK",
  "Jharkhand": "JH",
  "Karnataka": "KA",
  "Kerala": "KL",
  "Ladakh": "LA",
  "Lakshadweep": "LD",
  "Madhya Pradesh": "MP",
  "Maharashtra": "MH",
  "Manipur": "MN",
  "Meghalaya": "ML",
  "Mizoram": "MZ",
  "Nagaland": "NL",
  "Odisha": "OR",
  "Puducherry": "PY",
  "Punjab": "PB",
  "Rajasthan": "RJ",
  "Sikkim": "SK",
  "Tamil Nadu": "TN",
  "Telangana": "TS",
  "Tripura": "TR",
  "Uttar Pradesh": "UP",
  "Uttarakhand": "UK",
  "West Bengal": "WB"
}

export const bankNameConstants = {
  "BAJAJ HOUSING FINANCE LIMITED": "BAJAJHFL",
  "Aadhar Housing Finance Limited": "AADHARHFL",
  "Aditya Birla Finance Limited": "ADFL",
  "ADITYA BIRLA HOUSING FINANCE LIMITED": "ADHFL",
  "ARKA FINCORP": "ARKAFIN",
  "ASHV FINANCE LIMITED": "ASHV",
  "Avanse Financial Services Ltd": "AVANSEFSL",
  "AXIS BANK": "AXIS",
  "AXIS FINANCE LIMITED": "AXISF",
  "BAJAJ FINANCE LIMITED": "BAJFINL",
  "BANDHAN BANK LIMITED": "BANDHAN",
  "BANK OF BARODA": "BOB",
  "CANARA BANK": "CANARA",
  "Cholamandalam Investment and Finance Company Ltd": "CHOLAIFCL",
  "Clix Capital Services Pvt Ltd": "CLIX",
  "CREDENC": "CREDENC",
  "CREDIT SAISON(KISETSU SAISON FINANCE (INDIA) PVT LTD)": "CSPL",
  "DCB BANK": "DCB",
  "DEUTSCHE BANK": "DGZ",
  "Dhanvarsha Finvest Ltd": "DHANVFL",
  "ECL Finance Limited": "ECLF",
  "Edelweiss Housing Finance Limited": "EDELHFL",
  "Equitas Small Finance Bank Limited": "EQTSFBL",
  "FEDBANK FINANCIAL SERVICES LIMITED": "FEDBANKFSL",
  "FEDERAL BANK": "FEDBANK",
  "Finnable Technologies Pvt Ltd": "FINNABLE",
  "FINPLEX TECHNOLOGIES PVT LTD": "FINPLEX",
  "Fintree Finance Pvt Ltd": "FINTREEPL",
  "FINZY(Bridge Fintech Solutions Pvt Ltd)": "FINZY",
  "FIRELIGHT(TEZZRACT FINTECH PRIVATE LIMITED)": "FIRELIGHTPL",
  "FTCASH(NOMISMA MOBILE SOLUTIONS PVT LTD)": "FTCASHPL",
  "FULLERTON INDIA CREDIT COMPANY LIMITED": "FULLERTON",
  "FULLERTON INDIA HOME FINANCE COMPANY LTD": "FULLERTONIHFL",
  "Growth Source Financial Technologies Pvt Ltd": "GRWTHSRC",
  "H BANK": "H BANK",
  "HERO FINCORP LIMITED": "HEROF",
  "Housing Development Finance Corporation": "HOUSINGDFC",
  "ICICI BANK": "ICICI",
  "ICICI Home Finance Company": "ICICIHFL",
  "IDFC FIRST BANK": "IDBIFRST",
  "IIFL FINANCE LIMITED": "IIFL",
  "IKF FINANCE - HL & LAP": "IKF",
  "INCRED FINANCIAL SERVICES LIMITED": "INCRED",
  "India Infoline Housing Finance Ltd": "IIHFL",
  "India Shelter Finance Corporation Ltd": "INDSHEL",
  "INDIFI TECHNOLOGIES PRIVATE LIMITED": "INDIFITPL",
  "Inditrade Fincorp Limited": "INDITRADE",
  "INDOSTAR CAPITAL FINANCE LIMITED": "INDOSTARCFL",
  "INDUSIND BANK": "INDUSIND",
  "KOTAK MAHINDRA BANK": "KMBL",
  "L&T Finance Ltd": "LTFL",
  "LENDINGKART FINANCE LIMITED": "LENDINGKART",
  "LIC Housing Finance Limited": "LICHOUSFL",
  "Loantap Financial Technologies Private Limited": "LOANTAP",
  "MOTILAL OSWAL HOME FINANCE LIMITED": "MOTILALFL",
  "MUTHOOT FINANCE LIMITED": "MUTHOOTFIN",
  "NeoGrowth Credit Pvt Ltd": "NEOGROWC",
  "Oxyzo Financial Services Pvt Ltd": "OXYZOFIN",
  "PaySense Services India Private Limited": "PAYSENSE",
  "Piramal Capital & Housing Finance Limited": "PIRAMALCHFL",
  "PNB Housing Finance Limited": "PNBHOUSFL",
  "POONAWALLA FINCORP LIMITED": "POONAWALAFIN",
  "POONAWALLA HOUSING FINANCE LIMITED": "POONAWALAHFL",
  "profectus capital private limited": "PROFECTUSCPL",
  "RBL BANK LIMITED": "RBLBL",
  "RUPEEK FINTECH PRIVATE LIMITED": "RUPEEKFPL",
  "RUPTOK FINTECH PVT LTD": "RUPTOKFPL",
  "SHINHAN BANK": "SHINHAN",
  "SHRIRAM CITY UNION FINANCE LTD": "SCUFL",
  "Shriram Housing Finance Ltd": "SHFL",
  "SME CORNER(Digikredit Finance Pvt Ltd)": "SMECONR",
  "SOUTH INDIAN BANK": "SIB",
  "STANDARD CHARTERED BANK": "SCB",
  "SUNDHARAM BNP Paribhas Home finance LTD": "SUNDARAMBNP",
  "TATA CAPITAL FINANCIAL SERVICES LIMITED": "TATACAP",
  "tata capital housing finance limited": "TATACAPHFL",
  "UGRO CAPITAL LTD": "UGRO",
  "UNION BANK OF INDIA": "UNION",
  "UNITY SMALL FINANCE BANK": "UNITYSFB",
  "UPWARDS FINTECH SERVICES PRIVATE LIMITED": "UFSPL",
  "VASTU HOUSING FINANCE CORPORATION LIMITED": "VHFCL",
  "VISTAAR FINANCIAL SERVICES PVT LTD": "VISTAARFSPL",
  "YES BANK": "YES",
  "MAHINDRA AND MAHINDRA FINANCIAL SERVICES LIMITED": "MNMFSL",
  "SBM Bank India": "SBMI",
  "Stashfin": "STSHFIN"
};

export const bankCodeConstants = {
  BAJAJHFL: "BAJAJ HOUSING FINANCE LIMITED",
  AADHARHFL: "Aadhar Housing Finance Limited",
  ADFL: "Aditya Birla Finance Limited",
  ADHFL: "ADITYA BIRLA HOUSING FINANCE LIMITED",
  ARKAFIN: "ARKA FINCORP",
  ASHV: "ASHV FINANCE LIMITED",
  AVANSEFSL: "Avanse Financial Services Ltd",
  AXIS: "AXIS BANK",
  AXISF: "AXIS FINANCE LIMITED",
  BAJFINL: "BAJAJ FINANCE LIMITED",
  BANDHAN: "BANDHAN BANK LIMITED",
  BOB: "BANK OF BARODA",
  CANARA: "CANARA BANK",
  CHOLAIFCL: "Cholamandalam Investment and Finance Company Ltd",
  CLIX: "Clix Capital Services Pvt Ltd",
  CREDENC: "CREDENC",
  CSPL: "CREDIT SAISON(KISETSU SAISON FINANCE (INDIA) PVT LTD)",
  DCB: "DCB BANK",
  DGZ: "DEUTSCHE BANK",
  DHANVFL: "Dhanvarsha Finvest Ltd",
  ECLF: "ECL Finance Limited",
  EDELHFL: "Edelweiss Housing Finance Limited",
  EQTSFBL: "Equitas Small Finance Bank Limited",
  FEDBANKFSL: "FEDBANK FINANCIAL SERVICES LIMITED",
  FEDBANK: "FEDERAL BANK",
  FINNABLE: "Finnable Technologies Pvt Ltd",
  FINPLEX: "FINPLEX TECHNOLOGIES PVT LTD",
  FINTREEPL: "Fintree Finance Pvt Ltd",
  FINZY: "FINZY(Bridge Fintech Solutions Pvt Ltd)",
  FIRELIGHTPL: "FIRELIGHT(TEZZRACT FINTECH PRIVATE LIMITED)",
  FTCASHPL: "FTCASH(NOMISMA MOBILE SOLUTIONS PVT LTD)",
  FULLERTON: "FULLERTON INDIA CREDIT COMPANY LIMITED",
  FULLERTONIHFL: "FULLERTON INDIA HOME FINANCE COMPANY LTD",
  GRWTHSRC: "Growth Source Financial Technologies Pvt Ltd",
  "H BANK": "H BANK",
  HEROF: "HERO FINCORP LIMITED",
  HOUSINGDFC: "Housing Development Finance Corporation",
  ICICI: "ICICI BANK",
  ICICIHFL: "ICICI Home Finance Company",
  IDBIFRST: "IDFC FIRST BANK",
  IIFL: "IIFL FINANCE LIMITED",
  IKF: "IKF FINANCE - HL & LAP",
  INCRED: "INCRED FINANCIAL SERVICES LIMITED",
  IIHFL: "India Infoline Housing Finance Ltd",
  INDSHEL: "India Shelter Finance Corporation Ltd",
  INDIFITPL: "INDIFI TECHNOLOGIES PRIVATE LIMITED",
  INDITRADE: "Inditrade Fincorp Limited",
  INDOSTARCFL: "INDOSTAR CAPITAL FINANCE LIMITED",
  INDUSIND: "INDUSIND BANK",
  KMBL: "KOTAK MAHINDRA BANK",
  LTFL: "L&T Finance Ltd",
  LENDINGKART: "LENDINGKART FINANCE LIMITED",
  LICHOUSFL: "LIC Housing Finance Limited",
  LOANTAP: "Loantap Financial Technologies Private Limited",
  MOTILALFL: "MOTILAL OSWAL HOME FINANCE LIMITED",
  MUTHOOTFIN: "MUTHOOT FINANCE LIMITED",
  NEOGROWC: "NeoGrowth Credit Pvt Ltd",
  OXYZOFIN: "Oxyzo Financial Services Pvt Ltd",
  PAYSENSE: "PaySense Services India Private Limited",
  PIRAMALCHFL: "Piramal Capital & Housing Finance Limited",
  PNBHOUSFL: "PNB Housing Finance Limited",
  POONAWALAFIN: "POONAWALLA FINCORP LIMITED",
  POONAWALAHFL: "POONAWALLA HOUSING FINANCE LIMITED",
  PROFECTUSCPL: "profectus capital private limited",
  RBLBL: "RBL BANK LIMITED",
  RUPEEKFPL: "RUPEEK FINTECH PRIVATE LIMITED",
  RUPTOKFPL: "RUPTOK FINTECH PVT LTD",
  SHINHAN: "SHINHAN BANK",
  SCUFL: "SHRIRAM CITY UNION FINANCE LTD",
  SHFL: "Shriram Housing Finance Ltd",
  SMECONR: "SME CORNER(Digikredit Finance Pvt Ltd)",
  SIB: "SOUTH INDIAN BANK",
  SCB: "STANDARD CHARTERED BANK",
  SUNDARAMBNP: "SUNDHARAM BNP Paribhas Home finance LTD",
  TATACAP: "TATA CAPITAL FINANCIAL SERVICES LIMITED",
  TATACAPHFL: "tata capital housing finance limited",
  UGRO: "UGRO CAPITAL LTD",
  UNION: "UNION BANK OF INDIA",
  UNITYSFB: "UNITY SMALL FINANCE BANK",
  UFSPL: "UPWARDS FINTECH SERVICES PRIVATE LIMITED",
  VHFCL: "VASTU HOUSING FINANCE CORPORATION LIMITED",
  VISTAARFSPL: "VISTAAR FINANCIAL SERVICES PVT LTD",
  YES: "YES BANK",
  "MNMFSL": "MAHINDRA AND MAHINDRA FINANCIAL SERVICES LIMITED",
  "SBMI": "SBM Bank India",
  "STSHFIN": 'Stashfin'
};

export const bankNameConstantsForAccount = {
  "AAVAS FINANCIERS LTD": "AAVAS",
  "ADITYA BIRLA": "ABC",
  "THE AKOLA DISTRICT CENTRAL COOPERATIVE BANK": "ADCC",
  "Aditya Birla Finance Limited": "ADFL",
  "ADITYA BIRLA HOUSING FINANCE LIMITED": "ADHFL",
  "AKOLA JANATA COMMERCIAL COOPERATIVE BANK": "AKJB",
  "AHMEDABAD MERCANTILE COOPERATIVE BANK": "AMCB",
  "ANGEL BROKING": "ANGEL",
  "AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED": "ANZ",
  "AIRTEL PAYMENTS BANK LIMITED": "APBL",
  "THE ANDHRA PRADESH STATE COOPERATIVE BANK LIMITED": "APCOB",
  "ANDHRA PRAGATHI GRAMEENA BANK": "APGB",
  "THE A.P. MAHESH COOPERATIVE URBAN BANK LIMITED": "APMC",
  "APNA SAHAKARI BANK LIMITED": "APNA",
  "ARKA FINCORP": "ARKAFIN",
  "AROHAN FINANCE": "AROHAN",
  "ASHV FINANCE LIMITED": "ASHV",
  "ASPIRE HL": "ASPIRE",
  "AU SMALL FINANCE BANK": "AUSMB",
  "Avanse Financial Services Ltd": "AVANSEFSL",
  "AXIS BANK": "AXIS",
  "AXIS FINANCE LIMITED": "AXISF",
  "AXIS SECURITIES LIMITED": "AXISSL",
  "BASSEIN CATHOLIC COOPERATIVE BANK LIMITED": "BACB",
  "BAJAJ ALLIANZ LIFE INSURANCE": "BAJALI",
  "BAJAJ FINSERV": "BAJFIN",
  "BAJAJ FINANCE LIMITED": "BAJFINL",
  "BAJAJ HOUSING FINANCE LIMITED": "BAJAJHFL",
  "BANDHAN BANK LIMITED": "BANDHAN",
  "BARCLAYS BANK": "BARC",
  "BASIX": "BASIX",
  "BHARTI AXA LIFE INSURANCE": "BAXALIFE",
  "BANK BAZAAR INDIA": "BBAZAR",
  "BHARAT COOPERATIVE BANK MUMBAI LIMITED": "BCBM",
  "BELL FINVEST INDIA": "BELLFI",
  "BHARAT FINANCIAL INCLUSION LIMITED": "BFIL",
  "BANK INTERNASIONAL INDONESIA": "BII",
  "BMA WEALTH CREATORS LTD": "BMAWCL",
  "BHARATIYA MAHILA BANK LIMITED": "BMBL",
  "THE BANK OF NOVA SCOTIA": "BNS",
  "BANK OF BARODA": "BOB",
  "BANK OF AMERICA": "BOFA",
  "BANK OF INDIA": "BOI",
  "BANK OF MAHARASHTRA": "BOM",
  "BANK OF TOKYO MITSUBISHI LIMITED": "BTMU",
  "CANARA BANK": "CANARA",
  "CARE HEALTH INSURANCE COMPANY": "CARE",
  "COMMONWEALTH BANK OF AUSTRALIA": "CBA",
  "CENTRAL BANK OF INDIA": "CBI",
  "CITIZEN CREDIT COOPERATIVE BANK LIMITED": "CCCBL",
  "CENTRUM FINANCE": "CENTRUMF",
  "CAPITAL FLOAT": "CFLOAT",
  "CHOLAMANDALAM HL": "CHOLAHL",
  "Cholamandalam Investment and Finance Company Ltd": "CHOLAIFCL",
  "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK CALYON BANK": "CIB",
  "CITI BANK": "CITI",
  "CITY BANK": "CITY",
  "Clix Capital Services Pvt Ltd": "CLIX",
  "CORPORATION BANK": "CORP",
  "THE COSMOS CO OPERATIVE BANK LIMITED": "COSB",
  "COSMOS CO-OPERATIVE BANK": "COSMOS",
  "CREDENC": "CREDENC",
  "CRISIL LIMITED": "CRISIL",
  "CATHOLIC SYRIAN BANK LIMITED": "CSB",
  "CSB BANK": "CSBB",
  "CAPITAL SMALL FINANCE BANK LIMITED": "CSFB",
  "CREDIT SAISON(KISETSU SAISON FINANCE (INDIA) PVT LTD)": "CSPL",
  "CITY UNION BANK LIMITED": "CUBL",
  "DBS": "DBS",
  "DCB BANK": "DCB",
  "DENA BANK": "DENA",
  "DEOGIRI NAGARI SAHAKARI BANK LTD. AURANGABAD": "DEOB",
  "DEUTSCHE BANK": "DGZ",
  "DHFL": "DHFL",
  "Dhanvarsha Finvest Ltd": "DHANVFL",
  "DEPOSIT INSURANCE AND CREDIT GUARANTEE CORPORATION": "DICGC",
  "DHANLAKSHMI BANK": "DLB",
  "DNS BANK": "DNS",
  "DOMBIVLI NAGARI SAHAKARI BANK LIMITED": "DNSBL",
  "DOHA BANK QSC": "DOHA",
  "EDELWISE BANKING": "EDELBANK",
  "EDELWEISS FINANCIAL SERVICES LIMITED": "EDELFSL",
  "Edelweiss Housing Finance Limited": "EDELHFL",
  "EASY HOME FINANCE LIMITED": "EHFL",
  "ECL Finance Limited": "ECLF",
  "Equitas Small Finance Bank Limited": "EQTSFBL",
  "ESAF BANK": "ESAF",
  "EXPORT IMPORT BANK OF INDIA": "EXIM",
  "FEDERAL BANK": "FEDBANK",
  "FEDBANK FINANCIAL SERVICES LIMITED": "FEDBANKFSL",
  "Finnable Technologies Pvt Ltd": "FINNABLE",
  "FINO PAYMENTS BANK LTD": "FINO",
  "FINPLEX TECHNOLOGIES PVT LTD": "FINPLEX",
  "FINZY(Bridge Fintech Solutions Pvt Ltd)": "FINZY",
  "Fintree Finance Pvt Ltd": "FINTREEPL",
  "FIRSTRAND BANK LIMITED": "FIRSTRAND",
  "FIRELIGHT(TEZZRACT FINTECH PRIVATE LIMITED)": "FIRELIGHTPL",
  "FTCASH(NOMISMA MOBILE SOLUTIONS PVT LTD)": "FTCASHPL",
  "FULLERTON INDIA CREDIT COMPANY LIMITED": "FULLERTON",
  "FULLERTON INDIA CREDIT COMPANY": "FULTONICC",
  "FULLERTON INDIA HOME FINANCE COMPANY LTD": "FULLERTONIHFL",
  "FUTURE GENERALI LIFE INSURANCE": "FUTGLI",
  "THE GREATER BOMBAY COOPERATIVE BANK LIMITED": "GBCB",
  "THE GADCHIROLI DISTRICT CENTRAL COOPERATIVE BANK LIMITED": "GDCB",
  "GEOJIT FINANCIAL": "GEOJIT",
  "GRAMEEN KOOTA": "GRMNKOTA",
  "Growth Source Financial Technologies Pvt Ltd": "GRWTHSRC",
  "GURGAON GRAMIN BANK": "GURGRAM",
  "ZILA SAHAKRI BANK LIMITED GHAZIABAD": "GZSBL",
  "THE HASTI COOP BANK LTD": "HASTI",
  "H BANK": "H BANK",
  "HOME CREDIT INDIA": "HCRED",
  "HDB FINANCIAL SERVICES": "HDB",
  "HERO FINCORP LIMITED": "HEROF",
  "HOME FIRST FINANCE COMPANY HL": "HFFCL",
  "HINDUJA LEYLAND FINANCE": "HINDLEYF",
  "Housing Development Finance Corporation": "HOUSINGDFC",
  "HIMACHAL PRADESH STATE COOPERATIVE BANK LTD": "HPSCBL",
  "HSBC BANK": "HSBC",
  "HSBC BANK OMAN SAOG": "HSBCSAOG",
  "INDUSTRIAL BANK OF KOREA": "IBK",
  "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED": "ICBC",
  "ICICI BANK": "ICICI",
  "ICICI Home Finance Company": "ICICIHFL",
  "IDBI BANK": "IDBI",
  "IDFC FIRST BANK": "IDBIFRST",
  "INDIA FIRST LIFE INSURANCE": "IFLI",
  "IIFL FINANCE LIMITED": "IIFL",
  "India Infoline Housing Finance Ltd": "IIHFL",
  "IKF FINANCE HL &amp; LAP": "IKF",
  "INCRED FINANCIAL SERVICES LIMITED": "INCRED",
  "INDIABULLS HOUSING FINANCE LTD": "INDBULHFL",
  "INDIAN BANK": "INDIAN",
  "INDIFI FINANCE": "INDIFIFIN",
  "INDIFI TECHNOLOGIES PRIVATE LIMITED": "INDIFITPL",
  "Inditrade Fincorp Limited": "INDITRADE",
  "INDO STAR LAP": "INDOSTAR",
  "INDOSTAR CAPITAL FINANCE LIMITED": "INDOSTARCFL",
  "India Shelter Finance Corporation Ltd": "INDSHEL",
  "INDUSIND BANK": "INDUSIND",
  "INDIAN OVERSEAS BANK": "IOB",
  "INDIAN POST PAYMENTS BANK": "IPPB",
  "JANATA SAHAKARI BANK LIMITED": "JANATA",
  "JANAKALYAN SAHAKARI BANK LIMITED": "JANKAL",
  "JANASEVA SAHAKARI BANK LIMITED": "JANSEVA",
  "JALGAON JANATA SAHAKARI BANK LIMITED": "JJSB",
  "JAMMU AND KASHMIR BANK LIMITED": "JKBL",
  "THE JALGAON PEOPELS COOPERATIVE BANK LIMITED": "JPCB",
  "JP MORGAN BANK": "JPMORGAN",
  "JANA SMALL FINANCE BANK": "JSFB",
  "THE KANGRA CENTRAL COOPERATIVE BANK LIMITED": "KACE",
  "KALLAPPANNA AWADE ICHALKARANJI JANATA SAHAKARI BANK LIMITED": "KAIJ",
  "KALUPUR COMMERCIAL COOPERATIVE BANK": "KALUCCB",
  "THE KANGRA COOPERATIVE BANK LIMITED": "KANG",
  "KAPOL COOPERATIVE BANK LIMITED": "KAPOL",
  "KAPITAL TECH": "KAPTECH",
  "KARVY FINAPOLIS": "KARVYFINPOL",
  "KARVY FINTECH": "KARVYFINTEC",
  "KARNATAKA BANK LIMITED": "KBL",
  "KEB HANA BANK": "KEBHANA",
  "KERALA GRAMIN BANK": "KERALAGRM",
  "KALYAN JANATA SAHAKARI BANK": "KJSB",
  "KOTAK MAHINDRA BANK": "KMBL",
  "THE KURMANCHAL NAGAR SAHAKARI BANK LIMITED": "KNSB",
  "THE KARANATAKA STATE COOPERATIVE APEX BANK LIMITED": "KSCAPEX",
  "KARUR VYASA BANK": "KVB",
  "KVB BANK HL": "KVBHL",
  "KARNATAKA VIKAS GRAMEENA BANK": "KVGB",
  "LENDINGKART FINANCE LIMITED": "LENDINGKART",
  "LIC HOUSING HL": "LICHOUS",
  "LIC Housing Finance Limited": "LICHOUSFL",
  "Loantap Financial Technologies Private Limited": "LOANTAP",
  "L&amp;T Finance Ltd": "LTFL",
  "LAKSHMI VILAS BANK": "LVB",
  "MAGMA FINCORP LTD.": "MAGMA",
  "MAHARASHTRA GRAMIN BANK": "MAHAGRAMIN",
  "MAHINDRA FINANCE": "MAHIFIN",
  "MANAPPURRAM FINANCE LIMITED": "MANAPURAMFIN",
  "MANIPAL TECHNOLOGIES LIMITED": "MANIPALTEC",
  "MASHREQBANK PSC": "MASHREQBANK",
  "THE MAX GROUP": "MAX",
  "MAX BUPA HEALTH INSURANCE": "MAXBUPAHEALTH",
  "MAHANAGAR COOPERATIVE BANK": "MCBL",
  "THE MUMBAI DISTRICT CENTRAL COOPERATIVE BANK LIMITED": "MDCCB",
  "MIZUHO BANK LTD": "MIZUHO",
  "MOTILAL OSWAL HOME FINANCE LIMITED": "MOTILALFL",
  "THE MEHSANA URBAN COOPERATIVE BANK": "MSNU",
  "THE MUNICIPAL COOPERATIVE BANK LIMITED": "MUBL",
  "MUTHOOT": "MUTHOOT",
  "MUTHOOT FINANCE LIMITED": "MUTHOOTFIN",
  "MUTHOOT FINCORP": "MUTHOOTFINC",
  "THE MUTHOOT GROUP": "MUTHOOTGRP",
  "MYSHUBLIFE": "MYSHUBLIFE",
  "NATIONAL AUSTRALIA BANK LIMITED": "NAB",
  "NABARD - NATIONAL BANK FOR AGRICULTURE AND RURAL DEVELOPMENT": "NABARD",
  "NATIONAL BANK OF ABU DHABI PJSC": "NBAD",
  "NCNL FINANCE PRIVATE LIMITED": "NCNL",
  "NEOGROWTH RENEWAL CASES": "NEOGROW",
  "NeoGrowth Credit Pvt Ltd": "NEOGROWC",
  "NORTH EAST SMALL FINANCE BANK": "NESFB",
  "NEW INDIA COOPERATIVE BANK LIMITED": "NICBL",
  "NKGSB COOPERATIVE BANK LIMITED": "NKGSB",
  "THE NASIK MERCHANTS COOPERATIVE BANK LIMITED": "NMCB",
  "NORTH MALABAR GRAMIN BANK": "NMGB",
  "NAGPUR NAGARIK SAHAKARI BANK LIMITED": "NNSB",
  "NUTAN NAGARIK SAHAKARI BANK LIMITED": "NNSBL",
  "THE NAINITAL BANK LIMITED": "NTB",
  "NAGAR URBAN CO OPERATIVE BANK": "NUCB",
  "ORIENTAL BANK OF COMMERCE": "OBC",
  "OBC INDIA": "OBCI",
  "OCWEN FINANCIAL SOLUTIONS PVT. LTD. - APAC": "OCWEN",
  "Oxyzo Financial Services Pvt Ltd": "OXYZOFIN",
  "PARIBAS": "PARIBAS",
  "PARSIK BANK": "PARSIK",
  "PaySense Services India Private Limited": "PAYSENSE",
  "PAYTM": "PAYTM",
  "PIRAMAL HL": "PIRAMAL",
  "Piramal Capital & Housing Finance Limited": "PIRAMALCHFL",
  "PRAGATHI KRISHNA GRAMIN BANK": "PKGB",
  "PUNJAB AND MAHARSHTRA COOPERATIVE BANK": "PMC",
  "PUNJAB NATIONAL BANK": "PNB",
  "PNB HOUSING HL": "PNBHOUS",
  "PNB Housing Finance Limited": "PNBHOUSFL",
  "POONAWALA": "POONAWALA",
  "POONAWALLA FINCORP LIMITED": "POONAWALAFIN",
  "POONAWALLA HOUSING FINANCE LIMITED": "POONAWALAHFL",
  "PRATHAMA BANK": "PRATHAMA",
  "PRIME COOPERATIVE BANK LIMITED": "PRIMECOOP",
  "PROFECTUS CAPITAL LAP": "PROFECTUS",
  "profectus capital private limited": "PROFECTUSCPL",
  "PUNJAB AND SIND BANK": "PSB",
  "PT BANK MAYBANK INDONESIA TBK": "PTBMI",
  "THE PANDHARPUR URBAN CO OP. BANK LTD. PANDHARPUR": "PUCB",
  "RESERVE BANK OF INDIA": "RBI",
  "RBL BANK": "RBL",
  "RBL BANK LIMITED": "RBLBL",
  "THE ROYAL BANK OF SCOTLAND": "RBS",
  "RELIGARE": "RELIGARE",
  "RELIANCE CAPTIAL | RELIANCE GROUP": "RILCAP",
  "RELIANCE GENERAL INSURANCE": "RILGEN",
  "RELIANCE LIFE INSURANCE COMPANY": "RILLIC",
  "RAJKOT NAGRIK SAHAKARI BANK LIMITED": "RNSB",
  "RAJGURUNAGAR SAHAKARI BANK LIMITED": "RSBL",
  "THE RAJASTHAN STATE COOPERATIVE BANK LIMITED": "RSCB",
  "RUPEEK FRESH LOANS": "RUPEEK",
  "RUPEEK FINTECH PRIVATE LIMITED": "RUPEEKFPL",
  "RUPTOK FINTECH PVT LTD": "RUPTOKFPL",
  "ROYAL SUNDARAM": "RYLSUN",
  "SAHARA INDIA": "SAHARA",
  "SAHEBRAO DESHMUKH COOPERATIVE BANK LIMITED": "SAHE",
  "STATE BANK OF BIKANER AND JAIPUR": "SBBJ",
  "SBER BANK": "SBER",
  "STATE BANK OF HYDERABAD": "SBH",
  "STATE BANK OF INDIA": "SBI",
  "SBI CAP": "SBICAP",
  "SBM BANK": "SBM",
  "SBM BANK MAURITIUS LIMITED": "SBMBML",
  "STATE BANK OF MAURITIUS LIMITED": "SBML",
  "STATE BANK OF MYSORE": "SBMYS",
  "STATE BANK OF PATIALA": "SBP",
  "STATE BANK OF TRAVANCORE": "SBT",
  "STANDARD CHARTERED BANK": "SCB",
  "SATIN CREDITCARE NETWORK LIMITED": "SCNL",
  "SHRIRAM CITY BANK": "SCUF",
  "Shriram Housing Finance Ltd": "SHFL",
  "SHRIRAM CITY UNION FINANCE LTD": "SCUFL",
  "THE SURAT DISTRICT COOPERATIVE BANK LIMITED": "SDCB",
  "SHAREKHAN": "SHAREKHAN",
  "SURAT NATIONAL COOPERATIVE BANK LIMITED": "SHCBL",
  "SHINHAN BANK": "SHINHAN",
  "SOUTH INDIAN BANK": "SIB",
  "SUMITOMO MITSUI BANKING CORPORATION": "SMBC",
  "SHIVALIK MERCANTILE CO OPERATIVE BANK LTD": "SMCB",
  "SME CORNER(Digikredit Finance Pvt Ltd)": "SMECONR",
  "SAMARTH SAHAKARI BANK LTD": "SMRTH",
  "SOCIETE GENERALE": "SOCGEN",
  "THE SURATH PEOPLES COOPERATIVE BANK LIMITED": "SPCB",
  "SARASWAT COOPERATIVE BANK LIMITED": "SRCB",
  "SREI": "SREI",
  "SHIKSHAK SAHAKARI BANK LIMITED": "SSBL",
  "SURYODAY SMALL FINANCE BANK": "SSFB",
  "STAR HEALTH INSURANCE": "STARHEALTH",
  "SHRIRAM TRANSPORT FINANCE COMPANY LIMITED": "STFC",
  "SUNDHARAM BNP Paribhas Home finance LTD": "SUNDARAMBNP",
  "SUTEX COOPERATIVE BANK LIMITED": "SUTEX",
  "SVC BANK": "SVC",
  "THE SHAMRAO VITHAL COOPERATIVE BANK": "SVCB",
  "THE SEVA VIKAS COOPERATIVE BANK LIMITED": "SVCBL",
  "SYNDICATE BANK": "SYNDICATE",
  "TATA CAPITAL FINANCIAL SERVICES LIMITED": "TATACAP",
  "tata capital housing finance limited": "TATACAPHFL",
  "THE THANE BHARAT SAHAKARI BANK LIMITED": "TBSB",
  "THE THANE DISTRICT CENTRAL COOPERATIVE BANK LIMITED": "TDCC",
  "TUMKUR GRAIN MERCHANTS COOPERATIVE BANK LIMITED": "TGMC",
  "TJSB SAHAKARI BANK LIMITED": "TJSB1",
  "TJSB SAHAKARI BANK LTD": "TJSB2",
  "TAMILNAD MERCANTILE BANK LIMITED": "TMB",
  "THE TAMIL NADU STATE APEX COOPERATIVE BANK": "TNSC",
  "TVS CREDIT SERVICES": "TVSCRED",
  "UNITED BANK OF INDIA": "UBI",
  "UCO BANK": "UCO",
  "UGRO CAPITAL LTD": "UGRO",
  "AADHAAR HL": "UIDHL",
  "Aadhar Housing Finance Limited": "AADHARHFL",
  "UNION BANK OF INDIA": "UNION",
  "UNITY SMALL FINANCE BANK": "UNITYSFB",
  "UNITED OVERSEAS BANK LIMITED": "UOBL",
  "UPWARDS FINTECH SERVICES PRIVATE LIMITED": "UFSPL",
  "UJJIVAN SMALL FINANCE BANK": "USFB",
  "THE VARACHHA COOPERATIVE BANK LIMITED": "VARA",
  "VASTU FINANCE LAP": "VFLAP",
  "VASTU HOUSING FINANCE CORPORATION LIMITED": "VHFCL",
  "VIJAYA BANK": "VIJAYA",
  "VISTAAR HL": "VISTAAR",
  "VISTAAR FINANCIAL SERVICES PVT LTD": "VISTAARFSPL",
  "THE VISHWESHWAR SAHAKARI BANK LIMITED": "VSBL",
  "VASAI VIKAS SAHAKARI BANK LIMITED": "VVSBL",
  "THE WEST BENGAL STATE COOPERATIVE BANK": "WBSTCB",
  "WESTPAC BANKING CORPORATION": "WESTPAC",
  "WOORI BANK": "WOORI",
  "WILLIS TOWER WATSON": "WTW",
  "YES BANK": "YES",
  "THE ZOROASTRIAN COOPERATIVE BANK LIMITED": "ZCBL",
  "Others": "OTHER",
  "ADHARSH CO-OPERATIVE BANK": "ACOB",
  "APGVB BANK": "APGVB",
  "H Limited": "H Limited",
  "Suco Souharda Sahakari Bank Ltd": "SSSBL",
  "MAHINDRA AND MAHINDRA FINANCIAL SERVICES LIMITED": "MNMFSL",
  "SBM Bank India": "SBMI",
  "Stashfin": "STSHFIN"
};

export const bankCodeConstantsForAccount = {
  "AAVAS": "AAVAS FINANCIERS LTD",
  "ABC": "ADITYA BIRLA",
  "ADCC": "THE AKOLA DISTRICT CENTRAL COOPERATIVE BANK",
  "ADFL": "Aditya Birla Finance Limited",
  "ADHFL": "ADITYA BIRLA HOUSING FINANCE LIMITED",
  "AKJB": "AKOLA JANATA COMMERCIAL COOPERATIVE BANK",
  "AMCB": "AHMEDABAD MERCANTILE COOPERATIVE BANK",
  "ANGEL": "ANGEL BROKING",
  "ANZ": "AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED",
  "APBL": "AIRTEL PAYMENTS BANK LIMITED",
  "APCOB": "THE ANDHRA PRADESH STATE COOPERATIVE BANK LIMITED",
  "APGB": "ANDHRA PRAGATHI GRAMEENA BANK",
  "APMC": "THE A.P. MAHESH COOPERATIVE URBAN BANK LIMITED",
  "APNA": "APNA SAHAKARI BANK LIMITED",
  "ARKAFIN": "ARKA FINCORP",
  "AROHAN": "AROHAN FINANCE",
  "ASHV": "ASHV FINANCE LIMITED",
  "ASPIRE": "ASPIRE HL",
  "AUSMB": "AU SMALL FINANCE BANK",
  "AVANSEFSL": "Avanse Financial Services Ltd",
  "AXIS": "AXIS BANK",
  "AXISF": "AXIS FINANCE LIMITED",
  "AXISSL": "AXIS SECURITIES LIMITED",
  "BACB": "BASSEIN CATHOLIC COOPERATIVE BANK LIMITED",
  "BAJALI": "BAJAJ ALLIANZ LIFE INSURANCE",
  "BAJFIN": "BAJAJ FINSERV",
  "BAJFINL": "BAJAJ FINANCE LIMITED",
  "BAJAJHFL": "BAJAJ HOUSING FINANCE LIMITED",
  "BANDHAN": "BANDHAN BANK LIMITED",
  "BARC": "BARCLAYS BANK",
  "BASIX": "BASIX",
  "BAXALIFE": "BHARTI AXA LIFE INSURANCE",
  "BBAZAR": "BANK BAZAAR INDIA",
  "BCBM": "BHARAT COOPERATIVE BANK MUMBAI LIMITED",
  "BELLFI": "BELL FINVEST INDIA",
  "BFIL": "BHARAT FINANCIAL INCLUSION LIMITED",
  "BII": "BANK INTERNASIONAL INDONESIA",
  "BMAWCL": "BMA WEALTH CREATORS LTD",
  "BMBL": "BHARATIYA MAHILA BANK LIMITED",
  "BNS": "THE BANK OF NOVA SCOTIA",
  "BOB": "BANK OF BARODA",
  "BOFA": "BANK OF AMERICA",
  "BOI": "BANK OF INDIA",
  "BOM": "BANK OF MAHARASHTRA",
  "BTMU": "BANK OF TOKYO MITSUBISHI LIMITED",
  "CANARA": "CANARA BANK",
  "CARE": "CARE HEALTH INSURANCE COMPANY",
  "CBA": "COMMONWEALTH BANK OF AUSTRALIA",
  "CBI": "CENTRAL BANK OF INDIA",
  "CCCBL": "CITIZEN CREDIT COOPERATIVE BANK LIMITED",
  "CENTRUMF": "CENTRUM FINANCE",
  "CFLOAT": "CAPITAL FLOAT",
  "CHOLAHL": "CHOLAMANDALAM HL",
  "CHOLAIFCL": "Cholamandalam Investment and Finance Company Ltd",
  "CIB": "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK CALYON BANK",
  "CITI": "CITI BANK",
  "CITY": "CITY BANK",
  "CLIX": "Clix Capital Services Pvt Ltd",
  "CORP": "CORPORATION BANK",
  "COSB": "THE COSMOS CO OPERATIVE BANK LIMITED",
  "COSMOS": "COSMOS CO-OPERATIVE BANK",
  "CREDENC": "CREDENC",
  "CRISIL": "CRISIL LIMITED",
  "CSB": "CATHOLIC SYRIAN BANK LIMITED",
  "CSBB": "CSB BANK",
  "CSFB": "CAPITAL SMALL FINANCE BANK LIMITED",
  "CSPL": "CREDIT SAISON(KISETSU SAISON FINANCE (INDIA) PVT LTD)",
  "CUBL": "CITY UNION BANK LIMITED",
  "DBS": "DBS",
  "DCB": "DCB BANK",
  "DENA": "DENA BANK",
  "DEOB": "DEOGIRI NAGARI SAHAKARI BANK LTD. AURANGABAD",
  "DGZ": "DEUTSCHE BANK",
  "DHFL": "DHFL",
  "DHANVFL": "Dhanvarsha Finvest Ltd",
  "DICGC": "DEPOSIT INSURANCE AND CREDIT GUARANTEE CORPORATION",
  "DLB": "DHANLAKSHMI BANK",
  "DNS": "DNS BANK",
  "DNSBL": "DOMBIVLI NAGARI SAHAKARI BANK LIMITED",
  "DOHA": "DOHA BANK QSC",
  "EDELBANK": "EDELWISE BANKING",
  "EDELFSL": "EDELWEISS FINANCIAL SERVICES LIMITED",
  "EDELHFL": "Edelweiss Housing Finance Limited",
  "EHFL": "EASY HOME FINANCE LIMITED",
  "ECLF": "ECL Finance Limited",
  "EQTSFBL": "Equitas Small Finance Bank Limited",
  "ESAF": "ESAF BANK",
  "EXIM": "EXPORT IMPORT BANK OF INDIA",
  "FEDBANK": "FEDERAL BANK",
  "FEDBANKFSL": "FEDBANK FINANCIAL SERVICES LIMITED",
  "FINNABLE": "Finnable Technologies Pvt Ltd",
  "FINO": "FINO PAYMENTS BANK LTD",
  "FINPLEX": "FINPLEX TECHNOLOGIES PVT LTD",
  "FINZY": "FINZY(Bridge Fintech Solutions Pvt Ltd)",
  "FINTREEPL": "Fintree Finance Pvt Ltd",
  "FIRSTRAND": "FIRSTRAND BANK LIMITED",
  "FIRELIGHTPL": "FIRELIGHT(TEZZRACT FINTECH PRIVATE LIMITED)",
  "FTCASHPL": "FTCASH(NOMISMA MOBILE SOLUTIONS PVT LTD)",
  "FULLERTON": "FULLERTON INDIA CREDIT COMPANY LIMITED",
  "FULTONICC": "FULLERTON INDIA CREDIT COMPANY",
  "FULLERTONIHFL": "FULLERTON INDIA HOME FINANCE COMPANY LTD",
  "FUTGLI": "FUTURE GENERALI LIFE INSURANCE",
  "GBCB": "THE GREATER BOMBAY COOPERATIVE BANK LIMITED",
  "GDCB": "THE GADCHIROLI DISTRICT CENTRAL COOPERATIVE BANK LIMITED",
  "GEOJIT": "GEOJIT FINANCIAL",
  "GRMNKOTA": "GRAMEEN KOOTA",
  "GRWTHSRC": "Growth Source Financial Technologies Pvt Ltd",
  "GURGRAM": "GURGAON GRAMIN BANK",
  "GZSBL": "ZILA SAHAKRI BANK LIMITED GHAZIABAD",
  "HASTI": "THE HASTI COOP BANK LTD",
  "H BANK": "H BANK",
  "HCRED": "HOME CREDIT INDIA",
  "HDB": "HDB FINANCIAL SERVICES",
  "HEROF": "HERO FINCORP LIMITED",
  "HFFCL": "HOME FIRST FINANCE COMPANY HL",
  "HINDLEYF": "HINDUJA LEYLAND FINANCE",
  "HOUSINGDFC": "Housing Development Finance Corporation",
  "HPSCBL": "HIMACHAL PRADESH STATE COOPERATIVE BANK LTD",
  "HSBC": "HSBC BANK",
  "HSBCSAOG": "HSBC BANK OMAN SAOG",
  "IBK": "INDUSTRIAL BANK OF KOREA",
  "ICBC": "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED",
  "ICICI": "ICICI BANK",
  "ICICIHFL": "ICICI Home Finance Company",
  "IDBI": "IDBI BANK",
  "IDBIFRST": "IDFC FIRST BANK",
  "IFLI": "INDIA FIRST LIFE INSURANCE",
  "IIFL": "IIFL FINANCE LIMITED",
  "IIHFL": "India Infoline Housing Finance Ltd",
  "IKF": "IKF FINANCE HL &amp; LAP",
  "INCRED": "INCRED FINANCIAL SERVICES LIMITED",
  "INDBULHFL": "INDIABULLS HOUSING FINANCE LTD",
  "INDIAN": "INDIAN BANK",
  "INDIFIFIN": "INDIFI FINANCE",
  "INDIFITPL": "INDIFI TECHNOLOGIES PRIVATE LIMITED",
  "INDITRADE": "Inditrade Fincorp Limited",
  "INDOSTAR": "INDO STAR LAP",
  "INDOSTARCFL": "INDOSTAR CAPITAL FINANCE LIMITED",
  "INDSHEL": "India Shelter Finance Corporation Ltd",
  "INDUSIND": "INDUSIND BANK",
  "IOB": "INDIAN OVERSEAS BANK",
  "IPPB": "INDIAN POST PAYMENTS BANK",
  "JANATA": "JANATA SAHAKARI BANK LIMITED",
  "JANKAL": "JANAKALYAN SAHAKARI BANK LIMITED",
  "JANSEVA": "JANASEVA SAHAKARI BANK LIMITED",
  "JJSB": "JALGAON JANATA SAHAKARI BANK LIMITED",
  "JKBL": "JAMMU AND KASHMIR BANK LIMITED",
  "JPCB": "THE JALGAON PEOPELS COOPERATIVE BANK LIMITED",
  "JPMORGAN": "JP MORGAN BANK",
  "JSFB": "JANA SMALL FINANCE BANK",
  "KACE": "THE KANGRA CENTRAL COOPERATIVE BANK LIMITED",
  "KAIJ": "KALLAPPANNA AWADE ICHALKARANJI JANATA SAHAKARI BANK LIMITED",
  "KALUCCB": "KALUPUR COMMERCIAL COOPERATIVE BANK",
  "KANG": "THE KANGRA COOPERATIVE BANK LIMITED",
  "KAPOL": "KAPOL COOPERATIVE BANK LIMITED",
  "KAPTECH": "KAPITAL TECH",
  "KARVYFINPOL": "KARVY FINAPOLIS",
  "KARVYFINTEC": "KARVY FINTECH",
  "KBL": "KARNATAKA BANK LIMITED",
  "KEBHANA": "KEB HANA BANK",
  "KERALAGRM": "KERALA GRAMIN BANK",
  "KJSB": "KALYAN JANATA SAHAKARI BANK",
  "KMBL": "KOTAK MAHINDRA BANK",
  "KNSB": "THE KURMANCHAL NAGAR SAHAKARI BANK LIMITED",
  "KSCAPEX": "THE KARANATAKA STATE COOPERATIVE APEX BANK LIMITED",
  "KVB": "KARUR VYASA BANK",
  "KVBHL": "KVB BANK HL",
  "KVGB": "KARNATAKA VIKAS GRAMEENA BANK",
  "LENDINGKART": "LENDINGKART FINANCE LIMITED",
  "LICHOUS": "LIC HOUSING HL",
  "LICHOUSFL": "LIC Housing Finance Limited",
  "LOANTAP": "Loantap Financial Technologies Private Limited",
  "LTFL": "L&amp;T Finance Ltd",
  "LVB": "LAKSHMI VILAS BANK",
  "MAGMA": "MAGMA FINCORP LTD.",
  "MAHAGRAMIN": "MAHARASHTRA GRAMIN BANK",
  "MAHIFIN": "MAHINDRA FINANCE",
  "MANAPURAMFIN": "MANAPPURRAM FINANCE LIMITED",
  "MANIPALTEC": "MANIPAL TECHNOLOGIES LIMITED",
  "MASHREQBANK": "MASHREQBANK PSC",
  "MAX": "THE MAX GROUP",
  "MAXBUPAHEALTH": "MAX BUPA HEALTH INSURANCE",
  "MCBL": "MAHANAGAR COOPERATIVE BANK",
  "MDCCB": "THE MUMBAI DISTRICT CENTRAL COOPERATIVE BANK LIMITED",
  "MIZUHO": "MIZUHO BANK LTD",
  "MOTILALFL": "MOTILAL OSWAL HOME FINANCE LIMITED",
  "MSNU": "THE MEHSANA URBAN COOPERATIVE BANK",
  "MUBL": "THE MUNICIPAL COOPERATIVE BANK LIMITED",
  "MUTHOOT": "MUTHOOT",
  "MUTHOOTFIN": "MUTHOOT FINANCE LIMITED",
  "MUTHOOTFINC": "MUTHOOT FINCORP",
  "MUTHOOTGRP": "THE MUTHOOT GROUP",
  "MYSHUBLIFE": "MYSHUBLIFE",
  "NAB": "NATIONAL AUSTRALIA BANK LIMITED",
  "NABARD": "NABARD - NATIONAL BANK FOR AGRICULTURE AND RURAL DEVELOPMENT",
  "NBAD": "NATIONAL BANK OF ABU DHABI PJSC",
  "NCNL": "NCNL FINANCE PRIVATE LIMITED",
  "NEOGROW": "NEOGROWTH RENEWAL CASES",
  "NEOGROWC": "NeoGrowth Credit Pvt Ltd",
  "NESFB": "NORTH EAST SMALL FINANCE BANK",
  "NICBL": "NEW INDIA COOPERATIVE BANK LIMITED",
  "NKGSB": "NKGSB COOPERATIVE BANK LIMITED",
  "NMCB": "THE NASIK MERCHANTS COOPERATIVE BANK LIMITED",
  "NMGB": "NORTH MALABAR GRAMIN BANK",
  "NNSB": "NAGPUR NAGARIK SAHAKARI BANK LIMITED",
  "NNSBL": "NUTAN NAGARIK SAHAKARI BANK LIMITED",
  "NTB": "THE NAINITAL BANK LIMITED",
  "NUCB": "NAGAR URBAN CO OPERATIVE BANK",
  "OBC": "ORIENTAL BANK OF COMMERCE",
  "OBCI": "OBC INDIA",
  "OCWEN": "OCWEN FINANCIAL SOLUTIONS PVT. LTD. - APAC",
  "OXYZOFIN": "Oxyzo Financial Services Pvt Ltd",
  "PARIBAS": "PARIBAS",
  "PARSIK": "PARSIK BANK",
  "PAYSENSE": "PaySense Services India Private Limited",
  "PAYTM": "PAYTM",
  "PIRAMAL": "PIRAMAL HL",
  "PIRAMALCHFL": "Piramal Capital & Housing Finance Limited",
  "PKGB": "PRAGATHI KRISHNA GRAMIN BANK",
  "PMC": "PUNJAB AND MAHARSHTRA COOPERATIVE BANK",
  "PNB": "PUNJAB NATIONAL BANK",
  "PNBHOUS": "PNB HOUSING HL",
  "PNBHOUSFL": "PNB Housing Finance Limited",
  "POONAWALA": "POONAWALA",
  "POONAWALAFIN": "POONAWALLA FINCORP LIMITED",
  "POONAWALAHFL": "POONAWALLA HOUSING FINANCE LIMITED",
  "PRATHAMA": "PRATHAMA BANK",
  "PRIMECOOP": "PRIME COOPERATIVE BANK LIMITED",
  "PROFECTUS": "PROFECTUS CAPITAL LAP",
  "PROFECTUSCPL": "profectus capital private limited",
  "PSB": "PUNJAB AND SIND BANK",
  "PTBMI": "PT BANK MAYBANK INDONESIA TBK",
  "PUCB": "THE PANDHARPUR URBAN CO OP. BANK LTD. PANDHARPUR",
  "RBI": "RESERVE BANK OF INDIA",
  "RBL": "RBL BANK",
  "RBLBL": "RBL BANK LIMITED",
  "RBS": "THE ROYAL BANK OF SCOTLAND",
  "RELIGARE": "RELIGARE",
  "RILCAP": "RELIANCE CAPTIAL | RELIANCE GROUP",
  "RILGEN": "RELIANCE GENERAL INSURANCE",
  "RILLIC": "RELIANCE LIFE INSURANCE COMPANY",
  "RNSB": "RAJKOT NAGRIK SAHAKARI BANK LIMITED",
  "RSBL": "RAJGURUNAGAR SAHAKARI BANK LIMITED",
  "RSCB": "THE RAJASTHAN STATE COOPERATIVE BANK LIMITED",
  "RUPEEK": "RUPEEK FRESH LOANS",
  "RUPEEKFPL": "RUPEEK FINTECH PRIVATE LIMITED",
  "RUPTOKFPL": "RUPTOK FINTECH PVT LTD",
  "RYLSUN": "ROYAL SUNDARAM",
  "SAHARA": "SAHARA INDIA",
  "SAHE": "SAHEBRAO DESHMUKH COOPERATIVE BANK LIMITED",
  "SBBJ": "STATE BANK OF BIKANER AND JAIPUR",
  "SBER": "SBER BANK",
  "SBH": "STATE BANK OF HYDERABAD",
  "SBI": "STATE BANK OF INDIA",
  "SBICAP": "SBI CAP",
  "SBM": "SBM BANK",
  "SBMBML": "SBM BANK MAURITIUS LIMITED",
  "SBML": "STATE BANK OF MAURITIUS LIMITED",
  "SBMYS": "STATE BANK OF MYSORE",
  "SBP": "STATE BANK OF PATIALA",
  "SBT": "STATE BANK OF TRAVANCORE",
  "SCB": "STANDARD CHARTERED BANK",
  "SCNL": "SATIN CREDITCARE NETWORK LIMITED",
  "SCUF": "SHRIRAM CITY BANK",
  "SHFL": "Shriram Housing Finance Ltd",
  "SCUFL": "SHRIRAM CITY UNION FINANCE LTD",
  "SDCB": "THE SURAT DISTRICT COOPERATIVE BANK LIMITED",
  "SHAREKHAN": "SHAREKHAN",
  "SHCBL": "SURAT NATIONAL COOPERATIVE BANK LIMITED",
  "SHINHAN": "SHINHAN BANK",
  "SIB": "SOUTH INDIAN BANK",
  "SMBC": "SUMITOMO MITSUI BANKING CORPORATION",
  "SMCB": "SHIVALIK MERCANTILE CO OPERATIVE BANK LTD",
  "SMECONR": "SME CORNER(Digikredit Finance Pvt Ltd)",
  "SMRTH": "SAMARTH SAHAKARI BANK LTD",
  "SOCGEN": "SOCIETE GENERALE",
  "SPCB": "THE SURATH PEOPLES COOPERATIVE BANK LIMITED",
  "SRCB": "SARASWAT COOPERATIVE BANK LIMITED",
  "SREI": "SREI",
  "SSBL": "SHIKSHAK SAHAKARI BANK LIMITED",
  "SSFB": "SURYODAY SMALL FINANCE BANK",
  "STARHEALTH": "STAR HEALTH INSURANCE",
  "STFC": "SHRIRAM TRANSPORT FINANCE COMPANY LIMITED",
  "SUNDARAMBNP": "SUNDHARAM BNP Paribhas Home finance LTD",
  "SUTEX": "SUTEX COOPERATIVE BANK LIMITED",
  "SVC": "SVC BANK",
  "SVCB": "THE SHAMRAO VITHAL COOPERATIVE BANK",
  "SVCBL": "THE SEVA VIKAS COOPERATIVE BANK LIMITED",
  "SYNDICATE": "SYNDICATE BANK",
  "TATACAP": "TATA CAPITAL FINANCIAL SERVICES LIMITED",
  "TATACAPHFL": "tata capital housing finance limited",
  "TBSB": "THE THANE BHARAT SAHAKARI BANK LIMITED",
  "TDCC": "THE THANE DISTRICT CENTRAL COOPERATIVE BANK LIMITED",
  "TGMC": "TUMKUR GRAIN MERCHANTS COOPERATIVE BANK LIMITED",
  "TJSB1": "TJSB SAHAKARI BANK LIMITED",
  "TJSB2": "TJSB SAHAKARI BANK LTD",
  "TMB": "TAMILNAD MERCANTILE BANK LIMITED",
  "TNSC": "THE TAMIL NADU STATE APEX COOPERATIVE BANK",
  "TVSCRED": "TVS CREDIT SERVICES",
  "UBI": "UNITED BANK OF INDIA",
  "UCO": "UCO BANK",
  "UGRO": "UGRO CAPITAL LTD",
  "UIDHL": "AADHAAR HL",
  "AADHARHFL": "Aadhar Housing Finance Limited",
  "UNION": "UNION BANK OF INDIA",
  "UNITYSFB": "UNITY SMALL FINANCE BANK",
  "UOBL": "UNITED OVERSEAS BANK LIMITED",
  "UFSPL": "UPWARDS FINTECH SERVICES PRIVATE LIMITED",
  "USFB": "UJJIVAN SMALL FINANCE BANK",
  "VARA": "THE VARACHHA COOPERATIVE BANK LIMITED",
  "VFLAP": "VASTU FINANCE LAP",
  "VHFCL": "VASTU HOUSING FINANCE CORPORATION LIMITED",
  "VIJAYA": "VIJAYA BANK",
  "VISTAAR": "VISTAAR HL",
  "VISTAARFSPL": "VISTAAR FINANCIAL SERVICES PVT LTD",
  "VSBL": "THE VISHWESHWAR SAHAKARI BANK LIMITED",
  "VVSBL": "VASAI VIKAS SAHAKARI BANK LIMITED",
  "WBSTCB": "THE WEST BENGAL STATE COOPERATIVE BANK",
  "WESTPAC": "WESTPAC BANKING CORPORATION",
  "WOORI": "WOORI BANK",
  "WTW": "WILLIS TOWER WATSON",
  "YES": "YES BANK",
  "ZCBL": "THE ZOROASTRIAN COOPERATIVE BANK LIMITED",
  "OTHER": "Others",
  "ACOB": "ADHARSH CO-OPERATIVE BANK",
  "APGVB": "APGVB BANK ",
  "H Limited": "H Limited",
  "SSSBL": "Suco Souharda Sahakari Bank Ltd",
  "MNMFSL": "MAHINDRA AND MAHINDRA FINANCIAL SERVICES LIMITED",
  "SBMI": "SBM Bank India",
  "STSHFIN": 'Stashfin'
};


export const payoutStatusConstants = {
  MONTHLY: "Monthly",
  SPOT: "Spot",
  // ADVANCE: "Advance",
};

export const loginStatusConstants = {
  OPEN: "Open",
  APPLIED: "Applied",
  // WIP: "Work In Progress",
  APPROVED: "Approved",
  DISBURSED: "Disbursed",
  DECLINED: "Declined",
};

export const userRoleConstants = {
  CONNECTOR: "CONNECTOR",
  FIN_ADMIN: "FIN_ADMIN",
  CEO: "CEO",
  FIN_MANAGER: "FIN_MANAGER",
  CONN_MANAGER: "CONNECTOR_MANAGER",
  BRANCH_MANAGER: "BRANCH_MANAGER",
};

// application Status constants
export const loanStatusConstants = {
  OPEN: "OPEN",
  APPLIED: "APPLIED",
  REJECTED: "REJECTED",
  APPROVED: "APPROVED",
  DISBURSED: "DISBURSED",
  CANCELLED: "CANCELLED",
};

export const redeemedStatusConstants = {
  notProcessed: "Not Processed",
  reviewPending: "Review Pending",
  approvalPending: "Approval Pending",
  processed: "Processed",
  completed: "Completed"
};

export const productOrder = {
  'PL': 1,
  'AL': 2,
  'HL': 3,
  'BL': 4,
  'GL': 5,
  'IN': 6,
  'CC': 7,
  'EL': 8,

  'PERS': 1,
  'AUTO': 2,
  'HOME': 3,
  'BUSI': 4,
  'GOLD': 5,
  'INSR': 6,
  'CRED': 7,
  'EDUL': 8,
};

export const monthNumberToLabelMap = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
}

export const expenseCategory = {
  rent: "Rent",
  ebill: "E-Bills",
  tbill: "T-Bills",
  fixedasset: "Fixed Assets",
  rm: "R&M",
  sal: "Salaries",
  stat: "Stationeries",
  pena: "Penalties",
  misc: "Miscellaneous"
}