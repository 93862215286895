import { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import axios from 'axios';

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { ApiUrl } from "../../../helpers/getUrl";

const BUTTON_TYPE = {
  M1: '1M',
  M3: '3M',
  M6: '6M',
  CUS: 'CUS'
};

const covertCurrency = amount => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'INR' }).format(Number(amount) / 100);

export default function TransactionKYC({ openTransactionView, toggleTransactionView }) {

  const user = useMemo(() => {
    return JSON.parse(sessionStorage.getItem("data")) || {};
  }, []);

  const date = moment().toISOString().slice(0, 10);

  const [btn, setBtn] = useState(BUTTON_TYPE.M1);
  const [startdate, setStartdate] = useState(date);
  const [enddate, setEnddate] = useState(date);
  const [loading, setLoading] = useState(false);

  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const onBtnClick = choice => {
    setBtn(choice);
  };

  const onSetStartdate = event => {
    setStartdate(event.target.value);
  };

  const onSetEnddate = event => {
    setEnddate(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onCustomSearch = () => {
    setLoading(true);
    const payload = {};
    if (user.connectorId) payload['connectorId'] = user.connectorId;
    if (user.finadminId) payload['finadminId'] = user.finadminId;
    if (user.finMngrId) payload['finMngrId'] = user.finMngrId;
    if (user.ceoId) payload['ceoId'] = user.ceoId;

    payload['startdate'] = moment(startdate).toISOString();
    payload['enddate'] = moment(enddate).toISOString();

    const searchParams = new URLSearchParams();
    Object.keys(payload).forEach(key => searchParams.append(key, payload[key]));

    const query = searchParams.toString();

    axios.get(`${ApiUrl()}kyc/transactions?${query}`).then(result => {
      setTransactions(result?.data);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
      console.log(err);
    });
  }

  useEffect(() => {
    if (btn !== BUTTON_TYPE.CUS) {
      setLoading(true);
      const payload = {};
      if (user.connectorId) payload['connectorId'] = user.connectorId;
      if (user.finadminId) payload['finadminId'] = user.finadminId;
      if (user.finMngrId) payload['finMngrId'] = user.finMngrId;
      if (user.ceoId) payload['ceoId'] = user.ceoId;

      if (btn === BUTTON_TYPE.M1) payload['startdate'] = moment().date(1).hour(0).minute(0).second(0).millisecond(0).toISOString();
      if (btn === BUTTON_TYPE.M3) payload['startdate'] = moment().subtract(3, 'months').date(1).hour(0).minute(0).second(0).millisecond(0).toISOString();
      if (btn === BUTTON_TYPE.M6) payload['startdate'] = moment().subtract(6, 'months').date(1).hour(0).minute(0).second(0).millisecond(0).toISOString();

      payload['enddate'] = moment().toISOString();

      const searchParams = new URLSearchParams();
      Object.keys(payload).forEach(key => searchParams.append(key, payload[key]));

      const query = searchParams.toString();

      axios.get(`${ApiUrl()}kyc/transactions?${query}`).then(result => {
        setTransactions(result?.data);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        console.log(err)
      });
    }
  }, [btn, user]);

  return (
    <Dialog
      open={openTransactionView}
      onClose={toggleTransactionView}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        Transaction details
        <IconButton
          aria-label="close"
          onClick={toggleTransactionView}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <div style={{ display: btn === BUTTON_TYPE.CUS ? 'none' : 'flex', justifyContent: 'space-around', alignItems: 'center', padding: '0 1rem' }}>
            <Button variant={btn === BUTTON_TYPE.M1 ? 'contained' : 'outlined'} onClick={() => { onBtnClick(BUTTON_TYPE.M1) }}>This month</Button>
            <Button variant={btn === BUTTON_TYPE.M3 ? 'contained' : 'outlined'} onClick={() => { onBtnClick(BUTTON_TYPE.M3) }}>Last 3 months</Button>
            <Button variant={btn === BUTTON_TYPE.M6 ? 'contained' : 'outlined'} onClick={() => { onBtnClick(BUTTON_TYPE.M6) }}>Last 6 months</Button>
            <Button variant={btn === BUTTON_TYPE.CUS ? 'contained' : 'outlined'} onClick={() => { onBtnClick(BUTTON_TYPE.CUS) }}>Custom</Button>
          </div>
          <div style={{ display: btn !== BUTTON_TYPE.CUS ? 'none' : 'flex', justifyContent: 'space-around', alignItems: 'center', padding: '0 1rem' }}>
            <IconButton onClick={() => { onBtnClick(BUTTON_TYPE.M1) }} style={{ border: '1px solid' }}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <TextField id="start-date" label="Start Date" InputLabelProps={{ shrink: true }} variant="outlined" margin="dense" type="date" value={startdate} onChange={onSetStartdate} />
            <TextField id="end-date" label="End Date" InputLabelProps={{ shrink: true }} variant="outlined" margin="dense" type="date" value={enddate} onChange={onSetEnddate} />
            <Button variant="contained" disabled={startdate >= enddate} onClick={onCustomSearch}>Search</Button>
          </div>
        </Box>
        {loading && <LinearProgress />}
        <Paper sx={{ width: '100%', overflow: 'hidden', margin: '1rem 0' }}>
          <TableContainer sx={{ maxHeight: 450 }}>
            <Table stickyHeader aria-label="transaction table">
              <TableHead>
                <TableRow>
                  <TableCell>Transaction Type</TableCell>
                  <TableCell>Transaction Amount</TableCell>
                  <TableCell>Transaction Date-Time</TableCell>
                  <TableCell>Transaction Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  transactions
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((transaction, idx) => (
                      <TableRow hover tabIndex={-1} key={idx}>
                        <TableCell>{transaction.type}</TableCell>
                        <TableCell>{covertCurrency(transaction.amount)}</TableCell>
                        <TableCell>{moment(transaction.createdOn).format('DD/MM/yyyy hh:mm a')}</TableCell>
                        <TableCell>{transaction.message}</TableCell>
                      </TableRow>
                    ))
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={transactions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </DialogContent>
    </Dialog>
  );
}
