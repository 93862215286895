import { Alert, AlertTitle, FormControl, Stack, Table, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { green, indigo } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import moment from "moment";
import { useEffect, useState } from 'react';
import { expenseCategory, monthNumberToLabelMap } from "../../constants/appconstants";
import { ApiUrl } from '../../helpers/getUrl';

const PLReport = () => {


    const [value, setValue] = useState(moment(new Date()));
    const [yearWiseData, setYearWiseData] = useState([]);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        const year = value.year()
        axios.get(ApiUrl() + `pnlreport?year=${year}`).then((response) => {
            setYearWiseData(response.data)
        }).catch((error) => {
            console.error(error)
            setMessage({
                severity: 'error',
                title: 'Error',
                description: 'Invalid response recieved'
            });
        })
    }, [value])

    return (
        <>
            {message &&
                <>
                    <Alert severity={message.severity} onClose={() => { setMessage(null); }}>
                        <AlertTitle>{message.title}</AlertTitle>
                        {message.description}
                    </Alert>
                    <br />
                </>
            }
            <div className="sidenav-content">
                <div className="row my-2">
                    <div className="col-md-6">
                        <Typography
                            variant="h6"
                            gutterBottom
                            component="div"
                            className="leads-heading"
                        >
                            P & L Report - Year {value?.year()}
                        </Typography>
                    </div>
                    <div className="col-md-6">
                        <Stack spacing={4} direction="row-reverse" alignItems='flex-start'>
                            <FormControl>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        views={['year']}
                                        label=""
                                        value={value}
                                        onChange={(newValue) => {
                                            setValue(newValue);
                                        }}
                                        renderInput={(params) => <TextField style={{ marginTop: '-20px' }} {...params} helperText={null} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Stack>
                    </div>
                </div>
                <DataSummary yearWiseData={yearWiseData} />
            </div>
        </>
    );
}
export default PLReport;


function DataSummary({ yearWiseData }) {
    const columns = {
        netRevenue: "Bank Payouts",
        netReturns: "Govt. TDS",
        // netRefund: "Bank Payouts",
        // netDiscount: "Govt. TDS",
    }

    const [showRevenue, setShowRevenue] = useState(false);
    const [showExpense, setShowExpense] = useState(false);

    const useStyles = makeStyles(theme => ({
        footerCells: {
            fontWeight: 800,
            color: "#000",
            fontSize: "0.9rem",
            borderBottom: "1px dashed rgb(0 0 0)",
            borderTop: "1px dashed rgb(0 0 0)",
            "&:hover": {
                cursor: "pointer"
            }
        },
        totalRevenueColor: {
            backgroundColor: "#e5fad5 !important",
        },
        totalExpenseColor: {
            backgroundColor: "#ffd6d4 !important",
        },
        NetEarningColor: {
            backgroundColor: "#B0E0E6 !important",
        },
        headerCells: {
            color: "#fff",
            backgroundColor: `${indigo[600]} !important`
        },
        sticky: {
            position: "sticky",
            backgroundColor: "aliceblue",
            // zIndex: 800,
            left: 0
        }
    }));
    const classes = useStyles();



    return (
        <>
            <Box sx={{ margin: 0 }}>
                <TableContainer sx={{ maxHeight: 500 }}>
                    <Table stickyHeader aria-label="data table">
                        <TableHead>
                            <TableCell className={`${classes.headerCells} ${classes.sticky}`} sticky></TableCell>
                            {Object.values(monthNumberToLabelMap).map((month) =>
                                <TableCell className={classes.headerCells}>{month}</TableCell>
                            )}
                            <TableCell className={classes.headerCells}>Full Year</TableCell>
                        </TableHead>
                        {showRevenue &&
                            <>
                                {Object.entries(columns).map(([key, value]) => (
                                    <TableRow>
                                        <TableCell className={classes.sticky}>{value}</TableCell>
                                        {yearWiseData.map((item) =>
                                            <TableCell>{item[key].toLocaleString("hi")}</TableCell>
                                        )}
                                        <TableCell>
                                            {yearWiseData?.reduce((total, obj) => obj[key] + total, 0).toLocaleString("hi")}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>
                        }
                        <TableRow onClick={() => setShowRevenue(!showRevenue)}>
                            <TableCell className={`${`${classes.footerCells} ${classes.totalRevenueColor}`} ${classes.sticky}`}>Total Revenue</TableCell>
                            {yearWiseData.map((item) =>
                                <TableCell className={`${classes.footerCells} ${classes.totalRevenueColor}`}>{item.totalRevenue.toLocaleString("hi")}</TableCell>
                            )}
                            <TableCell className={`${classes.footerCells} ${classes.totalRevenueColor}`}>
                                {yearWiseData?.reduce((total, obj) => obj.totalRevenue + total, 0).toLocaleString("hi")}
                            </TableCell>
                        </TableRow>
                        {showExpense &&
                            <>
                                {Object.values(expenseCategory).map((x) => (
                                    <TableRow>
                                        <TableCell className={classes.sticky}>{x}</TableCell>
                                        {yearWiseData.map((item) =>
                                            <TableCell>{item[x].toLocaleString("hi")}</TableCell>
                                        )}
                                        <TableCell>
                                            {yearWiseData?.reduce((total, obj) => obj[x] + total, 0).toLocaleString("hi")}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>}
                        <TableFooter>
                            <TableRow>
                                <TableCell className={`${`${classes.footerCells} ${classes.NetEarningColor}`} ${classes.sticky}`}>Net Earnings</TableCell>
                                {yearWiseData.map((item) =>
                                    <TableCell className={`${classes.footerCells} ${classes.NetEarningColor}`}>{item?.netEarnings.toLocaleString("hi")}</TableCell>
                                )}
                                <TableCell className={`${classes.footerCells} ${classes.NetEarningColor}`}>
                                    {yearWiseData?.reduce((total, obj) => obj.netEarnings + total, 0).toLocaleString("hi")}
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                        <TableFooter>
                            <TableRow onClick={() => setShowExpense(!showExpense)}>
                                <TableCell className={`${`${classes.footerCells} ${classes.totalExpenseColor}`} ${classes.totalExpenseColor} ${classes.sticky}`}>Total Expenses</TableCell>
                                {yearWiseData.map((item) =>
                                    <TableCell className={`${classes.footerCells} ${classes.totalExpenseColor}`}>{item?.totalExpense.toLocaleString("hi")}</TableCell>
                                )}
                                <TableCell className={`${classes.footerCells} ${classes.totalExpenseColor}`}>
                                    {yearWiseData?.reduce((total, obj) => obj.totalExpense + total, 0).toLocaleString("hi")}
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}