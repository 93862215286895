import { Button, FormControl, Grid, IconButton, InputLabel, Paper, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { ApiUrl } from "../../../helpers/getUrl";
import Datatable from "./Datatable";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ErrorSnackbar } from "../../../components/CustomSnackBar";
import ReportContext from "./reportContext";
import SearchIcon from '@mui/icons-material/Search';
import SearchByLos from "./SearchByLos";

const ReportIndex = () => {

    const useStyles = makeStyles(() => ({
        tableCell: { textAlign: "center" },
        formControl: {
            padding: "5px",
            margin: "2px",
            width: "230px",
        },
        formLabel: {
            margin: '-15px 5px',
            fontSize: '10px'
        },
        cardProperties: {
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 100,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: "#92ffd2",
            },
        }
    }));

    const classes = useStyles();
    const today = moment(new Date()).format("YYYY-MM-DD");

    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [data, setData] = useState([]);
    const [count, setCount] = useState({});
    const [errorMsg, setErrorMsg] = useState("");
    const [contextData, setContextData] = useState({ startDate, endDate });
    const [openSearch, setOpenSearch] = useState(false);

    useEffect(() => {
        const start = new Date(startDate).toISOString();
        const end = moment(endDate).format("YYYY-MM-DD") + "T23:59:59.000Z";
        setContextData({ start, end })
        axios.get(ApiUrl() + `robo/total?startdate=${start}&enddate=${end}`).then((response) => {
            const _ = require("lodash");
            const data1 = _.groupBy(response.data.locationView, "state")
            setData(data1)
            setCount(response.data.statusView)
        }).catch((error) => {
            console.error(error.message)
            setErrorMsg("Invalid response from server");
        })

    }, [startDate, endDate]);


    const cardTitles = {
        location: "Location",
        startedCount: "Started",
        wipCount: "WIP",
        dedupCount: "Dedup",
        forwardedCount: "Forwarded",
        pendingCount: "Pending",
        approvedCount: "Approved",
        cancelledCount: "Cancelled/Rejected",
    }

    return (
        <ReportContext.Provider value={{ contextData, setContextData }}>
            <div className="sidenav-content">
                <div className="row my-2">
                    <div className="col-md-3">
                        <Button
                            sx={{ boxShadow: 2, marginLeft: 1, marginTop: 2 }}
                            variant="contained"
                            startIcon={<SearchIcon />}
                            onClick={() => setOpenSearch(true)}
                        >
                            Search By LOS#
                        </Button>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3">
                        <FormControl variant="standard" className={classes.formControl}>
                            <InputLabel className={classes.formLabel}>Start Date</InputLabel>
                            <TextField
                                type="date"
                                placeholder="Date"
                                className="date-picker"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </FormControl>
                    </div>
                    <div className="col-md-3">
                        <FormControl variant="standard" className={classes.formControl}>
                            <InputLabel className={classes.formLabel}>End Date</InputLabel>
                            <TextField
                                type="date"
                                placeholder="Date"
                                className="date-picker"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </FormControl>
                    </div>
                </div>
                {errorMsg && <ErrorSnackbar errorMsg={errorMsg} />}

                <div className="row">
                    <Grid container spacing={0}>
                        {Object.keys(cardTitles).map((item) =>
                            <Grid item xs={12} md={3} className="deposits-card">
                                <Paper
                                    sx={{
                                        p: 1,
                                        margin: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        height: "15vh",
                                        boxShadow: 2,
                                        textAlign: 'center',
                                        backgroundColor: "#ff767626",
                                        '&:hover': {
                                            background: '#d0d0d036',
                                            cursor: 'pointer'
                                        },
                                    }}>
                                    <Typography gutterBottom variant="h6" component="div" color="green">
                                        {cardTitles[item]}
                                    </Typography>
                                    <Typography gutterBottom variant="" component="b">
                                        Amount - ₹ {count[item.replace("Count", "Amount")]?.toLocaleString("hi") || 0}<br />
                                        Count - {count[item] || 0}
                                    </Typography>
                                </Paper>
                            </Grid>)}
                    </Grid>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow style={{ backgroundColor: '#8a8a8a' }}>
                                    <TableCell />
                                    <TableCell>State</TableCell>
                                    <TableCell>Location</TableCell>
                                    <TableCell>Started</TableCell>
                                    <TableCell>Pending</TableCell>
                                    <TableCell>WIP</TableCell>
                                    <TableCell>Reject</TableCell>
                                    <TableCell>Approved</TableCell>
                                    <TableCell>Dedup</TableCell>
                                    <TableCell>Cancelled</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.entries(data).map(([key, value]) => (<Datatable value={value} key1={key} />))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <SearchByLos openSearch={openSearch} setOpenSearch={setOpenSearch} />
        </ReportContext.Provider>

    )
}
export default ReportIndex;
