import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import GetAppIcon from '@mui/icons-material/GetApp';
import { Checkbox, FormControlLabel, InputLabel, MenuItem, Select } from "@mui/material";
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  ErrorSnackbar,
  SuccessSnackbar
} from "../../components/CustomSnackBar";
import PageLoader from "../../components/PageLoader";
import {
  bankCodeConstantsForAccount, bankNameConstantsForAccount, stateConstants, stateNameToCodeMapping, userRoleConstants
} from "../../constants/appconstants";
import { ApiUrl } from "../../helpers/getUrl";
import {
  postConnector, putConnector, resetLeads
} from "../../redux/reducers/LeadsDataService";
import useAppContext from "../../utils/useAppContext";
import BulkUpload from "../leads/bulkUpload";
import ConnectorTable from "./ConnectorTable";

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        console.log(errors)
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );
const phoneRegExp = new RegExp('[0-9]{10}');
const userRole = sessionStorage?.userRole;

const validationSchema = yup.object({
  connectorName: yup.string().required("DigiRep Name is Required"),
  connectorPhone: yup.string().required("DigiRep Phone is Required").matches(phoneRegExp, 'Must be a valid phone'),
  connectorEmail: yup.string().email('Must be a valid email').required("DigiRep Email is Required"),
  // connectorManager: yup.string().when(("isManager", "userrole"), {
  //   is: (isManager, userRole) => {
  //     let userrole = JSON.parse(sessionStorage?.userRole);
  //     if (userrole === "FIN_ADMIN") {
  //       return true;
  //     } else if (userrole === "BRANCH_MANAGER") {
  //       return !isManager;
  //     } else if (userrole === "CONNECTOR_MANAGER") {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   },
  //   then: yup.string().required("Connector Manager is Required"),
  //   otherwise: yup.string().notRequired(),
  // }),
  accountNumber: yup.string().required("Account Number is Required"),
  bankName: yup.string().required("Bank Name is Required"),
  ifsc: yup.string().matches(/[A-Za-z]{4}0[A-Z0-9a-z]{6}$/, { message: 'Please enter valid Ifsc Code.', excludeEmptyString: true }).required("IFSC Code is Required"),
  pan: yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}/, { message: 'Please enter valid Pan no.', excludeEmptyString: true }).required("PAN Number is Required"),
  state: yup.string().required("State is Required"),
  location: yup.string().when(('isManager', 'connectorManager'), {
    is: (isManager, connectorManager) => { return (!isManager && connectorManager === 1); },
    then: yup.string().required("Location is required")
  }),
});

const useStyles = makeStyles(() => ({
  tableCell: { textAlign: "center" },
  a: {
    color: "white"
  },
  select: {
    width: "200px",
    margin: "5px",
  },
  formControl: {
    padding: "5px",
    width: "250px",
  },
}));

function Connector() {
  const classes = useStyles();
  const dispatch = useDispatch(); // used to dispatch an action

  const { isLoading, successMsg, errorMsg, invokeGetLeadsApi, errorDetails } = useSelector(
    ({ leadsDataService }) => leadsDataService
  );
  const [managerList, setManagerList] = useState([]);
  const [branchManagerList, setBranchManagerList] = useState([]);
  const [locList, setLocList] = useState([]);
  const [isManager, setIsManager] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [formDataInEditMode, setFormDataInEditMode] = useState(null);
  const resolver = useYupValidationResolver(validationSchema);
  let auth = useAppContext();
  let localData = JSON.parse(sessionStorage.getItem('data'));
  let userrole = sessionStorage.getItem("userRole");

  const { connectorId } = localData || {};
  const { CONNECTOR, CEO, FIN_ADMIN, FIN_MANAGER, CONN_MANAGER, BRANCH_MANAGER } = userRoleConstants;

  let defaultValues = {
    accountHolderName: "",
    connectorName: "",
    connectorPhone: '',
    connectorEmail: "",
    // connectorManager: 0,
    isManager: false,
    accountNumber: "",
    bankName: "",
    ifsc: "",
    pan: "",
    state: "",
    location: "",
    createdBy: ""
  }

  if (isEditMode && formDataInEditMode?._id) {
    defaultValues = {
      accountHolderName: formDataInEditMode?.accountHolderName,
      connectorName: formDataInEditMode?.connectorName,
      connectorPhone: formDataInEditMode?.connectorPhone,
      connectorEmail: formDataInEditMode?.connectorEmail,
      connectorManager: formDataInEditMode?.connectorManager,
      isManager: formDataInEditMode?.isManager || false,
      accountNumber: formDataInEditMode?.accountNumber,
      bankName: formDataInEditMode?.bankName,
      ifsc: formDataInEditMode?.ifsc,
      pan: formDataInEditMode?.pan,
      state: formDataInEditMode?.state,
      location: formDataInEditMode?.location,
      createdBy: formDataInEditMode?.createdBy
    }
  };

  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver
  });

  window.register = register;

  const [openConnector, setOpenConnector] = useState(false);

  const resetData = () => {
    setOpenConnector(false);
    reset();
    setTimeout(() => {
      dispatch(resetLeads());
    }, 2000);
  };

  useEffect(() => {
    if (invokeGetLeadsApi) {
      setOpenConnector(false);
      reset();
      setTimeout(() => {
        dispatch(resetLeads());
      }, 2000);
    }
    getConnMgrList()
    getLocationList()
    return () => { };
  }, [invokeGetLeadsApi]);

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  const handleAddLead = () => {
    setEditMode(false);
    setOpenConnector(true);
    reset(defaultValues);
    reset();
  };

  const handleEditClick = (data) => {
    setFormDataInEditMode(data);
    setIsManager(data?.isManager)
    setOpenConnector(true);
    setEditMode(true);
  };

  const handleClose = () => {
    setFormDataInEditMode({});
    setOpenConnector(false);
    setEditMode(false);
    reset();
    resetData()
  };


  const getConnMgrList = () => {
    axios.get(`${ApiUrl()}connector/manager/list`)
      .then(res => {
        setManagerList(res.data)
      }).catch(error => {
        console.error('There was an error!', error);
      });
    axios.get(`${ApiUrl()}connector/branchmanagers/list`)
      .then(res => {
        setBranchManagerList(res.data)
      }).catch(error => {
        console.error('There was an error!', error);
      });
  }

  const getLocationList = () => {
    axios.get(`${ApiUrl()}connector/locations/list`)
      .then(res => {
        setLocList(res.data)
      }).catch(error => {
        console.error('There was an error!', error);
      });
  }

  const submitNewConnector = (data) => {
    let leadsData = {
      ...data,
      connectorPhone: parseInt(data.connectorPhone),
      isBranchManager: false
    };
    leadsData.connectorManager = parseInt(data.connectorManager);
    if (auth.user === CONN_MANAGER) {
      leadsData = {
        ...leadsData,
        connectorPhone: parseInt(data.connectorPhone),
        isManager: false,
        connectorManager: connectorId,

      }
    } else if (auth.user === BRANCH_MANAGER) {
      leadsData = {
        ...leadsData,
        connectorPhone: parseInt(data.connectorPhone),
        connectorManager: connectorId,
      }
    }
    if (leadsData.connectorManager === 1) {
      leadsData.isBranchManager = true;
      leadsData.isManager = true;
    }
    let email = (JSON.parse(sessionStorage?.user) || { userEmail: "login@demo.com" }).userEmail
    if (isEditMode) {
      delete leadsData.createdBy;
      delete leadsData.connectorId;
      delete leadsData.connectorEmail;
      leadsData.state = stateNameToCodeMapping[data.state];
      leadsData.bankName = bankNameConstantsForAccount[data.bankName];
      leadsData.updatedBy = email;
      console.log(data.bankName)
      dispatch(putConnector(formDataInEditMode?.connectorId, { ...leadsData }));
      setEditMode(false);
    } else {
      delete leadsData.updatedBy;
      leadsData.createdBy = email;
      leadsData.state = stateNameToCodeMapping[data.state];
      leadsData.bankName = bankNameConstantsForAccount[data.bankName];
      dispatch(postConnector({ ...leadsData }));
    }
    // console.log(data, "data");
  };

  // console.log(userrole, BRANCH_MANAGER)

  const addLeadElement = (
    <Dialog onBackdropClick="false" open={openConnector} onClose={handleClose}>
      <DialogTitle>{isEditMode ? 'Update' : 'Add'} DigiRep Module</DialogTitle>
      <form onSubmit={handleSubmit(submitNewConnector)}>
        <DialogContent sx={{ paddingTop: 0, marginTop: "-20px" }}>
          {errorDetails && errorDetails.map((item, index) =>
            <Alert severity="error">{item}</Alert>
          )}
          <div className="row form-fields mt-md-n5">
            {(auth.user === BRANCH_MANAGER || auth.user === FIN_ADMIN) &&
              <div className="col-md-12 pt-2 mb-0">
                <FormControl component="fieldset">
                  <FormControlLabel
                    label="Is manager"
                    control={
                      <Checkbox
                        {...register("isManager")}
                        checked={isManager}
                        onChange={() => { setIsManager(!isManager) }}
                      />
                    }
                  />
                </FormControl>
              </div>
            }
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("connectorName")}
                  label="DigiRep Name"
                  fullWidth
                  defaultValue={watch("connectorName")}
                  variant="standard"
                  error={errors?.connectorName && errors?.connectorName}
                  helperText={errors?.connectorName?.message}
                // required
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  type="text"
                  margin="dense"
                  {...register("connectorPhone")}
                  label="DigiRep Mobile Number"
                  fullWidth
                  variant="standard"
                  error={errors?.connectorPhone && errors?.connectorPhone}
                  helperText={errors?.connectorPhone?.message}
                // required
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("connectorEmail")}
                  label="DigiRep Email"
                  fullWidth
                  variant="standard"
                  error={errors?.connectorEmail && errors?.connectorEmail}
                  helperText={errors?.connectorEmail?.message}
                  disabled={isEditMode}
                // required
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("accountHolderName")}
                  label="Account Holder Name"
                  fullWidth
                  defaultValue={watch("accountHolderName")}
                  variant="standard"
                  error={errors?.accountHolderName && errors?.accountHolderName}
                  helperText={errors?.accountHolderName?.message}
                // required
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("accountNumber")}
                  label="Account Number"
                  fullWidth
                  variant="standard"
                  error={errors?.accountNumber && errors?.accountNumber}
                  helperText={errors?.accountNumber?.message}
                // required
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl} required>
                <Autocomplete
                  disablePortal
                  options={Object.keys(bankNameConstantsForAccount)}
                  id="demo-simple-select-standard"

                  renderInput={(params) => <TextField
                    {...register("bankName")}
                    {...params} label="Bank Name"
                  />}
                  defaultValue={bankCodeConstantsForAccount[watch('bankName')]}

                />
                {errors?.bankName?.message && (
                  <FormHelperText error={errors?.bankName && errors?.bankName}>
                    {errors?.bankName?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("ifsc")}
                  label="IFSC Code"
                  fullWidth
                  variant="standard"
                  error={errors?.ifsc && errors?.ifsc}
                  helperText={errors?.ifsc?.message}
                // required
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <Autocomplete
                  disablePortal
                  options={Object.values(stateConstants)}
                  id="demo-simple-select-standard"
                  renderInput={(params) => <TextField
                    {...register("state")}
                    {...params} label="State" />}
                  defaultValue={stateConstants[formDataInEditMode?.state]}
                // required
                />
                {errors?.state?.message && (
                  <FormHelperText
                    error={errors?.state && errors?.state}
                  >
                    {errors?.state?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("pan")}
                  label="PAN Number"
                  fullWidth
                  variant="standard"
                  error={errors?.pan && errors?.pan}
                  helperText={errors?.pan?.message}
                  inputProps={{ maxLength: 10 }}
                // required
                />
              </FormControl>
            </div>

            {(auth.user === CONN_MANAGER || (auth.user === BRANCH_MANAGER && isManager)) ?
              <></> : (<>
                <div className={"col-md-6 pt-2"}>
                  <FormControl variant="standard" className={classes.formControl}>
                    <InputLabel id={"label-Product Type"}>
                      DigiRep Manager
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      sx={{ marginTop: "20px" }}
                      {...register("connectorManager")}
                      label="DigiRep Manager"
                      defaultValue={watch("connectorManager")}
                      error={errors?.connectorManager && errors?.connectorManager}
                      helperText={errors?.connectorManager?.message}
                      required
                    >
                      {(isManager && auth.user === FIN_ADMIN) && <MenuItem value={1}>{"NONE"}</MenuItem>}
                      {isManager ? (
                        (branchManagerList?.map((x) => (
                          <MenuItem value={x?.connectorId}>{x?.connectorName}</MenuItem>
                        ))
                        )
                      ) :
                        managerList?.map((x) => (
                          <MenuItem value={x?.connectorId}>{x?.connectorName}</MenuItem>
                        ))
                      }
                    </Select>
                    {errors?.connectorManager?.message && (
                      <FormHelperText error={errors?.connectorManager && errors?.connectorManager}>
                        {errors?.connectorManager?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="col-md-6">
                  <FormControl variant="standard" className={!isManager ? 'd-none' : classes.formControl}>
                    <InputLabel id={"label-Product Type"}>
                      Location *
                    </InputLabel>
                    <Select
                      className={classes.textField}
                      sx={{ marginTop: "20px" }}
                      {...register("location")}
                      label="Location"
                      defaultValue={watch("location")}
                      error={errors?.location && errors?.location}
                      helperText={errors?.location?.message}
                    >
                      {locList?.map((x) => (
                        <MenuItem value={x?.locationName}>{x?.locationName}</MenuItem>
                      ))}
                    </Select>
                    {errors?.location?.message && (
                      <FormHelperText
                        error={errors?.location && errors?.location}
                      >
                        {errors?.location?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>

              </>)}
          </div>
          {/* <FormControl variant="standard" className={classes.formControl}>

      </FormControl> */}
        </DialogContent>
        <DialogActions sx={{ padding: "0 20px 20px 0" }}>
          <Button variant="text" sx={{ boxShadow: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.leadButton}
            type="submit"
          // disabled={isLoading ? true : false}
          >
            {isEditMode ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return (
    <div className="sidenav-content">
      <div className="row my-2">
        <div className="col-md-6">
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            className="leads-heading"
          >
            DigiRep Details
          </Typography>
        </div>
        <div className="col-md-6">
          <Stack spacing={2} direction="row-reverse">
            <Button
              variant="contained"
              startIcon={<AddOutlinedIcon />}
              onClick={handleAddLead}
              className={classes.leadButton}
            >
              Add DigiRep
            </Button>
            <BulkUpload type="CONNECTOR_FINADMIN" disabled={"true"} />
            <a href={ApiUrl() + "template/admin/connectors"} target="_blank" style={{ color: "#dadbde" }}>
              <Button
                variant="contained"
                startIcon={<GetAppIcon />}
                className={classes.leadButton}
                disabled
              >
                Download Template
              </Button>
            </a>
          </Stack>
        </div>
      </div>

      <ConnectorTable handleEditClick={handleEditClick} resetData={resetData} />

      {addLeadElement}

      {isLoading && <PageLoader />}
      {errorMsg && <ErrorSnackbar errorMsg={errorMsg} />}
      {successMsg && <SuccessSnackbar successMsg={successMsg} />}
    </div>
  );
}

export default Connector;