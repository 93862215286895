import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import axios from "axios";
import { ApiUrl } from "../../helpers/getUrl";
import { BootstrapButton } from "./BootstrapButton";
import {
  ErrorSnackbar,
  SuccessSnackbar,
} from "../../components/CustomSnackBar";
import { errorHandler } from "../../helpers/errorHandler";

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const validationSchema = yup.object({
  paidAmount: yup
    .number()
    .typeError("Transaction Amount is Required")
    .required()
    .lessThan(
      yup.ref("currentOutstandingAmount"),
      "Transaction Amount Should not be greater than Outstanding Amount"
    ),
});
const UpdatePaymentDialog = ({ display, handleModal }) => {
  const [successMsg, setSuccessMsg] = useState({
    status: false,
    msg: "",
  });
  const [errorMsg, setErrorMsg] = useState({ state: false, msg: null });
  let localData = JSON.parse(sessionStorage.getItem("user"));
  const { userEmail } = localData || {};
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    reset,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    resolver,
  });
  const [outstandingAmount, setOutstandingAmount] = useState(0);
  var displayDataObj = {
    connectorName: "",
    connectorPhone: "",
    location: "",
    outstandAmt: "",
    paidAmount: "",
    adminRemarks: "",
  };
  const [displayData, setDisplayData] = useState(displayDataObj);

  useEffect(() => {
    // console.log("update row: ", display.data)
    if (display.data) {
      setDisplayData({
        connectorId: display?.data?.connectorId,
        connectorName: display.data.connectorName,
        connectorPhone: display.data.connectorPhone,
        location: display.data.location,
        outstandAmt: display.data.currentOutstandingAmount,
      });

      setOutstandingAmount(display.data.currentOutstandingAmount);
      setValue("currentOutstandingAmount", outstandingAmount);
      // let currentOutstandingAmount = getValues("currentOutstandingAmount")
      // if (currentOutstandingAmount) console.log(currentOutstandingAmount);
    }
  }, [display, outstandingAmount]);

  const onSubmitForm = (data) => {
    let formData = {
      connectorId: display.data.connectorId,
      transactionAmount: parseInt(data.paidAmount),
      adminRemarks: data.adminRemarks,
      transactionType: data.transactionType,
      createdBy: userEmail,
    };
    console.log("update form data: ", formData);
    let url = `${ApiUrl()}`.replace("v1", "v2");
    axios
      .post(`${url}advance/update/payment`, formData)
      .then((res) => {
        if (res.status === 200) {
          reset();
          setDisplayData(displayDataObj);
          handleModal();
          setSuccessMsg({
            status: true,
            msg: "Payment Updated Successfully",
          });
        }
      })
      .catch((error) => {
        let errMsg = errorHandler(error);
        setErrorMsg({ state: true, msg: error?.response?.data?.issues?.[0] });
      });
  };
  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={display.show} onClose={handleModal}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="text-center mt-3 dialog_header">Update Payment</div>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <DialogContent sx={{ paddingTop: 0, marginTop: "10px" }}>
            <div className="row form-fields mt-md-n5">
              <div className="col-md-6 mt-3">
                <InputLabel htmlFor="connectorName">Digirep Name</InputLabel>
                <OutlinedInput
                  label="Outlined"
                  variant="standard"
                  className="w-100 outlined-input mt-2"
                  value={displayData.connectorName}
                  disabled
                />
              </div>
              <div className="col-md-6 mt-3">
                <InputLabel htmlFor="connectorPhone">
                  Digirep Mobile No.
                </InputLabel>
                <OutlinedInput
                  label="Outlined"
                  variant="standard"
                  className="w-100 outlined-input mt-2"
                  value={displayData.connectorPhone}
                  disabled
                />
              </div>
              <div className="col-md-6 mt-3">
                <InputLabel htmlFor="location">Location</InputLabel>
                <OutlinedInput
                  label="Outlined"
                  variant="standard"
                  className="w-100 outlined-input mt-2"
                  value={displayData.location}
                  disabled
                />
              </div>
              <div className="col-md-6 mt-3">
                <InputLabel htmlFor="outstandAmt">
                  Outstanding Amount
                </InputLabel>
                <OutlinedInput
                  label="Outlined"
                  variant="standard"
                  className="w-100 outlined-input mt-2"
                  value={displayData.outstandAmt?.cur()}
                  disabled
                />
                <p className="error"></p>
              </div>
              <div className="col-md-6 mt-3">
                <InputLabel htmlFor="component-disabled">
                  Transaction type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={"ADVANCE_PAYMENT"}
                  className="w-100 outlined-input"
                  {...register("transactionType")}
                >
                  <MenuItem value="ADVANCE_RECEIPT">Receipt</MenuItem>
                  <MenuItem value="ADVANCE_PAYMENT">Payment</MenuItem>
                </Select>
              </div>
              <div className="col-md-6 mt-3">
                <InputLabel htmlFor="paidAmount">Transaction Amt</InputLabel>
                <OutlinedInput
                  label="Outlined"
                  variant="standard"
                  className="w-100 outlined-input mt-2"
                  id="paidAmount"
                  {...register("paidAmount")}
                  type="number"
                />
                <p className="error">{errors?.paidAmount?.message}</p>
              </div>
              <div className="col-md-6 mt-3 d-none">
                <InputLabel>Transaction Amt</InputLabel>
                <OutlinedInput
                  label="Outlined"
                  variant="standard"
                  className="w-100 outlined-input mt-2"
                  id="currentOutstandingAmount"
                  {...register("currentOutstandingAmount")}
                  type="hidden"
                />
                <p className="error">{errors?.paidAmount?.message}</p>
              </div>
              <div className="col-12 mt-3">
                <InputLabel
                  htmlFor="adminRemarks"
                  className="mt-3"
                  style={{ textAlign: "left" }}
                >
                  Approver Remarks
                </InputLabel>
                <OutlinedInput
                  id="adminRemarks"
                  multiline
                  maxRows={4}
                  label="Outlined"
                  variant="standard"
                  className="w-100 outlined-input mt-2"
                  {...register("adminRemarks")}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions className="justify-content-center mb-5">
            <BootstrapButton
              aria-selected="true"
              className="w-auto px-5 py-2 border-0"
              autoFocus
              type="submit"
              variant="contained"
              disabled={!isDirty || !isValid}
            >
              Update
            </BootstrapButton>
            <BootstrapButton
              aria-selected="false"
              className="w-auto px-5 py-2"
              onClick={handleModal}
            >
              Cancel
            </BootstrapButton>
          </DialogActions>
        </form>
      </Dialog>
      {successMsg && <SuccessSnackbar successMsg={successMsg.msg} />}
      {errorMsg.state && <ErrorSnackbar errorMsg={errorMsg.msg} />}
    </>
  );
};

export default UpdatePaymentDialog;
