import { useState, useEffect } from "react";
import axios from "axios";

import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";

import CloseIcon from "@mui/icons-material/Close";

import { ErrorSnackbar } from "../../../components/CustomSnackBar";
import { ApiUrl } from "../../../helpers/getUrl";

const RAZORPAY_KEY =
  process.env.NODE_ENV === "production"
    ? "rzp_live_CWzlQT3wc9oz0r"
    : "rzp_test_MQrCuwtmXZd65w";

const FORM_STATE = {
  email: "",
  phoneNumber: "",
  amount: 1000.0,
};

const loadScript = (src) =>
  new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });

export default function RechargeKYC({ openRechange, toggleRechargeClick, successRechargeView }) {
  const { connectorId } = JSON.parse(sessionStorage.getItem("data")) || {};

  const [form, setForm] = useState(FORM_STATE);
  const [radioAmt, setRadioAmt] = useState(1);
  const [errorMessage, setErrorMessage] = useState({});
  const [isValidForm, setIsValidForm] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      const user = JSON.parse(sessionStorage.getItem("user"));
      if (user) {
        const _form = { ...form };
        if (user.userEmail) {
          _form["email"] = user.userEmail;
        }
        if (user.userPhone) {
          _form["phoneNumber"] = user.userPhone;
        }
        setForm(_form);
      }
    } catch (e) {
      console.error(JSON.stringify(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isFormValid = true;
    const errorMsg = {};
    if (form.email) {
      if (
        form.email.indexOf("@") === -1 ||
        form.email.indexOf(".") === -1 ||
        form.email.lastIndexOf("@") > form.email.lastIndexOf(".")
      ) {
        isFormValid = false;
        errorMsg["email"] = "Invalid Email Address";
      }
    } else {
      isFormValid = false;
    }
    if (form.phoneNumber) {
      if (form.phoneNumber.length > 0 && form.phoneNumber.length < 10) {
        isFormValid = false;
        errorMsg["phoneNumber"] = "Invalid Phone Number";
      }
    } else {
      isFormValid = false;
    }
    if (form.amount) {
      if (Number(form.amount) < 1) {
        isFormValid = false;
        errorMsg["amount"] = `Recharge amount should be more than ${(1).cur()}`;
      }
    } else {
      isFormValid = false;
    }
    setErrorMessage(errorMsg);
    setIsValidForm(isFormValid);
  }, [form]);

  const handleFormChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case "phoneNumber":
        const pattern = new RegExp(/^[0-9\b]+$/);
        if ((pattern.test(value) || value === "") && value.length <= 10) {
          setForm({
            ...form,
            [name]: value,
          });
        }
        break;
      case "amount":
        if (!isNaN(value)) {
          setForm({
            ...form,
            [name]: value,
          });
        }
        break;
      default:
        setForm({
          ...form,
          [name]: value,
        });
    }
  };

  const handleRadioAmtChange = (event) => {
    const value = Number(event.target.value);
    let amt = "";
    switch (value) {
      case 1:
        amt = "1000.00";
        break;
      case 2:
        amt = "500.00";
        break;
      case 3:
        amt = "100.00";
        break;
      default:
    }
    setRadioAmt(value);
    setForm({
      ...form,
      amount: amt,
    });
  };

  const displayRazorpay = async () => {
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("user"));

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Failed to load payment gateway. Please retry.");
      return;
    }

    const amount = Number(form.amount) * 100;

    const payload = {
      amount,
      currency: "INR",
      connectorId,
      email: form.email,
      phoneNumber: form.phoneNumber,
    };

    const { data } = await axios.post(`${ApiUrl()}payment`, payload);

    const options = {
      key: RAZORPAY_KEY,
      amount: data.amount.toString(),
      currency: data.currency,
      name: "Digi Loans",
      description: "Pay for the Know Your Customer facilities",
      image: "http://13.232.147.253/static/media/Logo.1a058d1b.png",
      order_id: data.id,
      handler: function (response) {
        setLoading(false);
        successRechargeView(true);
      },
      prefill: {
        name: user.userName,
        email: form.email,
        contact: form.phoneNumber,
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <>
      <Dialog
        open={openRechange}
        onClose={toggleRechargeClick}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          <div>Recharge</div>
          <IconButton
            aria-label="close"
            onClick={toggleRechargeClick}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ padding: "0.5rem" }}>
              <TextField
                id="email"
                label="Email Address"
                variant="standard"
                fullWidth
                onChange={handleFormChange}
                name="email"
                value={form.email}
                autoFocus
                error={Boolean(errorMessage["email"])}
                helperText={errorMessage["email"]}
              />
            </Grid>
            <Grid item xs={12} style={{ padding: "0.5rem" }}>
              <TextField
                id="mobile"
                label="Phone Number"
                variant="standard"
                fullWidth
                onChange={handleFormChange}
                name="phoneNumber"
                value={form.phoneNumber}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
                error={Boolean(errorMessage["phoneNumber"])}
                helperText={errorMessage["phoneNumber"]}
              />
            </Grid>
            <Grid item xs={12} style={{ padding: "0.5rem" }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Recharge Amount</FormLabel>
                <RadioGroup
                  aria-label="amount"
                  value={radioAmt}
                  onChange={handleRadioAmtChange}
                  name="amount"
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label={new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(1000)}
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label={new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(500)}
                  />
                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label={new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(100)}
                  />
                  <FormControlLabel
                    value={4}
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                padding: "0.5rem",
                display: radioAmt === 4 ? "inherit" : "none",
              }}
            >
              <TextField
                id="amount"
                variant="standard"
                fullWidth
                onChange={handleFormChange}
                name="amount"
                value={form.amount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">&#8377;</InputAdornment>
                  ),
                }}
                error={Boolean(errorMessage["amount"])}
                helperText={errorMessage["amount"]}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={displayRazorpay}
            variant="contained"
            fullWidth
            disabled={loading || !isValidForm}
            style={{ height: "3rem" }}
          >
            {loading ? <CircularProgress /> : "Payment Checkout"}
          </Button>
        </DialogActions>
      </Dialog>
      {errorMessage.API && <ErrorSnackbar errorMsges={errorMessage.API} />}
    </>
  );
}
