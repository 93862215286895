import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import {
  ErrorSnackbar,
  SuccessSnackbar
} from "../../components/CustomSnackBar";
import PageLoader from "../../components/PageLoader";
import {
  postLoginData,
  resetRedirectFlag
} from "../../redux/reducers/Auth/LoginService";
import useAppContext from "../../utils/useAppContext";
import logo from "./Logo.svg";
import loginBg from "./lms_login_bg.png"
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © Digi Loans"} {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const validationSchema = yup.object({
  email: yup.string().email().required("Email is Required"),
  password: yup.string().min(8).max(32).required("Password is Required"),
});

export default function LoginPage() {
  let history = useHistory();
  let location = useLocation();
  let auth = useAppContext();

  const resolver = useYupValidationResolver(validationSchema);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver,
    // defaultValues: { email: "mkhati@gmail.com", password: "aaBB11##" },
    // defaultValues: { email: "vadlasuresh_mbnr@yahoo.com", password: "aaBB11##" }, 
    // defaultValues: { email: "venkat@starpowerz.com", password: "aaBB11##" },
    // defaultValues: { email: "rajesh@starpowerz.com", password: "aaBB11##" },
  });

  const dispatch = useDispatch();

  const { loginData, isLoading, successMsg, errorMsg, redirectFlag } =
    useSelector(({ loginService }) => loginService);

  const onSubmitHandler = (data) => {
    // console.log(data, ":data");
    dispatch(postLoginData(data));
    // reset();
    // auth.signin(() => {
    //   history.replace(from);
    // });
  };

  useEffect(() => {
    reset();
    if (redirectFlag) {
      setTimeout(() => dispatch(resetRedirectFlag()), 100);
      auth.signin(() => {
        history.push("/");
      }, loginData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData, redirectFlag]);

  let { from } = location.state || { from: { pathname: "/" } };

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={0} alignItems="center"
        style={{
          backgroundColor: '#ffffff', height: '100vh', background: `url(${loginBg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '110%',
          backgroundPosition: 'top center',
        }}>
        <Grid item xs={12} sm={5} style={{ padding: 10, margin: '0 auto' }}>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: 3,
              textAlign: 'left',
              padding: '30px 40px',
              margin: '0 auto',
              maxWidth: '420px',
            }}
          >
            <Grid container>
              <Grid item xs={12} md={6} className="">
                <img
                  style={{ maxHeight: "40px", margin: '8px 0', marginRight: 'auto', width: "90px" }}
                  src={logo}
                  alt="logo"
                />
              </Grid>
              <Grid item xs={12} md={6} className="text-end">
                <IconButton aria-label="close">
                  <CloseIcon style={{ fontSize: 26 }} />
                </IconButton>
              </Grid>
            </Grid>
            <Typography
              as="h1"
              variant="h5"
              sx={{ my: 2, fontWeight: "500" }}
            >
              Login
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmitHandler)}
              sx={{ width: '100%' }}
            >
              <div class="mb-3">
                <label for="email" class="form-label">Email address</label>
                <input type="email" class="form-control form-control-lg" id="email" required
                  {...register("email")} />
              </div>
              <p>{errors.email?.message}</p>
              <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <input type="password" class="form-control form-control-lg" id="password" required
                  {...register("password")} />
              </div>
              <Grid container>
                <Grid item xs={12} md={6} className="text-start">
                  <Controller
                    name="remember"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => <Checkbox {...field}
                      sx={{
                        paddingLeft: 0
                      }}
                    />}
                  />
                  <FormLabel
                    htmlFor="remember"
                    color="primary"
                    sx={{ lineHeight: 1.5, marginTop: 0 }}
                  >
                    Remember me
                  </FormLabel>

                </Grid>
                <Grid item xs={12} md={6} className="d-flex align-items-center justify-content-end">
                  <Link href="/reset-pass" variant="body2" style={{ color: "#7E7E7E" }}>
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
              <p>{errors.password?.message}</p>
              <button
                type="submit"
                className="btn btn-primary btn-rounded w-100 my-3"
                style={{ borderRadius: "100px", height: "46px" }}>Login</button>
              <Copyright sx={{ mt: 2, mb: 2 }} />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {isLoading && <PageLoader />}
      {errorMsg && <ErrorSnackbar errorMsg={errorMsg} />}
      {successMsg && <SuccessSnackbar successMsg={successMsg} />}
    </ThemeProvider>
  );
}
