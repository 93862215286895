import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, Stack, TableBody, Tooltip, Typography, Zoom } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from 'react';
import { ApiUrl } from '../../../helpers/getUrl';
import CloseIcon from "@mui/icons-material/Close";
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

const UploadIndex = () => {

    const [data, setData] = useState([]);
    const [openUpload, setOpenUpload] = useState(false);
    const [selectedFile, setSelectedFile] = useState(false);

    useEffect(() => {
        axios.get(ApiUrl() + `robo/meta`).then(res => {
            setData(res.data)
        });
    }, [openUpload]);

    const handleSubmit = () => {

        const formData = new FormData();
        formData.append('file', selectedFile);

        axios.post(`${ApiUrl()}robo/upload`, formData).then((res) => {
            handleDialog()
        }).catch(error => {

        });
    }

    const changeHandler = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleDialog = () => {
        setSelectedFile(null);
        setOpenUpload(false);
    }

    return (
        <>
            <div className="row my-2">
                <div className="col-md-12">
                    <Stack spacing={1} direction="row-reverse">
                        <Button
                            sx={{ boxShadow: 2, marginRight: 6, marginTop: 3 }}
                            variant="contained"
                            endIcon={<FileUploadOutlinedIcon />}
                            onClick={() => setOpenUpload(true)}
                        >
                            Upload
                        </Button>
                    </Stack>
                </div>
            </div>
            <Grid container justifyContent='center' alignItems='center' paddingTop={3}>
                <TableContainer component={Paper} style={{ width: "150vh" }}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell>File Name</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Upload Date</TableCell>
                                <TableCell>Remark</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map(item => (
                                <TableRow>
                                    <TableCell>{item.key}</TableCell>
                                    <TableCell>{item.status}</TableCell>
                                    <TableCell>{moment(item.createdOn).format("DD/MM/YYYY HH:MM")}</TableCell>
                                    <TableCell>
                                        {item?.reasons?.[0].row === "Header" ? <Tooltip TransitionComponent={Zoom} title={"Invalid File Format"}>
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                            >
                                                <WarningTwoToneIcon color='error' />
                                            </IconButton>
                                        </Tooltip>
                                            : <>
                                                {item?.reasons && <Tooltip TransitionComponent={Zoom}
                                                    title={
                                                        <ol>
                                                            {item.reasons.map((reason, index) => (<li key={index} >{reason}</li>))}
                                                        </ol>
                                                    }>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                    >
                                                        <InfoTwoToneIcon color='primary' />
                                                    </IconButton>
                                                </Tooltip>}
                                            </>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Dialog maxWidth="sm" fullWidth open={openUpload} onClose={() => setOpenUpload(false)}>
                <DialogTitle>Upload
                    <IconButton
                        aria-label="close"
                        onClick={() => handleDialog()}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }} >
                        <CloseIcon />
                    </IconButton>
                    <hr /></DialogTitle>

                <DialogContent>
                    <form>
                        <FormControl variant="standard">
                            <label htmlFor="contained-button-file">
                                <input style={{ display: 'none' }} id="contained-button-file" type="file" onChange={changeHandler} />
                                <Button variant='outlined' component='span'>
                                    choose file here
                                </Button>
                            </label>
                            {selectedFile && <Typography color="green" variant="h6" gutterBottom component="div">
                                {selectedFile?.name}
                            </Typography>}
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={handleSubmit} disabled={!selectedFile}>
                        <FileUploadOutlinedIcon />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default UploadIndex;