import CheckIcon from '@mui/icons-material/Check';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Stack, TextareaAutosize, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import moment from "moment";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { monthNumberToLabelMap } from '../../constants/appconstants';
import { ApiUrl } from '../../helpers/getUrl';

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [confirm, setConfirm] = React.useState({
        display: false,
        type: null,
    });


    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
    } = useForm({
    });

    const onSubmit = (data1) => {
        const { userEmail } = JSON.parse(sessionStorage.getItem("user"));

        axios.get(ApiUrl() + `expense?month=${row.month}&year=${row.year}`).then(async res => {
            const data = await res.data;
            const expenseIds = data.map(item => item.expenseId);
            const formData = {
                expenseIds,
                "comments": data1.comments,
                "updatedBy": userEmail
            };
            axios.put(ApiUrl() + `expense/status/${confirm.type}`, formData).then((res) => {
                handleConfirm(null);
                reset();
            }).catch((error) => {
                console.error(error)
            })
        });
    }
    const handleConfirm = (data) => {
        setConfirm({
            display: !confirm.display,
            type: data
        })
        props.setUpdateData(!props.updateData);
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {monthNumberToLabelMap[row.month + 1]}
                </TableCell>
                <TableCell>{row.totalGrossAmount}</TableCell>
                <TableCell>{row.totalGrossAmount}</TableCell>
                <TableCell>{row.totalNetAmount}</TableCell>
                <TableCell>
                    {row.status === "APPROVED" && <Chip label="Approved" size="small" variant='outlined' color="success" />}
                    {row.status === "REJECTED" && <Chip label="Rejected" size="small" variant='outlined' color="error" />}
                    {row.status === "PENDING" && <Chip label="Pending" size="small" variant='outlined' color="secondary" />}
                </TableCell>
                <TableCell>
                    {row.status === "PENDING" &&
                        <>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => handleConfirm("REJECTED")}
                            >
                                <ClearIcon color='error' />
                            </IconButton>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => handleConfirm("APPROVED")}
                            >
                                <CheckIcon color='success' />
                            </IconButton>
                        </>
                    }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#f0f8ff' }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <SubRow row={row} />
                    </Collapse>
                </TableCell>
            </TableRow>
            <form>
                <Dialog
                    fullWidth
                    open={confirm.display} onClose={() => handleConfirm(null)}>
                    <DialogTitle>Approve/Reject Expense</DialogTitle>
                    <DialogContent sx={{ paddingTop: 0, marginTop: "10px" }}>
                        <div className="row form-fields mt-md-n5">
                            <InputLabel style={{ paddingLeft: "12px" }}>Comments</InputLabel>
                            <FormControl variant="standard" style={{ padding: "12px" }}>
                                <TextareaAutosize
                                    minRows={5}
                                    placeholder="Enter Comments"
                                    {...register("comments")}
                                />
                            </FormControl>
                        </div>
                    </DialogContent>

                    <DialogActions sx={{ padding: "0 30px 20px 0" }}>
                        <Button variant="text" sx={{ boxShadow: 2 }}
                            onClick={() => handleConfirm(null)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>

        </React.Fragment >
    );
}


export default function ExpenseApproval() {

    const [value, setValue] = useState(moment(new Date()));
    const [yearWiseData, setYearWiseData] = useState([]);
    const [updateData, setUpdateData] = useState(false);

    useEffect(() => {
        const year = value.year()
        axios.get(ApiUrl() + `expense/calendar?year=${year}`).then((response) => {
            setYearWiseData(response.data)
        }).catch((error) => {
            console.error(error)
        })
    }, [value, updateData])


    return (
        <div className="sidenav-content">
            <div className="row my-2">
                <div className="col-md-6">
                    <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        className="leads-heading"
                    >
                        Expense Data
                    </Typography>
                </div>
                <div className="col-md-6">
                    <Stack spacing={4} direction="row-reverse" alignItems='flex-start'>
                        <FormControl>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    views={['year']}
                                    label=""
                                    value={value}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                    renderInput={(params) => <TextField style={{ marginTop: '-20px' }} {...params} helperText={null} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Stack>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow style={{ backgroundColor: '#cecece' }}>
                                    <TableCell />
                                    <TableCell>Month</TableCell>
                                    <TableCell>Total Amount</TableCell>
                                    <TableCell>Total TDS</TableCell>
                                    <TableCell>Total Net Amount</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {yearWiseData?.map((row) => (
                                    <Row key={row.month} row={row} updateData={updateData} setUpdateData={setUpdateData} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    );
}

function SubRow(props) {
    const { row } = props;

    const [data, setData] = React.useState([])

    React.useEffect(() => {
        axios.get(ApiUrl() + `expense?month=${row.month}&year=${row.year}`).then((res) => {
            setData(res.data);
        }).catch((error) => {
            console.error(error)
        })
    }, [row]);

    return (
        <React.Fragment>
            <Box sx={{ margin: 3 }}>
                <TableContainer >
                    <Table size="small" stickyHeader aria-label="data table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Category</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>TDS</TableCell>
                                <TableCell>IGST</TableCell>
                                <TableCell>CGST</TableCell>
                                <TableCell>SGST</TableCell>
                                <TableCell>TDS Payment Status</TableCell>
                                <TableCell>Paid On</TableCell>
                                <TableCell>Net Amount</TableCell>
                                <TableCell>Remarks</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ backgroundColor: "#bbbbbb4f" }}>
                            {data?.map((data, idx) => (
                                <TableRow hover tabIndex={-1} key={idx}>
                                    <TableCell>{data.category}</TableCell>
                                    <TableCell>{data.grossAmount}</TableCell>
                                    <TableCell>{data.tds}</TableCell>
                                    <TableCell>{data.igst}</TableCell>
                                    <TableCell>{data.cgst}</TableCell>
                                    <TableCell>{data.sgst}</TableCell>
                                    <TableCell>{data.isTdsPaid ? "Yes" : "No"}</TableCell>
                                    <TableCell>{moment(data?.paidOn)?.format("DD/MM/YYYY") || moment((new Date(data?.paidOn)))?.format("DD/MM/YYYY")}</TableCell>
                                    <TableCell>{data.netAmount}</TableCell>
                                    <TableCell>{data.remark}</TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </React.Fragment >
    );
}
