import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { bankNameConstants, loanStatusConstants, productConstants } from "../../constants/appconstants";
import { ApiUrl } from "../../helpers/getUrl";

const AdminLeadDownload = ({ openLeadDownload, setOpenLeadDownload }) => {

    const useStyles = makeStyles(() => ({
        tableCell: { textAlign: "center" },
        formControl: {
            padding: "5px",
            margin: "3px",
            width: "250px",
        },
    }));

    const classes = useStyles();

    const handleOpenLeadDownload = () => {
        setOpenLeadDownload(false)
        setProductType([])
        setLoanStatus([])
        reset();
    }

    const [productType, setProductType] = useState([]);
    const [loanStatus, setLoanStatus] = useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setProductType(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const {
        handleSubmit,
        register,
        reset,
        getValues,
        formState: { errors },
    } = useForm({
    });

    const onSubmit = (data) => {
        const productType1 = productType.map((item) => { return Object.keys(productConstants).filter(key => productConstants[key] === item)[0]; })

        let formData = {
            "productType": productType1,
            "loanStatus": loanStatus,
            "loanBank": data.loanBank ? [bankNameConstants[data.loanBank]] : [],
        }

        if (data.fromDate !== '' && data.toDate !== '') {
            const fromDate = new Date(data.fromDate).toISOString();
            const toDate = new Date(data.toDate).toISOString();

            if (data.dateType !== 'Application Date') {
                formData = {
                    ...formData,
                    applicationDate: {
                        fromDate,
                        toDate
                    }
                }
            }
            else {
                formData = {
                    ...formData,
                    disbursementDate: {
                        fromDate,
                        toDate
                    }
                }
            }
        }

        Object.entries(formData).forEach(([key, val]) =>
            (val === null || val === "" || val?.length === 0) && delete formData[key]
        );
        console.log(formData)

        const link = document.createElement("a");
        link.target = "_blank";
        link.download = "leads_data.csv"

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };
        fetch(ApiUrl() + 'leads/download/', requestOptions)
            .then(async response => {
                const blob = await response.blob();
                const newBlob = new Blob([blob]);
                const blobUrl = window.URL.createObjectURL(newBlob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', `Leads_Data-${new Date().toLocaleDateString()}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(blob);
                handleOpenLeadDownload();
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    return (
        <div className="sidenav-content">

            <Dialog open={openLeadDownload} onClose={handleOpenLeadDownload}>
                <DialogTitle >Download Leads Data</DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent sx={{ paddingTop: 0, marginTop: "10px" }}>
                        <div className="row form-fields mt-md-n5">
                            <div className="col-md-6">
                                <FormControl
                                    variant="standard" className={classes.formControl}>
                                    <InputLabel id="productType-label">Product Type</InputLabel>
                                    <Select
                                        labelId="productType-label"
                                        id="productType"
                                        multiple
                                        value={productType}
                                        onChange={handleChange}
                                        input={<OutlinedInput label="productType" />}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {Object.values(productConstants).map((name) => (
                                            <MenuItem key={name} value={name}>
                                                <Checkbox checked={productType.indexOf(name) > -1} />
                                                <ListItemText primary={name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-6">
                                <FormControl
                                    variant="standard" className={classes.formControl}>
                                    <InputLabel id="loanStatus-label">Loan Status</InputLabel>
                                    <Select
                                        labelId="loanStatus-label"
                                        id="loanStatus"
                                        multiple
                                        value={loanStatus}
                                        onChange={(e) => { setLoanStatus(typeof value === 'string' ? e.target.value.split(',') : e.target.value); }}
                                        input={<OutlinedInput label="loanStatus" />}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {Object.keys(loanStatusConstants).map((name) => (
                                            <MenuItem key={name} value={name}>
                                                <Checkbox checked={loanStatus.indexOf(name) > -1} />
                                                <ListItemText primary={name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-6">
                                <FormControl variant="standard" className={classes.formControl}>
                                    <Autocomplete
                                        disablePortal
                                        options={Object.keys(bankNameConstants)}
                                        id="demo-simple-select-standard"
                                        renderInput={(params) => <TextField
                                            {...register("loanBank")}
                                            {...params} label="Bank Name" />}
                                    />
                                </FormControl>
                            </div>
                            <div className="col-md-6">
                                <FormControl variant="standard" className={classes.formControl}>
                                    <Select
                                        labelId="dateType"
                                        id="dateType"
                                        sx={{ marginTop: "20px" }}
                                        defaultValue="Application Date"
                                        {...register("dateType")}
                                        label="Date Type"
                                        fullWidth
                                    >
                                        {['Application Date', 'Disbursement Date'].map((x) => (
                                            <MenuItem value={x}>{x}</MenuItem>
                                        ))}
                                    </Select>
                                    <InputLabel>Date Type</InputLabel>
                                </FormControl>
                            </div>
                            <div className="col-md-6">
                                <FormControl variant="standard" className={classes.formControl}>
                                    <InputLabel style={{ margin: '-15px 5px', fontSize: '10px' }}>From Date</InputLabel>
                                    <input
                                        type="date"
                                        placeholder="Date"
                                        className="date-picker"
                                        {...register("fromDate")}
                                    />
                                </FormControl>
                            </div>
                            <div className="col-md-6">
                                <FormControl variant="standard" className={classes.formControl}>
                                    <InputLabel style={{ margin: '-15px 5px', fontSize: '10px' }}>To Date</InputLabel>
                                    <input
                                        type="date"
                                        placeholder="Date"
                                        className="date-picker"
                                        {...register("toDate")}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions sx={{ padding: "0 30px 20px 0" }}>
                        <Button variant="text" sx={{ boxShadow: 2 }} onClick={handleOpenLeadDownload}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                        // disabled={isLoading ? "true" : "false"}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </form>
            </Dialog >
        </div>

    )
}
export default AdminLeadDownload;