import { useState } from "react";
import axios from "axios";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";

import CloseIcon from "@mui/icons-material/Close";

import { ApiUrl } from "../../../helpers/getUrl";

const instructionBlock = (
  <Paper elevation={0}>
    <Typography variant="h5">Instruction to follow:</Typography>
    <ol>
      <li>
        <Typography>
          Click on the below <strong>Login to Digilocker</strong> button.
        </Typography>
      </li>
      <li>
        <Typography>
          It will take you a new tab to enter your 12 digit Aadhar card number.
        </Typography>
      </li>
      <li>
        <Typography>
          Click <strong>Next</strong> and provide the OTP from the Aadhar card
          owner's phone.
        </Typography>
      </li>
      <li>
        <Typography>
          After clicking <strong>Continue</strong> button, accept{" "}
          <strong>Signzy</strong> access to <strong>DigiLocker</strong> by
          clicking on <strong>Allow</strong> button.
        </Typography>
      </li>
      {/* <li>
        <Typography>
          If the signzy site shows
          <i>You have successfully given access for your digilocker account.</i>
          , return back to this site.
        </Typography>
      </li>
      <li>
        <Typography>
          Click on the <strong>Download Aadhar details</strong> button to get
          Aadhar user details.
        </Typography>
      </li> */}
    </ol>
  </Paper>
);

const linkEvent = (url) => {
  const element = document.createElement("a");
  element.id = "digiLocker";
  element.href = url;
  element.target = "_blank";
  element.rel = "next";
  document.body.appendChild(element);
  element.click();
};

export default function AadharKYC({
  openAadharVerfication,
  toggleAadharVerfication,
}) {
  const [isApprove, setIsApprove] = useState(false);
  const [loading, setLoading] = useState(false);

  const onClickDigiLockerLogin = async () => {
    setLoading(true);
    const endPoint = `${ApiUrl()}kyc/aadhar/url`;

    const { data } = await axios.get(endPoint);

    if (data?.result?.requestId && data?.result?.url) {
      linkEvent(data.result.url);
    } else {
      // ToDo: Handle Error
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={openAadharVerfication}
      onClose={toggleAadharVerfication}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        Aadhar Verification
        <IconButton
          aria-label="close"
          onClick={toggleAadharVerfication}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>{instructionBlock}</div>
        <FormControl component="fieldset">
          <FormControlLabel
            label="I hereby understand the above instructions and wiling to use this service."
            control={
              <Checkbox
                checked={isApprove}
                onChange={(e) => setIsApprove(e.target.checked)}
              />
            }
          />
        </FormControl>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Button
            onClick={onClickDigiLockerLogin}
            variant="outlined"
            disabled={loading || !isApprove}
            style={{ height: "3rem" }}
          >
            {"Login to Digilocker"}
          </Button>
          {/* <Button
            onClick={onClickDownloadAadhar}
            variant="outlined"
            disabled={loading || !requestId || !isApprove}
            style={{ height: "3rem" }}
          >
            {"Download Aadhar details"}
          </Button> */}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={toggleAadharVerfication}
          variant="outlined"
          disabled={loading}
          style={{ height: "3rem" }}
        >
          {"Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
