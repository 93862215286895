import axios from "axios";
import { ApiUrl } from "../../helpers/getUrl";

export function AdminAdvanceService(data) {
    return new Promise((resolve, reject) => {
        const url = ApiUrl() + `advance/list/undefined`;

        axios.post(url, data)
            .then((res) => {
                if (res.status < 204 && res.data.data) {
                    // console.log("AdminAdvanceService: ", res.data.data);
                    resolve({ status: true, data: res.data.data });
                } else {
                    reject({ status: false, error: res.data.data });
                }
            })
            .catch((error) => {
                reject({ status: false, error });
            });
    });
}
