import { FormControl, InputLabel, TextField } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import axios from "axios";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { ApiUrl } from "../../helpers/getUrl";
import TransactionSection from "./stats";


const useStyles = makeStyles(() => ({
  tableCell: { textAlign: "center" },
  formControl: {
    padding: "5px",
    margin: "2px",
    marginBottom: 5,
    width: "100%",
  },
  formLabel: {
    margin: '-15px 5px',
    fontSize: '10px'
  },
  cardProperties: {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: 100,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: "#92ffd2",
    },
  }
}));

function Reports() {

  const [level, setLevel] = useState('L1');

  const [location, setlocation] = useState(undefined);
  const [branchManager, setBranchManager] = useState(undefined);
  const [connManager, setconnManager] = useState(undefined);
  const [data, setData] = useState([]);
  const today = moment(new Date()).format("YYYY-MM-DD");
  const classes = useStyles();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  useEffect(() => {
    const start = new Date(startDate).toISOString();
    const end = moment(endDate).format("YYYY-MM-DD") + "T23:59:59.000Z";
    const body = {
      "startDate": start,
      "endDate": end
    }
    axios.post(`${ApiUrl()}reports/${location}/${branchManager?.id}/${connManager?.id}`, body)
      .then(res => {
        let result = res.data;
        if (level === 'L2') {
          console.log(result)
          setData(result);
        } else
          setData(result);
      }).catch(error => {
        console.error('There was an error!', error);
      });
  }, [level, startDate, endDate]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} className="sidenav-content">
      <Grid container spacing={3} className="row m-0 sidenav-info">
        <div className="row my-2">
          <div className="col-md-6">
          </div>
          <div className="col-md-3">
            <FormControl variant="standard" className={classes.formControl}>
              <InputLabel className={classes.formLabel}>Start Date</InputLabel>
              <TextField
                type="date"
                placeholder="Date"
                className="date-picker"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormControl>
          </div>
          <div className="col-md-3">
            <FormControl variant="standard" className={classes.formControl}>
              <InputLabel className={classes.formLabel}>End Date</InputLabel>
              <TextField
                type="date"
                placeholder="Date"
                className="date-picker"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormControl>
          </div>
        </div>
        <TransactionSection startDate={startDate} endDate={endDate} />

        <div className="row ceo-reports-container">
          {level === 'L1' && <div>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="text.primary">Master Report</Typography>
            </Breadcrumbs>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="Master Report">
                <TableHead>
                  <TableRow>
                    <TableCell>Location</TableCell>
                    <TableCell>No. of Branch Managers</TableCell>
                    <TableCell>No. of Managers</TableCell>
                    <TableCell>Active Connectors</TableCell>
                    <TableCell>Total Disbursment</TableCell>
                    <TableCell>PL Disbursment</TableCell>
                    <TableCell>HL Disbursment</TableCell>
                    <TableCell>Auto Disbursment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <TableRow
                      key={row.locationName}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      hover
                      onClick={(event) => {
                        setLevel('L2');
                        setlocation(row.locationName);
                      }}
                    >
                      <TableCell component="th" scope="row">{row.locationName}</TableCell>
                      <TableCell>{row.numberOfBranchManagers}</TableCell>
                      <TableCell>{row.numberOfManagers}</TableCell>
                      <TableCell>{row.numberOfConnectors}</TableCell>
                      <TableCell>{row.totalDisbursedAmount}</TableCell>
                      <TableCell>{row.PERS}</TableCell>
                      <TableCell>{row.HOME}</TableCell>
                      <TableCell>{row.AUTO}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>}
          {level === 'L2' &&
            <div>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" onClick={() => {
                  setLevel('L1');
                  setlocation(undefined);
                  setBranchManager(undefined);
                }}>Master Report</Link>
                <Typography color="text.primary">{location}</Typography>
              </Breadcrumbs>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="Master Report">
                  <TableHead>
                    <TableRow>
                      <TableCell>Branch Manager Name</TableCell>
                      <TableCell>No. of Managers</TableCell>
                      <TableCell>Active Connectors</TableCell>
                      <TableCell>Total Disbursment</TableCell>
                      <TableCell>PL Disbursment</TableCell>
                      <TableCell>HL Disbursment</TableCell>
                      <TableCell>Auto Disbursment</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((row) => (
                      <TableRow
                        key={row.connectorBranchManagerId}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        hover
                        onClick={(event) => {
                          setLevel('L3');
                          setBranchManager({ id: row.connectorBranchManagerId, name: row.connectorBranchManagerName });
                        }}
                      >
                        <TableCell component="th" scope="row">{row.connectorBranchManagerName}</TableCell>
                        <TableCell>{row.numberOfManagers}</TableCell>
                        <TableCell>{row.numberOfConnectors}</TableCell>
                        <TableCell>{row.totalDisbursedAmount}</TableCell>
                        <TableCell>{row.PERS}</TableCell>
                        <TableCell>{row.HOME}</TableCell>
                        <TableCell>{row.AUTO}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>}
          {level === 'L3' && <div>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" onClick={() => {
                setLevel('L1');
                setlocation(undefined);
                setBranchManager(undefined);
                setconnManager(undefined);
              }}>Master Report
              </Link>
              <Link underline="hover" color="inherit" onClick={() => {
                setLevel('L2');
                setBranchManager(undefined);
                setconnManager(undefined);
              }}>{location}
              </Link>
              <Link underline="hover" color="inherit">{branchManager?.name}
              </Link>
              <Typography color="text.primary">{connManager?.name}</Typography>
            </Breadcrumbs>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="Master Report">
                <TableHead>
                  <TableRow>
                    <TableCell>Manager Name</TableCell>
                    <TableCell>Active Connectors</TableCell>
                    <TableCell>Total Disbursment</TableCell>
                    <TableCell>PL Disbursment</TableCell>
                    <TableCell>HL Disbursment</TableCell>
                    <TableCell>Auto Disbursment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <TableRow
                      key={row.loanState}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={(event) => {
                        setLevel('L4');
                        setconnManager({ id: row.connectorManagerId, name: row.connectorManagerName });
                      }}
                    >
                      <TableCell component="th" scope="row">{row.connectorManagerName}</TableCell>
                      <TableCell>{row.numberOfConnectors}</TableCell>
                      <TableCell>{row.totalDisbursedAmount}</TableCell>
                      <TableCell>{row.PERS}</TableCell>
                      <TableCell>{row.HOME}</TableCell>
                      <TableCell>{row.AUTO}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>}
          {level === 'L4' && <div>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" onClick={() => {
                setLevel('L1');
                setlocation(undefined);
                setBranchManager(undefined);
                setconnManager(undefined);
              }}>Master Report
              </Link>
              <Link underline="hover" color="inherit" onClick={() => {
                setLevel('L2');
                setBranchManager(undefined);
                setconnManager(undefined);
              }}>{location}
              </Link>
              <Link underline="hover" color="inherit" onClick={() => {
                setLevel('L3');
                setconnManager(undefined);
              }}>{branchManager?.name}
              </Link>
              <Typography color="text.primary">{connManager?.name}</Typography>
            </Breadcrumbs>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="Master Report">
                <TableHead>
                  <TableRow>
                    <TableCell>Active Connectors</TableCell>
                    <TableCell>Total Disbursment</TableCell>
                    <TableCell>PL Disbursment</TableCell>
                    <TableCell>HL Disbursment</TableCell>
                    <TableCell>Auto Disbursment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <TableRow
                      key={row.loanState}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">{row.connectorName}</TableCell>
                      <TableCell>{row.totalDisbursedAmount}</TableCell>
                      <TableCell>{row.PERS}</TableCell>
                      <TableCell>{row.HOME}</TableCell>
                      <TableCell>{row.AUTO}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>}
        </div>
      </Grid>
    </Container>
  );
}

export default Reports;
