import React, { useState, useEffect } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from "axios";
import { ApiUrl } from '../../../helpers/getUrl';
import useAppContext from "../../../utils/useAppContext";
import { productOrder } from "../../../constants/appconstants";

function ProductWiseSummary() {

    const { freqValue, productType } = useAppContext();
    const [result, setResult] = useState([]);

    useEffect(() => {
        getProductProjection();
    }, [productType, freqValue]);

    const getProductProjection = () => {
        axios.get(`${ApiUrl()}projection/product/ceo?product=${productType}&timeframe=${freqValue}`)
            .then(res => {
                let data = res.data;
                data.forEach((item) => item.index = productOrder[item.label])
                data = data.sort((a, b) => (a.index > b.index) ? 1 : -1)
                setResult(data);
            }).catch(error => {
                console.error('There was an error!', error);
            });
    }
    
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                data={result}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar barSize={15} dataKey="totalApplications" name="Submitted" stackId="a" fill="#68CAFB" />
                <Bar barSize={15} dataKey="totalDisbursedApplications" name="Disbursed" stackId="a" fill="#EF96B4" />
            </BarChart>
        </ResponsiveContainer>
    );
}

export default ProductWiseSummary;

