import PostAddIcon from "@mui/icons-material/PostAdd";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";

const nonActive = { borderRadius: "5px", color: "white" };

const DataStoragePolicy = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItem className="pt-0 pb-0" onClick={() => setOpen(true)}>
        <ListItemButton sx={nonActive}>
          <ListItemIcon sx={{ color: "white" }}>
            <PostAddIcon />
          </ListItemIcon>
          <ListItemText primary="Data Storage Policy" />
        </ListItemButton>
      </ListItem>
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
        // fullWidth
      >
        <DialogTitle>{"Data Storage Policy"}</DialogTitle>
        <DialogContent>
          <div class="modal-body footer_modal_content">
            <p class="heading">Effective Date:</p>
            <ul>
              <li>
                <p class="heading">1. Introduction</p>
                <p>
                  M/s Star Powerz Digital Technologies Pvt Ltd is committed to
                  the responsible and secure storage of personal and financial
                  data. This Data Storage Policy outlines how we store, retain,
                  and protect data in compliance with relevant data protection
                  and industry regulations.
                </p>
              </li>
              <li>
                <p class="heading">2. Data Types and Categories</p>
                <p>
                  We store various types of data, including but not limited to:
                  Personal information: Names, addresses, contact details.
                  Financial information: Income, expenses, credit history.
                  Identification information: Government-issued IDs, social
                  security numbers. Employment and income details. Loan
                  transaction information.
                </p>
              </li>
              <li>
                <p class="heading">3. Data Storage Principles</p>
                <ul class="inner-ul">
                  <p class="sub-heading">
                    Our data storage practices are guided by the following
                    principles:
                  </p>
                  <li>
                    a. Legal Compliance: We adhere to all applicable data
                    protection laws, regulations, and industry standards. We are
                    committed to maintaining the highest standards of data
                    privacy and security.
                  </li>
                  <li>
                    b. Data Minimization: We store only the data that is
                    necessary for our legitimate purposes, such as loan
                    processing and compliance with legal obligations.
                  </li>
                  <li>
                    c. Retention Period: We retain data for the period required
                    by law and regulatory requirements or as necessary for our
                    legitimate business purposes. We will not retain data longer
                    than needed.
                  </li>
                  <li>
                    d. Data Security: We implement robust technical and
                    organizational measures to protect stored data from
                    unauthorized access, disclosure, alteration, or destruction.
                    These measures include encryption, access controls, and
                    regular security assessments.
                  </li>
                  <li>
                    e. Regular Review: We periodically review and assess our
                    data storage practices to ensure compliance with changing
                    regulations and evolving security threats.
                  </li>
                </ul>
              </li>
              <li>
                <p class="heading">4. Access Control</p>
                <p>
                  Access to stored data is restricted to authorized personnel
                  who require it for legitimate business purposes. Access
                  controls include user authentication and authorization
                  mechanisms.
                </p>
              </li>
              <li>
                <p class="heading">5. Data Backup</p>
                <p>
                  We maintain secure and regular data backups to ensure data
                  integrity and availability. Backup procedures include
                  encryption and secure storage.
                </p>
              </li>
              <li>
                <p class="heading">6. Data Retention and Deletion</p>
                <p>
                  We retain data only for the period required by law or as
                  necessary for legitimate business purposes. When data is no
                  longer required, it is securely deleted or anonymized to
                  protect the data subject's privacy.
                </p>
              </li>
              <li>
                <p class="heading">7. Data Transfer</p>
                <p>
                  If data is transferred outside the organization, we ensure
                  appropriate safeguards are in place to protect data privacy
                  and security, including encryption and secure transmission.
                </p>
              </li>
              <li>
                <p class="heading">8. Policy Review and Updates</p>
                <p>
                  This policy will be periodically reviewed and updated to
                  ensure compliance with data protection regulations and
                  industry best practices. Data subjects and stakeholders will
                  be informed of significant policy changes.
                </p>
              </li>
              <li>
                <p class="heading">9. Contact Information</p>
                <p>
                  If you have questions or concerns about our data storage
                  policy, please contact our Data Protection Officer Gutta
                  Rajesh at Rajesh@stardigiloans.com.
                </p>
              </li>
            </ul>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DataStoragePolicy;
