import { Grid, Paper } from "@mui/material";
import { ApiUrl } from "../../helpers/getUrl";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilePresentIcon from '@mui/icons-material/FilePresent';

const HelpIndex = () => {

    return (
        <Grid container spacing={2}>

            <Grid item xs={12} sm={12} md={5}>
                <Paper
                    sx={{
                        p: 2,
                        margin: 4,
                        display: "flex",
                        flexDirection: "column",
                        height: 130,
                        boxShadow: 3,
                        textAlign: 'center',
                        backgroundColor: "#0067766b",
                        '&:hover': {
                            background: '#8eb9c069',
                        },
                    }}
                    className="chart-section"
                >
                    <a href={`${ApiUrl()}help/`} >

                        <div className="row" style={{ flexDirection: 'row' }}>
                            {/* <h3>Help</h3>
                        <hr /> */}
                            <div style={{ height: 20, width: 20 }}>
                                <FilePresentIcon style={{
                                    padding: '10px',
                                    fontSize: '80px',
                                    backgroundColor: '#ffffff',
                                    borderRadius: '50%',
                                    color: '#006776'
                                }} />
                            </div>
                            <h5 style={{ padding: '7px 0 0 90px', color: "#000" }}>LMS Reference Document</h5>
                            <FileDownloadOutlinedIcon />
                        </div>
                    </a>

                </Paper>
            </Grid>
        </Grid >
    )
}
export default HelpIndex