import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { createTheme, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useContext } from "react";
import AppContextProvider from "../../components/contextProvider/AppContextProvider/AppContext";
import BankWiseSummary from './charts/bankWiseSummary';
// import Chart from './Chart';
import DayWiseSummary from "./charts/dayWiseSummary";
import OverViewChart from './charts/overView';
import ProductWiseSummary from './charts/productWiseSummary';
import EnhancedTable from "./charts/table";
import TopTenBanks from "./charts/topTenBanks";
import TopTenConnectors from './charts/topTenConnectors';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function ChartsSection() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const MT20PX = {
    marginTop: "20px"
  }

  const { freqValue } =
    useContext(AppContextProvider);

  const renderSwitch = (param) => {
    switch (param) {
      case 'MTD':
        return "Monthly";
      case 'LTD':
        return "LTD";
      case 'YTD':
        return "Yearly";
      case 'QTD':
        return "Quarterly";
      case 'HTD':
        return "Half Yearly";
    }
  }

  return (
    <>
      <div className="col-12 col-md-6" style={MT20PX}>
          <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 400,
                  boxShadow: 3,
                }}
                className="chart-section"
              >
                <h6>Overview</h6>
                <OverViewChart />
          </Paper>
      </div>
      <div className="col-12 col-md-6" style={MT20PX}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 400,
              boxShadow: 3,
            }}
            className="chart-section"
          >
            <h6>Product Wise Summary</h6>
            <ProductWiseSummary />
          </Paper>
      </div>
      <div className="col-12 col-md-6" style={MT20PX}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 400,
              boxShadow: 3,
            }}
            className="chart-section"
          >
            <h6>Bank Wise Summary</h6>
            <BankWiseSummary />
          </Paper>
      </div>
      <div className="col-12 col-md-6" style={MT20PX}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 400,
              boxShadow: 3,
            }}
            className="chart-section"
          >
            <h6 style={{ display: "inline-block" }}>Day Wise Summary of Disbursements</h6>
            <DayWiseSummary />
          </Paper>
      </div>
      <div className="col-12 col-md-12 col-lg-6" style={MT20PX}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 400,
              boxShadow: 3,
            }}
            className="chart-section"
          >
            <h6>Top 10 Connectors</h6>
            <TopTenConnectors />
          </Paper>
      </div>
      <div className="col-12 col-md-12 col-lg-6" style={MT20PX}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 400,
              boxShadow: 3,
            }}
            className="chart-section"
          >
            <h6>Top 10 Banks</h6>
            <TopTenBanks />
          </Paper>
      </div>
    </>
  );
}

export default ChartsSection;
