import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { ApiUrl } from "../../helpers/getUrl";

const AddOfferForm = ({ setShowForm, showForm }) => {

    const useStyles = makeStyles(() => ({
        tableCell: { textAlign: "center" },
        formControl: {
            padding: "15px",
            width: "100%",
        },
        label: {
            padding: "0 0 0 15px",
        },
        radioGroup: {
            padding: "25px 0 0 12px"
        }
    }));

    const classes = useStyles();

    const [selectedFile, setSelectedFile] = useState(null);
    const [value, setValue] = useState(false);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
    } = useForm({
    });


    const changeHandler = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const onSubmit = (data1) => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        axios.post(ApiUrl() + `enquiry/${data1.bankName}?isOfferDocument=${value}`, formData).then((res) => {
            handleModal();
        })
    }

    const handleModal = () => {
        setShowForm(false);
        setSelectedFile(null)
        reset();
        setValue(false)
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={showForm} onClose={handleModal}>
            <DialogTitle >Add Offers</DialogTitle>
            <DialogContent sx={{ paddingTop: 0, marginTop: "10px" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row form-fields mt-md-n5">
                        <div className="col-md-8">
                            <FormControl variant="standard" className={classes.formControl}>
                                <Select
                                    labelId="bankName"
                                    id="bankName"
                                    {...register("bankName", { required: "Bank Name is required" })}
                                    label="bankName"
                                    fullWidth
                                >
                                    {["AXIS", "ICICI", "TATA CAPITAL", "HBANK", "IDFC", "FULLERTON", "AXIS FINANCE", "ABFL", "POONAWALA"].map((x) => (
                                        <MenuItem value={x}>{x}</MenuItem>
                                    ))}
                                </Select>
                                <InputLabel className={classes.label}>Bank Name</InputLabel>
                                <FormHelperText
                                    error={errors?.bankName && errors?.bankName}
                                >
                                    {errors?.bankName?.message}
                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div className="col-md-8">
                            <FormControl variant="standard" className={classes.formControl}>
                                <InputLabel className={classes.label}>Is this Offer Document?</InputLabel>
                                <RadioGroup
                                    className={classes.radioGroup}
                                    row
                                    {...register("offerDocument")}
                                    value={value}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="col-md-8">
                            <FormControl variant="standard" className={classes.formControl}>
                                {selectedFile ? <Typography color="success.main" gutterBottom component="div">
                                    {selectedFile?.name}
                                </Typography>
                                    :
                                    <label htmlFor="contained-button-file">
                                        <input id="contained-button-file"
                                            {...register("file", { required: "File is required" })}
                                            hidden type="file" onChange={changeHandler} />
                                        <Button variant='outlined' component='span'>
                                            choose file here
                                        </Button>
                                        <FormHelperText
                                            error={errors?.file && errors?.file}
                                        >
                                            {errors?.file?.message}
                                        </FormHelperText>
                                    </label>
                                }

                            </FormControl>
                        </div>

                    </div>
                    <div className="row" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div className="col-md-4">
                        </div>
                        <div className="col-md-4">
                            <Stack spacing={2} direction="row-reverse">
                                <Button
                                    variant="contained"
                                    type="submit"
                                    style={{ float: 'right', marginRight: '25px' }}
                                >
                                    Save
                                </Button>

                                <Button variant="text" sx={{ boxShadow: 2 }} onClick={() => handleModal()}>
                                    Cancel
                                </Button>
                            </Stack>
                        </div>

                    </div>
                </form >
            </DialogContent>
        </Dialog >

    )
}
export default AddOfferForm;