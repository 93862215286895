import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import BadgeIcon from "@mui/icons-material/Badge";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SpeedRoundedIcon from "@mui/icons-material/SpeedRounded";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { ApiUrl } from "../../helpers/getUrl";
import AadharKYC from "./children/kyc.aadhar";
import BankStmtAnalyzer from "./children/kyc.bsa";
import Notify from "./children/kyc.notify";
import PanKYC from "./children/kyc.pan";
import RechargeKYC from "./children/kyc.recharge";
import SuccessRecharge from "./children/kyc.recharge.success";
import TransactionKYC from "./children/kyc.transaction";


const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "blue",
    display: "flex",
    borderRadius: "5px 5px 0px 0px",
    height: "60px",
    color: "white",
    padding: "20px 0px 15px 15px",
    fontWeight: "600",
    whiteSpace: "nowrap",
  },
  content: {
    padding: "1rem",
  },
  button: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "1rem 1rem",
    cursor: "pointer",
  },
  btnTxt: {
    padding: "0 1rem",
  },
  btnTitle: {
    fontWeight: "bold",
    fontSize: 15,
    paddingRight: "3rem",
  },
  servicePaper: {
    width: "100%",
    height: "6.5rem",
  },
  serviceBtn: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#EEEEEE",
    padding: "1rem 1rem",
    cursor: "pointer",
    justifyContent: "space-between",
    height: "100%",
  },
  circle: {
    borderRadius: "50%",
    width: "75px",
    height: "75px",
    backgroundColor: "white",
    [theme.breakpoints.only("xs")]: {
      borderRadius: "50%",
      width: "60px",
      height: "60px",
      backgroundColor: "white",
      margin: "30px 0 0px 10px",
      display: "none",
    },
  },
  icon: {
    color: "#FF7F50",
    float: "left",
    margin: "22px 0px 0px 30%",
    width: "30px",
    height: "30px",
    [theme.breakpoints.only("xs")]: {
      color: "#FF7F50",
      float: "left",
      margin: "20px 15px 0px 15px",
      width: "22px",
      height: "22px",
    },
  },
  userDetailTitle: {
    display: "flex",
    padding: "15px 10px 10px 10px",
    fontWeight: "500",
    backgroundColor: "#E0FFFF",
  },
}));

const DIALOG_KEY = {
  RECHARGE: "RECHARGE",
  TRANSACTION: "TRANSACTION",
  PAN: "PAN",
  AADHAR: "AADHAR",
  BSA: "BSA",
  NOTIFY: "NOTIFY"
};

export default function KYC() {
  const classes = useStyles();

  const [currBalance, setCurrBalance] = useState(0);
  const [openDialog, setOpenDialog] = useState(null);
  const [successDialog, setSuccessDialog] = useState(false);
  const [updateBalance, setUpdateBalance] = useState(true);
  const [serviceName, setServiceName] = useState(null);

  const toggleDialog = (type, service) => {
    if (type === openDialog) {
      setOpenDialog(null);
      setServiceName(null);
    } else {
      setOpenDialog(type);
      setServiceName(service);
    }
  };

  const toggleSuccessDialog = () => {
    setOpenDialog(null);
    setSuccessDialog(!successDialog);
    setUpdateBalance(true);
  };

  const updateBalanceFn = () => {
    setUpdateBalance(true);
  };

  useEffect(() => {
    if (updateBalance) {
      const user = JSON.parse(sessionStorage.getItem("data")) || {};
      let URL = `${ApiUrl()}kyc/balance?`;
      if (user.connectorId) {
        URL = URL + `connectorId=${user.connectorId}`;
      } else if (user.finMngrId) {
        URL = URL + `finMngrId=${user.finMngrId}`;
      } else if (user.finadminId) {
        URL = URL + `finadminId=${user.finadminId}`;
      } else if (user.finMngrId) {
        URL = URL + `finadminId=${user.finadminId}`;
      } else if (user.ceoId) {
        URL = URL + `ceoId=${user.ceoId}`;
      }
      axios
        .get(URL)
        .then((result) => {
          setCurrBalance(result?.data?.balanceAmount || 0);
          setUpdateBalance(false);
        })
        .catch((error) => {
          setUpdateBalance(false);
        });
    }
  }, [updateBalance]);

  return (
    <>
      <Paper>
        <header className={classes.title}>Know Your Customer</header>
        <section className={classes.content}>
          <Grid container>
            <Grid item sm={6} xs={12} style={{ padding: "0.5rem" }}>
              <Paper
                onClick={() => {
                  toggleDialog(DIALOG_KEY.RECHARGE);
                }}
              >
                <div
                  className={classes.button}
                  style={{ backgroundColor: "#EEEEEE" }}
                >
                  <AccountBalanceWalletIcon />
                  <div className={classes.btnTxt}>
                    <Typography
                      className={classes.btnTitle}
                      style={{ color: "#26C6DA" }}
                    >
                      {new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                      }).format(currBalance / 100)}
                    </Typography>
                    <Typography>Recharge</Typography>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item sm={6} xs={12} style={{ padding: "0.5rem" }}>
              <Paper
                onClick={() => {
                  toggleDialog(DIALOG_KEY.TRANSACTION);
                }}
              >
                <div
                  className={classes.button}
                  style={{ backgroundColor: "#EEEEEE" }}
                >
                  <ReceiptLongIcon />
                  <div className={classes.btnTxt}>
                    <Typography
                      className={classes.btnTitle}
                      style={{ color: "#26C6DA" }}
                    >
                      Transaction Details
                    </Typography>
                    <Typography>Select Date</Typography>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} style={{ padding: "0.5rem", display: "none" }}>
              <Paper className={classes.servicePaper}>
                <div
                  className={classes.serviceBtn}
                  style={{ backgroundColor: "#FFE4C4" }}
                >
                  <div className={classes.circle}>
                    <ReceiptIcon className={classes.icon} />
                  </div>
                  <Typography className={classes.btnTitle}>
                    Bank Statement Analyzer
                  </Typography>
                  <div className={classes.button}>
                    <Typography className={classes.btnTitle}>
                      {new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                      }).format(1)}
                    </Typography>
                    <KeyboardArrowRightIcon />
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} style={{ padding: "0.5rem" }}>
              <Paper
                className={classes.servicePaper}
                onClick={() => {
                  currBalance / 100 >= 1 ?
                    toggleDialog(DIALOG_KEY.PAN)
                    : toggleDialog(DIALOG_KEY.NOTIFY, "Pan Verification");
                }}
              >
                <div
                  className={classes.serviceBtn}
                  style={{ backgroundColor: "#E0FFFF" }}
                >
                  <div className={classes.circle}>
                    <BadgeIcon
                      className={classes.icon}
                      style={{ color: "#00FA9A" }}
                    />
                  </div>
                  <Typography className={classes.btnTitle}>
                    PAN Verification
                  </Typography>
                  <div className={classes.button}>
                    <Typography className={classes.btnTitle}>
                      {new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                      }).format(1)}
                    </Typography>
                    <KeyboardArrowRightIcon />
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} style={{ padding: "0.5rem" }}>
              <Paper
                className={classes.servicePaper}
                onClick={() => {
                  currBalance / 100 >= 1 ?
                    toggleDialog(DIALOG_KEY.AADHAR)
                    : toggleDialog(DIALOG_KEY.NOTIFY, "Aadhar Verification");
                }}
              >
                <div
                  className={classes.serviceBtn}
                  style={{ backgroundColor: "#FFF0F5" }}
                >
                  <div className={classes.circle}>
                    <CheckCircleIcon
                      className={classes.icon}
                      style={{ color: "#BA55D3" }}
                    />
                  </div>
                  <Typography className={classes.btnTitle}>
                    Aadhar Verification
                  </Typography>
                  <div className={classes.button}>
                    <Typography className={classes.btnTitle}>
                      {new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                      }).format(1)}
                    </Typography>
                    <KeyboardArrowRightIcon />
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} style={{ padding: "0.5rem" }}>
              <Paper
                className={classes.servicePaper}
                onClick={() => {
                  currBalance / 100 >= 7 ?
                    toggleDialog(DIALOG_KEY.BSA)
                    : toggleDialog(DIALOG_KEY.NOTIFY, "Bank Statement Analyzer");
                }}
              >
                <div
                  className={classes.serviceBtn}
                  style={{ backgroundColor: "#B0E0E6" }}
                >
                  <div className={classes.circle}>
                    <ReceiptLongIcon
                      className={classes.icon}
                      style={{ color: "#1E90FF" }}
                    />
                  </div>
                  <Typography className={classes.btnTitle}>
                    Bank Statement Analyzer
                  </Typography>
                  <div className={classes.button}>
                    <Typography className={classes.btnTitle}>
                      {new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                      }).format(7)}
                    </Typography>
                    <KeyboardArrowRightIcon />
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} style={{ padding: "0.5rem" }}>
              <Paper className={classes.servicePaper}>
                <div
                  className={classes.serviceBtn}
                  style={{ backgroundColor: "#e5fad5" }}
                >
                  <div className={classes.circle}>
                    <SpeedRoundedIcon
                      className={classes.icon}
                      style={{ color: "#0f9900" }}
                    />
                  </div>
                  <Typography className={classes.btnTitle}>
                    Credit Score
                  </Typography>
                  <div className={classes.button}>
                    <Typography
                      className={classes.btnTitle}
                      style={{ color: "#0091ff" }}
                    >
                      {/* {new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                      }).format(1)} */}
                      Coming Soon
                    </Typography>
                    <KeyboardArrowRightIcon />
                  </div>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </section>
      </Paper>
      {/* Recharge Dialog */}
      {openDialog === DIALOG_KEY.RECHARGE && (
        <RechargeKYC
          openRechange={openDialog === DIALOG_KEY.RECHARGE}
          toggleRechargeClick={() => {
            toggleDialog(DIALOG_KEY.RECHARGE);
          }}
          successRechargeView={toggleSuccessDialog}
        />
      )}
      {/* Success Recharge Dialog */}
      {successDialog && (
        <SuccessRecharge
          successDialog={successDialog}
          toggleSuccessDialog={toggleSuccessDialog}
        />
      )}
      {/* Transaction Dialog */}
      {openDialog === DIALOG_KEY.TRANSACTION && (
        <TransactionKYC
          openTransactionView={openDialog === DIALOG_KEY.TRANSACTION}
          toggleTransactionView={() => {
            toggleDialog(DIALOG_KEY.TRANSACTION);
          }}
        />
      )}
      {/* PAN Verification Dialog */}
      {openDialog === DIALOG_KEY.PAN && (
        <PanKYC
          OpenPanVerification={openDialog === DIALOG_KEY.PAN}
          updateBalance={updateBalanceFn}
          togglePanVerification={() => {
            toggleDialog(DIALOG_KEY.PAN);
          }}
        />
      )}
      {/* Aadhar Verification Dialog */}
      {openDialog === DIALOG_KEY.AADHAR && (
        <AadharKYC
          openAadharVerfication={openDialog === DIALOG_KEY.AADHAR}
          toggleAadharVerfication={() => {
            toggleDialog(DIALOG_KEY.AADHAR);
          }}
        />
      )}

      {/* Bank Statement Analyzer Dialog */}
      {openDialog === DIALOG_KEY.BSA && (
        <BankStmtAnalyzer
          openBSA={openDialog === DIALOG_KEY.BSA}
          toggleBSA={() => {
            toggleDialog(DIALOG_KEY.BSA);
          }}
        />
      )}

      {/* Notify Dialog */}
      {openDialog === DIALOG_KEY.NOTIFY && (
        <Notify
          OpenNotify={openDialog === DIALOG_KEY.NOTIFY}
          serviceName={serviceName}
          toggleNotify={toggleDialog}
        />
      )}
    </>
  );
}
