import { Close } from "@mui/icons-material";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Box } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from 'react';
import { ApiUrl } from "../../helpers/getUrl";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const OfferDetailView = ({ displayOffer, setDisplayOffer }) => {

    const [doc, setDoc] = useState(null);

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [confirm, setConfirm] = useState({
        display: false,
        data: null
    });
    const [loading, setLoading] = useState(false);
    const [offerList, setOfferList] = useState([]);
    const { connectorId } = JSON.parse(sessionStorage.getItem('data'));

    const handleListItemClick = (
        event,
        index,
    ) => {
        setSelectedIndex(index);
    };


    const ConfirmDialog = ({ confirm, setConfirm }) => {

        const handleConfirmDialog = () => {
            console.log("HII")
            setConfirm({
                display: false,
                data: null
            })
        }
        const onConfirmSubmit = () => {
            axios.delete(ApiUrl() + `enquiry/attachment/${confirm.data.attachmentId}`).then((res) => {
                handleConfirmDialog();
            }).catch((error) => {
                console.log(error)
            })
        }

        return (
            <Dialog open={confirm.display} maxWidth="xs" fullWidth onClose={() => handleConfirmDialog()}>
                <DialogTitle>Are you sure you want to delete {confirm?.data?.name}?</DialogTitle>
                <DialogActions>
                    <Button color="primary" variant="outlined" onClick={handleConfirmDialog}>
                        Cancel
                    </Button>
                    <Button color="error" variant="contained" onClick={onConfirmSubmit}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleDialog = () => {
        setDisplayOffer({
            display: false,
            bankName: ""
        });
        setOfferList([]);
        setConfirm(false);
        setSelectedIndex(0);
        setDoc(null);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        if (displayOffer.display && !confirm.display) {
            axios.get(ApiUrl() + `enquiry/list/${displayOffer.bankName}`).then((res) => {
                setOfferList(res.data.data);
                setSelectedIndex(0);
                setLoading(false);
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [displayOffer, confirm]);

    useEffect(() => {
        if (offerList.length > 0) {
            const item = offerList[selectedIndex];
            axios.get(ApiUrl() + `enquiry/attachment/${item?.attachmentId}`, { responseType: 'blob' }).then((res) => {
                const rawData = res.data;
                setDoc(URL.createObjectURL(rawData))
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [selectedIndex, offerList])

    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            open={displayOffer.display} onClose={() => handleDialog()}>
            <DialogTitle>{displayOffer.bankName}
                <IconButton
                    aria-label="close"
                    onClick={() => handleDialog()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }} >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ paddingTop: 0, marginTop: "10px", float: 'right', height: '500px' }}>
                {offerList.length === 0 && <p>No Offers available for {displayOffer?.bankName}</p>}
                <Grid
                    container
                    direction="row-reverse"
                    height="70vh"
                >
                    <Grid item xs={6} md={9}>
                        <iframe src={doc} title="testPdf" height="100%" width="100%" />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <List component="nav" aria-label="main">
                            {offerList?.map((item, id) => (
                                <ListItem style={{ paddingRight: 0 }}
                                    secondaryAction=
                                    {!connectorId && <IconButton edge="end" aria-label="delete" selected={selectedIndex === id}>
                                        <DeleteIcon onClick={() => setConfirm({
                                            display: true,
                                            data: item
                                        })} />
                                    </IconButton>}

                                >
                                    <ListItemButton
                                        selected={selectedIndex === id}
                                        onClick={(event) => handleListItemClick(event, id)}
                                    >
                                        <ListItemIcon>
                                            {item.type === "image/png" ? <PhotoOutlinedIcon /> : <ArticleOutlinedIcon />}
                                        </ListItemIcon>
                                        <ListItemText primary={item.name} />
                                        <a href={`${ApiUrl()}enquiry/attachment/${item?.attachmentId}`}>
                                            <FileDownloadOutlinedIcon style={{ color: "#757575" }} />
                                        </a>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
                {confirm && <ConfirmDialog confirm={confirm} setConfirm={setConfirm} />}
            </DialogContent>
        </Dialog>
    )
}
export default OfferDetailView;