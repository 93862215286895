import axios from "axios";
import React, { useEffect, useState } from "react";
import 'react-circular-progressbar/dist/styles.css';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ApiUrl } from "../../../helpers/getUrl";
import useAppContext from "../../../utils/useAppContext";

function DayWiseSummary() {
  const { freqValue, productType } = useAppContext();

  const [data, setData] = useState([]);

  useEffect(() => {
    getCeoCount();
  }, [productType, freqValue]);

  const getCeoCount = () => {
    axios.get(`${ApiUrl()}projection/disbursement/ceo?product=${productType}`)
      .then(res => {
        //console.log("getCeoCount", productType, res.data);
        let tempData = res.data.filter((o) => o.day).sort((a, b) => b.day - a.day).reverse();
        setData(tempData);
      }).catch(error => {
        console.error('There was an error!', error);
      });
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line dot={false} strokeWidth={4} type="none" dataKey="day" stroke="#F06292" />
        <Line dot={false} strokeWidth={4} type="none" dataKey="CurrentMonth" stroke="#26C6DA" />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default DayWiseSummary;
