import { Grid, Paper } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { ApiUrl } from "../../helpers/getUrl";
// import UpdatePaymentDialog from "../../UpdatePaymentDialog";
import * as React from "react";
import Deposits from "../AdminHome/Deposits";
import { cardProperties } from "../AdminHome/TransactionSections";

export const KPIs = ({ connectorId }) => {
  const [kpiData, setKpiData] = useState(null);
  useEffect(() => {
    let url = connectorId
      ? `eaggregator/dashboard?connectorId=${connectorId}`
      : `eaggregator/dashboard`;
    axios
      .get(ApiUrl() + url)
      .then((res) => {
        if (res.status === 200) {
          setKpiData(res.data[0]);
        }
      })
      .catch((error) => {
        if (error.response.status !== 404) {
          //    let errMsg = errorHandler(error);
          //    setErrorMsg({ state: true, msg: errMsg });
        }
      });
  }, []);
  return (
    <>
      <Grid container spacing={2}>
        {/* First row */}
        <Grid item xs={12} md={4} lg={4} className="deposits-card">
          <Paper sx={cardProperties}>
            <div style={{ borderRadius: "100px", height: "4rem" }}>
              <Deposits
                noOfLeads={kpiData?.totalSubmittedLeads || 0}
                name="Leads Entered"
                amount={kpiData?.totalSubmittedAmounts || 0}
                subText={"In last 30 days"}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={4} className="deposits-card">
          <Paper sx={cardProperties}>
            <div style={{ borderRadius: "100px", height: "4rem" }}>
              <Deposits
                noOfLeads={kpiData?.totalAcceptedLeads || 0}
                name="Approved Leads"
                amount={kpiData?.totalAcceptedAmounts || 0}
                subText={"In last 30 days"}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={4} className="deposits-card">
          <Paper sx={cardProperties}>
            <div style={{ borderRadius: "100px", height: "4rem" }}>
              <Deposits
                noOfLeads={kpiData?.totalDisbursedLeads || 0}
                name="Disbursement Leads"
                amount={kpiData?.totalDisbursedAmounts || 0}
                subText={"In last 30 days"}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
