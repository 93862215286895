import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const BootstrapButton = styled(Button)(
    ({ 'aria-selected': selected = 'false' }) =>
    ({
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 14,
        padding: '10px 25px',
        border: '1px solid',
        lineHeight: 1.5,
        marginRight: 10,
        // width: '100%',
        ...(selected === 'false' && {
            background: "#ffffff",
            color: "#000",
            borderColor: '#000',
        }),
        ...(selected === 'true' && {
            background: "#1C67F6",
            color: "#ffffff",
            borderColor: '#0063cc',
        }),
        borderRadius: 25,
        '&:hover': {
            backgroundColor: '#0069d9',
            borderColor: '#0062cc',
            boxShadow: 'none',
            color: "#ffffff",
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    })
);