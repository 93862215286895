import { FileCopyOutlined } from "@mui/icons-material";
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import SubCategoryList from "./SubCategoryList";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

const active = {
  background: "white",
  borderRadius: "5px",
  borderRight: 6,
  borderColor: "#40c4ff",
  '&:hover': {
    background: 'white',
  },
};
const nonActive = { borderRadius: "5px", color: "white" };

export default function CEOList({ handleDrawerToggle }) {
  let location = useLocation();
  let match = useRouteMatch();
  const { pathname } = location;


  const reportsCategory = [
    { icon: <CreditScoreIcon />, label: 'Master Report', path: 'ceo-masterReports' },
    { icon: <AssessmentOutlinedIcon />, label: 'DigiRep Report', path: 'ceo-digiRepReports' },
  ];
  const accountCategory = [
    { icon: <CreditScoreIcon />, label: 'Manage Expenses', path: 'ceo-expenses' },
    { icon: <AssessmentOutlinedIcon />, label: 'P & L Report', path: 'pl-report' },
  ];
  const enquiryCategory = [
    { icon: <FileCopyOutlined />, label: 'HDFC Report', path: 'hdfc-report' },
    { icon: <HelpOutlineIcon />, label: 'Help', path: 'help' },
  ];
  const categoryList = [
    { label: 'Reports', icon: <PersonAddOutlinedIcon />, category: reportsCategory },
    { label: 'Accounts', icon: <AccountBalanceOutlinedIcon />, category: accountCategory },
    { label: 'Enquiry', icon: <DriveFileRenameOutlineIcon />, category: enquiryCategory },
  ];

  return (
    <div>
      <List sx={{ paddingLeft: "0px" }}>
        <ListItem
          component={({ innerRef, ...props }) => (
            <>
              <Link {...props} to="ceo-home" />
            </>
          )}
        >
          <ListItemButton
            sx={
              pathname === "/admin/ceo-home" || pathname === "/ceo/"
                ? active
                : nonActive
            }
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={
                pathname === "/admin/ceo-home" || pathname === "/ceo/"
                  ? {}
                  : { color: "white" }
              }
            >
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>

        {categoryList.map((item, idx) => (
          <SubCategoryList
            key={idx}
            item={item}
            innerPath="admin"
            handleDrawerToggle={handleDrawerToggle}
          />
        ))}

        {/* <ListItem
          component={({ innerRef, ...props }) => (
            <>
              <Link {...props} to="advance-approval" />
            </>
          )}
        >
          <ListItemButton
            sx={pathname === "/admin/advance-approval" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={
                pathname === "/admin/advance-approval" ? {} : { color: "white" }
              }
            >
              <AssignmentTurnedInOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Advance Approvals" />
          </ListItemButton>
        </ListItem> */}
        <ListItem
          component={({ innerRef, ...props }) => (
            <>
              <Link {...props} to="digital-applications" />
            </>
          )}
        >
          <ListItemButton
            sx={pathname === "/admin/digital-applications" ? active : nonActive}
          >
            <ListItemIcon
              sx={
                pathname === "/admin/digital-applications"
                  ? {}
                  : { color: "white" }
              }
            >
              <SwapHorizIcon />
            </ListItemIcon>
            <ListItemText primary="Digital Applications" />
          </ListItemButton>
        </ListItem>
        <ListItem
          component={({ innerRef, ...props }) => (
            <>
              <Link {...props} to="notification" />
            </>
          )}
        >
          <ListItemButton
            sx={pathname === "/admin/notification" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={pathname === "/admin/notification" ? {} : { color: "white" }}
            >
              <NotificationAddOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </ListItemButton>
        </ListItem>

        {/* <ListItem
          component={({ innerRef, ...props }) => (
            <><Link {...props} to="admin-loan-enquiry" /></>
          )}
        >
          <ListItemButton
            sx={pathname === "/admin/admin-loan-enquiry" ? active : nonActive}
          >
            <ListItemIcon
              sx={
                pathname === "/admin/admin-loan-enquiry"
                  ? {}
                  : { color: "white" }
              }
            >
              <FactCheckOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Loan Enquiry" />
          </ListItemButton>
        </ListItem>

        <ListItem
          component={({ innerRef, ...props }) => (
            <><Link {...props} to="admin-add-connector" /></>
          )}
        >
          <ListItemButton
            sx={pathname === "/admin/admin-add-connector" ? active : nonActive}
          >
            <ListItemIcon
              sx={
                pathname === "/admin/admin-add-connector"
                  ? {}
                  : { color: "white" }
              }
            >
              <PersonAddOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Add Connector" />
          </ListItemButton>
        </ListItem> */}
      </List>
    </div>
  );
}
