import { KeyboardArrowDown } from "@mui/icons-material";
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const active = {
    background: "white",
    borderRadius: "5px",
    borderRight: 6,
    borderColor: "#40c4ff",
    '&:hover': {
        background: 'white',
    },
};
const nonActive = { borderRadius: "5px", color: "white" };

const SubCategoryList = ({ item, innerPath, handleDrawerToggle }) => {

    const [open, setOpen] = useState({ label: null, category: null });
    let location = useLocation();
    const { pathname } = location;

    const handleCategoryToggle = (item) => {
        if (item.label === open.label) {
            setOpen({ label: null, category: null });
        } else {
            setOpen({ label: item.label, category: item.category });
        }
    }

    return (
        <>
            <ListItemButton
                alignItems="flex-start"
                onClick={() => handleCategoryToggle(item)}
                sx={{
                    px: 3.5,
                    pt: 1.5,
                    pb: 1.5,
                }}
            >
                <ListItemIcon
                    sx={
                        pathname === `/${innerPath}/${item.path}` || pathname === `/${innerPath}/`
                            ? {}
                            : { color: "white" }
                    }
                    onClick={handleDrawerToggle}
                >{item.icon}
                </ListItemIcon>
                <ListItemText
                    primary={item.label}
                    sx={nonActive}
                />
                <KeyboardArrowDown
                    sx={{
                        mr: -1,
                        transform: open.category && item.label === open.label ? 'rotate(0deg)' : 'rotate(-90deg)',
                        transition: '0.2s',
                        color: '#fff'
                    }}
                />
            </ListItemButton>

            {open.category && item.label === open.label ?
                <>
                    {open.category.map(item => (
                        <ListItem
                            component={({ innerRef, ...props }) => (
                                <Link {...props} to={item.path} />
                            )}
                            sx={{
                                backgroundColor: '#183dbf'
                            }}
                        >
                            <ListItemButton
                                sx={pathname === `/${innerPath}/${item.path}` ? active : nonActive}
                                onClick={handleDrawerToggle}
                            >
                                <ListItemIcon
                                    style={{ minWidth: '35px' }}
                                    sx={pathname === `/${innerPath}/${item.path}` ? {} : { color: "white" }}
                                >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.label} sx={{ wordWrap: 'break-word' }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </>
                : <></>}
        </>
    )
}
export default SubCategoryList