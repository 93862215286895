import { createSlice } from "@reduxjs/toolkit";
import { productOrder } from "../../constants/appconstants";
import { httpGet } from "../../utils/Rest";

const initialData = {
  productProjectionsData: [],
  isLoading: false,
  errorMsg: null,
  successMsg: null,
};

const slice = createSlice({
  name: "ProductProjections", //Reference Name, It can be anything
  initialState: {
    ...initialData,
  },
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true;
      state.errorMsg = null;
    },
    updateProductProjectionsData: (state, action) => {
      state.productProjectionsData = action.payload;
      state.isLoading = false;
      state.errorMsg = null;
    },
    fetchError: (state, action) => {
      state.errorMsg = action?.payload?.data?.error;
      state.isLoading = false;
    },
    fetchSuccess: (state, action) => {
      state.successMsg = action.payload;
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

// Actions

const { updateProductProjectionsData, fetchStart, fetchError, fetchSuccess } =
  slice.actions;

export const fetchProductProjections = (connectorId, productType, freqValue) => {
  return (dispatch) => {
    dispatch(fetchStart());
    let url = `projection/product/connector/${connectorId}`;
    url = productType || freqValue ? `${url}?` : url;
    url = productType ? url + `product=${productType}` : url;
    url = freqValue ? `${url}&` : url;
    url = url + `timeframe=${freqValue}`;

    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          let result = res.data;
          result.forEach((item) => item.index = productOrder[item.productType])
          result = result.sort((a, b) => (a.index > b.index) ? 1 : -1);
          dispatch(updateProductProjectionsData(result));
          dispatch(fetchSuccess());
        } else {
          dispatch(fetchError(res.message));
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};
