import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useContext } from "react";
import AppContextProvider from "../../components/contextProvider/AppContextProvider/AppContext";
import {
  productConstants, timeIntervalLabels
} from "../../constants/appconstants";

const TopButtons = (props) => {

  const { freqValue, changeFreqValue, productType, changeProductType } = useContext(AppContextProvider);

  const CEO_BUTTONS = Object.keys(timeIntervalLabels);

  const UpdateActiveButton = (freq) => {
    changeFreqValue(freq);
  };

  const handleChange = (event) => {
    changeProductType(event.target.value);
  };

  return (
    <div>
      <div className="row w-100">
        {CEO_BUTTONS.map((x, idx) => {
          return (
            <div className={"col-sm-3 col-md-2"} key={idx}>
              <button
                className={"ceo-buttons w-100 " + (freqValue === x ? 'button-active' : 'buttons-tabs')}
                onClick={(e) => UpdateActiveButton(e.target.value)}
                value={x}>
                {timeIntervalLabels[x]}
              </button>
            </div>
          )
        })}
        <div className="col-sm-3 col-md-2">
          <FormControl
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              m: 1,
              minWidth: 180,
              backgroundColor: '#f8f9fd',
              border: 0,
              outline: 0,
              boxShadow: "10px 10px 6px -10px rgb(178 178 178 / 40%)",
            }}
            style={{ float: "right" }}
            className="dropdown-products"
          >
            <Select
              style={{ width: '100%' }}
              value={productType}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}

            >
              <MenuItem value={""}>All Products</MenuItem>
              {Object.keys(productConstants).map((i, idx) => (
                <MenuItem value={i} key={idx}>{productConstants[i]}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {/* <div className="col-md-6 d-flex flex-nowrap ">
          
        </div> */}
      </div>
    </div>
  );
};

export default React.memo(TopButtons);
