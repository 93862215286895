import FolderIcon from '@mui/icons-material/Folder';
import { Avatar, Grid, Paper } from "@mui/material";
import axios from 'axios';
import { useState, useEffect } from 'react';
import { ApiUrl } from '../../helpers/getUrl';
import OfferDetailView from "./OfferDetailView";

const BankList = () => {

    const [displayOffer, setDisplayOffer] = useState({
        display: false,
        bankName: ""
    });

    const [banksData, setBanksData] = useState([]);

    useEffect(() => {
        axios.get(ApiUrl() + `bank/list`).then((res) => {
            setBanksData(res?.data?.data);
        })
    }, []);

    return (
        <Grid container spacing={0}>
            {banksData?.map((item) => <Grid item xs={6} sm={6} md={2}>
                <Paper
                    elevation={24}
                    sx={{
                        p: 1,
                        margin: 1,
                        marginTop: 3,
                        display: "flex",
                        flexDirection: "column",
                        height: "20vh",
                        boxShadow: 3,
                        textAlign: 'center',
                        backgroundColor: "#fff",
                        '&:hover': {
                            background: '#d0d0d036',
                            cursor: 'pointer'
                        },
                    }}
                    onClick={() => setDisplayOffer({
                        display: true,
                        bankName: item.bankName
                    })}
                >
                    <div className="row" style={{ flexDirection: 'row' }}>
                        {item?.attachmentId ?
                            <img src={`${ApiUrl()}enquiry/icons/${item?.attachmentId}`} alt="icon" />
                            :
                            <>
                                <div style={{ paddingLeft: '30px' }}>
                                    <Avatar sx={{ width: 56, height: 56, marginBottom: "15px" }}>{item.bankName.split(" ")[0][0]}</Avatar>
                                </div>
                            </>
                        }
                    </div>
                    <div className="row">
                        <h6 style={{ paddingTop: 15, color: "#000" }}>{item.bankName}</h6>
                    </div>
                </Paper>
            </Grid>)}
            <OfferDetailView displayOffer={displayOffer} setDisplayOffer={setDisplayOffer} />
        </Grid >
    )
}
export default BankList;