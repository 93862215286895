import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ApiUrl } from '../../helpers/getUrl';
import useAppContext from "../../utils/useAppContext";
import Deposits from "./Deposits";

function TransactionSection() {
  const dispatch = useDispatch();

  const { freqValue, productType, connectorId } = useAppContext();

  const [result, setResult] = useState({});

  useEffect(() => {
    getCeoCount();
  }, [productType, freqValue]);

  const getCeoCount = () => {
    axios.get(`${ApiUrl()}total/ceo?product=${productType}&timeframe=${freqValue}`)
      .then(res => {
        setResult(res.data);
      }).catch(error => {
        console.error('There was an error!', error);
      });
  }

  const {
    totalBankPayoutCount = 0,
    totalBankPayoutReceived = 0,
    totalConnectorCount = 0,
    totalConnectorPaid = 0,
    totalConnectorPendingCount = 0,
    totalConnectorPendingPayment = 0,
    totalDisbursedAmount = 0,
    totalDisbursedApplications = 0,
    totalLeadsAmount = 0,
    totalLeadsEntered = 0
  } = result;

  const style = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: 100,
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={3} className="deposits-card">
          <Paper sx={style}>
            <div style={{ borderRadius: "100px", height: "4rem" }}>
              <Deposits
                noOfLeads={totalLeadsEntered}
                name="Leads Listing"
                amount={totalLeadsAmount}
              />
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={3} className="deposits-card">
          <Paper sx={style}>
            <Deposits
              name="Disbursement Listing"
              noOfLeads={totalDisbursedApplications}
              amount={totalDisbursedAmount}
            />
          </Paper>
        </Grid>

        {/* <Grid item xs={12} xs={12} md={2.35} lg={2.35} className="deposits-card">
          <Paper sx={style}>
            <Deposits
              noOfLeads={totalBankPayoutCount}
              name="Bank Payouts"
              amount={totalBankPayoutReceived}
              type="currency"
            />
          </Paper>
        </Grid> */}

        <Grid item xs={12} sm={12} md={6} lg={3} className="deposits-card">
          <Paper sx={style}>
            <Deposits
              noOfLeads={totalConnectorCount}
              name="Connector Payouts"
              amount={totalConnectorPaid}
              type="currency"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} className="deposits-card">
          <Paper sx={style}>
            <div style={{ borderRadius: "100px", height: "4rem" }}>
              <Deposits
                noOfLeads={totalConnectorPendingCount}
                name="Pending Payments"
                amount={totalConnectorPendingPayment}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default TransactionSection;
