import { Grid, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
}));

export const KPI = () => {
    const [data, setData] = React.useState([
        {
            title: "Total Advances",
            Amount: "0"
        },
        {
            title: "Total Spot Payments",
            Amount: "0"
        },
        {
            title: "Total Outstanding",
            Amount: "0"
        },
    ]);

    return (
        <Grid container spacing={5}>
            {data.map(item => (
                <Grid item xs={12} lg={4}>
                    <Item>
                        <Typography variant="div" gutterBottom>
                            {item.title}
                        </Typography>
                        <Typography variant="h4" gutterBottom>
                            {item.Amount.cur()}
                        </Typography>
                    </Item>
                </Grid>
            ))}
        </Grid>
    )
}