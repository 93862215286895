import List from "@mui/material/List";
import React from "react";

import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import PostAddIcon from "@mui/icons-material/PostAdd";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { Box } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import DataStoragePolicy from "./DataStoragePolicy";
import DataPrivacyPolicy from "./DataPrivacyPolicy";

const active = {
  background: "white",
  borderRadius: "5px",
  borderRight: 6,
  borderColor: "#40c4ff",
  "&:hover": {
    background: "white",
  },
};
const nonActive = { borderRadius: "5px", color: "white" };

export default function DigiRepList({ handleDrawerToggle }) {
  let location = useLocation();

  const { pathname } = location;
  return (
    <>
      <Box>
        <List>
          <ListItem
            component={({ innerRef, ...props }) => (
              <>
                <>
                  <Link {...props} to="home" />
                </>
              </>
            )}
          >
            <ListItemButton
              sx={
                pathname === "/DigiRep/home" || pathname === "/DigiRep/"
                  ? active
                  : nonActive
              }
              onClick={handleDrawerToggle}
            >
              <ListItemIcon
                sx={
                  pathname === "/DigiRep/home" || pathname === "/DigiRep/"
                    ? {}
                    : { color: "white" }
                }
              >
                <HomeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>

          <ListItem
            component={({ innerRef, ...props }) => (
              <>
                <Link {...props} to="leads" />
              </>
            )}
          >
            <ListItemButton
              sx={pathname === "/DigiRep/leads" ? active : nonActive}
              onClick={handleDrawerToggle}
            >
              <ListItemIcon
                sx={pathname === "/DigiRep/leads" ? {} : { color: "white" }}
              >
                <PersonOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Leads" />
            </ListItemButton>
          </ListItem>

          <ListItem
            component={({ innerRef, ...props }) => (
              <>
                <Link {...props} to="redemption-management" />
              </>
            )}
          >
            <ListItemButton
              sx={
                pathname === "/DigiRep/redemption-management"
                  ? active
                  : nonActive
              }
              onClick={handleDrawerToggle}
            >
              <ListItemIcon
                sx={
                  pathname === "/DigiRep/redemption-management"
                    ? {}
                    : { color: "white" }
                }
              >
                <FactCheckOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Accounts" />
            </ListItemButton>
          </ListItem>

          <ListItem
            component={({ innerRef, ...props }) => (
              <>
                <Link {...props} to="loan-enquiry" />
              </>
            )}
          >
            <ListItemButton
              sx={pathname === "/DigiRep/loan-enquiry" ? active : nonActive}
              onClick={handleDrawerToggle}
            >
              <ListItemIcon
                sx={
                  pathname === "/DigiRep/loan-enquiry" ? {} : { color: "white" }
                }
              >
                <FactCheckOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Loan Enquiry" />
            </ListItemButton>
          </ListItem>

          <ListItem
            component={({ innerRef, ...props }) => (
              <>
                <Link {...props} to="bank-wise-offer" />
              </>
            )}
          >
            <ListItemButton
              sx={pathname === "/DigiRep/bank-wise-offer" ? active : nonActive}
              onClick={handleDrawerToggle}
            >
              <ListItemIcon
                sx={
                  pathname === "/DigiRep/bank-wise-offer"
                    ? {}
                    : { color: "white" }
                }
              >
                <AccountBalanceOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Bank Wise Offer" />
            </ListItemButton>
          </ListItem>

          <ListItem
            component={({ innerRef, ...props }) => (
              <>
                <Link {...props} to="advance" />
              </>
            )}
          >
            <ListItemButton
              sx={pathname === "/DigiRep/advance" ? active : nonActive}
            >
              <ListItemIcon
                sx={pathname === "/DigiRep/advance" ? {} : { color: "white" }}
              >
                <SwapHorizIcon />
              </ListItemIcon>
              <ListItemText primary="Advance" />
            </ListItemButton>
          </ListItem>

          <ListItem
            component={({ innerRef, ...props }) => (
              <>
                <Link {...props} to="digital-applications" />
              </>
            )}
          >
            <ListItemButton
              sx={
                pathname === "/DigiRep/digital-applications"
                  ? active
                  : nonActive
              }
            >
              <ListItemIcon
                sx={
                  pathname === "/DigiRep/digital-applications"
                    ? {}
                    : { color: "white" }
                }
              >
                <SwapHorizIcon />
              </ListItemIcon>
              <ListItemText primary="Digital Applications" />
            </ListItemButton>
          </ListItem>

          <ListItem
            component={({ innerRef, ...props }) => (
              <>
                <Link {...props} to="help" />
              </>
            )}
          >
            <ListItemButton
              sx={pathname === "/DigiRep/help" ? active : nonActive}
            >
              <ListItemIcon
                sx={pathname === "/DigiRep/help" ? {} : { color: "white" }}
              >
                <HelpOutlineIcon />
              </ListItemIcon>
              <ListItemText primary="Help" />
            </ListItemButton>
          </ListItem>

          <DataPrivacyPolicy/>
          <DataStoragePolicy/>
          <ListItem className="pt-0 pb-0">
            <a href="https://sachet.rbi.org.in/" target="_blank" rel="noreferrer">
              <ListItemButton sx={nonActive}>
                <ListItemIcon sx={{ color: "white" }}>
                  <PostAddIcon />
                </ListItemIcon>
                <ListItemText primary="RBI Policy" />
              </ListItemButton>
            </a>
          </ListItem>
        </List>
      </Box>
    </>
  );
}
