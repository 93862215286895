import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

function Chart2({ data }) {
  //₹
  const renderCustomizedLabel = ({
    x, y, value, index
  }) => {
    return (
      <text x={x} y={y} fill={"black"} textAnchor="start" dominantBaseline="central">
        { value.cur() }
      </text>
    );
  };
  const renderCustomToolTip = ({ active, label, payload }) => {
    return payload.length ? (
      <div style={{
        background: "white",
        padding: "1em",
        borderRadius: "5px",
        boxShadow: "0 1px 4px rgba(0,0,0,0.3)",
      }}>
        <p> {payload[0].name} : {payload[0].value.cur()} </p>
      </div>
    ) : null;
  }
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <ResponsiveContainer width="100%">
      <>
        <PieChart width={500} height={250}>
          <Pie data={data} dataKey="totalConnectorPayout" isAnimationActive={false} nameKey="monthName" cx="50%" cy="50%"
            innerRadius={60} outerRadius={80} fill="#82ca9d" label={renderCustomizedLabel} labelLine={true}>
            {data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip content={renderCustomToolTip} />
        </PieChart>
      </>
    </ResponsiveContainer>
  );
}

export default Chart2;