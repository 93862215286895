import { Button, Dialog, IconButton, OutlinedInput } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import axios from "axios";
import { ApiUrl } from "../../../helpers/getUrl";
import { ErrorSnackbar } from "../../../components/CustomSnackBar";
import PageLoader from "../../../components/PageLoader";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Otp = ({ open, setOpen, setOtpVerified, mobile, setSuccessMsg }) => {
  const [timer, setTimer] = useState("00:00");
  const [messageId, setMessageId] = useState(null);
  const [errorMsg, setErrorMsg] = useState({ state: false, msg: null });
  const [loader, setLoader] = useState(false);

  const Ref = useRef(null);

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    watch: watch1,
    errors: errors1,
    setValue,
    reset,
    control: control1,
  } = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };
  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("05:00");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const onSubmitOTP = (values) => {
    let data = {
      otp: parseInt(
        values.otp_1 +
          values.otp_2 +
          values.otp_3 +
          values.otp_4 +
          values.otp_5 +
          values.otp_6
      ),
      message_id: messageId,
    };
    setLoader(true);
    axios
      .put(`${ApiUrl()}otp/verify`, data)
      .then((res) => {
        setSuccessMsg({ state: true, msg: "OTP verified successfully" });
        setTimeout(() => {
          setLoader(false);
          setOtpVerified(true);
          setOpen(false);
        }, 2000);
        reset();
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        if (error?.response?.data?.message) {
          setErrorMsg({
            state: true,
            msg: error.response.data.message,
          });
          reset();
        }
      });
    setSuccessMsg({ state: false, msg: null });
    setErrorMsg({ state: false, msg: null });
  };

  const handleOtpInputChange = (e) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("_");
    let fieldIntIndex = parseInt(fieldIndex, 10);

    if (!isNaN(parseInt(value))) {
      if (fieldIntIndex < 6) {
        // Get the next input field using it's name
        const nextfield = document.querySelector(
          `input[name=otp_${fieldIntIndex + 1}]`
        );

        // If found, focus the next field
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    } else {
      setValue(name, "");
    }
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 0);
    deadline.setMinutes(deadline.getMinutes() + 5);
    return deadline;
  };

  useEffect(() => {
    if (open && mobile) {
      clearTimer(getDeadTime());
      sendOTP();
    }
  }, [open, mobile]);

  function sendOTP() {
    axios
      .get(`${ApiUrl()}otp/${mobile}`)
      .then((res) => {
        setSuccessMsg({
          state: true,
          msg: "OTP sent successfully",
        });

        setMessageId(res?.data?.message_id);
      })
      .catch((error) => {
        console.log(error);
      });
    setSuccessMsg({ state: false, msg: null });
  }

  const resendOtp = () => {
    clearTimer(getDeadTime());
    reset();
    sendOTP();
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="text-center">
          <h4>Enter OTP</h4>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit1(onSubmitOTP)}>
            <div className="d-flex flex-column align-items-center">
              OTP to verify
              <div className="text-center my-5">
                <OutlinedInput
                  variant="standard"
                  type="text"
                  inputProps={{
                    maxLength: "1",
                  }}
                  id="otp_1"
                  name="otp_1"
                  autoFocus
                  {...register1("otp_1")}
                  onChange={(e) => handleOtpInputChange(e)}
                  className="outlined-input otp-input col-1 mx-2"
                />
                <OutlinedInput
                  variant="standard"
                  type="text"
                  inputProps={{
                    maxLength: "1",
                  }}
                  className="outlined-input otp-input col-1 mx-2"
                  id="otp_2"
                  name="otp_2"
                  {...register1("otp_2")}
                  onChange={(e) => handleOtpInputChange(e)}
                />
                <OutlinedInput
                  variant="standard"
                  type="text"
                  inputProps={{
                    maxLength: "1",
                  }}
                  className="outlined-input otp-input col-1 mx-2"
                  id="otp_3"
                  name="otp_3"
                  {...register1("otp_3")}
                  onChange={(e) => handleOtpInputChange(e)}
                />
                <OutlinedInput
                  variant="standard"
                  type="text"
                  inputProps={{
                    maxLength: "1",
                  }}
                  className="outlined-input otp-input col-1 mx-2"
                  id="otp_4"
                  name="otp_4"
                  {...register1("otp_4")}
                  onChange={(e) => handleOtpInputChange(e)}
                />
                <OutlinedInput
                  variant="standard"
                  type="text"
                  inputProps={{
                    maxLength: "1",
                  }}
                  className="outlined-input otp-input col-1 mx-2"
                  id="otp_5"
                  name="otp_5"
                  {...register1("otp_5")}
                  onChange={(e) => handleOtpInputChange(e)}
                />
                <OutlinedInput
                  variant="standard"
                  type="text"
                  inputProps={{
                    maxLength: "1",
                  }}
                  className="outlined-input otp-input col-1 mx-2"
                  id="otp_6"
                  name="otp_6"
                  {...register1("otp_6")}
                  onChange={(e) => handleOtpInputChange(e)}
                />
              </div>
              {timer} Sec
              <Button
                className="w-75 mx-5 mt-5"
                style={{ borderRadius: "25px" }}
                variant="contained"
                type="submit"
              >
                Verify
              </Button>
              <div className="py-3">
                Not received OTP?{" "}
                <a href="#" onClick={() => resendOtp()}>
                  Resend
                </a>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>{" "}
      {loader && <PageLoader />}
      {errorMsg.state && <ErrorSnackbar errorMsg={errorMsg.msg} />}{" "}
    </>
  );
};
export default Otp;
