import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { createTheme, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useContext, useEffect } from "react";
// import Chart from './Chart';
import { useDispatch, useSelector } from "react-redux";
import { ResponsiveContainer } from 'recharts';
import AppContextProvider from "../../components/contextProvider/AppContextProvider/AppContext";
import { fetchAdminPeriodicProjections } from '../../redux/reducers/PeriodicProjection';
import Chart2 from "../AdminHome/charts/chart2";
import BankWiseSummary from "../CEOHome/charts/bankWiseSummary";


function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function ChartsSection() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAdminPeriodicProjections());
  }, [])

  const { adminPeriodicProjectionsData } = useSelector(
    ({ periodicProjectionService }) => periodicProjectionService
  );

  const { dataPerDay, dataPerMonth } = adminPeriodicProjectionsData;

  const { freqValue } =
    useContext(AppContextProvider);

  const renderSwitch = (param) => {
    switch (param) {
      case 'MTD':
        return "Monthly";
      case 'LTD':
        return "LTD";
      case 'YTD':
        return "Yearly";
      case 'QTD':
        return "Quarterly";
      case 'HTD':
        return "Half Yearly";
    }
  }

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} md={6}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 430,
            boxShadow: 3,
          }}
          className="chart-section"
        >
          <div>
            <h6 style={{ display: "inline-block" }}> Bank Wise Summary</h6>
          </div>

          {/* <Chart1 input_data={dataPerDay} /> */}
          <ResponsiveContainer width="100%" height="100%">
            {/* <LineChart
              width={500}
              height={300}
              data={dataPerDay}
              margin={{
                top: 5,
                right: 5,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="0" />
              <XAxis dataKey="day" label={{ value: 'day of months', angle: 0, position: 'insideBottom' }} />
              <YAxis label={{ value: 'Number of payouts', angle: -90, position: 'insideLeft' }}>
              </YAxis>
              <Tooltip />
              <Legend />
              <Line dot={false} strokeWidth={4} type="none" name="Previous Month" dataKey="previousApplications" stroke="#F06292" />
              <Line dot={false} strokeWidth={4} type="none" name="Current Month" dataKey="currentApplications" stroke="#26C6DA" />
            </LineChart> */}
            <BankWiseSummary />
          </ResponsiveContainer>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 430,
            boxShadow: 3,
          }}
          className="chart-section"
        >
          <h6>Month Wise Summary of Payouts</h6>
          <Chart2 data={dataPerMonth} />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ChartsSection;
