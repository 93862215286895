import {
  Button,
  Card,
  Checkbox,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import PageLoader from "../../../components/PageLoader";
import { ApiUrl } from "../../../helpers/getUrl";
import Otp from "./Otp";

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const validationSchema = yup.object({
  applicant_first_name: yup
    .string()
    .required("First Name is Required")
    .matches(/^[aA-zZ \s]+$/, "Special characters are not allowed"),
  // applicant_middle_name: yup.string().required("Middle Name is Required"),
  applicant_last_name: yup.string().required("Last Name is Required"),
  employment_type: yup.string().required("Employment Type is Required"),
  mobileNo: yup
    .string()
    .test(
      "len",
      "Please enter valid mobile number",
      (val) => val.toString().length == 10 || val.toString().length == 0
    ),
  panNo: yup
    .string()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}/, {
      message: "Please enter valid Pan no.",
      excludeEmptyString: true,
    })
    .required("PAN Number is Required"),
  pincode: yup
    .string()
    .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, {
      message: "Please enter valid pincode",
      excludeEmptyString: true,
    })
    .required("Pincode is Required"),
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is Required"),
  min_loan_amount: yup
    .number()
    .typeError("Amount must be a number")
    .min(50000, "Min amount should be greater than 50k")
    .max(700000, "Max amount should be less than 7L"),

  Max_loan_amount: yup
    .number()
    .typeError("Amount must be a number")
    .min(50000, "Max amount should be greater than 50k")
    .max(700000, "Max amount should be less than 7L"),
  acknowledgement: yup
    .bool()
    .oneOf([true], "You must accept the terms and conditions"),
});

export function DigitalApplForm({ setShowForm, setSuccessMsg, setErrorMsg }) {
  const today = moment(new Date()).format("YYYY-MM-DD");
  const resolver = useYupValidationResolver(validationSchema);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [formData, setFormData] = useState(null);

  const {
    register,
    reset,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver,
  });

  let watchPincode = watch("pincode");

  const onSubmitForm = (data) => {
    data.dob = moment(data.dob).format("DD-MM-YYYY");
    let localData = JSON.parse(sessionStorage.getItem("data"));
    const { connectorId } = localData || {};
    data.connectorId = connectorId;
    setFormData(data);
    setOtpVerified(false);
    setOpen(true);
  };
  useEffect(() => {
    getStateCity();
  }, [watchPincode]);

  useEffect(() => {
    if (otpVerified && formData) {
      setLoader(true);
      axios
        .post(`${ApiUrl()}eaggregator/submit/`, formData)
        .then((res) => {
          reset();
          setSuccessMsg({
            status: true,
            msg: "Details Saved Successfully",
          });
          setShowForm(false);
          setLoader(false);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            let errMsg = error.response.data.message;
            setErrorMsg({ state: true, msg: errMsg });
          }
          setLoader(false);
        });
      setErrorMsg({ state: false, msg: null });
    }
  }, [otpVerified]);

  function getStateCity() {
    let pincodeLen = Number(watchPincode).toString().length;

    if (pincodeLen >= 6) {
      axios
        .get(`https://api.postalpincode.in/pincode/${watchPincode}`)
        .then((res) => {
          if (res?.data[0]?.status === "Error") {
            setValue("state", "");
            setValue("city", "");
          } else {
            let data = res?.data[0]?.PostOffice[0];
            setValue("state", data.State);
            setValue("city", data.Division);
          }
        })
        .catch((error) => {});
    }
  }

  return (
    <>
      <Card style={{ padding: 20, margin: 20 }}>
        <form id="editSpotForm" onSubmit={handleSubmit(onSubmitForm)}>
          <div className="row form-fields mt-md-n5">
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">
                Applicant First Name*
              </InputLabel>
              <OutlinedInput
                {...register("applicant_first_name")}
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input mt-2"
              />
              {errors?.applicant_first_name && (
                <p className="error">{errors?.applicant_first_name?.message}</p>
              )}
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">
                Applicant Middle Name
              </InputLabel>
              <OutlinedInput
                {...register("applicant_middle_name")}
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input mt-2"
              />
              {errors?.applicant_middle_name && (
                <p className="error">
                  {errors?.applicant_middle_name?.message}
                </p>
              )}
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">
                Applicant Last Name*{" "}
              </InputLabel>
              <OutlinedInput
                {...register("applicant_last_name")}
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input mt-2"
              />
              {errors?.applicant_last_name && (
                <p className="error">{errors?.applicant_last_name?.message}</p>
              )}
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">
                Mother First Name{" "}
              </InputLabel>
              <OutlinedInput
                {...register("mother_first_name")}
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input mt-2"
              />
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">
                Mother Middle Name{" "}
              </InputLabel>
              <OutlinedInput
                {...register("mother_middle_name")}
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input mt-2"
              />
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">
                Mother Last Name{" "}
              </InputLabel>
              <OutlinedInput
                {...register("mother_last_name")}
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input mt-2"
              />
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">Mobile No.* </InputLabel>
              <OutlinedInput
                {...register("mobileNo")}
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input mt-2"
              />
              {errors?.mobileNo && (
                <p className="error">{errors?.mobileNo?.message}</p>
              )}
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">DOB * </InputLabel>
              <OutlinedInput
                {...register("dob")}
                type="date"
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input mt-2"
              />
              {errors?.dob && <p className="error">{errors?.dob?.message}</p>}
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">PAN No.* </InputLabel>
              <OutlinedInput
                {...register("panNo")}
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input mt-2"
              />
              {errors?.panNo && (
                <p className="error">{errors?.panNo?.message}</p>
              )}
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">Gender </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                className="outlined-input w-100"
                defaultValue="Male"
                id="gender"
                {...register("gender")}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">
                Salary Range{" "}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                className="outlined-input w-100"
                defaultValue="0"
                id="salaryRange"
                {...register("salaryRange")}
              >
                {[
                  "Less than Rs.3Lac",
                  "Rs. 3 to 5 Lac",
                  "Rs. 5 to 10 Lac",
                  "Rs. 10 to 15 Lac",
                  "Rs. 15 to 20 Lac",
                  "Greater than 25 Lac",
                ].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">
                Annual Income{" "}
              </InputLabel>
              <OutlinedInput
                {...register("annual_income")}
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input mt-2"
              />
            </div>

            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">
                Education Qualification
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                className="outlined-input w-100"
                defaultValue="0"
                id="education_qualification"
                {...register("education_qualification")}
              >
                {["Doctor", "CA", "Masters", "Graduate", "Non-graduate"].map(
                  (item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  )
                )}
              </Select>
            </div>

            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">
                Employment Type *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                className="outlined-input w-100"
                defaultValue="Salaried"
                id="employment_type"
                {...register("employment_type")}
              >
                <MenuItem value="Salaried">Salaried</MenuItem>
                <MenuItem value="Self Employed">Self Employed</MenuItem>
              </Select>
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">Pin code * </InputLabel>
              <OutlinedInput
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input mt-2"
                onChange={() => getStateCity()}
                {...register("pincode")}
              />
              {errors?.pincode && (
                <p className="error">{errors?.pincode?.message}</p>
              )}
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">City </InputLabel>
              <OutlinedInput
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input mt-2"
                {...register("city")}
                disabled
              />
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">State </InputLabel>
              <OutlinedInput
                {...register("state")}
                disabled
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input mt-2"
              />
            </div>

            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">
                Residence Ownership
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                className="outlined-input w-100"
                defaultValue="0"
                id="residence_ownership"
                {...register("residence_ownership")}
              >
                {["Owned", "Rented"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </div>

            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">
                Personal Email Id *{" "}
              </InputLabel>
              <OutlinedInput
                label="Outlined"
                type="email"
                variant="standard"
                className="w-100 outlined-input mt-2"
                {...register("email")}
              />
              {errors?.email && (
                <p className="error">{errors?.email?.message}</p>
              )}
            </div>

            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">
                Total Work Exp
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                className="outlined-input w-100"
                defaultValue="0"
                id="total_work_exp"
                {...register("total_work_exp")}
              >
                {["<1 year", "1-3 years", "3-7 years", ">7 Years"].map(
                  (item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  )
                )}
              </Select>
            </div>

            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">Company Name</InputLabel>
              <OutlinedInput
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input mt-2"
                {...register("company_name")}
              />
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">
                Company Category
              </InputLabel>
              <OutlinedInput
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input mt-2"
                {...register("company_category")}
              />
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">
                Official Email Id
              </InputLabel>
              <OutlinedInput
                label="Outlined"
                type="email"
                variant="standard"
                className="w-100 outlined-input mt-2"
                {...register("official_email_id")}
              />
              {errors?.official_email_id && (
                <p className="error">{errors?.official_email_id?.message}</p>
              )}
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">
                Min Loan Amount{" "}
              </InputLabel>
              <OutlinedInput
                {...register("min_loan_amount")}
                type="number"
                defaultValue={0}
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input mt-2"
              />
              {errors?.min_loan_amount && (
                <p className="error">{errors?.min_loan_amount?.message}</p>
              )}
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">
                Max Loan Amount{" "}
              </InputLabel>
              <OutlinedInput
                {...register("Max_loan_amount")}
                defaultValue={0}
                type="number"
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input mt-2"
              />
              {errors?.Max_loan_amount && (
                <p className="error">{errors?.Max_loan_amount?.message}</p>
              )}
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">
                Required Loan Amt.
              </InputLabel>
              <OutlinedInput
                {...register("required_loan_amount")}
                type="number"
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input mt-2"
              />
            </div>
            <div className="col-md-4 mt-3">
              <InputLabel htmlFor="component-disabled">Tenure </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                {...register("tenure")}
                className="outlined-input w-100"
                // defaultValue={selectedRow?.connectorPayoutStatus}
                defaultValue="6"
                id="tenure"
              >
                <MenuItem value="6">6 Months</MenuItem>
                <MenuItem value="12">12 Months</MenuItem>
                <MenuItem value="18">18 Months</MenuItem>
                <MenuItem value="24">24 Months</MenuItem>
                <MenuItem value="30">30 Months</MenuItem>
                <MenuItem value="36">36 Months</MenuItem>
                <MenuItem value="42">42 Months</MenuItem>
                <MenuItem value="48">48 Months</MenuItem>
                <MenuItem value="54">54 Months</MenuItem>
                <MenuItem value="60">60 Months</MenuItem>
              </Select>
            </div>
            <div className="col-md-12 mt-3">
              <Checkbox {...register("acknowledgement")} />
              Digirep acknowledges the necessity of obtaining customer consent
              prior to submitting a loan application with L&T Finance.
              {errors?.acknowledgement && (
                <p className="error" style={{ marginLeft: 10 }}>
                  {errors?.acknowledgement?.message}
                </p>
              )}
            </div>
          </div>
          <div className="offset-md-8 col-md-4 mt-3">
            <div className="d-flex justify-content-end">
              <Button
                className="p-2  px-4"
                style={{ borderRadius: "25px" }}
                variant="contained"
                type="submit"
                disabled={loader}
              >
                Save & Submit
              </Button>
            </div>
          </div>
        </form>
        {loader && <PageLoader />}
        <Otp
          open={open}
          setOpen={setOpen}
          setOtpVerified={setOtpVerified}
          mobile={formData?.mobileNo}
          setSuccessMsg={setSuccessMsg}
        />
      </Card>
    </>
  );
}
