import { Add } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useState } from "react";
import { BootstrapButton } from "../BootstrapButton";
import { AdvancePaymentGrid } from "./Advance/AdvancePayoutGrid";
import { RequestAdvanceForm } from "./Advance/RequestAdvanceForm";
import "../index.scss";
import { KPI } from "./Kpis";
import { SpotPaymentGrid } from "./Spot/SpotPaymentGrid";
import { AdvancePaymentGridV2 } from "./Advance/AdvancePayoutGridV2";

export const AdvanceDashboard = () => {
  const [selected, setSelected] = useState("Advance");
  const [addAdvance, setAddAdvance] = useState(false);

  const handleModal = (e, reason) => {
    if (reason && reason === "backdropClick") return;
    setAddAdvance(!addAdvance);
  };

  return (
    <div className="mt-5 px-5">
      {/* <KPI />
            <Grid pt={3} pb={3} container>
                <Grid item xs={12} lg={6} container style={{ maxWidth: '100%' }}>
                    <BootstrapButton mr={2} aria-selected={selected === "Advance" ? 'true' : 'false'} onClick={() => setSelected("Advance")}>Advances</BootstrapButton>
                    <BootstrapButton aria-selected={selected === "Spot" ? 'true' : 'false'} onClick={() => setSelected("Spot")}>Spot Payment</BootstrapButton>
                </Grid>
                <Grid item xs={12} lg={6}>
                    {selected === "Advance" && <BootstrapButton aria-selected='true' style={{ float: "right", padding: "10px" }} onClick={handleModal}><Add />Request Advance</BootstrapButton>}
                </Grid>
            </Grid> */}
      {selected === "Spot" ? (
        <SpotPaymentGrid />
      ) : (
        <AdvancePaymentGridV2 addAdvance={addAdvance} />
      )}
      <RequestAdvanceForm display={addAdvance} handleModal={handleModal} />
    </div>
  );
};
