import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import * as React from "react";
// import Chart from './Chart';
import ChartsSection from "./chartsSection";
import TopButtons from "./topButtons.js";
import TransactionSection from "./TransactionSections";

function CEOHome() {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} className="sidenav-content">
      <Grid container spacing={3} className="row m-0 sidenav-info">
        {/* Buttons */}
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12 ">
              <TopButtons />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 deposits-container">
              <TransactionSection />
            </div>
          </div>

          <div className="row">
            <ChartsSection />
          </div>
        </div>
      </Grid>
    </Container>
  );
}

export default CEOHome;
