import Alert from '@mui/material/Alert';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { ApiUrl } from "../../helpers/getUrl";
import BannerLeft from "./BannerLeft";
import logo from "./Logo.svg";
import {
  ErrorSnackbar,
  SuccessSnackbar
} from "../../components/CustomSnackBar";


function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © Digi Loans"} {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const validationSchema = yup.object({
  email: yup.string().email().required("Email is Required"),
});

export default function LoginPage() {
  let history = useHistory();

  const resolver = useYupValidationResolver(validationSchema);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver,
  });

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const onSubmitHandler = (data) => {
    axios.post(`${ApiUrl()}auth/generate`, data)
      .then(res => {
        setSuccessMessage('The link has been sent , please check your email to rest your password!');
        setTimeout(() => {
          history.push("/login");
        }, 1000);
      }).catch(error => {
        if (error.response.data.status === 404) {
          setErrorMessage(error.response.data.message);
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={0} alignItems="center" style={{ backgroundColor: '#ffffff', height: '100vh' }}>
        <BannerLeft />
        <Grid item xs={12} sm={5} style={{ padding: 10 }}>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: 3,
              textAlign: 'left',
              padding: '20px',
              margin: '20px',
              alignItems: 'baseline',
              maxWidth: '400px',
              margin: 'auto'
            }}
          >
            <img
              style={{ maxHeight: "40px", margin: '8px 0' }}
              src={logo}
            />
            <Typography
              as="h1"
              variant="h5"
              sx={{ my: 2, fontWeight: "500" }}
            >
              Reset Password
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmitHandler)}
              sx={{ width: '100%' }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                required
                variant="standard"
                {...register("email")}
              />
              <p>{errors.email?.message}</p>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "#4DD0E1" }}
              >
                Reset
              </Button>
              <Copyright sx={{ mt: 2, mb: 2 }} />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {errorMessage && <ErrorSnackbar errorMsg={errorMessage} />}
      {successMessage && <SuccessSnackbar successMsg={successMessage} />}
    </ThemeProvider>
  );
}
