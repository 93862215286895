
export const numbersToCashFormat = (num) => {
    // remove all alphabets from 'num'
    if (typeof num === 'string') num = num.replace(/\D/g, '')

    const cashFormatter = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 20,
        minimumSignificantDigits: 1,
        maximumSignificantDigits: 20,
        notation: 'compact',
        compactDisplay: "short",
    })

    let result = cashFormatter.format(num).replace('T', 'K');
    return result;
}