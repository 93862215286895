import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Paper from "@mui/material/Paper";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useEffect, useState } from 'react';
import { ApiUrl } from '../../helpers/getUrl';

import Order from './table';
import { productConstants } from "../../constants/appconstants";
import { Autocomplete, CircularProgress, LinearProgress, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  title: {
    backgroundColor: 'blue',
    display: 'flex',
    borderRadius: '5px 5px 0px 0px',
    height: '60px',
    color: 'white',
    padding: '20px 0px 15px 15px',
    fontWeight: '600',
    whiteSpace: 'nowrap'
  },
  content: {
    padding: '1rem'
  },
  fieldName: {
    color: 'blue',
    fontWeight: '500'
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  bankHead: {
    padding: "15px 10px 10px 10px",
    fontWeight: '500',
    backgroundColor: '#1ecef4',
    height: '50px'
  }
}));

const INITIAL_STATE = {
  companyName: null,
  salary: 0,
  productType: 'PERS',
  tenure: 5,
  balanceTenure: 0,
  emiAmoumt: 0,
  preference: 'loanAmount'
};

const personalTenure = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const autoTenure = [1, 2, 3, 4, 5, 6, 7];
const goldTenure = [1, 2];
const educationTenure = [1, 2, 3, 4, 5, 6, 7];
const homeTenure = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
const insuranceTenure = [1, 2, 3, 4, 5];
const businessTenure = [1, 2, 3, 4, 5];
const ccTenure = [1, 2];

export default function Enquiry(props) {
  const classes = useStyles(props);

  const [openCompanyName, setOpenCompanyName] = useState(false);
  const [companyNameOptions, setCompanyNameOptions] = useState([]);
  const [companySearch, setCompanySearch] = useState('');
  const [productTenure, setProductTenure] = useState(personalTenure);
  const [tenureType, setTenureType] = useState('new');
  const [form, setForm] = useState(INITIAL_STATE);
  const [calcLoading, setCalcLoading] = useState(false);
  const [calcResult, setCalcResult] = useState([]);

  const loading = openCompanyName && companySearch.length > 2 && companyNameOptions.length === 0;


  const handleChange = (event, value) => {
    if (value && value.EMPLOYERNAME) {
      setForm({
        ...form,
        companyName: value
      });
    } else {
      setForm({
        ...form,
        [event.target.name]: event.target.value
      });
    }
  };

  const handleCompanySearch = (event, value) => {
    setCompanySearch(value);
  };

  const handleSubmission = () => {
    const payload = { ...form };
    payload['companyName'] = form.companyName && form.companyName.EMPLOYERNAME ? form.companyName.EMPLOYERNAME : '';
    payload['tenure'] = (form.tenure) * 12;

    setCalcLoading(true);

    axios.post(`${ApiUrl()}enquiry`, payload)
      .then(response => {
        setForm(INITIAL_STATE);
        setCalcResult(response.data);
        setCalcLoading(false);
      })
      .catch(error => {
        console.error(error);
        setCalcLoading(false);
      });
  };

  useEffect(() => {
    if (companySearch && companySearch.length > 2) {
      (async () => {
        const result = await axios.get(`${ApiUrl()}enquiry?search=${companySearch}`);
        setCompanyNameOptions(result.data);
      })();
    }
  }, [companySearch]);

  useEffect(() => {
    if (form.productType) {
      let tenure = [];
      switch (form.productType) {
        case 'PERS':
          tenure = personalTenure;
          break;
        case 'AUTO':
          tenure = autoTenure;
          break;
        case 'HOME':
          tenure = homeTenure;
          break;
        case 'GOLD':
          tenure = goldTenure;
          break;
        case 'EDUL':
          tenure = educationTenure;
          break;
        case 'INSR':
          tenure = insuranceTenure;
          break;
        case 'CRED':
          tenure = ccTenure;
          break;
        case 'BUSI':
          tenure = businessTenure;
          break;
        default:
      }
      if (productTenure !== tenure) {
        setProductTenure(tenure);
        setForm({ ...form, tenure: null });
      }
    }
  }, [form, productTenure]);

  return (
    <Paper>
      <header className={classes.title}>Loan Enquiry</header>
      <section className={classes.content}>
        <Grid container>

          <Grid item xs={12} style={{ padding: '0.5rem' }}>
            <div className={classes.checkbox}>
              <Typography className={classes.fieldName} component="span">Required Product</Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-label="tenure-type"
                  name="tenureType"
                  value={tenureType}
                  onChange={e => { setTenureType(e.target.value) }}
                >
                  <FormControlLabel value="new" control={<Radio />} label="New" />
                  <FormControlLabel value="balance" control={<Radio disabled />} label="Balance Transfer" />
                </RadioGroup>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={6} style={{ padding: '0.5rem' }}>
            <TextField
              select
              label="Product Type"
              value={form.productType}
              name="productType"
              onChange={handleChange}
              fullWidth
            >
              {
                Object.keys(productConstants).map(typeCode => (
                  <MenuItem value={typeCode} key={typeCode}>{productConstants[typeCode]}</MenuItem>
                ))
              }
            </TextField>
          </Grid>
          <Grid item xs={6} style={{ padding: '0.5rem' }}>
            <TextField
              select
              label="Tenure (in Years)"
              value={form.tenure}
              name="tenure"
              onChange={handleChange}
              fullWidth
              disabled={productTenure.length === 0}
            >
              {
                productTenure.map(tenure => (
                  <MenuItem value={tenure} key={tenure}>{tenure}</MenuItem>
                ))
              }
            </TextField>
          </Grid>
          <Grid item xs={12} style={{ padding: '0.5rem' }}>
            <Typography className={classes.fieldName}>Income Details</Typography>
          </Grid>
          <Grid item xs={6} style={{ padding: '0.5rem 0' }}>
            <Autocomplete
              open={openCompanyName}
              onOpen={() => { setOpenCompanyName(true) }}
              onClose={() => { setOpenCompanyName(false) }}
              isOptionEqualToValue={(option, value) => option.EMPLOYERNAME === value.EMPLOYERNAME}
              getOptionLabel={(option) => option.EMPLOYERNAME}
              options={companyNameOptions}
              loading={loading}
              onInputChange={handleCompanySearch}
              onChange={handleChange}
              value={form.companyName}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Company Name"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} style={{ padding: '0.5rem' }}>
            <TextField
              label="Salary"
              type="number"
              name="salary"
              onChange={handleChange}
              value={form.salary}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} style={{ padding: '0.5rem' }}>
            <Typography className={classes.fieldName}>Existing Loans/Obligation Details</Typography>
          </Grid>
          <Grid item xs={6} style={{ padding: '0.5rem' }}>
            <TextField
              label="EMI Amount"
              type="number"
              name="emiAmoumt"
              onChange={handleChange}
              value={form.emiAmoumt}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} style={{ padding: '0.5rem' }}>
            <TextField
              label="Balance Tenure"
              type="number"
              name="balanceTenure"
              onChange={handleChange}
              value={form.balanceTenure}
              fullWidth
              disabled={!form.tenure}
            />
          </Grid>
          <Grid item xs={6} style={{ padding: '0.5rem' }}>
            <TextField
              select
              label="Preference"
              value={form.preference}
              name="preference"
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value={`loanAmount`} key={`loanAmount`}>{`Loan Amount`}</MenuItem>
              <MenuItem value={`roi`} key={`ROI`}>{`Rate of Interest`}</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6} style={{ padding: '0.5rem' }}>
            <Button variant='contained' onClick={handleSubmission} disabled={calcLoading} sx={{ backgroundColor: '#1ecef4', margin: '15px 10px 15px 20px', color: 'white', float: 'right' }}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </section>
      <section>
        {calcLoading && <LinearProgress />}
        <header className={classes.bankHead}>Recommended Banks</header>
        <Order result={calcResult} />
      </section>
    </Paper>
  );
}
