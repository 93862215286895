import AdjustIcon from '@mui/icons-material/Adjust';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Chip, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useEffect, useState } from 'react';
import { BootstrapButton } from '../../BootstrapButton';
import UploadData from './UploadData';
import axios from 'axios';
import { ApiUrl } from '../../../../helpers/getUrl';

export const PayoutGrid = () => {

    const [display, setDisplay] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [count, setCount] = useState(0);

    useEffect(() => {
        getData();
    }, [rowsPerPage, page]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function getData() {
        const formData = {
            limit: rowsPerPage,
            orderBy: "lastUpdatedOn",
            orderDirection: "DESC",
            page: page + 1,
        };
        axios.post(ApiUrl() + `reports/master`, formData).then((res) => {
            if (res.status === 200) {
                setData(res.data.data);
                setCount(res.data.count)
            }
        }).catch((error) => {
            console.error(error)
        })
    }

    const handleUploadModal = (e, reason) => {
        if (reason && reason === "backdropClick") return;
        setDisplay(false)
    }

    return (
        <>
            <Grid pt={3} container spacing={0} className="justify-content-end">
                <BootstrapButton
                    aria-selected="true"
                    onClick={() => setDisplay(true)}
                    endIcon={<FileUploadOutlinedIcon />}
                >
                    Upload Data</BootstrapButton>
                <BootstrapButton>
                    <FileDownloadOutlinedIcon />
                    Download Data</BootstrapButton>
            </Grid>
            <Grid container paddingTop={1}>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Month</TableCell>
                                    <TableCell align="center">Bank</TableCell>
                                    <TableCell align="center">Product</TableCell>
                                    <TableCell align="center">Ref No.</TableCell>
                                    <TableCell align="center">Customer Name</TableCell>
                                    <TableCell align="center">Amount</TableCell>
                                    <TableCell align="center">Exp %</TableCell>
                                    <TableCell align="center">Exp Amt</TableCell>
                                    <TableCell align="center">Exp Num</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map((row, idx) => (
                                    <TableRow
                                        key={idx}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row?.month} {row?.year}
                                        </TableCell>
                                        <TableCell align="center">{row?.loanBank}</TableCell>
                                        <TableCell align="center">{row?.productType}</TableCell>
                                        <TableCell align="center">{row?.referenceNumber}</TableCell>
                                        <TableCell align="center">{row?.customerName}</TableCell>
                                        <TableCell align="center">{row?.disbursementAmount.cur()}</TableCell>
                                        <TableCell align="center">{(row?.connectorPayoutPercentage * 100)?.toFixed(2)}</TableCell>
                                        <TableCell align="center">{row?.connectorPayoutAmount.cur()}</TableCell>
                                        <TableCell align="center">{row?.connectorPhone}</TableCell>
                                        <TableCell align="center">
                                            {row.bankPayoutStatus === "RECEIVED" ? <Chip label="Received" variant="outlined" icon={<AdjustIcon style={{ color: "#32C552", fontSize: "14px" }} />} />
                                                : <Chip label="Pending" variant="outlined" icon={<AdjustIcon style={{ color: "#F56363", fontSize: "14px" }} />} />}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {data && data.length === 0 && (
                                    <TableRow>
                                        <TableCell align="center" colSpan={10}>
                                            No data found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        component="div"
                        className='custom_pagination'
                        count={count || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>
            <UploadData display={display} handleUploadModal={handleUploadModal} />
        </>
    )
}