import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import axios from "axios";
import { ApiUrl } from "../../../../helpers/getUrl";
import { BootstrapButton } from "../../BootstrapButton";
import {
  ErrorSnackbar,
  SuccessSnackbar,
} from "../../../../components/CustomSnackBar";
import { errorHandler } from "../../../../helpers/errorHandler";

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const validationSchema = yup.object({
  paidAmount: yup
    .number()
    .typeError("Transaction Amount is Required")
    .required(),
  connectorPhone: yup.string().required("Connector Phone is Required"),
  connectorName: yup.string().required("Connector Name is Required"),
  remarks: yup.string().required("Remarks are Required"),
});
const AddDigiRepModal = ({ display, handleModal }) => {
  const [successMsg, setSuccessMsg] = useState({
    status: false,
    msg: "",
  });
  const [errorMsg, setErrorMsg] = useState({ state: false, msg: null });
  const [connectorId, setConnectorId] = useState(null);
  let localData = JSON.parse(sessionStorage.getItem("user"));
  const { userEmail } = localData || {};
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    reset,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    resolver,
  });
  let watchPhone = watch("connectorPhone");

  useEffect(() => {
    if (watchPhone) {
      axios
        .get(`${ApiUrl()}connector/mobile/${watchPhone}`)
        .then((res) => {
          if (res.data) {
            setConnectorId(res.data.connectorId);
            setValue("connectorName", res.data.connectorName);
            setValue("location", res.data.location);
            setValue("state", res.data.state);
          } else {
            setConnectorId(null);
            setValue("connectorName", null);
            setValue("location", null);
            setValue("state", null);
          }
        })
        .catch((error) => {
          let errMsg = errorHandler(error);
          setErrorMsg({ state: true, msg: errMsg });
        });
    }
  }, [watchPhone]);

  const onSubmitForm = (data) => {
    let formData = {
      connectorId: connectorId,
      transactionAmount: data?.paidAmount,
      remarks: data?.remarks,
      transactionType: data?.transactionType,
    };
    let url = `${ApiUrl()}`.replace("v1", "v2");

    axios
      .post(`${url}advance/request`, formData)
      .then((res) => {
        if (res.status === 200) {
          reset();
          handleModal();
          setSuccessMsg({
            status: true,
            msg: "Advance request added successfully",
          });
        }
      })
      .catch((error) => {
        let errMsg = errorHandler(error);
        setErrorMsg({ state: true, msg: errMsg });
      });
  };

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={display} onClose={handleModal}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="text-center mt-3 dialog_header">
            Add DigiRep - Advances
          </div>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <DialogContent sx={{ paddingTop: 0, marginTop: "10px" }}>
            <div className="row form-fields mt-md-n5">
              <div className="col-md-6 mt-3">
                <InputLabel htmlFor="connectorName">
                  Digirep Mobile No
                </InputLabel>
                <OutlinedInput
                  label="Outlined"
                  variant="standard"
                  className="w-100 outlined-input mt-2"
                  type="number"
                  {...register("connectorPhone")}
                />
                <p className="error">{errors?.connectorPhone?.message}</p>
              </div>
              <div className="col-md-6 mt-3">
                <InputLabel htmlFor="connectorName">Digirep Name</InputLabel>
                <OutlinedInput
                  label="Outlined"
                  variant="standard"
                  className="w-100 outlined-input mt-2"
                  {...register("connectorName")}
                  disabled
                />
                <p className="error">{errors?.connectorName?.message}</p>
              </div>
              <div className="col-md-6 mt-3">
                <InputLabel htmlFor="location">Location</InputLabel>
                <OutlinedInput
                  label="Outlined"
                  variant="standard"
                  className="w-100 outlined-input mt-2"
                  {...register("location")}
                  disabled
                />
              </div>
              <div className="col-md-6 mt-3">
                <InputLabel htmlFor="location">State</InputLabel>
                <OutlinedInput
                  label="Outlined"
                  variant="standard"
                  className="w-100 outlined-input mt-2"
                  {...register("state")}
                  disabled
                />
              </div>
              <div className="col-md-6 mt-3">
                <InputLabel htmlFor="component-disabled">
                  Transaction type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={"ADVANCE_PAYMENT"}
                  className="w-100 outlined-input"
                  {...register("transactionType")}
                >
                  {/* <MenuItem value="ADVANCE_RECEIPT">Receipt</MenuItem> */}
                  <MenuItem value="ADVANCE_PAYMENT">Payment</MenuItem>
                </Select>
              </div>
              <div className="col-md-6 mt-3">
                <InputLabel htmlFor="paidAmount">Transaction Amt</InputLabel>
                <OutlinedInput
                  label="Outlined"
                  variant="standard"
                  className="w-100 outlined-input mt-2"
                  id="paidAmount"
                  {...register("paidAmount")}
                  type="number"
                />
                <p className="error">{errors?.paidAmount?.message}</p>
              </div>
              <div className="col-12 mt-3">
                <InputLabel
                  htmlFor="adminRemarks"
                  className="mt-3"
                  style={{ textAlign: "left" }}
                >
                  Remarks
                </InputLabel>
                <OutlinedInput
                  id="remarks"
                  multiline
                  maxRows={4}
                  label="Outlined"
                  variant="standard"
                  className="w-100 outlined-input mt-2"
                  {...register("remarks")}
                />
                <p className="error">{errors?.remarks?.message}</p>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="justify-content-center mb-5">
            <BootstrapButton
              aria-selected="true"
              className="w-auto px-5 py-2 border-0"
              autoFocus
              type="submit"
              variant="contained"
              disabled={!isDirty || !isValid}
            >
              Submit
            </BootstrapButton>
            <BootstrapButton
              aria-selected="false"
              className="w-auto px-5 py-2"
              onClick={handleModal}
              type="reset"
            >
              Cancel
            </BootstrapButton>
          </DialogActions>
        </form>
      </Dialog>
      {successMsg && <SuccessSnackbar successMsg={successMsg.msg} />}
      {errorMsg.state && <ErrorSnackbar errorMsg={errorMsg.msg} />}
    </>
  );
};

export default AddDigiRepModal;
