import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { default as React, useState } from 'react';
import { ErrorSnackbar, SuccessSnackbar } from "../../components/CustomSnackBar";
import { ApiUrl } from "../../helpers/getUrl";

export default function BulkUpload(props) {
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [openBulkUpload, setOpenBulkUpload] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const localData = JSON.parse(sessionStorage.getItem('data'));
    const { connectorId } = localData;


    const handleBulkUpload = () => {
        setOpenBulkUpload(true);
    };

    const handleUploadClose = () => {
        setOpenBulkUpload(false);
        setSelectedFile(null);
        setIsFilePicked(false);
    };

    const changeHandler = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setIsFilePicked(true);
    };

    const handleSubmission = () => {
        const formData = new FormData();
        formData.append('file', selectedFile);

        axios.post(`${ApiUrl()}leads/connector/upload/${connectorId}`, formData)
            .then(res => {
                setOpenBulkUpload(false);
                setSuccessMessage('Bulk upload successfully completed');
                setSelectedFile(null);
                setIsFilePicked(false);
            }).catch(error => {
                setErrorMessage(error.message);
                setOpenBulkUpload(false);
                setSelectedFile(null);
                setIsFilePicked(false);
            });
    };

    return (
        <div>
            <Button
                sx={{ boxShadow: 2 }}
                variant="text"
                endIcon={<FileUploadOutlinedIcon />}
                onClick={handleBulkUpload}
                disabled={props?.disabled || false}
            >Bulk Upload</Button>
            <Dialog open={openBulkUpload} onClose={handleUploadClose}>
                <DialogTitle >Bulk Upload</DialogTitle>
                <form>
                    <DialogContent>
                        <div>
                            <FormControl variant="standard">
                                {isFilePicked && selectedFile?.name}
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <label htmlFor="contained-button-file">
                                        <input style={{ display: 'none' }} id="contained-button-file" type="file" onChange={changeHandler} />
                                        <Button variant='outlined' component='span'>
                                            choose file here
                                        </Button>
                                    </label>
                                    <Button variant="contained" onClick={handleSubmission} disabled={!isFilePicked}>
                                        <FileUploadOutlinedIcon />
                                    </Button>
                                </Stack>
                            </FormControl>
                        </div>
                    </DialogContent>
                </form>
            </Dialog>
            {errorMessage && <ErrorSnackbar errorMsges={errorMessage} />}
            {successMessage && <SuccessSnackbar successMsg={successMessage} />}
        </div>
    )
}