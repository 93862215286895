import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { ErrorSnackbar, SuccessSnackbar } from "../../../../components/CustomSnackBar";
import { transactionEnums, transactionTypes } from "../../../../helpers/constants";
import { errorHandler } from "../../../../helpers/errorHandler";
import { ApiUrl } from "../../../../helpers/getUrl";
import { BootstrapButton } from "../../BootstrapButton";

const useYupValidationResolver = (validationSchema) =>
    useCallback(
        async (data) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors, currentError) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

const validationSchema = yup.object({
    paymentStatus: yup.string().required("Payment Status is Required"),
    paymentDate: yup.string().required("Payment Date is Required"),

});

export const RequestAdvanceForm = ({ display, handleModal, selectedRow }) => {
    const [successMsg, setSuccessMsg] = useState({
        status: false,
        msg: '',
    });
    const today = moment(new Date()).format('YYYY-MM-DD');
    const resolver = useYupValidationResolver(validationSchema);
    const { ADVANCE_PAYMENT, ADVANCE_RECEIPT } = transactionTypes;
    const { PAYMENT, RECEIPT } = transactionEnums;
    const [errorMsg, setErrorMsg] = useState({ state: false, msg: null });

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver,
    });

    const [displayData, setDisplayData] = useState({
        connectorName: '',
        transactionType: '',
        currentOutstandingAmount: '',
        requestedAmount: '',
        approvedAmount: '',
        createdOn: '',
        accountHolderName: '',
        accountNumber: '',
        ifsc: '',
        bankName: '',
        pan: '',
        gstin: '',
        paymentStatus: 'PAYMENT_PROCESSED',
        paymentDate: '',
        connectorRemarks: '',
        connecterAcknowledgeFlag: null,
        transactionId: '',
        ceoDecisionDate: '',
    })

    useEffect(() => {
        // console.log("RequestAdvanceDetails in edit advance: ", selectedRow)
        if (selectedRow) {

            setDisplayData({
                connectorName: selectedRow.connectorName,
                transactionType: selectedRow.transactionType,
                currentOutstandingAmount: selectedRow.currentOutstandingAmount,
                requestedAmount: selectedRow.requestedAmount,
                approvedAmount: selectedRow.approvedAmount,
                createdOn: selectedRow.createdOn,
                accountHolderName: selectedRow.accountHolderName,
                accountNumber: selectedRow.accountNumber,
                ifsc: selectedRow.ifsc,
                bankName: selectedRow.bankName,
                pan: selectedRow.pan,
                gstin: selectedRow.gstin,
                connectorRemarks: selectedRow.connectorRemarks,
                connecterAcknowledgeFlag: selectedRow.connecterAcknowledgeFlag,
                transactionId: displayData.transactionId,
                ceoDecisionDate: selectedRow.ceoDecisionDate,
            })

        }
    }, [selectedRow])

    const onSubmitForm = (data) => {

        var formatData = {
            createdBy: "swagger@domain.com",
            paymentDate: moment(data.paymentDate).toISOString(),
            paymentStatus: data.paymentStatus,
            transactionId: selectedRow.transactionId,
        }
        // console.log("edit form data: ", formatData)
        axios.put(`${ApiUrl()}advance/admin`, formatData).then((res) => {
            reset();
            handleModal();
            setSuccessMsg({
                status: true,
                msg: 'Payment Processed Successfully',
            })
        }).catch(error => {
            let errMsg = errorHandler(error);
            setErrorMsg({ state: true, msg: errMsg })
        });
    }

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={display} onClose={handleModal}>
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleModal}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div className="text-center mt-3 dialog_header">Edit Advance Details</div>
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmitForm)}>
                    <DialogContent sx={{ paddingTop: 0, marginTop: "10px" }}>
                        <div className="row form-fields mt-md-n5">
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Digirep Name</InputLabel>
                                <OutlinedInput
                                    label="Outlined"
                                    variant="standard"
                                    className="w-100 outlined-input mt-2"
                                    value={displayData.connectorName}
                                    disabled />

                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Request type</InputLabel>
                                <OutlinedInput id="outlined-basic" value=
                                    {displayData.transactionType === ADVANCE_PAYMENT ? PAYMENT
                                        : displayData.transactionType === ADVANCE_RECEIPT ? RECEIPT
                                            : "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Outstanding Amount</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.currentOutstandingAmount?.cur()} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Requested Amount</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.requestedAmount?.cur()} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Approved Amount</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.approvedAmount?.cur()} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Approved Date</InputLabel>
                                <OutlinedInput id="outlined-basic" value={moment(displayData.ceoDecisionDate).format("DD MMM YYYY")} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Bank Holder Name</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.accountHolderName || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Bank Account Number</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.accountNumber || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">IFSC Code</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.ifsc || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Bank Name</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.bankName || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">PAN Number</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.pan || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">GSTIN</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.gstin || "-"} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel id="demo-simple-select-label">Payment Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    value={displayData.paymentStatus}
                                    className="outlined-input w-100"
                                    onChange={(e) => setDisplayData({ ...displayData, paymentStatus: e.target.value })}
                                    defaultValue="PAYMENT_PROCESSED"
                                    id="paymentStatus"
                                    {...register("paymentStatus")}
                                >
                                    <MenuItem value="PAYMENT_PROCESSED">Processed</MenuItem>
                                    <MenuItem value="PAYMENT_PENDING">Pending</MenuItem>
                                    <MenuItem value="REJECTED">Rejected</MenuItem>
                                </Select>
                                <p className="error">{errors?.paymentStatus?.message}</p>
                            </div>
                            <div className="col-md-6 mt-3">
                                <InputLabel htmlFor="component-disabled">Payment Date</InputLabel>
                                <OutlinedInput
                                    type="date"
                                    variant="standard"
                                    aria-label="From"
                                    className="outlined-input w-100"
                                    id="paymentDate"
                                    inputProps={{ max: today }}
                                    {...register("paymentDate")}
                                />
                                <p className="error">{errors?.paymentDate?.message}</p>
                            </div>
                            <div className="col-md-12 mt-3">
                                <InputLabel htmlFor="component-disabled">Remarks</InputLabel>
                                <OutlinedInput id="outlined-basic" value={displayData.connectorRemarks || "-"} multiline maxRows={4} label="Outlined" variant="standard" className="w-100 outlined-input mt-2" disabled />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="justify-content-center">
                        <BootstrapButton aria-selected="true" className="w-auto px-4 pt-2" autoFocus type="submit">
                            Submit
                        </BootstrapButton>
                    </DialogActions>
                </form>
            </Dialog>
            {successMsg && <SuccessSnackbar successMsg={successMsg.msg} />}
            {errorMsg.state && <ErrorSnackbar errorMsg={errorMsg.msg} />}
        </>
    )
}