import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { httpGet, httpPost } from "../../utils/Rest";
import { userRoleConstants } from "../../constants/appconstants";

const initialLeadsData = {
  accountsData: {},
  leadsData: {},
  connectorsData: {},
  isLoading: false,
  errorMsg: null,
  successMsg: null,
};

const slice = createSlice({
  name: "GetLeadsDataService", //Reference Name, It can be anything
  initialState: {
    ...initialLeadsData,
  },
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true;
      state.errorMsg = null;
    },
    accountsData: (state, action) => {
      state.accountsData = action.payload;
      state.isLoading = false;
      state.errorMsg = null;
    },
    connectorsData: (state, action) => {
      state.connectorsData = action.payload;
      state.isLoading = false;
      state.errorMsg = null;
    },
    leadsData: (state, action) => {
      state.leadsData = action.payload;
      state.isLoading = false;
      state.errorMsg = null;
    },
    fetchError: (state, action) => {
      console.log(action, "actionaction");
      state.errorMsg = action?.payload;
      state.isLoading = false;
    },
    fetchSuccess: (state, action) => {
      state.successMsg = action.payload;
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

// Actions

const { leadsData, accountsData, connectorsData, fetchStart, fetchError, fetchSuccess } = slice.actions;

export const getConnectorsData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    let userrole = sessionStorage.getItem("userRole");
    let { connectorId } = JSON.parse(sessionStorage.getItem("data"));
    let url = (userrole === '"CONNECTOR_MANAGER"' || userrole === '"BRANCH_MANAGER"') ? `manager/connectors/${connectorId}` : `connector/list`;
    httpPost(url, data)
      .then((res) => {
        if (res?.status === 200) {
          let obj = {
            data: res?.data?.data,
            count: res?.data?.count,
          };

          dispatch(connectorsData(obj));
          dispatch(fetchSuccess());
        } else {
          dispatch(fetchError(res.message));
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
}

export const getLeadsData = (connectorId, data, func) => {
  return (dispatch) => {
    dispatch(fetchStart());
    let userrole = sessionStorage.getItem("userRole");
    let url = (userrole === '"CONNECTOR_MANAGER"' || userrole === '"BRANCH_MANAGER"') ? `leads/manager/${connectorId}` : `leads/${connectorId}`;
    console.log(url, userrole)
    httpPost(url, data)
      .then((res) => {
        if (res?.status === 200) {
          let result = res?.data?.data;
          result.forEach(element => {
            element.applicationDateFormat = element.applicationDate ? moment(new Date(element.applicationDate)).format("DD/MM/YYYY") : '-';
            element.disbursementDateFormat = element.disbursementDate ? moment(new Date(element.disbursementDate)).format("DD/MM/YYYY") : '-';
            element.connectorPayoutDateFormat = element.connectorPayoutDate ? moment(new Date(element.connectorPayoutDate)).format("DD/MM/YYYY") : '-';
          })
          let obj = {
            data: res?.data?.data,
            count: res?.data?.count,
          };
          dispatch(leadsData(obj));
          func && func();
          dispatch(fetchSuccess());
        } else {
          dispatch(fetchError(res.message));
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};


export const getAccountsByConnector = (id) => {
  return (dispatch) => {
    httpGet(`connector/user/${id}`)
      .then((res) => {
        if (res?.status === 200) {
          const { connectorId } = res.data;
          sessionStorage.connectorId = connectorId
          httpGet(`accounts/${connectorId}`)
            .then((res) => {
              if (res?.status === 200) {
                let accounts = res.data.accounts;
                let grossAmount = res.data.grossAmount;

                const secondaryAccounts = accounts.filter(account => account.isDefault === false && account.accountStatus === "approved")
                let secondaryAccountsAmounts = secondaryAccounts.map(({ amount }) => +amount);
                const totalSecondaryAccountsAmount = secondaryAccountsAmounts.reduce((a, b) => a + b, 0);
                const primaryAccountsAmount = grossAmount - totalSecondaryAccountsAmount;
                accounts.forEach(account => {
                  if (account.isDefault) {
                    account.amount = primaryAccountsAmount.cur();
                  } else {
                    account.amount = account.amount.cur();
                  }
                })
                dispatch(accountsData({ 'accounts': accounts }));
                // dispatch(fetchSuccess("Lead data updated successfully"));
              } else {
                dispatch(fetchError(res?.message));
              }
            })
            .catch((error) => {
              dispatch(fetchError(error?.message));
            });
          //
          // dispatch(accountsData(res.data));
          // dispatch(fetchSuccess("Lead data updated successfully"));
        } else {
          dispatch(fetchError(res?.message));
        }
      })
      .catch((error) => {
        dispatch(fetchError(error?.message));
      });
  };
};
