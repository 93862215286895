import { Breadcrumbs, Grid, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { ErrorSnackbar } from "../../../../components/CustomSnackBar";
import { errorHandler } from "../../../../helpers/errorHandler";
import { ApiUrl } from "../../../../helpers/getUrl";
import { BootstrapButton } from "../../BootstrapButton";
import { RequestAdvanceDetails } from "../../Connector/Advance/RequestAdvanceDetails";
import { SpotPaymentGrid } from "../../Connector/Spot/SpotPaymentGrid";
import { AdvancePaymentGrid } from "./AdvancePayoutGrid";
// import { SpotPayoutGrid } from "./SpotPayoutGrid";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddDigiRepModal from "./DigirepDialog";

export const AdvanceHistory = () => {
  let localData = JSON.parse(sessionStorage.getItem("data"));
  const { connectorId } = localData || {};

  const [level, setLevel] = useState("L1");
  const [connectorsList, setConnectorsList] = useState([]);

  // const today = moment(new Date()).format("YYYY-MM-DD");
  // const [data, setData] = useState(null);
  // const [startDate, setStartDate] = useState(today);
  // const [endDate, setEndDate] = useState(today);
  const [searchAmount, setSearchAmount] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selected, setSelected] = useState("Advance");
  const [displayL2, setDisplayL2] = useState({
    show: false,
    data: [],
  });
  const [addDigiRep, setAddDigiRep] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [totalAdvances, setTotalAdvances] = useState(null);
  const [errorMsg, setErrorMsg] = useState({ state: false, msg: null });

  const handleModal = () => {
    setDisplayL2({
      show: false,
      data: [],
    });
  };
  useEffect(() => {
    getConnectorsList();
  }, [level, rowsPerPage, page]);

  useEffect(() => {
    if (selected === "Spot") {
      setLevel("L1");
    }
  }, [selected]);

  const getConnectorsList = () => {
    const formData = {
      limit: rowsPerPage,
      orderBy: "createdOn",
      orderDirection: "DESC",
      page: page,
      // search: {
      //     dateRange: {
      //         startDate: startDate + "T00:00:00.000Z",
      //         endDate: endDate + "T23:59:59.000Z",
      //     },
      // },
    };
    if (searchAmount !== null && searchAmount !== "") {
      formData.search.approvedAmount = parseInt(searchAmount);
    }
    // console.log(connectorId);
    axios
      .post(ApiUrl() + `advance/list/${connectorId}`, formData)
      .then((res) => {
        // console.log("advance list in admin list L2: ", res.data.data);
        if (res.status === 200) {
          setConnectorsList(res.data.data);
        }
      })
      .catch((error) => {
        let errMsg = errorHandler(error);
        setErrorMsg({ state: true, msg: errMsg });
      });
  };
  return (
    <div className="mt-4 px-4">
      <h4>Advance Ledger</h4>
      {/* <KPI /> */}
      <Grid pt={3} container>
        <Grid item xs={12} lg={6} container>
          <BootstrapButton
            mr={2}
            style={{ borderRadius: "5px", fontSize: "20px" }}
            aria-selected={"true"}
          >
            Total Advances : Rs.{" "}
            {totalAdvances?.toLocaleString('hi')}
          </BootstrapButton>
        </Grid>
        <Grid item xs={12} lg={6} container className="justify-content-end">
          <BootstrapButton
            startIcon={<AddOutlinedIcon />}
            aria-selected={"true"}
            onClick={() => setAddDigiRep(true)}
          >
            Add Digirep
          </BootstrapButton>
        </Grid>
      </Grid>
      {selected === "Spot" ? (
        <>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="text.primary">Spot History</Typography>
          </Breadcrumbs>
          <SpotPaymentGrid
            showProcessedPayoutStatus={true}
            showOnlySpot={true}
          />
        </>
      ) : (
        <AdvancePaymentGrid
          level={level}
          refreshData={refreshData}
          setLevel={setLevel}
          setTotalAdvances={setTotalAdvances}
          connectorsList={connectorsList}
          setDisplayL2={setDisplayL2}
        />
      )}
      <RequestAdvanceDetails display={displayL2} handleModal={handleModal} />
      <AddDigiRepModal
        display={addDigiRep}
        handleModal={() => {
          setAddDigiRep(false);
          setRefreshData(!refreshData);
        }}
      />
      {errorMsg.state && <ErrorSnackbar errorMsg={errorMsg.msg} />}
    </div>
  );
};
