import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppContextProvider from "../../components/contextProvider/AppContextProvider/AppContext";
import { stateConstants } from "../../constants/appconstants";
import { ApiUrl } from '../../helpers/getUrl';
import { getConnectorsData } from "../../redux/reducers/GetLeadsDataService";


function loadServerRows(page, data) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data.rows.slice(page * 5, (page + 1) * 5));
    }, Math.random() * 500 + 100); // simulate network latency
  });
}


export default function ConnectorTable({ handleEditClick }) {
  const dispatch = useDispatch();
  const { connectorId } = useContext(AppContextProvider);
  const { isLoading, successMsg, errorMsg, connectorsData } = useSelector(
    ({ getLeadsDataService }) => getLeadsDataService
  );

  const { invokeGetLeadsApi } = useSelector(
    ({ leadsDataService }) => leadsDataService
  );

  const [filterModel, setFilterModel] = React.useState({ items: [] });
  let [sortModel, setSortModel] = React.useState([
    { field: 'createdOn', sort: 'asc' },
  ]);


  const handleDeleteClick = (row) => {
    // console.log(row.connectorId);
    axios.delete(`${ApiUrl()}connector/${row.connectorId}`)
      .then(res => {
        resetData();
      })

  }

  const { data = [], count = 0 } = connectorsData;

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);

  const resetData = () => {
    let reqData = {
      limit: pageSize,
      orderBy: "createdOn",
      orderDirection: "DESC",
      page: page + 1,
      search: {},
    };
    dispatch(getConnectorsData(connectorId, reqData));
  }

  const [reqData, setreqData] = useState({
    limit: pageSize,
    orderBy: "createdOn",
    orderDirection: "DESC",
    page: page + 1,
    search: {},
  });

  const setReqData = () => {
    let filterSearch = filterModel.items.reduce((o, n) => { n.value && (o[n.columnField] = n.value); return o; }, {});
    //console.log("onFilterChange filterSearch", filterSearch, reqData.search);
    let payload = {
      ...reqData,
      orderBy: sortModel[0].field,
      orderDirection: sortModel[0].sort.toUpperCase(),
      range: reqData.range,
      page: page + 1,
      limit: pageSize,
      search: {
        ...filterSearch
      }
    };
    setreqData(payload);
    return payload;
  }

  useEffect(() => {
    if (invokeGetLeadsApi) {
      dispatch(getConnectorsData(connectorId, reqData)); //Invoke Action
    }
    return () => { };
  }, [invokeGetLeadsApi]);

  useEffect(() => {
    let payload = setReqData();
    dispatch(getConnectorsData(connectorId, payload)); //Invoke Action
  }, [connectorId, filterModel, sortModel, page, pageSize]);


  const onFilterChange = (filter) => {
    //console.log("onFilterChange", filter);
    setFilterModel(filter);
  }

  const onSortChange = (sort) => {
    if (sort.length) {
      let selected = sortModel[0] ?? {};
      let newsort = sort[0] ?? {};
      if (selected.field != newsort.field || selected.sort != newsort.sort) {
        setSortModel([
          newsort
        ])
        setReqData();
      }
    }
  }

  // useEffect(() => {
  //   let reqData = {
  //     limit: pageSize,
  //     orderBy: "createdOn",
  //     orderDirection: "DESC",
  //     page: page + 1,
  //     search: {},
  //   };
  //   dispatch(getConnectorsData(connectorId, reqData)); //Invoke Action

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [connectorId, page, pageSize]);


  // if (rowVal.isManager === false && rowVal.isBranchManager === false) {
  //   role = 'Connector';
  // } else if (rowVal.isManager === true && rowVal.isBranchManager === false) {
  //   role = 'Manager';
  // } else if (rowVal.isManager === false && rowVal.isBranchManager === true) {
  //   role = 'BranchManager';
  // } else if (rowVal.isManager === true && rowVal.isBranchManager === true) {
  //   role = 'Manager';
  // } else if (rowVal.isBranchManager === true) {
  //   role = 'BranchManager';
  // } else if (rowVal.isManager === true) {
  //   role = 'Manager';
  // } else if (rowVal.isBranchManager === false) {
  //   role = 'Manager';
  // } else if (rowVal.isManager === false) {
  //   role = 'BranchManager';
  // }

  function getRoleProps(params) {
    let rowVal = params.row;
    // console.log(rowVal);
    let role = "";

    if (rowVal.isManager === false && rowVal.isBranchManager === false) {
      role = 'Connector';
    } else if (rowVal.isManager === true && rowVal.isBranchManager === false) {
      role = 'Manager';
    } else if (rowVal.isManager === true && rowVal.isBranchManager === true) {
      role = 'BranchManager';
    }
    // console.log("role: ", role);
    return role
  }


  const columns = [
    { headerName: "DigiRep Name", field: "connectorName", width: 200 },
    { headerName: "DigiRep ID", field: "connectorId", width: 200 },
    {
      headerName: "Role", field: "isManager", width: 200, renderCell: (params) => <>{getRoleProps(params)}</>
    },
    { headerName: "DigiRep Email", field: "connectorEmail", width: 200 },
    //{ headerName: "Connector Manager", field: "connectorManager", width: 150 },
    { headerName: "DigiRep Phone", field: "connectorPhone", width: 150 },
    // { headerName: "Customer Number", field: "customerPhone", width: 150 },
    {
      headerName: "State", field: "state", width: 150,
      renderCell: (params) => <>{stateConstants[params.value]}</>
    },
    { headerName: "Location", field: "location", width: 150 },
    {
      field: "edit", type: "actions", headerName: "Edit", width: 90,
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={() => { handleEditClick(row); }}
            color="inherit"
          />]
      },
    },
    {
      field: "delete", type: "actions", headerName: "Delete", width: 90,
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Edit"
            onClick={() => { handleDeleteClick(row) }}
            color="inherit"
          />
        ]
      },
    },

  ];
  return (
    <div className="row">
      <div
        style={{ height: 520, maxWidth: "100%" }}
        className="col-md-12 leads-table"
      >
        <DataGrid
          rows={data}
          getRowId={(e) => e._id}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          rowCount={count}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={onSortChange}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => {
            console.log()
            setPage(0);
            setPageSize(newPageSize);
          }}
          loading={isLoading}
        />
      </div>
    </div>
  );
}
