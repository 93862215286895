import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";
// import AddOfferForm from "./AddOfferForm";
// import BankList from "./BankList";
import {
  ErrorSnackbar,
  SuccessSnackbar,
} from "../../../components/CustomSnackBar";
import { DigitalApplicationTable } from "./DigApplicationTable";
import { DigitalApplForm } from "./DigitalApplForm";
import RBIGuideline from "./RbiGuideline";
import icon from "./l&TFinance.svg";
import aditya_birla_icon from "./aditya_birla.svg";
import LntFeeCharges from "./lntFeeCharges";

const DigitalApplDashboard = () => {
  const [showGrid, setShowGrid] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ state: false, msg: null });
  const [successMsg, setSuccessMsg] = useState({
    status: false,
    msg: "",
  });
  const [showForm, setShowForm] = useState(false);

  return (
    <>
      {" "}
      <div className="sidenav-content">
        <div className="row my-2">
          {!showGrid ? (
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              className="leads-heading"
            >
              {" "}
              Digital Applications
            </Typography>
          ) : (
            <div className="row my-2">
              <div className="col-md-6">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    underline="hover"
                    color="inherit"
                    to="#"
                    onClick={() => {
                      setShowGrid(false);
                      setShowForm(false);
                    }}
                  >
                    Digital Applications
                  </Link>
                  <Typography color="text.primary">L&T Finance </Typography>
                </Breadcrumbs>
              </div>
              <div className="col-md-6 text-end">
                {!showForm && (
                  <Button
                    className="p-2 px-4"
                    style={{
                      borderRadius: "25px",
                    }}
                    variant="contained"
                    onClick={() => setShowForm(true)}
                  >
                    Apply Now
                  </Button>
                )}
              </div>
            </div>
          )}
          {!(showGrid || showForm) ? (
            <Grid container spacing={0}>
              <Grid item xs={6} sm={6} md={3}>
                <Paper
                  elevation={24}
                  sx={{
                    p: 1,
                    margin: 1,
                    marginTop: 3,
                    display: "flex",
                    //   flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    height: "15vh",
                    boxShadow: 3,
                    textAlign: "center",
                    backgroundColor: "#FCE40B",
                    "&:hover": {
                      background: "#FCE40B",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => setShowGrid(true)}
                >
                  <div
                    className="row justify-content-center align-content-center"
                    style={{ flexDirection: "row", padding: 8 }}
                  >
                    <img src={icon} alt="icon" width={60} height={60} />
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <Paper
                  elevation={24}
                  sx={{
                    p: 1,
                    margin: 1,
                    marginTop: 3,
                    display: "flex",
                    //   flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "15vh",
                    boxShadow: 3,
                    textAlign: "center",
                    backgroundColor: "#CD1C36",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <a
                    href="https://creditlink.finbox.in/?partnerCode=SP_GUHTTB"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div
                      className="row justify-content-center align-content-center"
                      style={{ flexDirection: "row", padding: 8 }}
                    >
                      <img
                        src={aditya_birla_icon}
                        alt="icon"
                        width={80}
                        height={80}
                      />
                    </div>
                  </a>
                </Paper>
              </Grid>
            </Grid>
          ) : showForm ? (
            <DigitalApplForm
              setShowForm={setShowForm}
              setSuccessMsg={setSuccessMsg}
              setErrorMsg={setErrorMsg}
            />
          ) : (
            <DigitalApplicationTable />
          )}
          {showGrid && (
            <>
              {" "}
              <Grid container spacing={0}>
                <LntFeeCharges />
                <RBIGuideline />
              </Grid>
            </>
          )}
        </div>
      </div>
      {successMsg && <SuccessSnackbar successMsg={successMsg.msg} />}
      {errorMsg.state && <ErrorSnackbar errorMsg={errorMsg.msg} />}{" "}
    </>
  );
};
export default DigitalApplDashboard;
