import { FileCopyOutlined } from "@mui/icons-material";
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import SubCategoryList from "./SubCategoryList";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import WorkOutlineSharpIcon from '@mui/icons-material/WorkOutlineSharp';
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

const active = {
  background: "white",
  borderRadius: "5px",
  borderRight: 6,
  borderColor: "#40c4ff",
  '&:hover': {
    background: 'white',
  },
};
const nonActive = { borderRadius: "5px", color: "white" };

export default function AdminList({ handleDrawerToggle }) {
  let location = useLocation();
  let match = useRouteMatch();
  const { pathname } = location;

  const digirepCategory = [
    { icon: <PersonAddOutlinedIcon />, label: 'Add DigiRep', path: 'admin-add-DigiRep' },
    { icon: <HowToRegOutlinedIcon />, label: 'Account Approval', path: 'approve-DigiRep' },
  ];
  const accountCategory = [
    { icon: <CreditScoreIcon />, label: 'Manage Expenses', path: 'manage-expense' },
  ];

  const enquiryCategory = [
    { icon: <FactCheckOutlinedIcon />, label: 'Loan Enquiry', path: 'admin-loan-enquiry' },
    { icon: <AccountBalanceOutlinedIcon />, label: 'Bank Wise Offer', path: 'bank-wise-offer' },
    { icon: <FileCopyOutlined />, label: 'HDFC Report', path: 'hdfc-report' },
    { icon: <HelpOutlineIcon />, label: 'Help', path: 'help' },
  ];
  const categoryList = [
    { label: 'DigiRep', icon: <SupervisorAccountIcon />, category: digirepCategory },
    { label: 'Accounts', icon: <AccountBalanceOutlinedIcon />, category: accountCategory },
    { label: 'Enquiry', icon: <DriveFileRenameOutlineIcon />, category: enquiryCategory },
  ];

  return (
    <div>
      <List sx={{ paddingLeft: "0px" }}>
        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="admin-home" />
          )}
        >
          <ListItemButton
            sx={
              pathname === "/admin/admin-home" || pathname === "/admin/"
                ? active
                : nonActive
            }
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={
                pathname === "/admin/admin-home" || pathname === "/admin/"
                  ? {}
                  : { color: "white" }
              }
              onClick={handleDrawerToggle}
            >
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>

        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="admin-leads" />
          )}
        >
          <ListItemButton
            sx={pathname === "/admin/admin-leads" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={pathname === "/admin/admin-leads" ? {} : { color: "white" }}
            >
              <PersonOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Leads" />
          </ListItemButton>
        </ListItem>
        {categoryList.map((item, idx) => (
          <SubCategoryList
            item={item}
            key={idx}
            innerPath="admin"
            handleDrawerToggle={handleDrawerToggle}
          />
        ))}

        {/* <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="advance-payment-approval" />
          )}
        >
          <ListItemButton
            sx={
              pathname === "/admin/advance-payment-approval"
                ? active
                : nonActive
            }
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={
                pathname === "/admin/advance-payment-approval"
                  ? {}
                  : { color: "white" }
              }
            >
              <InventoryOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Payment Approvals" />
          </ListItemButton>
        </ListItem> */}

        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="advance-payment-history" />
          )}
        >
          <ListItemButton
            sx={
              pathname === "/admin/advance-payment-history" ? active : nonActive
            }
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={
                pathname === "/admin/advance-payment-history"
                  ? {}
                  : { color: "white" }
              }
            >
              <WorkOutlineSharpIcon />
            </ListItemIcon>
            <ListItemText primary="Advance Ledger" />
          </ListItemButton>
        </ListItem>
        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="payout-details" />
          )}
        >
          <ListItemButton
            sx={pathname === "/admin/payout-details" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={
                pathname === "/admin/payout-details" ? {} : { color: "white" }
              }
            >
              <InventoryOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Payout Details" />
          </ListItemButton>
        </ListItem>
        <ListItem
          component={({ innerRef, ...props }) => (
            <>
              <Link {...props} to="digital-applications" />
            </>
          )}
        >
          <ListItemButton
            sx={
              pathname === "/admin/digital-applications" ? active : nonActive
            }
          >
            <ListItemIcon
              sx={
                pathname === "/admin/digital-applications"
                  ? {}
                  : { color: "white" }
              }
            >
              <SwapHorizIcon />
            </ListItemIcon>
            <ListItemText primary="Digital Applications" />
          </ListItemButton>
        </ListItem>

        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="notification" />
          )}
        >
          <ListItemButton
            sx={pathname === "/admin/notification" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={pathname === "/admin/notification" ? {} : { color: "white" }}
            >
              <NotificationAddOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
}
