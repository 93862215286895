import axios from "axios";
import { default as React, useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ApiUrl } from '../../../helpers/getUrl';
import useAppContext from "../../../utils/useAppContext";

function BankWiseSummary() {
    const { freqValue, productType } = useAppContext();
    const [result, setResult] = useState([]);

    useEffect(() => {
        getProductProjection();
    }, [productType, freqValue]);

    const getProductProjection = () => {
        axios.get(`${ApiUrl()}projection/bank/ceo?product=${productType}&timeframe=${freqValue}`)
        .then(res => {
            let tempData = res.data.sort((a, b) => {
                let amtdiff = b.totalDisbursedAmount - a.totalDisbursedAmount;
                let apldiff = b.totalApplications - a.totalApplications;
                //return ( amtdiff > 0 ) ? amtdiff : apldiff;
                return apldiff;
            }).slice(0, 5);
            setResult(tempData);
            //console.log("getProductProjection",res.data);
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                data={result}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="productType" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar barSize={15} dataKey="totalApplications" name="Submitted" stackId="a" fill="#61CBA5" />
                <Bar barSize={15} dataKey="totalDisbursedApplications" name="Disbursed" stackId="a" fill="#F7CC6D" />
            </BarChart>
        </ResponsiveContainer>
    );
}
export default BankWiseSummary;
