import AdjustIcon from "@mui/icons-material/Adjust";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { ErrorSnackbar } from "../../../../components/CustomSnackBar";
import { errorHandler } from "../../../../helpers/errorHandler";
import {
  advancePaymentPossibleStatus,
  enumsArr,
  statusColors,
  transactionEnums,
  transactionTypes,
} from "../../../../helpers/constants";
import { ApiUrl } from "../../../../helpers/getUrl";
import { ConfirmationDialog } from "../../ConfirmationDialog";
import { RequestAdvanceDetails } from "./RequestAdvanceDetails";
import { Box } from "@mui/system";

export const AdvancePaymentGridV2 = ({
  addAdvance,
  ackColumn,
  connectorIdData,
}) => {
  let localData = JSON.parse(sessionStorage.getItem("data"));
  const { connectorId } = localData || {};
  // const today = moment(new Date()).format("YYYY-MM-DD");
  const [confirm, setConfirm] = useState({
    show: false,
    reason: null,
  });
  const [display, setDisplay] = useState({
    show: false,
    data: [],
  });
  const [data, setData] = useState(null);
  // const [startDate, setStartDate] = useState(today);
  // const [endDate, setEndDate] = useState(today);
  const [searchAmount, setSearchAmount] = useState("");
  // const [transactionType, setTransactionType] = useState("RECEIPT");
  const [userRowObj, setUserRowObj] = useState({});
  const [reqAmt, setReqAmt] = useState("");
  const [decisionBy, setDecisionBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [errorMsg, setErrorMsg] = useState({ state: false, msg: null });
  const [count, setCount] = useState(0);
  const { REJECTED, PENDING_FOR_CONNECTOR_ACKNOWLEDGEMENT } =
    advancePaymentPossibleStatus;
  const { SUCCESS, PENDING, ERROR } = statusColors;
  const { ADVANCE_PAYMENT, ADVANCE_RECEIPT } = transactionTypes;
  const { PAYMENT, RECEIPT } = transactionEnums;

  const POST_URL = connectorIdData
    ? `advance/list/${connectorIdData}`
    : `advance/list/${connectorId}`;

  useEffect(() => {
    // running getData after closing pop-up
    if (!confirm.show && !addAdvance) {
      getData();
    }
  }, [addAdvance, confirm, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function getData() {
    console.log(connectorIdData);
    const formData = {
      limit: rowsPerPage,
      orderBy: "createdOn",
      orderDirection: "DESC",
      page: page + 1,
      search: {
        // dateRange: {
        //   startDate: startDate + "T00:00:00.000Z",
        //   endDate: endDate + "T23:59:59.000Z",
        // },
      },
    };
    if (searchAmount !== null && searchAmount !== "") {
      formData.search.approvedAmount = parseInt(searchAmount);
    }
    let url = `${ApiUrl()}`.replace("v1", "v2");
    axios
      .post(url + POST_URL, formData)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data.data);
          getDecision();
          setCount(res.data.count);
        }
      })
      .catch((error) => {
        let errMsg = errorHandler(error);
        setErrorMsg({ state: true, msg: errMsg });
      });
  }

  const handleModal = (e, reason) => {
    // console.log("reasons: ", reason);
    setDisplay({
      show: false,
      data: [],
    });
  };

  const handleClick = (row, reason) => {
    // console.log(row, reason);
    setConfirm({ show: true, reason: reason });
    setReqAmt(row.requestedAmount);
    setUserRowObj(row);
    setDecisionBy("connector");
  };

  // Show Acknowledgements (accept/reject) chips for table list
  const getDecision = (
    row,
    ceoDecisionFlag,
    connecterAcknowledgeFlag,
    paymentStatus
  ) => {
    if (
      ceoDecisionFlag === true &&
      paymentStatus === PENDING_FOR_CONNECTOR_ACKNOWLEDGEMENT
    ) {
      // show and enable both accept and reject chips for connector decision
      return (
        <>
          <Chip
            label="Accept"
            variant="filled"
            className="px-2 me-4"
            onClick={() => handleClick(row, "Accept")}
          />
          <Chip
            label="Reject"
            variant="filled"
            className="px-2"
            onClick={() => handleClick(row, "Reject")}
          />
        </>
      );
    } else if (connecterAcknowledgeFlag === true) {
      //  show ACCEPTED chip
      return <Chip color="success" label="Accepted" />;
    } else if (paymentStatus === REJECTED) {
      //  show REJECTED chip
      return <Chip color="error" label="Rejected" />;
    } else {
      //  show and disable both accept and reject option chip //waiting for ceo decision
      return (
        <>
          <Chip
            label="Accept"
            variant="filled"
            className="px-2 me-4"
            disabled
          />
          <Chip label="Reject" variant="filled" className="px-2" disabled />
        </>
      );
    }
  };
  const getStatus = (status) => {
    var result = enumsArr.filter((obj) => {
      return obj.status === status;
    });

    return (
      <Chip
        label={result[0].status && result[0].label}
        variant="outlined"
        icon={
          <AdjustIcon
            style={
              result[0].color === "success"
                ? SUCCESS
                : result[0].color === "pending"
                ? PENDING
                : ERROR
            }
          />
        }
      />
    );
  };
  return (
    <>
      <Grid container paddingBottom={4}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Transaction date</TableCell>
                  <TableCell align="center">Transaction Type</TableCell>
                  <TableCell align="center">Transaction amt</TableCell>
                  <TableCell align="center">Outstanding amt</TableCell>
                  <TableCell align="center">Remarks</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!data ? (
                  <TableRow>
                    <TableCell colSpan={9}>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : null}
                {data?.map((row, idx) => (
                  <TableRow
                    key={idx}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      {moment(row.createdOn).format("DD MMM YYYY") || "-"}
                    </TableCell>
                    <TableCell align="center">
                      {row.transactionType === ADVANCE_PAYMENT
                        ? PAYMENT
                        : row.transactionType === ADVANCE_RECEIPT
                        ? RECEIPT
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {row.transactionAmount?.cur() ||
                        row.paidAmount?.cur() ||
                        "-"}
                    </TableCell>
                    <TableCell align="center">
                      {row.currentOutstandingAmount?.cur()}
                    </TableCell>
                    <TableCell>{row.remarks}</TableCell>
                  </TableRow>
                ))}
                {data && data.length === 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={7}>
                      No data found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            component="div"
            className="custom_pagination"
            count={count || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
      <ConfirmationDialog
        open={confirm}
        setOpen={setConfirm}
        reqAmt={reqAmt}
        userRowObj={userRowObj}
      />
      <RequestAdvanceDetails display={display} handleModal={handleModal} />
      {errorMsg.state && <ErrorSnackbar errorMsg={errorMsg.msg} />}
    </>
  );
};
