import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from "axios";
import moment from 'moment';
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { expenseCategory } from "../../constants/appconstants";
import { ApiUrl } from "../../helpers/getUrl";

const EditExpense = ({ isEdit, setisEdit }) => {

    const [date, setDate] = useState(new Date());
    const useStyles = makeStyles(() => ({
        tableCell: { textAlign: "center" },
        formControl: {
            padding: "5px",
            width: "250px",
        },
    }));

    const classes = useStyles();

    const {
        handleSubmit,
        register,
        watch,
        reset,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: isEdit.data,
    });
    const watchFields = watch(["grossAmount", "tds", "sgst", "cgst", "igst"]);

    useEffect(() => {
        if (isEdit?.data?.paidOn) {
            const paidDate = isEdit.data.paidOn.substring(0,10).split("/").reverse().join("-");
            setDate(paidDate);
        }
    }, [isEdit])

    useEffect(() => {
        if (watchFields[0] !== undefined) {
            const amount = parseInt(watchFields[0]) || 0;
            const tds = parseInt(watchFields[1]) || 0;
            const sgst = parseInt(watchFields[2]) || 0;
            const cgst = parseInt(watchFields[3]) || 0;
            const igst = parseInt(watchFields[4]) || 0;
            const netAmt = (amount + sgst + cgst + igst) - tds;
            setValue('netAmount', netAmt)
        }
    }, [watchFields]);

    const onSubmit = (data1) => {
        const { finadminEmail } = JSON.parse(sessionStorage.getItem("data"));
        const formData = {
            "category": data1.category,
            "grossAmount": parseInt(data1.grossAmount),
            "tds": parseInt(data1.tds),
            "cgst": parseInt(data1.cgst),
            "sgst": parseInt(data1.sgst),
            "igst": parseInt(data1.igst),
            "isTdsPaid": data1.isTdsPaid !== "Yes" ? false : true,
            paidOn: data1.paidOn,
            "netAmount": data1.netAmount,
            "remark": data1.remark,
            "updatedBy": finadminEmail,
            status: "PENDING"
        }
        axios.put(ApiUrl() + `expense/${isEdit?.data?.expenseId}`, formData).then((res) => {
            handleModal()
        }).catch((error) => {
            console.error(error)
        })
    }

    const handleModal = () => {
        setisEdit({ data: {}, display: false });
        reset();
    }

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={isEdit?.display} onClose={handleModal}>
            <DialogTitle>Edit Expense</DialogTitle>
            <DialogContent sx={{ paddingTop: 0, marginTop: "10px" }}>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row form-fields mt-md-n5">
                        <div className="col-md-4">
                            <FormControl variant="standard" className={classes.formControl}>
                                <Select
                                    labelId="category"
                                    id="category"
                                    sx={{ marginTop: "20px" }}
                                    {...register("category")}
                                    defaultValue={isEdit?.data?.category}
                                    label="category"
                                    fullWidth
                                >
                                    {Object.values(expenseCategory).map((x) => (
                                        <MenuItem value={x}>{x}</MenuItem>
                                    ))}
                                </Select>
                                <InputLabel>Expense Category</InputLabel>
                            </FormControl>
                        </div>
                        <div className="col-md-4">
                            <FormControl variant="standard" className={classes.formControl}>
                                <TextField
                                    className={classes.textField}
                                    autoFocus
                                    type='number'
                                    margin="dense"
                                    {...register("grossAmount", { required: "This field is required" })}
                                    label="Amount"
                                    defaultValue={isEdit?.data?.grossAmount}
                                    fullWidth
                                    variant="standard"
                                />
                                <FormHelperText
                                    error={errors?.grossAmount && errors?.grossAmount}
                                >
                                    {errors?.grossAmount?.message}
                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div className="col-md-4">
                            <FormControl variant="standard" className={classes.formControl}>
                                <TextField
                                    className={classes.textField}
                                    autoFocus
                                    type='number'
                                    margin="dense"
                                    {...register("tds")}
                                    defaultValue={isEdit?.data?.tds}
                                    label="TDS"
                                    fullWidth
                                    variant="standard"
                                />
                            </FormControl>
                        </div>
                        <div className="col-md-4">
                            <FormControl variant="standard" className={classes.formControl}>
                                <TextField
                                    className={classes.textField}
                                    autoFocus
                                    type='number'
                                    margin="dense"
                                    {...register("sgst")}
                                    defaultValue={isEdit?.data?.sgst}
                                    label="SGST"
                                    fullWidth
                                    variant="standard"
                                />
                            </FormControl>
                        </div>
                        <div className="col-md-4">
                            <FormControl variant="standard" className={classes.formControl}>
                                <TextField
                                    className={classes.textField}
                                    autoFocus
                                    type='number'
                                    margin="dense"
                                    {...register("igst")}
                                    defaultValue={isEdit?.data?.igst}
                                    label="IGST"
                                    fullWidth
                                    variant="standard"
                                />
                            </FormControl>
                        </div>
                        <div className="col-md-4">
                            <FormControl variant="standard" className={classes.formControl}>
                                <TextField
                                    className={classes.textField}
                                    autoFocus
                                    type='number'
                                    margin="dense"
                                    {...register("cgst")}
                                    defaultValue={isEdit?.data?.cgst}
                                    label="CGST"
                                    fullWidth
                                    variant="standard"
                                />
                            </FormControl>
                        </div>

                        <div className="col-md-4">
                            <FormControl variant="standard" className={classes.formControl}>
                                <Select
                                    labelId="isTdsPaid"
                                    id="isTdsPaid"
                                    sx={{ marginTop: "20px" }}
                                    {...register("isTdsPaid")}
                                    defaultValue={isEdit?.data?.isTdsPaid ? "Yes" : "No"}
                                    label="isTdsPaid"
                                    fullWidth
                                >
                                    {['Yes', 'No'].map((x) => (
                                        <MenuItem value={x}>{x}</MenuItem>
                                    ))}
                                </Select>
                                <InputLabel>TDS Payment Status</InputLabel>
                            </FormControl>
                        </div>
                        <div className="col-md-4">
                            <InputLabel>Paid On</InputLabel>
                            <FormControl variant="standard" className={classes.formControl}>
                                <FormControl variant="standard" >

                                    <input id="fd"
                                        className="date-picker"
                                        placeholder="Paid On"
                                        value={date}
                                        type="date"
                                        onChange={(e) => {
                                            const x = new Date(e.target.value)?.toISOString();
                                            const paidDate = x.substring(0, 10).split("/").reverse().join("-");
                                            setValue('paidOn', x);
                                            setDate(paidDate);
                                        }}
                                    />

                                </FormControl>
                            </FormControl>
                        </div>
                        <div className="col-md-4">
                            <FormControl variant="standard" className={classes.formControl}>
                                <TextField
                                    className={classes.textField}
                                    autoFocus
                                    margin="dense"
                                    {...register("netAmount")}
                                    label="Net Amount"
                                    defaultValue={isEdit?.data?.netAmount}
                                    disabled
                                    fullWidth
                                    variant="standard"
                                />
                            </FormControl>
                        </div>
                        <div className="col-md-8">
                            <FormControl variant="standard" className={classes.formControl}>
                                <TextField
                                    className={classes.textField}
                                    autoFocus
                                    margin="dense"
                                    defaultValue={isEdit?.data?.remark}
                                    {...register("remark")}
                                    label="Remarks"
                                    fullWidth
                                    variant="standard"
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className="row" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div className="col-md-4">
                        </div>
                        <div className="col-md-4">
                            <Stack spacing={2} direction="row-reverse">
                                <Button
                                    variant="contained"
                                    type="submit"
                                    style={{ float: 'right', marginRight: '25px' }}
                                >
                                    Save
                                </Button>

                                <Button variant="text" sx={{ boxShadow: 2 }} onClick={handleModal}>
                                    Cancel
                                </Button>

                            </Stack>
                        </div>

                    </div>
                </form >
            </DialogContent>
        </Dialog>
    )
}
export default EditExpense;