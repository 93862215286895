import AdjustIcon from "@mui/icons-material/Adjust";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Autocomplete,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { statusColors } from "../../helpers/constants";
import { ApiUrl } from "../../helpers/getUrl";
import { BootstrapButton } from "../../utils/BootstrapButton";
// import UpdatePaymentDialog from "../../UpdatePaymentDialog";
import { VisibilityOutlined } from "@mui/icons-material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import * as React from "react";
import {
  ErrorSnackbar,
  SuccessSnackbar,
} from "../../components/CustomSnackBar";
import { errorHandler } from "../../helpers/errorHandler";
import { cardProperties } from "../AdminHome/TransactionSections";
import Deposits from "../AdminHome/Deposits";
import { KPIs } from "./KPIs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DigitalApplicationTable = ({ refreshTable, setRefresh }) => {
  const [display, setDisplay] = useState({
    show: false,
    data: [],
  });

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [searchMobile, setSearchMobile] = useState("");
  const [searchApplicationStatus, setApplicationStatus] = useState("ALL");
  const [connectorId, setConnectorId] = useState(null);
  const [connectorList, setConnectorList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [count, setCount] = useState(0);
  const { SUCCESS, PENDING, ERROR } = statusColors;
  const [record, setRecord] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [errorMsg, setErrorMsg] = useState({ state: false, msg: null });
  const [successMsg, setSuccessMsg] = useState({
    status: false,
    msg: "",
  });

  useEffect(() => {
    if (refreshTable) {
      getData();
    }
  }, [refreshTable]);

  useEffect(() => {
    getData();
  }, [rowsPerPage, page]);

  useEffect(() => {
    getConnectorList();
  }, []);

  const getConnectorList = () => {
    // const formData = {
    //   limit: 3500,
    //   orderBy: "createdOn",
    //   orderDirection: "DESC",
    //   page: 0,
    // };
    // axios
    //   .post(ApiUrl() + `connector/list`, formData)
    //   .then((res) => {
    //     setConnectorList(res.data.data);
    //   })
    //   .catch((error) => {});
  };

  function getData() {
    setLoader(true);
    let formData = {
      limit: rowsPerPage,
      orderBy: "createdOn",
      orderDirection: "DESC",
      page: page + 1,
      search: {
        applicant_first_name: searchName,
        mobileNo: searchMobile,
        connectorId: connectorId,
        "eligibilityStatus.status": searchApplicationStatus,
      },
    };
    !connectorId && delete formData.search.connectorId;
    searchApplicationStatus === "ALL" &&
      delete formData.search["eligibilityStatus.status"];
    setData(null);
    axios
      .post(ApiUrl() + `eaggregator/list`, formData)
      .then((res) => {
        // console.log("advance history list in admin: ", res.data.data);
        if (res.status === 200) {
          setData(res.data[0].data);
          setCount(res.data[0]?.total?.totalRecords);
        }
        setLoader(false);
        setRefresh(false);
      })
      .catch((error) => {
        if (error.response.status !== 404) {
          let errMsg = errorHandler(error);
          setErrorMsg({ state: true, msg: errMsg });
        }
        setLoader(false);
      });
  }
  const handleChangePage = (event, newPage) => {
    // console.log("page: ", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log("limit: ", event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getStatus = (row) => {
    let rowStatusCode = row?.leadStatus?.statusCode;
    let leadStatus;

    leadStatus = row?.leadStatus?.status || row?.status;
    leadStatus =
      leadStatus?.charAt(0).toUpperCase() + leadStatus?.slice(1).toLowerCase();

    if (rowStatusCode === 200 && row?.eligibilityStatus) {
      return (
        <Chip
          label={leadStatus}
          style={{ minWidth: "100px" }}
          variant="outlined"
          icon={
            <AdjustIcon
              style={
                leadStatus === "Success"
                  ? SUCCESS
                  : leadStatus === "Pending"
                  ? PENDING
                  : ERROR
              }
            />
          }
        />
      );
    } else {
      return (
        <Chip
          label={leadStatus === "Fail" ? "Failed" : leadStatus}
          className=""
          style={{ minWidth: "100px" }}
          variant="outlined"
          icon={
            <AdjustIcon
              style={
                leadStatus === "Success"
                  ? SUCCESS
                  : leadStatus === "Pending"
                  ? PENDING
                  : ERROR
              }
            />
          }
        />
      );
    }
  };
  const getApplicationStatus = (row) => {
    let rowStatusCode = row?.leadStatus?.statusCode;
    let applicationStatus;

    if (rowStatusCode === 200 && row?.eligibilityStatus) {
      applicationStatus = row?.eligibilityStatus?.status;

      applicationStatus =
        applicationStatus?.charAt(0).toUpperCase() +
        applicationStatus?.slice(1).toLowerCase();
      return (
        <Chip
          label={applicationStatus === "Fail" ? "Failed" : applicationStatus}
          style={{ minWidth: "100px" }}
          variant="outlined"
          icon={
            <AdjustIcon
              style={
                applicationStatus === "Success"
                  ? SUCCESS
                  : applicationStatus === "Pending"
                  ? PENDING
                  : ERROR
              }
            />
          }
        />
      );
    } else {
      return <>-</>;
    }
  };
  const getRemarks = (row) => {
    let leadMsg = row?.leadStatus?.errorDesc;
    let rowStatusCode = row?.leadStatus?.statusCode;
    let finalText;
    if (leadMsg === "NO_ERROR") {
      finalText = <span className="d-block text-center">-</span>;
    } else {
      finalText = leadMsg;
    }

    // let applicationStatus = row?.eligibilityStatus;
    // console.log(applicationStatus);

    if (rowStatusCode === 200 && row?.eligibilityStatus) {
      finalText = row?.eligibilityStatus?.errorDesc;
    }
    return <>{finalText}</>;
  };
  return (
    <>
      <div>
        <>
          <KPIs/>
          <Grid container spacing={2} paddingTop={3}>
            <Grid item xs={12} lg={3}>
              <InputLabel htmlFor="component-disabled">
                Connector Name
              </InputLabel>
              <Autocomplete
                options={connectorList}
                getOptionLabel={(option) =>
                  option.connectorName + " " + option.connectorPhone
                }
                getOptionSelected={(option, value) =>
                  option.connectorId === value.connectorId
                }
                disabled
                onChange={(event, newValue) => {
                  newValue
                    ? setConnectorId(newValue?.connectorId)
                    : setConnectorId(null);
                }}
                id="connectorName"
                className="outlined-input w-100"
                placeholder="Connector Name"
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <InputLabel htmlFor="component-disabled">
                Customer Mobile No
              </InputLabel>
              <OutlinedInput
                id="outlined-basic"
                placeholder="Customer Mobile No"
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input"
                value={searchMobile}
                onChange={(e) => setSearchMobile(e.target.value)}
                type="text"
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <InputLabel htmlFor="component-disabled">
                Applicantion Date
              </InputLabel>
              <OutlinedInput
                type="month"
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input"
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <InputLabel htmlFor="component-disabled">
                Applicantion Status
              </InputLabel>
              <Select
                defaultValue={""}
                labelId="demo-simple-select-label"
                className="outlined-input w-100"
                id="status"
                onChange={(e) => setApplicationStatus(e.target.value)}
              >
                <MenuItem value="ALL">All</MenuItem>
                <MenuItem value="SUCCESS">Success</MenuItem>
                <MenuItem value="FAIL">Failed</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} lg={2} container style={{ maxWidth: "100%" }}>
              <BootstrapButton
                mr={2}
                aria-selected={"true"}
                style={{
                  borderRadius: "12px",
                  marginTop: "16px",
                  width: "100%",
                }}
                onClick={getData}
              >
                Search
              </BootstrapButton>
            </Grid>
          </Grid>
          <Grid container paddingTop={4}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 600 }}>
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      {[
                        "Connector name",
                        "Connector Mobile",
                        "Applicant name",
                        // "Application Date",
                        "Mobile no",
                        // "Pan no.",
                        // "Email address",
                        "Lead Status",
                        "Application Status",
                        "ROI",
                        "Max Eligibility",
                        "Remarks",
                      ].map((item) => {
                        return (
                          <TableCell>
                            <div style={{ whiteSpace: "nowrap" }}>{item}</div>
                          </TableCell>
                        );
                      })}

                      <TableCell
                        style={{ zIndex: 900, position: "sticky", right: 0 }}
                      >
                        Disbursement Details
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loader && (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <CircularProgress />
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.map((row, idx) => (
                      <TableRow
                        key={idx}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.connectorName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.connectorPhone}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.applicant_first_name} {row.applicant_last_name}
                        </TableCell>
                        {/* <TableCell align="center">
                          {row.createdOn?.substring(0, 10)}
                        </TableCell> */}
                        <TableCell align="center">{row.mobileNo}</TableCell>
                        {/* <TableCell align="center">{row.panNo}</TableCell>
                        <TableCell align="">{row.email}</TableCell> */}
                        <TableCell align="center">{getStatus(row)}</TableCell>
                        <TableCell align="center">
                          {getApplicationStatus(row)}
                        </TableCell>
                        <TableCell>
                          {row.finalStatus
                            ? row.finalStatus?.Content?.interestRate
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {row.finalStatus
                            ? row.finalStatus?.Content?.breMaxEligibility
                            : "-"}
                        </TableCell>
                        <TableCell>{getRemarks(row)}</TableCell>
                        <TableCell
                          align="center"
                          style={{
                            position: "sticky",
                            right: 0,
                            zIndex: 800,
                            backgroundColor: "aliceblue",
                          }}
                        >
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            onClick={(e) => {
                              setRecord(row);
                              setOpen(true);
                            }}
                          >
                            <VisibilityOutlined />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    {data?.length === 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan={11}>
                          No data found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                component="div"
                className="custom_pagination"
                count={count || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </>
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{"Disbursement Details"}</DialogTitle>
        <DialogContent>
          <table className="w-100">
            <tr>
              <th>Application Number</th>
              <td>{record?.finalStatus?.Content?.appRef || "-"}</td>
            </tr>
            <tr>
              <th>Application Date</th>
              <td>{record?.createdOn?.substring(0, 10) || "-"}</td>
            </tr>
            <tr>
              <th>Stage</th>
              <td>{record?.finalStatus?.Content?.stage || "-"}</td>
            </tr>
            <tr>
              <th>Sub-Stage</th>
              <td>{record?.finalStatus?.Content?.subStage || "-"}</td>
            </tr>
            <tr>
              <th>Net Disbursement</th>
              <td>
                {record?.disbursement_details?.net_disbursal_amt?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "INR",
                  }
                ) || "-"}
              </td>
            </tr>
            <tr>
              <th>Processing Fee</th>
              <td>
                {record?.disbursement_details?.processing_fee?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "INR",
                  }
                ) || "-"}
              </td>
            </tr>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      {successMsg && <SuccessSnackbar successMsg={successMsg.msg} />}
      {errorMsg.state && <ErrorSnackbar errorMsg={errorMsg.msg} />}{" "}
    </>
  );
};
