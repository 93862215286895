import { Typography } from "@mui/material";
import React from "react";
import ApproveConnectorTable from "./ApproveConnectorTable";

function ApproveConnectorIndex() {


    return (
        <>
            <div className="sidenav-content">
                <div className="row my-2">
                    <div className="col-md-6">
                        <Typography
                            variant="h6"
                            gutterBottom
                            component="div"
                            className="leads-heading"
                        >
                            Account Approval
                        </Typography>
                    </div>
                </div>
                <ApproveConnectorTable />
            </div>
        </>
    );
}
export default ApproveConnectorIndex;