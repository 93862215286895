import CloseIcon from "@mui/icons-material/Close";
import { Button, DialogActions } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

export default function Notify({ OpenNotify, toggleNotify, serviceName }) {
    return (
        <Dialog
            open={OpenNotify}
            onClose={() => toggleNotify("NOTIFY")}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => toggleNotify("NOTIFY")}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div className="notification-error">
                    Insufficient funds!!<br /> Kindly recharge to use {serviceName} service
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained"
                    onClick={() => toggleNotify("RECHARGE")}>
                    Recharge
                </Button>
            </DialogActions>
        </Dialog>
    );
}