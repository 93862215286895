export const errorHandler = (error) => {
    let errStatusCode = error.response.status;
    let message;
    // if (errStatusCode === 400) {
    //     message = "Bad Request";
    // } else if (errStatusCode === 401) {
    //     message = "Unauthorized";
    // } else if (errStatusCode === 500) {
    //     message = "Internal Server Error";
    // } else {
    //     message = "opps! something went wrong";
    // }
    if (errStatusCode >= 400 && errStatusCode <= 499) {
        message = "Bad Request: The request contains bad syntax or cannot be fulfilled";
    }
    if (errStatusCode >= 500 && errStatusCode <= 599) {
        message = "Server Error: The server failed to fulfill an apparently valid request";
    }
    return message;
};