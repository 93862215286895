import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import moment from 'moment';
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
    ErrorSnackbar,
    SuccessSnackbar
} from "../../components/CustomSnackBar";
import PageLoader from "../../components/PageLoader";
import {
    bankCodeConstants, bankNameConstants, loginStatusConstants, payoutStatusConstants, productConstants, stateConstants, stateNameToCodeMapping,
    userRoleConstants
} from "../../constants/appconstants";
import {
    postLeadsData,
    putLeadsData,
    resetLeads
} from "../../redux/reducers/LeadsDataService";
import useAppContext from "../../utils/useAppContext";
import LeadsTable from "./AdminLeadsTable";
import BulkUpload from "./bulkUploadConnector";



const useYupValidationResolver = (validationSchema) =>
    useCallback(
        async (data) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors) {
                return {
                    values: {},
                    errors: errors?.inner?.reduce(
                        (allErrors, currentError) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

const phoneRegExp = new RegExp('[1-9]{1}[0-9]{9}');

const validationSchema = yup.object({
    customerName: yup.string().required("Customer Name is required"),
    productType: yup.string().required("Product Type is required"),
    loginStatus: yup.string().required("Login Status is required"),
    // customerPhone: yup
    //   .string().when('loginStatus', {
    //     is: (loginStatus) => { console.log(loginStatus); return loginStatus === 'DISBURSED'; },
    //     then: yup.string().matches(phoneRegExp, "Must be a valid phone")
    //   })
    // ,
    loanBank: yup.string().required("Bank Name is required"),
    loanAmount: yup
        .number().positive()
        .min(1).typeError('Must be a valid amount')
        .required("Loan Amount is required"),
    // leadManager: yup.string(),
    location: yup.string().required("Location is required"),
    loanState: yup.string().required("State is required"),
    applicationNumber: yup.string(),
    applicationDate: yup.string().when('applicationNumber', {
        is: (applicationNumber) => { return applicationNumber.length > 0; },
        then: yup.string().required("Application Date is required")
    }),
    connectorPayoutOption: yup.string().required("Payout Type is required"),
});

const useStyles = makeStyles(() => ({
    tableCell: { textAlign: "center" },
    select: {
        width: "200px",
        margin: "5px",
    },
    // textField: {
    //   marginLeft: '30px'
    // },
    formControl: {
        padding: "5px",
        width: "250px",
    },
}));

function ConnectorManagerLeads() {
    let localData = JSON.parse(sessionStorage.getItem('user'));
    const { userEmail } = localData || {};
    const today = moment(new Date()).format('YYYY-MM-DD');
    const classes = useStyles();
    // const { connectorId } = useContext(AppContextProvider);
    const [openLead, setOpenLead] = useState(false);
    const [isEditMode, setEditMode] = useState(false);
    const [formDataInEditMode, setFormDataInEditMode] = useState(null);
    const [applicationDate, setApplicationDate] = useState('');
    const [applicationNumber, setApplicationNumber] = useState(null);
    const [displayPhone, setDisplayPhone] = useState(false);
    const { CONNECTOR, CEO, FIN_ADMIN, FIN_MANAGER, CONN_MANAGER } = userRoleConstants;

    let auth = useAppContext();

    const dispatch = useDispatch(); // used to dispatch an action

    const { isLoading, successMsg, errorMsg, invokeGetLeadsApi } = useSelector(
        ({ leadsDataService }) => leadsDataService
    );
    const resolver = useYupValidationResolver(validationSchema);

    let defaultValues = {
        applicationDate: '',
        connectorPayoutOption: "MONTHLY",
        loanStatus: "OPEN",
        sourceType: "INTERNAL",
        disbursementAmount: 0,
        disbursementDate: "",
        productType: "",
        loginStatus: "OPEN",

        connectorPayoutPercentage: 0,
        connectorPayoutAmount: 0,
        connectorPayoutDate: "",

        bankPayoutPercentage: 0,
        bankPayoutAmount: 0,
        bankPayoutDate: "",

        createdBy: userEmail,
        lanNumber: "",
        paymentStatus: "notProcessed",
        remarks: "",
    };

    // console.log(formDataInEditMode?.leadId, "oust");

    if (isEditMode && formDataInEditMode?.leadId) {
        defaultValues = {
            ...defaultValues,
            applicationDate: formDataInEditMode?.applicationDate || "",
            applicationNumber: formDataInEditMode?.applicationNumber || "",
            customerName: formDataInEditMode?.customerName || "",
            productType: formDataInEditMode?.productType || "",
            // customerPhone: formDataInEditMode?.customerPhone || "",
            loanBank: formDataInEditMode?.loanBank || "",
            loanAmount: formDataInEditMode?.loanAmount || 0,
            leadManager: formDataInEditMode?.leadManager || 1,
            loanState: formDataInEditMode?.loanState || "",
            location: formDataInEditMode?.location || "",
            loginStatus: formDataInEditMode?.loginStatus || "",
            connectorPayoutOption: formDataInEditMode?.connectorPayoutOption || "",
        };
    }

    useEffect(() => {
        reset(defaultValues);
    }, [isEditMode]);

    const {
        handleSubmit,
        register,
        control,
        setValue,
        reset,
        watch,
        getValues,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver,
    });

    useEffect(() => {
        if (openLead === false && isEditMode === true) {
            setFormDataInEditMode(null);
            setEditMode(false);
        }
    }, [openLead]);

    useEffect(() => {
        if (invokeGetLeadsApi) {
            setOpenLead(false);
            reset();
            setTimeout(() => {
                dispatch(resetLeads());
            }, 2000);
        }
        return () => { };
    }, [invokeGetLeadsApi]);

    const handleAddLead = () => {
        setOpenLead(true);
        setEditMode(false);
        setFormDataInEditMode(null);
        reset(defaultValues);
    };
    const handleEditClick = (data) => {
        setOpenLead(true);
        setEditMode(true);
        setFormDataInEditMode(data);
        setApplicationNumber(data?.applicationNumber);
        setApplicationDate(moment(data?.applicationDate).format('YYYY-MM-DD'));
    };

    return (
        <div className="sidenav-content">
            <div className="row my-2">
                <div className="col-md-6">
                    <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        className="leads-heading"
                    >
                        Leads Data
                    </Typography>
                </div>
            </div>

            <LeadsTable handleEditClick={handleEditClick} />
        </div>
    );
}

export default ConnectorManagerLeads;
