import ArticleIcon from '@mui/icons-material/Article';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';
import ReportIndex from "./ReportIndex";
import UploadIndex from './UploadIndex';

const RoboReport = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className="div-center-flex">
                <Tabs value={value} onChange={handleChange}>
                    <Tab icon={<ArticleIcon />} label="HDFC Status Report" />
                    <Tab icon={<CloudUploadIcon />} label="Upload" />
                </Tabs>
            </div>
            {value === 0 && <ReportIndex />}
            {value === 1 && <UploadIndex />}
        </>
    )
}
export default RoboReport;
