import React from "react";
import {
  CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis,
  YAxis
} from 'recharts';



function Chart1({ input_data }) {
  const finalData = (data) => {
    // if (data) {
    //   let month_days = Array.from(Array(31).keys())
    //   let months = Object.keys(data).sort().reverse()
    //   let current = data[months[0]], previous = data[months[1]]
    //   let result = []
    //   month_days.forEach(day => {
    //     let today_obj = {}
    //     let today = current.find(x => x.day === day)
    //     let today_lm = previous.find(x => x.day === day)
    //     today_obj.name = `Day: ${day + 1}`
    //     today_obj.currentMonth = today ? today.totalConnectorPayout : 0
    //     today_obj.previousMonth = today_lm ? today_lm.totalConnectorPayout : 0
    //     result.push(today_obj)
    //   })
    //   return result
    return []
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={input_data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="0" />
        <XAxis dataKey="name" tick={false} domain={['', '']} label='day of months' />
        <YAxis label={{ value: 'number of payouts', angle: -90, position: 'insideLeft' }}>
        </YAxis>
        <Tooltip />
        <Legend />
        <Line dot={false} strokeWidth={4} type="none" name="Previous Month" dataKey="previousMonth" stroke="#F06292" />
        <Line dot={false} strokeWidth={4} type="none" name="Current Month" dataKey="currentMonth" stroke="#26C6DA" />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default Chart1;
