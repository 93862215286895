import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation, useRouteMatch } from "react-router-dom";

const active = {
  background: "white",
  borderRadius: "5px",
  borderRight: 6,
  borderColor: "#40c4ff",
  '&:hover': {
    background: 'white',
  },
};
const nonActive = { borderRadius: "5px", color: "white" };

export default function ConnectorManagerList({ handleDrawerToggle }) {
  let location = useLocation();
  let match = useRouteMatch();
  const { pathname } = location;

  return (
    <div>
      <List sx={{ paddingLeft: '0px' }}>

        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="leads" />
          )}
        >
          <ListItemButton
            sx={pathname === "/manager/leads" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={pathname === "/manager/leads" ? {} : { color: "white" }}
            >
              <PersonOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Leads" />
          </ListItemButton>
        </ListItem>

        <ListItem
          component={({ innerRef, ...props }) => (
            <Link {...props} to="add-DigiRep" />
          )}
        >
          <ListItemButton
            sx={pathname === "/manager/add-DigiRep" ? active : nonActive}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon
              sx={pathname === "/manager/add-DigiRep" ? {} : { color: "white" }}
            >
              <PersonAddOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Add DigiRep" />
          </ListItemButton>
        </ListItem>

      </List>
    </div>
  );
}
