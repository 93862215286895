import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ApiUrl } from "../../helpers/getUrl";
export default function CompletedLeads() {

    const [result, setResult] = useState({
        'data': [],
        'total': 0
    });
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let param = {
            "limit": pageSize,
            "orderBy": "createdOn",
            "orderDirection": "DESC",
            "page": page,
            "search": {}
        }
        setLoading(true);
        axios.post(`${ApiUrl()}redemption/archive`, param)
            .then(res => {
                setLoading(false);
                setResult(res.data);
            }).catch(error => {
                setLoading(false);
            });
    }, [page, pageSize]);

    const handleDownload = () => {
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = "connector-payout.csv"


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(ApiUrl() + 'redemption/completedRedemptionDownload', requestOptions)
            .then(async response => {
                const blob = await response.blob();
                const newBlob = new Blob([blob]);
                const blobUrl = window.URL.createObjectURL(newBlob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', `completed-payments-${new Date().toLocaleDateString()}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(blob);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    };

    let columns = [
        {
            headerName: "Payment Month", field: "paymentMonth", width: 150,
            valueGetter: (params) => {
                const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                const d = new Date(params?.row?.createdOn);
                return monthNames[d.getMonth()];
            }
        },
        {
            headerName: "DigiRep ID", field: "connectorId", width: 130,
            valueGetter: (params) => {
                return params?.row?.account?.connectorId;
            }
        },
        { headerName: "DigiRep Name", field: "connectorName", width: 180 },
        { headerName: "DigiRep Email", field: "connectorEmail", width: 200 },
        {
            headerName: "Account Holder Name", field: "accountHolderName", width: 220,
            valueGetter: (params) => {
                return params?.row?.account?.accountHolderName;
            }
        },
        {
            headerName: "Account Number", field: "accountNumber", width: 200,
            valueGetter: (params) => {
                return params?.row?.account?.accountNumber;
            }
        },
        {
            headerName: "Bank", field: "bankName", width: 240,
            valueGetter: (params) => {
                return params?.row?.account?.bankName;
            }
        },
        {
            headerName: "IFSC Code", field: "ifsc", width: 150,
            valueGetter: (params) => {
                return params?.row?.account?.ifsc;
            }
        },
        {
            headerName: "PAN Number", field: "pan", width: 150,
            valueGetter: (params) => {
                return params?.row?.account?.pan;
            }
        },
        {
            headerName: "Net Payout", field: "amount", width: 180,
            valueGetter: (params) => {
                const amt = parseFloat(params?.row?.account?.amount);
                return amt.toFixed(2);
            }
        },
    ];

    return (
        <div className="sidenav-content">
            <div className="row">
                <div
                    style={{ height: 500, maxWidth: "100%" }}
                    className="col-md-12 leads-table"
                >
                    <div className="row mb-2">
                        <div className="col-6">
                            <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                Completed Payments
                            </Typography>
                        </div>
                        <div className="col-6">
                            <Button
                                sx={{ boxShadow: 2, float: 'right' }}
                                variant="contained"
                                startIcon={<ArrowDownwardIcon />}
                                onClick={handleDownload}
                            >
                                Download
                            </Button>
                        </div>
                    </div>


                    <DataGrid
                        rows={result.data}
                        getRowId={(e) => e._id}
                        columns={columns}
                        pagination
                        pageSize={pageSize}
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        rowCount={result.total?.totalRecords}
                        height
                        rowHeight={40}
                        paginationMode="server"
                        onPageChange={(newPage) => setPage(newPage)}
                        onPageSizeChange={(newPageSize) => {
                            setPage(0);
                            setPageSize(newPageSize);
                        }}
                        loading={loading}
                        disableSelectionOnClick
                    />
                </div>
            </div>
        </div>
    );
}
