import AddIcon from '@mui/icons-material/Add';
import EditIcon from "@mui/icons-material/Edit";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import { useState } from "react";
import { ApiUrl } from "../../helpers/getUrl";
import AddExpenseForm from "./AddExpenseForm";
import moment from "moment";

const AddExpense = ({ addExpense, setAddExpense, setSuccessMsg, setErrorMsg }) => {

    const handleOpenDialog = () => {
        setShowForm(false)
        setData([])
        setisEdit(false)
        setAddExpense(false)
    }
    const [showForm, setShowForm] = useState(false);
    const [data, setData] = useState([]);
    const [isEdit, setisEdit] = useState({});

    const handleEdit = (data, id) => {
        const item = { ...data, id }
        setisEdit(item);
        setShowForm(true)
    }

    const handleSubmit = () => {

        const { finadminEmail } = JSON.parse(sessionStorage.getItem("data"));
        const formData = data.map((item) => {
            return {
                "month": new Date(item.month).getMonth(),
                "year": new Date(item.month).getFullYear(),
                "category": item.category,
                "grossAmount": parseInt(item.grossAmount),
                "tds": parseInt(item.tds),
                "cgst": parseInt(item.cgst),
                "sgst": parseInt(item.sgst),
                "igst": parseInt(item.igst),
                "isTdsPaid": item.isTdsPaid === "Yes" ? true : false,
                paidOn: item.paidOn,
                "netAmount": item.netAmount,
                "remark": item.remark,
                "createdBy": finadminEmail
            }
        })
        axios.post(ApiUrl() + 'expense', formData).then((response) => {
            handleOpenDialog();
            setSuccessMsg('Expense Information has been saved')
        }).catch((error) => {
            console.log(error)
            setErrorMsg('There was an error in saving Expense Information')
        })
    }

    return (

        <Dialog
            fullWidth
            maxWidth="md"
            open={addExpense} onClose={handleOpenDialog}>
            <DialogTitle >Add Expenses</DialogTitle>
            <DialogContent sx={{ paddingTop: 0, marginTop: "10px" }}>

                <Button
                    startIcon={<AddIcon />}
                    onClick={() => setShowForm(true)}
                    variant="contained"
                    style={{ float: 'right', margin: '15px' }}
                    className={!showForm ? '' : 'd-none'}
                >
                    Add
                </Button>
                {showForm && <AddExpenseForm data={data} isEdit={isEdit} setisEdit={setisEdit} setData={setData} setShowForm={setShowForm} />}
                <Paper sx={{ width: '100%', overflow: 'auto', margin: '1rem 0' }}>
                    <TableContainer >
                        <Table stickyHeader aria-label="data table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Month & Year</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>TDS</TableCell>
                                    <TableCell>IGST</TableCell>
                                    <TableCell>CGST</TableCell>
                                    <TableCell>SGST</TableCell>
                                    <TableCell>TDS Payment Status</TableCell>
                                    <TableCell>Paid On</TableCell>
                                    <TableCell>Net Amount</TableCell>
                                    <TableCell>Remarks</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data.map((data, idx) => (
                                        <TableRow hover tabIndex={-1} key={idx}>
                                            <TableCell>{data.month}</TableCell>
                                            <TableCell>{data.category}</TableCell>
                                            <TableCell>{data.grossAmount}</TableCell>
                                            <TableCell>{data.tds}</TableCell>
                                            <TableCell>{data.igst}</TableCell>
                                            <TableCell>{data.cgst}</TableCell>
                                            <TableCell>{data.sgst}</TableCell>
                                            <TableCell>{data.isTdsPaid}</TableCell>
                                            <TableCell>{moment(data?.paidOn)?.format("DD/MM/YYYY")}</TableCell>
                                            <TableCell>{data.netAmount}</TableCell>
                                            <TableCell>{data.remark}</TableCell>
                                            <TableCell><GridActionsCellItem
                                                icon={<EditIcon />}
                                                label="Edit"
                                                onClick={() => { handleEdit(data, idx) }}
                                                color="inherit"
                                            /></TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </DialogContent>
            <DialogActions sx={{ padding: "0 30px 20px 0" }}>
                <Button variant="text" sx={{ boxShadow: 2 }} onClick={handleOpenDialog}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    disabled={data.length === 0}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog >
        // </div>

    )
}
export default AddExpense;