export const advancePaymentPossibleStatus = {
    PENDING_WITH_CEO: 'PENDING_WITH_CEO',
    PENDING_FOR_CONNECTOR_ACKNOWLEDGEMENT: 'PENDING_FOR_CONNECTOR_ACKNOWLEDGEMENT',
    PAYMENT_PENDING: 'PAYMENT_PENDING',
    PAYMENT_PROCESSED: 'PAYMENT_PROCESSED',
    PAYMENT_PAID: 'PAYMENT_PAID',
    REJECTED: 'REJECTED'
};
export const statusColors = {
    SUCCESS: { color: "#32C552", fontSize: "14px" },
    PENDING: { color: "#FF9141", fontSize: "14px" },
    ERROR: { color: "#F56363", fontSize: "14px" }
}

export const enumsArr = [
    {
        label: "CEO Decision Pending",
        color: "pending",
        status: advancePaymentPossibleStatus.PENDING_WITH_CEO
    },
    {
        label: "Payment Pending",
        color: "pending",
        status: advancePaymentPossibleStatus.PAYMENT_PENDING
    },
    {
        label: "DigiRep Decision Pending",
        color: "pending",
        status: advancePaymentPossibleStatus.PENDING_FOR_CONNECTOR_ACKNOWLEDGEMENT
    },
    {
        label: "Approved",
        color: "success",
        status: advancePaymentPossibleStatus.PAYMENT_PROCESSED
    },
    {
        label: "Payment Paid",
        color: "success",
        status: advancePaymentPossibleStatus.PAYMENT_PAID
    },
    {
        label: "Declined",
        color: "error",
        status: advancePaymentPossibleStatus.REJECTED
    },
]

export const paymentStatus = {
    APPROVED: "processed",
    PENDING: "PENDING",
    REJECTED: "REJECTED",
}
export const spotPaymentStatus = [
    {
        label: "Completed",
        color: "success",
        status: 'completed',
    },
    {
        label: "Processed",
        color: "success",
        status: "processed"
    },
    {
        label: "Not Processed",
        color: "error",
        status: 'notProcessed'
    },
    {
        label: "Review Pending",
        color: "pending",
        status: 'reviewPending'
    },

]
export const spotPaymentStatusEnums = {
    PROCESSED: 'processed',
    COMPLETED: 'completed',
    PENDING: 'pending',
    NOT_PROCESSED: 'notProcessed',
}

export const transactionTypes = {
    ADVANCE_PAYMENT: 'ADVANCE_PAYMENT',
    ADVANCE_RECEIPT: 'ADVANCE_RECEIPT',
}
export const transactionEnums = {
    PAYMENT: 'Payment',
    RECEIPT: 'Receipt',
}

export const leadListStatus = [
    {
        label: "Completed",
        color: "success",
        status: 'SUCCESS',
    },
    {
        label: "Failed",
        color: "error",
        status: 'FAIL',
    },
]