import { createSlice } from "@reduxjs/toolkit";
import { monthNumberToLabelMap } from "../../constants/appconstants";
import { httpGet } from "../../utils/Rest";
import _ from "lodash";

const initialData = {
  periodicProjectionsData: [],
  adminPeriodicProjectionsData: {},
  isLoading: false,
  errorMsg: null,
  successMsg: null,
};

const slice = createSlice({
  name: "PeriodicProjection", //Reference Name, It can be anything
  initialState: {
    ...initialData,
  },
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true;
      state.errorMsg = null;
    },
    updatePeriodicProjectionsData: (state, action) => {
      state.periodicProjectionsData = action.payload;
      state.isLoading = false;
      state.errorMsg = null;
    },
    updatePeriodicAdminProjectionsData: (state, action) => {
      state.adminPeriodicProjectionsData = action.payload;
      state.isLoading = false;
      state.errorMsg = null;
    },
    fetchError: (state, action) => {
      state.errorMsg = action?.payload;
      state.isLoading = false;
    },
    fetchSuccess: (state, action) => {
      state.successMsg = action.payload;
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

// Actions

const {
  updatePeriodicProjectionsData,
  updatePeriodicAdminProjectionsData,
  fetchStart,
  fetchError,
  fetchSuccess,
} = slice.actions;

export const fetchPeriodicProjections = (
  connectorId,
  productType,
  freqValue
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    let url = `projection/periodic/connector/${connectorId}`;
    url = productType || freqValue ? `${url}?` : url;
    url = productType ? url + `product=${productType}` : url;
    url = freqValue ? `${url}&` : url;
    url = url + `timeframe=${freqValue}`;

    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          let result = _.sortBy(res.data, ['year', 'month']);
          dispatch(updatePeriodicProjectionsData(result));
          dispatch(fetchSuccess());
        } else {
          dispatch(fetchError(res.message));
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};



export const fetchAdminPeriodicProjections = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    let url = `projection/periodic/finadmin`;
    httpGet(url)
      .then((res) => {
        if (res?.status === 200) {
          let result = res.data;
          result.dataPerMonth.forEach(element => {
            element.monthName = monthNumberToLabelMap[element.month]
          });
          dispatch(updatePeriodicAdminProjectionsData(result));
          dispatch(fetchSuccess());
        } else {
          dispatch(fetchError(res.message));
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};
