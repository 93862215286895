import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import { ApiUrl } from '../../helpers/getUrl';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';



function createData(SNo, BankName, LoanAmount, ROI) {
  return { SNo, BankName, LoanAmount, ROI };
}

const rows = [
  createData(1, 'Bank of Baroda', 536489, 9.8),

];

export default function Order(props) {

  const bankList = ['HDFC', 'AXIS', 'ICICI', 'IDFC'];
  const uniqueBanks = [...new Map(props.result.map(item =>
    [item['bankName'], item])).values()];
    console.log(uniqueBanks)

  const [offer, setOffer] = useState(null);

  const onToggleVisible = (bankName) => {
    console.log(bankName);
    axios.get(`${ApiUrl()}enquiry/${bankName}`, { responseType: 'blob' })
      .then(response => {
        if (response.status === 200) {
          let raw = response.data;
          setOffer(URL.createObjectURL(raw));
        }
      })
      .catch(error => {
        console.error(error);
      });
  }


  return (
    <React.Fragment>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table stickyHeader size='medium' >
            <TableHead>
              <TableRow>
                <TableCell >S. No.</TableCell>
                <TableCell >Bank Name</TableCell>
                <TableCell >Loan Amount</TableCell>
                <TableCell >ROI</TableCell>
                <TableCell >Offer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props && uniqueBanks.map((row, id) => (
                <TableRow key={row.id}>
                  <TableCell>{id + 1}</TableCell>
                  <TableCell>{row.bankName}</TableCell>
                  <TableCell>{row.loanAmount}</TableCell>
                  <TableCell>{row.ROI}</TableCell>
                  <TableCell>
                    {bankList.includes(row.bankName) && <IconButton
                      aria-label="done"
                      onClick={() => onToggleVisible(row.bankName)}
                      style={{ color: "#1ecef4" }}
                    >
                      <VisibilityIcon />
                    </IconButton>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog open={offer} onClose={() => { setOffer(null) }} fullWidth maxWidth="md">
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => { setOffer(null) }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img src={offer} alt="image" />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}