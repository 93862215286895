import AdjustIcon from "@mui/icons-material/Adjust";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Chip,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  enumsArr,
  statusColors,
  transactionEnums,
  transactionTypes,
} from "../../../../helpers/constants";
import { ApiUrl } from "../../../../helpers/getUrl";
import { BootstrapButton } from "../../BootstrapButton";
import { ConfirmationDialog } from "../../ConfirmationDialog";
import UpdatePaymentDialog from "../../UpdatePaymentDialog";
import { AdvancePaymentGridV2 as AdvanceGrid } from "../../Connector/Advance/AdvancePayoutGridV2";
import { errorHandler } from "../../../../helpers/errorHandler";
import { ErrorSnackbar } from "../../../../components/CustomSnackBar";

export const AdvancePaymentGrid = ({
  level,
  setLevel,
  refreshData,
  setTotalAdvances,
}) => {
  // const today = moment(new Date()).format("YYYY-MM-DD");
  const [confirm, setConfirm] = useState({ show: false, reason: null });
  const [display, setDisplay] = useState({
    show: false,
    data: [],
  });
  const [connectorNameData, setConnectorNameData] = useState("");
  const [connectorIdData, setConnectorIdData] = useState("");

  const [data, setData] = useState(null);
  // const [startDate, setStartDate] = useState(today);
  // const [endDate, setEndDate] = useState(today);
  const [searchAmount, setSearchAmount] = useState("");
  // const [transactionType, setTransactionType] = useState("RECEIPT");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [count, setCount] = useState(0);
  const [updateModal, setUpdateModal] = useState(false);
  // let localData = JSON.parse(sessionStorage.getItem('data'));
  // const { connectorId } = localData || {};
  const { SUCCESS, PENDING, ERROR } = statusColors;
  const { ADVANCE_PAYMENT, ADVANCE_RECEIPT } = transactionTypes;
  const { PAYMENT, RECEIPT } = transactionEnums;
  const [errorMsg, setErrorMsg] = useState({ state: false, msg: null });
  const [connectorId, setConnectorId] = useState(null);
  const [connectorPhone, setConnectorPhone] = useState(null);
  const [transactionType, setTransactionType] = useState(null);
  const [connectorList, setConnectorList] = useState([]);

  const handleModal = () => {
    setDisplay({
      show: false,
      data: [],
    });
    setUpdateModal(false);
  };

  useEffect(() => {
    if (!updateModal) {
      getData();
      getConnectorList();
    }
  }, [updateModal, rowsPerPage, page, refreshData]);

  function getData() {
    const formData = {
      limit: rowsPerPage,
      orderBy: "createdOn",
      orderDirection: "DESC",
      page: page + 1,
      search: {
        transactionType: transactionType,
        connectorPhone: connectorPhone,
        connectorId: connectorId,
      },
    };
    if (searchAmount !== null && searchAmount !== "") {
      formData.search.approvedAmount = parseInt(searchAmount);
    }
    let url = `${ApiUrl()}`.replace("v1", "v2");

    axios
      .post(url + `advance/history/list`, formData)
      .then((res) => {
        // console.log("advance history list in admin: ", res.data.data);
        if (res.status === 200) {
          setData(res.data.data);
          setCount(res.data.count);
          setTotalAdvances(res.data.totalAdvances);
        }
      })
      .catch((error) => {
        let errMsg = errorHandler(error);
        setErrorMsg({ state: true, msg: errMsg });
      });
  }

  const getConnectorList = () => {
    const formData = {
      limit: 3500,
      orderBy: "createdOn",
      orderDirection: "DESC",
      page: 0,
    };
    axios
      .post(ApiUrl() + `connector/list`, formData)
      .then((res) => {
        setConnectorList(res.data.data);
      })
      .catch((error) => {});
  };

  const handleChangePage = (event, newPage) => {
    // console.log("page: ", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log("limit: ", event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getStatus = (status) => {
    var result = enumsArr.filter((obj) => {
      return obj.status === status;
    });

    return (
      <Chip
        label={result[0].status && result[0].label}
        variant="outlined"
        icon={
          <AdjustIcon
            style={
              result[0].color === "success"
                ? SUCCESS
                : result[0].color === "pending"
                ? PENDING
                : ERROR
            }
          />
        }
      />
    );
  };
  return (
    <>
      {/* LEVEL 1 */}
      {level === "L1" && (
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            {/* <Typography color="text.primary">Advance History</Typography> */}
          </Breadcrumbs>
          <Grid container spacing={2} marginBottom={5}>
            {" "}
            <Grid item xs={12} lg={3}>
              <InputLabel htmlFor="component-disabled">
                Connector Name
              </InputLabel>
              <Autocomplete
                options={connectorList}
                getOptionLabel={(option) =>
                  option.connectorName + " " + option.connectorPhone
                }
                getOptionSelected={(option, value) =>
                  option.connectorId === value.connectorId
                }
                onChange={(event, newValue) => {
                  newValue
                    ? setConnectorId(newValue?.connectorId)
                    : setConnectorId(null);
                }}
                id="connectorName"
                className="outlined-input w-100"
                placeholder="Connector Name"
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <InputLabel htmlFor="component-disabled">Mobile No.</InputLabel>
              <OutlinedInput
                id="outlined-basic"
                label="Outlined"
                variant="standard"
                className="w-100 outlined-input"
                value={connectorPhone}
                onChange={(e) => setConnectorPhone(e.target.value)}
                type="number"
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <InputLabel htmlFor="component-disabled">
                Transaction type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="w-100 outlined-input"
                value={transactionType}
                onChange={(e) => setTransactionType(e.target.value)}
              >
                <MenuItem value="ADVANCE_RECEIPT">Receipt</MenuItem>
                <MenuItem value="ADVANCE_PAYMENT">Payment</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} lg={3} container style={{ maxWidth: "100%" }}>
              <BootstrapButton
                mr={2}
                aria-selected={"true"}
                style={{
                  borderRadius: "12px",
                  marginTop: "16px",
                  width: "100%",
                }}
                onClick={getData}
              >
                Search
              </BootstrapButton>
            </Grid>
          </Grid>
          <Grid container paddingTop={0}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Digirep Name</TableCell>
                      <TableCell align="center">Location</TableCell>
                      <TableCell align="center">Outstanding amt</TableCell>
                      <TableCell align="center">
                        Last Transaction Date
                      </TableCell>
                      <TableCell align="center">
                        Last Transaction Type
                      </TableCell>
                      <TableCell align="center">Aging</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!data ? (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <CircularProgress />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {data?.map((row, idx) => (
                      <TableRow
                        hover
                        onClick={() => {
                          setLevel("L2");
                          setConnectorNameData(row.connectorName);
                          setConnectorIdData(row.connectorId);
                        }}
                        key={idx}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.connectorName}
                        </TableCell>
                        <TableCell align="center">{row.location}</TableCell>
                        <TableCell align="center">
                          {row.currentOutstandingAmount?.cur()}
                        </TableCell>
                        <TableCell align="center">
                          {moment(row.lastTransactionDate).format(
                            "DD MMM YYYY"
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row.lastTransactionType === ADVANCE_PAYMENT
                            ? PAYMENT
                            : row.lastTransactionType === ADVANCE_RECEIPT
                            ? RECEIPT
                            : "-"}
                        </TableCell>
                        <TableCell align="center">{row.aging} Days</TableCell>
                        <TableCell align="center">
                          <BootstrapButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setDisplay({
                                show: true,
                                data: row,
                              });
                              setUpdateModal(true);
                            }}
                          >
                            Update Payment
                          </BootstrapButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    {data && data.length === 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan={6}>
                          No data found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                component="div"
                className="custom_pagination"
                count={count || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </div>
      )}
      {/* LEVEL 2 */}
      {level === "L2" && (
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              to="#"
              onClick={() => {
                setLevel("L1");
              }}
            >
              Advance Ledger
            </Link>
            <Typography color="text.primary">{connectorNameData}</Typography>
          </Breadcrumbs>
          {/* <Grid pt={3} container spacing={2}>
                    <Grid item xs={12} lg={3} >
                        <InputLabel htmlFor="component-disabled">Transaction type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className='w-100 outlined-input'
                            value={transactionType}
                            onChange={(e) => setTransactionType(e.target.value)}
                        >
                            <MenuItem value="RECEIPT">Receipt</MenuItem>
                            <MenuItem value="PAYMENT">Payment</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <InputLabel htmlFor="component-disabled">Amount</InputLabel>
                        <OutlinedInput
                            id="outlined-basic"
                            label="Outlined"
                            variant="standard"
                            className="w-100 outlined-input"
                            value={searchAmount}
                            onChange={(e) => setSearchAmount(e.target.value)}
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <InputLabel htmlFor="component-disabled">From</InputLabel>
                        <OutlinedInput id="outlined-basic" label="Outlined" type="date" variant="standard" aria-label="From" className="outlined-input w-100" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <InputLabel htmlFor="component-disabled">To</InputLabel>
                        <OutlinedInput id="outlined-basic" label="Outlined" type="date" variant="standard" className="outlined-input w-100" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </Grid>
                    <Grid item xs={12} lg={2} container style={{ maxWidth: '100%' }}>
                        <BootstrapButton mr={2} aria-selected={'true'} style={{ borderRadius: "12px", marginTop: "16px", width: "100%" }} onClick={getData}>Search</BootstrapButton>
                    </Grid>
                </Grid> */}
          <AdvanceGrid connectorIdData={connectorIdData} ackColumn="hide" />
        </div>
      )}
      <ConfirmationDialog open={confirm} setOpen={setConfirm} />
      <UpdatePaymentDialog display={display} handleModal={handleModal} />
      {errorMsg.state && <ErrorSnackbar errorMsg={errorMsg.msg} />}
    </>
  );
};
