import { yupResolver } from "@hookform/resolvers/yup";
import GetAppIcon from '@mui/icons-material/GetApp';
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import moment from 'moment';
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import AppContextProvider from "../../components/contextProvider/AppContextProvider/AppContext";
import { ErrorSnackbar, SuccessSnackbar } from "../../components/CustomSnackBar";
import PageLoader from "../../components/PageLoader";
import { bankCodeConstants, bankNameConstants, loanStatusConstants, payoutStatusConstants, productConstants, redeemedStatusConstants, stateConstants, stateNameToCodeMapping } from "../../constants/appconstants";
import {
  postLeadsData,
  putLeadsData,
  resetLeads
} from "../../redux/reducers/LeadsDataService";
import AdminLeadDownload from "./AdminLeadDownload";
import AdminLeadsTable from "./AdminLeadsTable";
import BulkUpload from "./bulkUpload";
import TemplateDownload from "./TemplateDownload";

const validationSchema = yup.object({
  // Connector Details
  connectorName: yup.string().required("DigiRep Name is required"),
  connectorPhone: yup.string().required("DigiRep Phone is required"),
  connectorPayoutOption: yup.string().required("Payout Option is required"),
  connectorEmail: yup.string().required("DigiRep Email is required"),

  // Loan Details
  productType: yup.string().required("Product Type is required"),
  customerName: yup.string().required("Customer Name is required"),
  customerPhone: yup
    .string()
    .test(
      "len",
      "Please enter valid mobile number",
      (val) => val.toString().length == 10 || val.toString().length == 0
    ),
  // .required("Phone Number is Required"),
  loanBank: yup.string().required("Bank Name is required"),
  loanAmount: yup
    .string()
    .required("Loan Amount is required"),
  loanState: yup.string().required("State is required"),
  lanNumber: yup.string().notRequired()
    .matches(/^[a-zA-Z0-9]{0,40}$/, "Must be alphanumeric"),
  applicationNumber: yup.string(),
  applicationDate: yup.string().when('applicationNumber', {
    is: (applicationNumber) => { return applicationNumber.length > 0; },
    then: yup.string().required("Application Date is required")
  }),
});

const status_map = (data) => {
  return data.filter(x => x !== 'processed' && x !== 'completed')
}

const useStyles = makeStyles(() => ({
  tableCell: { textAlign: "center" },
  // select: {
  //   width: "200px",
  //   margin: "5px",
  // },
  // textField: {
  //   marginLeft: '30px'
  // },
  formControl: {
    padding: "5px",
    width: "250px",
  },
}));

function Leads() {
  const classes = useStyles();
  let localData = JSON.parse(sessionStorage.getItem('user'));
  const { userEmail } = localData || {};
  const { connectorId } = useContext(AppContextProvider);
  const dispatch = useDispatch(); // used to dispatch an action

  const [openLeadDownload, setOpenLeadDownload] = useState(false);
  const [openLead, setOpenLead] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [formDataInEditMode, setFormDataInEditMode] = useState(null);

  const [bankPayoutAmount, setBankPayoutAmount] = useState(0);
  const [connectorPayoutAmount, setConnectorPayoutAmount] = useState(0);
  const [grossPayoutAmount, setGrossPayoutAmount] = useState(0);
  const [applicationDate, setApplicationDate] = useState("");
  const [disbursedDate, setDisbursedDate] = useState("");
  const [applicationNumber, setApplicationNumber] = useState("");
  const [connectorPayoutPercentage, setConnectorPayoutPercentage] = useState(0);
  const [bankPayoutReceivedDate, setBankPayoutReceivedDate] = useState("");
  const [bankPayoutTDSPercent, setbankPayoutTDSPercent] = useState(0);

  const { isLoading, successMsg, errorMsg, invokeGetLeadsApi } = useSelector(
    ({ leadsDataService }) => leadsDataService
  );

  const resolver = yupResolver(validationSchema);
  const today = moment(new Date()).format('YYYY-MM-DD');
  let defaultValues = {
    connectorName: "",
    connectorPhone: "",
    connectorPayoutOption: "MONTHLY",
    leadManager: 1,

    // customer details
    customerName: "",
    customerPhone: "",
    location: "",

    // Loan details
    productType: "",
    loanStatus: "OPEN",
    applicationNumber: "",
    loanBank: "",
    loanAmount: "",
    lanNumber: "",

    // Redeemed details
    bankPayoutAmount: 0,
    bankPayoutPercentage: 0,
    remarks: "",

    applicationDate: "",
    disbursementAmount: 0,
    disbursementDate: "",

    connectorPayoutPercentage: 0,
    connectorPayoutAmount: 0,
    grossPayoutAmount: 0,
    connectorPayoutDate: "",
    connectorPayoutStatus: 'notProcessed'
  };

  if (isEditMode && formDataInEditMode?.leadId) {
    defaultValues = {
      connectorName: formDataInEditMode?.connectorName || "",
      connectorPhone: formDataInEditMode?.connectorPhone || "",
      connectorEmail: formDataInEditMode?.connectorEmail || "",
      connectorPayoutOption: formDataInEditMode?.connectorPayoutOption || "MONTHLY",
      leadManager: formDataInEditMode?.leadManager || 1,

      // customer details
      customerName: formDataInEditMode?.customerName || "",
      customerPhone: formDataInEditMode?.customerPhone || "",
      location: formDataInEditMode?.location || "",

      // Loan details
      productType: formDataInEditMode?.productType || "",
      loanStatus: formDataInEditMode?.loanStatus || "OPEN",
      applicationNumber: formDataInEditMode?.applicationNumber || "",
      loanBank: bankCodeConstants[formDataInEditMode?.loanBank] || "",
      loanAmount: formDataInEditMode?.loanAmount || "",
      lanNumber: formDataInEditMode?.lanNumber || "",
      loanState: formDataInEditMode?.loanState || "",

      // Redeemed details
      bankPayoutAmount: formDataInEditMode?.bankPayoutAmount || 0,
      bankPayoutPercentage: formDataInEditMode?.bankPayoutPercentage || 0,
      remarks: formDataInEditMode?.remarks || "",

      applicationDate: formDataInEditMode?.applicationDate || "",
      disbursementAmount: formDataInEditMode?.disbursementAmount || 0,
      disbursementDate: formDataInEditMode?.disbursementDate || "",

      connectorPayoutPercentage: formDataInEditMode?.connectorPayoutPercentage || 0,
      connectorPayoutAmount: formDataInEditMode?.connectorPayoutAmount || 0,
      connectorPayoutDate: formDataInEditMode?.connectorPayoutDate || "",
      connectorPayoutStatus: formDataInEditMode?.connectorPayoutStatus || "",

      // Payout Details
      bankPayoutTDSPercent: formDataInEditMode?.bankPayoutTDSPercent || 0,
      bankPayoutTDSAmount: formDataInEditMode?.bankPayoutTDSAmount || 0,
      bankPayoutNetAmount: formDataInEditMode?.bankPayoutNetAmount || 0,
    };
  }

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  useEffect(() => {
    if (openLead === false && isEditMode === true) {
      setFormDataInEditMode(null);
      setEditMode(false);
    }
  }, [openLead])

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver,
  });


  useEffect(() => {
    if (bankPayoutTDSPercent !== undefined) {
      const bankPayoutNetAmount = bankPayoutAmount - (parseInt(bankPayoutTDSPercent / 100 * bankPayoutAmount));
      const bankPayoutTDSAmount = (parseInt(bankPayoutTDSPercent) / 100 * bankPayoutAmount);

      setValue('bankPayoutNetAmount', bankPayoutNetAmount)
      setValue('bankPayoutTDSAmount', bankPayoutTDSAmount)
    }
  }, [bankPayoutTDSPercent, bankPayoutAmount, register]);

  useEffect(() => {
    if (invokeGetLeadsApi) {
      setOpenLead(false);
      reset();
      setTimeout(() => {
        dispatch(resetLeads());
      }, 2000);
    }
    return () => { };
  }, [invokeGetLeadsApi]);

  const handleEditClick = (data) => {
    data.applicationDateFormat && setApplicationDate(data.applicationDateFormat.split("/").reverse().join("-"));
    data.disbursementDateFormat && setDisbursedDate(data.disbursementDateFormat.split("/").reverse().join("-"));
    data.bankPayoutReceivedDate && setBankPayoutReceivedDate(moment(data.bankPayoutReceivedDate).format("YYYY-MM-DD"));
    setFormDataInEditMode(data);
    setOpenLead(true);
    setEditMode(true);
    setBankPayoutAmount(data.bankPayoutAmount);
    setConnectorPayoutAmount(data.connectorPayoutAmount);
    setGrossPayoutAmount(data?.grossPayoutAmount || 0);
    setConnectorPayoutPercentage(data?.connectorPayoutPercentage || 0);
    setbankPayoutTDSPercent(data?.bankPayoutTDSPercent || 0)
  };

  const handleClose = () => {
    setBankPayoutAmount(0);
    setConnectorPayoutAmount(0);
    setGrossPayoutAmount(0);
    setConnectorPayoutPercentage(0);
    setOpenLead(false);
    setEditMode(false);
    setFormDataInEditMode({});
    setbankPayoutTDSPercent(0);
    setBankPayoutReceivedDate("");
  };

  const onSubmit = (data) => {
    data.bankPayoutAmount = + bankPayoutAmount;
    data.connectorPayoutAmount = + connectorPayoutAmount;
    data.grossPayoutAmount = + grossPayoutAmount;
    data.disbursementAmount = + data.disbursementAmount;
    data.loanAmount = + data.loanAmount;

    let leadsData = {
      ...data,
      applicationDate: defaultValues.applicationDate && new Date(defaultValues.applicationDate),
      disbursementDate: defaultValues.disbursementDate && new Date(defaultValues.disbursementDate),
      connectorPayoutDate: defaultValues.connectorPayoutDate && new Date(defaultValues.connectorPayoutDate),
      loanStatus: data.applicationNumber !== '' ? 'APPLIED' : 'OPEN',
      loanState: stateNameToCodeMapping[data.loanState],
      loanBank: bankNameConstants[data.loanBank],
      connectorId,
      createdBy: userEmail,
      sourceType: "INTERNAL",
    };
    // Update add object
    data.loanStatus = data.applicationNumber !== '' ? 'APPLIED' : 'OPEN'
    data.loanStatus = data.disbursementAmount != 0 ? 'DISBURSED' : data.loanStatus
    data.connectorPayoutStatus = data.loanStatus == 'DISBURSED' ? 'reviewPending' : data.connectorPayoutStatus;
    data.loanState = stateNameToCodeMapping[data.loanState];
    data.loanBank = bankNameConstants[data.loanBank];
    // Update edit object
    leadsData.loanStatus = data.disbursementAmount != 0 ? 'DISBURSED' : leadsData.loanStatus
    leadsData.connectorPayoutStatus = data.loanStatus == 'DISBURSED' ? 'reviewPending' : leadsData.connectorPayoutStatus

    data.applicationDate = applicationDate && new Date(applicationDate);
    data.disbursementDate = disbursedDate && new Date(disbursedDate);
    data.connectorPayoutDate = defaultValues.connectorPayoutDate && new Date(defaultValues.connectorPayoutDate);
    data.disbursementDate = disbursedDate && new Date(disbursedDate);
    data.bankPayoutReceivedDate = bankPayoutReceivedDate && new Date(bankPayoutReceivedDate);

    if (isEditMode) {
      dispatch(putLeadsData(formDataInEditMode?.leadId, data));
      console.log("data: ", data);
    } else {
      dispatch(postLeadsData({ ...leadsData }));
    }
  };

  const handleApplicationDate = (e) => {
    setApplicationDate(e.target.value);
    setValue('applicationDate', e.target.value);
  }

  const onPickDate = (id) => {
    document.getElementById("ad").type = "date";
  }

  const handleApplicationNumber = (e) => {
    const value = e.target.value;
    setApplicationNumber(value);
    setApplicationDate(moment(new Date()).format('YYYY-MM-DD'));
    setValue('applicationNumber', value);
    if (!value) {
      setApplicationDate('');
      setValue('applicationDate', '');
    }
  }

  const handleInputChange = (event) => {
    const disbursementAmount = document.getElementById('disbursementAmount').value;
    const field = event.target;
    if (field.name === 'bankPayoutPercentage') {
      setBankPayoutAmount((disbursementAmount * field.value / 100).toFixed(3));
    } else if (field.name === 'connectorPayoutPercentage') {
      setConnectorPayoutPercentage(field.value)
      const gpa = disbursementAmount * field.value / 100;
      setGrossPayoutAmount(gpa.toFixed(3));
      setConnectorPayoutAmount((gpa - 0.05 * gpa).toFixed(3));
    } else if (field.name === 'disbursementAmount') {
      const bankPayoutPercentage = document.getElementById('bankPayoutPercentage').value;
      const connectorPayoutPercentage = document.getElementById('connectorPayoutPercentage').value;
      const gpa = disbursementAmount * connectorPayoutPercentage / 100;
      setGrossPayoutAmount(gpa.toFixed(3));
      setConnectorPayoutAmount((gpa - 0.05 * gpa).toFixed(3));
      setBankPayoutAmount((disbursementAmount * bankPayoutPercentage / 100).toFixed(3));
    } else if (field.name === 'applicationNumber') {
      setApplicationNumber(event.target.value)
    }
  }

  const addLeadElement = (
    <Dialog onBackdropClick="false" open={openLead} onClose={handleClose}>
      <DialogTitle>Lead Entry Module - DigiRep</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ paddingTop: 0, marginTop: "-20px" }}>
          <DialogTitle>DigiRep Details</DialogTitle>
          <div className="row form-fields mt-md-n5">
            {/* Connector Details */}
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  margin="dense"
                  {...register("connectorName")}
                  label="DigiRep Name"
                  fullWidth
                  variant="standard"
                  error={errors?.connectorName && errors?.connectorName}
                  helperText={errors?.connectorName?.message}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("connectorPhone")}
                  label="DigiRep Phone"
                  fullWidth
                  variant="standard"
                  error={errors?.connectorPhone && errors?.connectorPhone}
                  helperText={errors?.connectorPhone?.message}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("connectorEmail")}
                  label="DigiRep Email"
                  fullWidth
                  variant="standard"
                  error={errors?.connectorEmail && errors?.connectorEmail}
                  helperText={errors?.connectorEmail?.message}
                />
              </FormControl>
            </div>
            {/* <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("leadManager")}
                  label="Connector Manager"
                  fullWidth
                  variant="standard"
                  error={errors?.leadManager && errors?.leadManager}
                  helperText={errors?.leadManager?.message}
                  
                />
              </FormControl>
            </div> */}
            <div className="col-md-6">
              <FormControl
                variant="standard" className={classes.formControl}>
                <InputLabel>Payout Option</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  sx={{ marginTop: "20px" }}
                  {...register("connectorPayoutOption")}
                  label="DigiRep Payout Option"
                  fullWidth
                  defaultValue={watch("connectorPayoutOption")}
                  error={errors?.connectorPayoutOption && errors?.connectorPayoutOption}
                  helperText={errors?.connectorPayoutOption?.message}
                >
                  {Object.keys(payoutStatusConstants).map((x) => (
                    <MenuItem value={x}>{payoutStatusConstants[x]}</MenuItem>
                  ))}
                </Select>
                {errors?.payOutType?.message && (
                  <FormHelperText
                    error={errors?.payOutType && errors?.payOutType}
                  >
                    {errors?.payOutType?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            {/* Connector Details End*/}

            {/* Customer Details */}
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("customerName")}
                  label="Customer Name"
                  fullWidth
                  variant="standard"
                  error={errors?.customerName && errors?.customerName}
                  helperText={errors?.customerName?.message}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("customerPhone")}
                  label="Mobile Number"
                  fullWidth
                  variant="standard"
                  error={errors?.customerPhone && errors?.customerPhone}
                  helperText={errors?.customerPhone?.message}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <Autocomplete
                  disablePortal
                  options={Object.values(stateConstants)}
                  id="demo-simple-select-standard"
                  renderInput={(params) => <TextField
                    {...register("loanState")}
                    {...params} label="State" />}
                  defaultValue={stateConstants[watch("loanState")]}
                />
                {errors?.loanState?.message && (
                  <FormHelperText
                    error={errors?.loanState && errors?.loanState}
                  >
                    {errors?.loanState?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("location")}
                  label="Customer Location"
                  fullWidth
                  variant="standard"
                  error={errors?.location}
                  helperText={errors?.location?.message}
                />
              </FormControl>
            </div>
            {/* Customer Details End*/}

            {/* Loan Details Start */}
            <DialogTitle>Loan Details</DialogTitle>
            <div className="col-md-6">
              <FormControl
                variant="standard" className={classes.formControl}>
                <InputLabel id={"label-Product-Type"}>
                  {"Product Type"}
                </InputLabel>
                <Select
                  // error={errors?.productType && errors?.productType}
                  className={classes.textField}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  sx={{ marginTop: "20px" }}
                  {...register("productType")}
                  label="Product Type"
                  defaultValue={watch("productType")}
                >
                  {Object.keys(productConstants)?.map((x) => (
                    <MenuItem value={x}>{productConstants[x]}</MenuItem>
                  ))}
                </Select>
                {errors?.productType?.message && (
                  <FormHelperText
                    error={errors?.productType && errors?.productType}
                  >
                    {errors?.productType?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <Autocomplete
                  disablePortal
                  options={Object.keys(bankNameConstants)}
                  id="demo-simple-select-standard"
                  renderInput={(params) => <TextField
                    {...register("loanBank")}
                    {...params} label="Bank Name" />}
                  defaultValue={watch("loanBank")}
                />
                {errors?.loanBank?.message && (
                  <FormHelperText error={errors?.loanBank && errors?.loanBank}>
                    {errors?.loanBank?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  type="number"
                  {...register("loanAmount")}
                  label="Loan Amount"
                  fullWidth
                  InputProps={{ inputProps: { min: 0 } }}
                  variant="standard"
                  error={errors?.loanAmount && errors?.loanAmount}
                  helperText={errors?.loanAmount?.message}
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl
                variant="standard" className={classes.formControl}>
                <InputLabel id={"label-Loan Status"}>
                  {"Loan Status"}
                </InputLabel>
                <Select
                  // error={errors?.productType && errors?.productType}
                  className={classes.textField}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  sx={{ marginTop: "20px" }}
                  {...register("loanStatus")}
                  label="Loan Status"
                  defaultValue={watch("loanStatus")}
                // value={iswatch_with()}
                >
                  {Object.keys(loanStatusConstants)?.map((x) => (
                    <MenuItem value={x}>{loanStatusConstants[x]}</MenuItem>
                  ))}
                </Select>
                {errors?.loanStatus?.message && (
                  <FormHelperText
                    error={errors?.loanStatus && errors?.loanStatus}
                  >
                    {errors?.loanStatus?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("applicationNumber")}
                  label="Application Number"
                  id='applicationNumber'
                  onChange={handleApplicationNumber}
                  variant="standard"
                  error={errors?.applicationNumber && errors?.applicationNumber}
                  helperText={errors?.applicationNumber?.message}
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={applicationNumber?.length > 0 ? 'w-100' : 'd-none'}>
                {/* <label for="applicationDate">Application Date</label> */}
                <TextField
                  id="ad"
                  className="date-picker"
                  type="text"
                  label="Application Date"
                  value={applicationDate}
                  onFocus={onPickDate}
                  onChange={handleApplicationDate}
                  error={errors?.applicationDate}
                  helperText={errors?.applicationDate?.message}
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("disbursementAmount")}
                  label="Disbursed Amount"
                  fullWidth
                  id='disbursementAmount'
                  onChange={handleInputChange}
                  variant="standard"
                  error={
                    errors?.disbursementAmount && errors?.disbursementAmount
                  }
                  helperText={errors?.disbursementAmount?.message}
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel style={{ margin: '-15px 5px', fontSize: '10px' }}>Disbursed Date</InputLabel>
                <input
                  type="date"
                  placeholder="Disbursed Date"
                  className="date-picker"
                  value={disbursedDate}
                  max={today}
                  onChange={e => setDisbursedDate(e.target.value)}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  type="text"
                  {...register("lanNumber")}
                  label="Loan Account Number (LAN)"
                  fullWidth
                  variant="standard"
                  error={errors?.lanNumber && errors?.lanNumber}
                  helperText={errors?.lanNumber?.message}
                />
              </FormControl>
            </div>
            {/* Loan Details END */}

            {/* Reedemed Details Start */}
            <DialogTitle>Reedemed Details</DialogTitle>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  type='number'
                  inputProps={{
                    maxLength: 20,
                    minLength: 5,
                    step: "0.001",
                    min: connectorPayoutPercentage
                  }}
                  margin="dense"
                  {...register("bankPayoutPercentage")}
                  id='bankPayoutPercentage'
                  label="Bank Payout Percentage"
                  fullWidth
                  variant="standard"
                  onChange={handleInputChange}
                  // onChange={hanxdleInputChange}
                  error={
                    errors?.bankPayoutPercentage && errors?.bankPayoutPercentage
                  }
                  helperText={errors?.bankPayoutPercentage?.message}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  type="number"
                  className={classes.textField}
                  margin="dense"
                  {...register("bankPayoutAmount", {
                    disabled: true
                  })}
                  value={bankPayoutAmount}
                  label="Bank Payout Amount"
                  fullWidth
                  variant="standard"
                  error={errors?.bankPayoutAmount && errors?.bankPayoutAmount}
                  helperText={errors?.bankPayoutAmount?.message}
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  type="number"
                  className={classes.textField}
                  margin="dense"
                  inputProps={{
                    maxLength: 20,
                    step: "0.001"
                  }}
                  {...register("connectorPayoutPercentage")}
                  label="DigiRep Payout Percentage"
                  id='connectorPayoutPercentage'
                  onChange={handleInputChange}
                  fullWidth
                  variant="standard"
                // error={errors?.connectorPayoutPercentage && errors?.connectorPayoutPercentage}
                // helperText={errors?.connectorPayoutPercentage?.message}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  type="number"
                  className={classes.textField}
                  margin="dense"
                  {...register("grossPayoutAmount", {
                    disabled: true,
                  })}
                  label="Gross Payout Amount"
                  value={grossPayoutAmount}
                  fullWidth
                  variant="standard"
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  type="number"
                  className={classes.textField}
                  margin="dense"
                  {...register("connectorPayoutAmount", {
                    disabled: true,
                  })}
                  label="DigiRep Payout Amount"
                  value={connectorPayoutAmount}
                  fullWidth
                  variant="standard"
                  error={errors?.connectorPayoutAmount && errors?.connectorPayoutAmount}
                  helperText={errors?.connectorPayoutAmount?.message}
                />
              </FormControl>
            </div>
            <div className="col-md-6" style={{ display: 'none' }}>
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel>Payment Status</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  sx={{ marginTop: "30px" }}
                  {...register("connectorPayoutStatus")}
                  fullWidth
                  defaultValue={watch("connectorPayoutStatus")}
                >
                  {status_map(Object.keys(redeemedStatusConstants)).map((x) => (
                    <MenuItem value={x}>{redeemedStatusConstants[x]}</MenuItem>
                  ))}
                </Select>
                {errors?.connectorPayoutStatus?.message && (
                  <FormHelperText
                    error={errors?.connectorPayoutStatus && errors?.connectorPayoutStatus}
                  >
                    {errors?.connectorPayoutStatus?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            <div className="col-md-6" style={{ display: 'none' }}>
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("reedemBankPayoutPct")}
                  label="Bank Payout Pct"
                  fullWidth
                  variant="standard"
                  error={
                    errors?.reedemBankPayoutPct && errors?.reedemBankPayoutPct
                  }
                  helperText={errors?.reedemBankPayoutPct?.message}
                />
              </FormControl>
            </div>

            {/* {NOT TO SHOW}  ^^^^ */}

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel style={{ margin: '-15px 5px', fontSize: '10px' }}>Bank Payout Received Date</InputLabel>
                <input
                  type="date"
                  className="date-picker"
                  {...register("bankPayoutReceivedDate")}
                  value={bankPayoutReceivedDate}
                  onChange={e => setBankPayoutReceivedDate(e.target.value)}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  type="number"
                  className={classes.textField}
                  margin="dense"
                  inputProps={{
                    maxLength: 20,
                    step: "0.001"
                  }}
                  {...register("bankPayoutTDSPercent")}
                  label="TDS Percentage"
                  id='bankPayoutTDSPercent'
                  fullWidth
                  variant="standard"
                  value={bankPayoutTDSPercent}
                  onChange={e => setbankPayoutTDSPercent(e.target.value)}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  type="number"
                  className={classes.textField}
                  margin="dense"
                  defaultValue={watch("bankPayoutTDSAmount")}
                  value={watch("bankPayoutTDSAmount")}
                  {...register("bankPayoutTDSAmount", {
                    disabled: true,
                  })}
                  InputLabelProps={{ shrink: true }}
                  label="TDS Amount"
                  fullWidth
                  variant="standard"
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  type="number"
                  className={classes.textField}
                  margin="dense"
                  value={watch("bankPayoutNetAmount")}
                  {...register("bankPayoutNetAmount", {
                    disabled: true,
                  })}
                  InputLabelProps={{ shrink: true }}
                  label="Net Payout Amount"
                  fullWidth
                  variant="standard"
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  margin="dense"
                  {...register("remarks")}
                  label="Remarks"
                  fullWidth
                  variant="standard"
                  error={errors?.remarks && errors?.remarks}
                  helperText={errors?.remarks?.message}
                />
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ padding: "0 30px 20px 0" }}>
          <Button variant="text" sx={{ boxShadow: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.leadButton}
            type="submit"
          // disabled={isLoading ? "true" : "false"}
          >
            {isEditMode ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </form>
    </Dialog >
  );

  return (
    <div className="sidenav-content">
      <div className="row my-2">
        <div className="col-md-6">
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            className="leads-heading"
          >
            Leads Data
          </Typography>
        </div>
        <div className="col-md-6">
          <Stack spacing={2} direction="row-reverse">
            {/* <Button
              variant="contained"
              startIcon={<AddOutlinedIcon />}
              onClick={handleAddLead}
              className={classes.leadButton}
            >
              Add Lead
            </Button> */}
            <BulkUpload />
            <TemplateDownload />
            <Button
              variant="contained"
              startIcon={<GetAppIcon />}
              className={classes.leadButton}
              onClick={() => setOpenLeadDownload(true)}
            >
              Leads
            </Button>
          </Stack>
        </div>
      </div>

      <AdminLeadsTable handleEditClick={handleEditClick} />
      <AdminLeadDownload openLeadDownload={openLeadDownload} setOpenLeadDownload={setOpenLeadDownload} />
      {addLeadElement}

      {isLoading && <PageLoader />}
      {errorMsg && <ErrorSnackbar errorMsg={errorMsg} />}
      {successMsg && <SuccessSnackbar successMsg={successMsg} />}
    </div>
  );
}

export default Leads;
