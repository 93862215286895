import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import AppContext from "../../components/contextProvider/AppContextProvider/AppContext";
import {
  ErrorSnackbar,
  SuccessSnackbar
} from "../../components/CustomSnackBar";
import { fetchTotalProjections } from "../../redux/reducers/TotalProjections";
import Deposits from "./Deposits";
import "../../helpers";

function TransactionSection() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { freqValue, productType } = useContext(AppContext);
  const data = JSON.parse(sessionStorage.getItem('data'));
  const connectorId = data?.connectorId;

  const { totalProjectionsData, isLoading, successMsg, errorMsg } = useSelector(
    ({ totalProjections }) => totalProjections
  );

  useEffect(() => {
    dispatch(fetchTotalProjections(connectorId, productType, freqValue)); //Invoke Action
  }, [freqValue, productType, connectorId]);


  let totalSubmittedLoans = 0;
  let totalDisbursedLoans = 0;
  let totalDisbursedAmount = 0;
  let totalConnectorAmount = 0;

  const dummy = totalProjectionsData && totalProjectionsData?.map((x) => {
    totalSubmittedLoans += x.totalApplications;
    totalDisbursedLoans += x.totalDisbursedApplications;
    totalDisbursedAmount += x.totalDisbursedAmount;
    totalConnectorAmount += x.totalConnectorPayout;
  })

  const navigate = (type) => {
    const queryParam = productType ? `type=${type}&productType=${productType}&range=${freqValue}` : `type=${type}&range=${freqValue}`;
    history.push(`/DigiRep/leads?${queryParam}`);
  }

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={3} lg={3} className="deposits-card">
          <Paper
            onClick={() => navigate('submitted')}
            sx={{
              p: 3,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: "#92ffd2",
              },

            }}
          >
            <Deposits name="Loans Submitted" amount={totalSubmittedLoans} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={3} lg={3} className=" deposits-card">
          <Paper
            onClick={() => navigate('DISBURSED')}
            sx={{
              p: 3,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: "#92ffd2",
              },
            }}
          >
            <Deposits name="Loans Processed" amount={totalDisbursedLoans} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={3} lg={3} className="deposits-card">
          <Paper
            onClick={() => navigate('DISBURSED')}
            sx={{
              p: 3,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: "#92ffd2",
              },
            }}
          >
            <Deposits
              name="Amount Disbursed"
              amount={totalDisbursedAmount}
              type="currency"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={3} lg={3} className="deposits-card">
          <Paper
            onClick={() => navigate('Completed')}
            sx={{
              p: 3,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: "#92ffd2",
              },
            }}
          >
            <Deposits
              name="Redeemed Amount"
              amount={totalConnectorAmount}
              type="currency"
            />
          </Paper>
        </Grid>
      </Grid>
      {errorMsg && <ErrorSnackbar errorMsg={errorMsg} />}
      {successMsg && <SuccessSnackbar successMsg={successMsg} />}
    </>
  );
}

export default TransactionSection;
