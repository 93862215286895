import PostAddIcon from "@mui/icons-material/PostAdd";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";

const nonActive = { borderRadius: "5px", color: "white" };

const DataPrivacyPolicy = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItem className="pt-0 pb-0" onClick={() => setOpen(true)}>
        <ListItemButton sx={nonActive}>
          <ListItemIcon sx={{ color: "white" }}>
            <PostAddIcon />
          </ListItemIcon>
          <ListItemText primary="Data Privacy Policy" />
        </ListItemButton>
      </ListItem>
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
        // fullWidth
      >
        <DialogTitle>
          {"Data Privacy Policy for Intermediaries in Digital Lending"}
        </DialogTitle>
        <DialogContent>
          <div class="modal-body footer_modal_content">
            <p class="heading">Effective Date:</p>
            <ul>
              <li>
                <p class="heading">1. Introduction</p>
                <p>
                  M/s Star Powerz Digital Technologies Pvt Ltd is committed to
                  safeguarding the privacy and security of personal data in our
                  role as intermediaries in the digital lending industry. This
                  Data Privacy Policy outlines how we collect, use, disclose,
                  and protect personal data in compliance with relevant data
                  protection laws.{" "}
                </p>
              </li>
              <li>
                <p class="heading">2. Definitions </p>
                <p>
                  Personal Data: Any information that can directly or indirectly
                  identify an individual. Processing: Any operation or set of
                  operations performed on personal data, whether by automated
                  means or not, such as collection, recording, organization,
                  storage, adaptation, and retrieval. Data Subject: An
                  individual to whom personal data pertains.
                </p>
              </li>
              <li>
                <p class="heading">3. Data Collection </p>
                <ul class="inner-ul">
                  <p class="sub-heading">
                    We collect personal data for the following purposes:{" "}
                  </p>
                  <li>
                    • Identifying and verifying applicants for digital loans.{" "}
                  </li>
                  <li>• Assessing creditworthiness. </li>
                  <li>• Processing loan applications. </li>
                  <li>• Servicing and managing loans. </li>
                  <li>• Complying with legal and regulatory requirements. </li>
                </ul>
                <ul class="inner-ul">
                  <p class="sub-heading">
                    We may collect the following types of personal data, among
                    others:{" "}
                  </p>
                  <li>
                    • Contact information (name, address, email, phone number).{" "}
                  </li>
                  <li>
                    • Financial information (income, expenses, credit history).{" "}
                  </li>
                  <li>
                    • Identification information (government-issued ID, social
                    security number).{" "}
                  </li>
                  <li>• Employment and income details. </li>
                  <li>• Information related to loan transactions. </li>
                </ul>
              </li>
              <li>
                <p class="heading">4. Legal Basis for Processing </p>
                <ul class="inner-ul">
                  <p class="sub-heading">
                    We process personal data on the following legal bases:
                  </p>
                  <li>
                    • To perform the contract with the data subject (e.g.,
                    processing loan applications).{" "}
                  </li>
                  <li>
                    • To comply with legal obligations (e.g., regulatory
                    reporting).{" "}
                  </li>
                  <li>• Based on the data subject's consent when required. </li>
                  <li>
                    • For legitimate interests (e.g., fraud prevention, risk
                    assessment).{" "}
                  </li>
                </ul>
              </li>
              <li>
                <p class="heading">5. Data Security </p>
                <ul class="inner-ul">
                  <p class="sub-heading">
                    We implement technical and organizational measures to
                    protect personal data from unauthorized access, disclosure,
                    alteration, and destruction. These measures include:{" "}
                  </p>
                  <li>• Encryption of data in transit and at rest. </li>
                  <li>• Regular security assessments and audits. </li>
                  <li>• Access controls and authentication. </li>
                  <li>• Employee training on data security. </li>
                </ul>
              </li>
              <li>
                <p class="heading">6. Data Sharing </p>
                <ul class="inner-ul">
                  <p class="sub-heading">
                    We may share personal data with the following parties:{" "}
                  </p>
                  <li>• Lending institutions and financial partners. </li>
                  <li>• Credit bureaus and reporting agencies. </li>
                  <li>• Legal and regulatory authorities. </li>
                  <li>• Service providers who assist with loan processing. </li>
                  <p class="sub-heading">
                    We ensure that third parties have adequate data protection
                    measures in place.{" "}
                  </p>
                </ul>
              </li>
              <li>
                <p class="heading">7. Data Subject Rights </p>
                <ul class="inner-ul">
                  <p class="sub-heading">
                    Data subjects have the following rights:{" "}
                  </p>
                  <li>• Right to access and rectify personal data. </li>
                  <li>• Right to erasure (where applicable). </li>
                  <li>• Right to restrict processing. </li>
                  <li>• Right to data portability. </li>
                  <li>• Right to object to processing. </li>
                  <li>
                    • Right not to be subject to automated decision-making.{" "}
                  </li>
                  <p class="heading">
                    To exercise these rights, please contact our Data Protection
                    Officer (DPO) at [DPO contact details].{" "}
                  </p>
                </ul>
              </li>
              <li>
                <p class="heading">8. Data Breach Response</p>
                <ul class="inner-ul">
                  <li>
                    In the event of a data breach, we will notify affected data
                    subjects and relevant authorities as required by law.{" "}
                  </li>
                </ul>
              </li>
              <li>
                <p class="heading">9. International Data Transfers </p>
                <ul class="inner-ul">
                  <li>
                    If we transfer personal data outside the jurisdiction, we
                    will ensure that adequate safeguards are in place, such as
                    standard contractual clauses or approved binding corporate
                    rules.{" "}
                  </li>
                </ul>
              </li>
              <li>
                <p class="heading">10. Policy Updates </p>
                <ul class="inner-ul">
                  <li>
                    We will regularly review and update this policy to stay
                    compliant with data protection regulations. We encourage
                    data subjects to periodically review this policy for any
                    changes.{" "}
                  </li>
                </ul>
              </li>
              <li>
                <p class="heading">11. Contact Information</p>
                <ul class="inner-ul">
                  <li>
                    If you have any questions or concerns about this policy,
                    please contact our Data Protection Officer (Gutta Rajesh) at
                    [Rajesh@stardigiloans.com].{" "}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DataPrivacyPolicy;
