import Typography from "@mui/material/Typography";
import * as React from "react";
import { useSelector } from "react-redux";
import PageLoader from "../../components/PageLoader";

export default function Deposits({ name, amount, noOfLeads, subText }) {
  const { isLoading } = useSelector(({ totalProjections }) => totalProjections);
  return (
    <>
      <div className="d-flex justify-content-between">
        <Typography component="p" variant="h3">
          {name}
        </Typography>
        <label> {subText}</label>
      </div>
      <div>
        <label>
          No. Leads -<span style={{ marginLeft: 10 }}>{noOfLeads.toLocaleString('hi')}</span>
        </label>
      </div>
      <div>
        <label>
          Amount -<span style={{ marginLeft: 10 }}>{amount.cur()}</span>
        </label>
      </div>

      {isLoading && <PageLoader />}
    </>
  );
}
