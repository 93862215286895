import { Breadcrumbs, Card, CardContent, Chip, Container, Grid, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { DataGrid, getGridStringOperators } from '@mui/x-data-grid';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { ErrorSnackbar } from '../../components/CustomSnackBar';
import { errorHandler } from '../../helpers/errorHandler';
import { ApiUrl } from '../../helpers/getUrl';

const ConnectorReport = () => {
    const search = useLocation().search;
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [selectedIds, setSelectedIds] = React.useState([]);
    const [filterModel, setFilterModel] = React.useState({ items: [{ columnField: 'productType', operatorValue: 'contains', value: '' }] });
    let [sortModel, setSortModel] = React.useState([
        { field: 'createdOn', sort: 'asc' },
    ]);
    const [level, setLevel] = useState('L1');
    const [connectorId, setConnectorId] = useState(0);
    const [connectorDetails, setConnectorDetails] = useState({});
    const [reqData, setreqData] = useState({
        limit: pageSize,
        orderBy: "createdOn",
        orderDirection: "DESC",
        range: "LTD",
        page: page + 1,
        search: {},
    });
    const [errorMsg, setErrorMsg] = useState({ state: false, msg: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let columns = [
        { headerName: "Digirep Name", field: "connectorName", width: 200 },
        { headerName: "Location", field: "location", width: 180 },
        { headerName: "Branch Manager", field: "branchManagerName", width: 200 },
        { headerName: "Manager", field: "managerName", width: 200 },
        { headerName: "DOJ", field: "doj", width: 150 },
        { headerName: "Vintage", field: "vintage", width: 150 },
        {
            headerName: "Last Month Business", field: "lastMonth", width: 200,
            valueGetter: (params) => params.row?.lastMonth?.totalBusiness
        },
        {
            headerName: "Status", field: "active", renderCell: (params) => {
                let status = params.row.active, statusText;
                (status) ? statusText = 'Active' : statusText = 'Inactive';
                return <p className={`${status ? 'text-success' : 'text-danger'} mx-auto`}>{statusText}</p>
            }
        },
        {
            headerName: 'Action',
            field: 'action',
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    // console.log(params.row.connectorId);
                    e.stopPropagation();
                    setConnectorId(params.row.connectorId)
                    setLevel("L2");
                };

                return <Chip label="View" variant="outlined" size='small' className='px-2 mx-auto' onClick={onClick} />
            },
        },
    ]
    const columnsWithFilterOptions = React.useMemo(
        () =>
            columns.map((col) => {

                return {
                    ...col,
                    filterOperators: getGridStringOperators().filter(
                        (operator) => operator.value === 'contains',
                    ),

                };
            }),
        [columns],
    );
    const onFilterChange = (filter) => {
        setFilterModel(filter);
    }

    const onSortChange = (sort) => {
        if (sort.length) {
            let selected = sortModel[0] ?? {};
            let newsort = sort[0] ?? {};
            if (selected.field != newsort.field || selected.sort != newsort.sort) {
                setSortModel([
                    newsort
                ])
                setReqData();
            }
        }
    }

    const getComputedReqData = () => {
        const type = new URLSearchParams(search).get("type");
        if (type) {
            console.log(type);
        }
        reqData.page = page + 1;
        reqData.limit = pageSize;
        return reqData;
    }

    const setReqData = () => {
        let ret = getComputedReqData();
        let filterSearch = filterModel.items.reduce((o, n) => { n.value && (o[n.columnField] = n.value); return o; }, {});
        //console.log("onFilterChange filterSearch", filterSearch, reqData.search);
        // const type = new URLSearchParams(search).get("type");
        // console.log(type);
        let payload = {
            ...ret,
            orderBy: sortModel[0].field,
            orderDirection: sortModel[0].sort.toUpperCase(),
            range: reqData.range,
            search: {
                ...filterSearch,
            }
        };
        setreqData(payload);
        return payload;
    }

    const onPageChange = (newPage) => {
        setSelectedIds([]);
        setPage(newPage);
    };
    useEffect(() => {
        // row data from API request
        const formData = {
            limit: pageSize,
            orderBy: "createdOn",
            orderDirection: "DESC",
            page: page + 1,
            search: {}
        };

        axios
            .post(ApiUrl() + `reports/connectors/list`, formData)
            .then((res) => {
                if (res.status === 200) {
                    // console.log(res);
                    setData(res.data.data)
                    setCount(res.data.count)
                }
            }).catch(error => {
                let errMsg = errorHandler(error);
                setErrorMsg({ state: true, msg: errMsg })
            });

        setIsLoading(false)
    }, [page, pageSize])

    const rowsSummary = [
        {
            month: 'Current Month',
            overallBusiness: connectorDetails.currentMonth?.totalBusiness.cur(),
            personalLoan: connectorDetails.currentMonth?.businessData.PERS.amount.cur(),
            businessLoan: connectorDetails.currentMonth?.businessData.BUSI.amount.cur(),
            homeLoan: connectorDetails.currentMonth?.businessData.HOME.amount.cur(),
            autoLoan: connectorDetails.currentMonth?.businessData.AUTO.amount.cur(),

            goldLoan: connectorDetails.currentMonth?.businessData.GOLD.amount.cur(),
            insuranceLoan: connectorDetails.currentMonth?.businessData.INSR.amount.cur(),
            creditCardLoan: connectorDetails.currentMonth?.businessData.CRED.amount.cur(),
            educationLoan: connectorDetails.currentMonth?.businessData.EDUL.amount.cur(),
            others: '-',
        },
        {
            month: 'Last Month',
            overallBusiness: connectorDetails.lastMonth?.totalBusiness.cur(),
            personalLoan: connectorDetails.lastMonth?.businessData.PERS.amount.cur(),
            businessLoan: connectorDetails.lastMonth?.businessData.BUSI.amount.cur(),
            homeLoan: connectorDetails.lastMonth?.businessData.HOME.amount.cur(),
            autoLoan: connectorDetails.lastMonth?.businessData.AUTO.amount.cur(),

            goldLoan: connectorDetails.lastMonth?.businessData.GOLD.amount.cur(),
            insuranceLoan: connectorDetails.lastMonth?.businessData.INSR.amount.cur(),
            creditCardLoan: connectorDetails.lastMonth?.businessData.CRED.amount.cur(),
            educationLoan: connectorDetails.lastMonth?.businessData.EDUL.amount.cur(),
            others: '-',
        },
        {
            month: 'Last 3 Month',
            overallBusiness: connectorDetails.last3Months?.totalBusiness.cur(),
            personalLoan: connectorDetails.last3Months?.businessData.PERS.amount.cur(),
            businessLoan: connectorDetails.last3Months?.businessData.BUSI.amount.cur(),
            homeLoan: connectorDetails.last3Months?.businessData.HOME.amount.cur(),
            autoLoan: connectorDetails.last3Months?.businessData.AUTO.amount.cur(),

            goldLoan: connectorDetails.last3Months?.businessData.GOLD.amount.cur(),
            insuranceLoan: connectorDetails.last3Months?.businessData.INSR.amount.cur(),
            creditCardLoan: connectorDetails.last3Months?.businessData.CRED.amount.cur(),
            educationLoan: connectorDetails.last3Months?.businessData.EDUL.amount.cur(),
            others: '-',
        },
    ];
    useEffect(() => {
        if (connectorId > 0) {
            axios
                .post(ApiUrl() + `reports/connector/${connectorId}`)
                .then((res) => {
                    if (res.status === 200) {
                        // console.log(res.data);
                        setConnectorDetails(res.data);
                    }
                }).catch(error => {
                    let errMsg = errorHandler(error);
                    setErrorMsg({ state: true, msg: errMsg })
                });
        }
    }, [connectorId]);
    return (
        <>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} className="sidenav-content">
                <Grid container spacing={3} className="row m-0 sidenav-info">
                    <div className="row my-2">
                        <div className="col-md-6">
                            <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                                className="leads-heading"
                            >
                                DigiRep Report
                            </Typography>
                        </div>

                        {level === 'L1' && <>
                            <Breadcrumbs aria-label="breadcrumb" className='m-0 mb-3'>
                                <Typography color="text.primary">DigiRep Report</Typography>
                            </Breadcrumbs>
                            <div className="row">
                                <div
                                    style={{ height: 530, maxWidth: "100%" }}
                                    className="col-md-12 leads-table"
                                >
                                    <DataGrid
                                        rows={data}
                                        getRowId={(e) => e.connectorId}
                                        columns={columnsWithFilterOptions}
                                        pagination
                                        pageSize={pageSize}
                                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                        rowCount={count}
                                        height
                                        filterMode="server"
                                        filterModel={filterModel}
                                        onFilterModelChange={onFilterChange}
                                        sortingMode="server"
                                        sortModel={sortModel}
                                        onSortModelChange={onSortChange}
                                        rowHeight={40}
                                        isRowSelectable={(params) => params.row.connectorPayoutStatus === 'reviewPending'}
                                        onSelectionModelChange={(ids) => {
                                            const selectedIDs = new Set(ids);
                                            const selectedRowData = data.filter((row) =>
                                                selectedIDs.has(row._id)
                                            );
                                            setSelectedRows(selectedRowData);
                                            setSelectedIds([...selectedIDs]);
                                            // console.log("ids", [selectedIDs]);
                                            // console.log("onSelectionModelChange:", selectedRowData);
                                        }}
                                        selectionModel={selectedIds}
                                        paginationMode="server"
                                        onPageChange={(newPage) => onPageChange(newPage)}
                                        onPageSizeChange={(newPageSize) => {
                                            setPage(0);
                                            setPageSize(newPageSize);
                                        }}
                                        loading={isLoading}
                                        disableSelectionOnClick
                                    />
                                </div>
                            </div>
                        </>
                        }
                        {level === 'L2' && <div>
                            <Breadcrumbs aria-label="breadcrumb" className='m-0 mb-3'>
                                <Link underline="hover" color="inherit" to="#" onClick={() => {
                                    setLevel('L1');
                                    setConnectorDetails({})
                                }}>DigiRep Report</Link>
                                <Typography color="text.primary">{connectorDetails?.connectorName}</Typography>
                            </Breadcrumbs>
                            <Card sx={{ minWidth: 275 }} variant="outlined">
                                <CardContent>
                                    <Typography variant="h5" component="div" sx={{ fontWeight: '500' }}>
                                        {connectorDetails?.connectorName}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5, fontWeight: '500' }} color="#717171">
                                        Employee
                                    </Typography>
                                    <hr />
                                    <Typography variant="body2">
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} md={4}>
                                                <List className="p-0">
                                                    <ListItem className="px-0">
                                                        <ListItemText>
                                                            <span style={{ color: '#717171' }} className="me-2">Mobile:</span>
                                                            <span style={{ fontWeight: '500' }}>{connectorDetails?.connectorPhone || '-'}</span></ListItemText>
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <List className="p-0">
                                                    <ListItem className="px-0">
                                                        <ListItemText>
                                                            <span style={{ color: '#717171' }} className="me-2">Branch Manager:</span>
                                                            <span style={{ fontWeight: '500' }}>{connectorDetails?.branchManagerName || '-'}</span></ListItemText>
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <List className="p-0">
                                                    <ListItem className="px-0">
                                                        <ListItemText>
                                                            <span style={{ color: '#717171' }} className="me-2">Manager:</span>
                                                            <span style={{ fontWeight: '500' }}>{connectorDetails?.managerName || '-'}</span></ListItemText>
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <List className="p-0">
                                                    <ListItem className="px-0">
                                                        <ListItemText>
                                                            <span style={{ color: '#717171' }} className="me-2">Date of Joining:</span>
                                                            <span style={{ fontWeight: '500' }}>{moment(connectorDetails?.createdOn).format("DD MMM YYYY") || '-'}</span></ListItemText>
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <List className="p-0">
                                                    <ListItem className="px-0">
                                                        <ListItemText>
                                                            <span style={{ color: '#717171' }} className="me-2">Zone:</span>
                                                            <span style={{ fontWeight: '500' }}>{connectorDetails?.zone || '-'}</span></ListItemText>
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <List className="p-0">
                                                    <ListItem className="px-0">
                                                        <ListItemText>
                                                            <span style={{ color: '#717171' }} className="me-2">Location:</span>
                                                            <span style={{ fontWeight: '500' }}>{connectorDetails?.location || '-'}</span></ListItemText>
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                                className="leads-heading pt-4 pb-2"
                            >
                                Business Summary
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 550 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Month</TableCell>
                                            <TableCell>Overall Business</TableCell>
                                            <TableCell>Personal Loan</TableCell>
                                            <TableCell>Business Loan</TableCell>
                                            <TableCell>Home Loans</TableCell>
                                            <TableCell>Auto Loans</TableCell>
                                            <TableCell>Gold Loans</TableCell>
                                            <TableCell>Insurance Loans</TableCell>
                                            <TableCell>Credit Card Loans</TableCell>
                                            <TableCell>Education Loans</TableCell>
                                            <TableCell>Others</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rowsSummary.map((row, idx) => (
                                            <TableRow
                                                key={idx}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.month}
                                                </TableCell>
                                                <TableCell>{row.overallBusiness}</TableCell>
                                                <TableCell>{row.personalLoan}</TableCell>
                                                <TableCell>{row.businessLoan}</TableCell>
                                                <TableCell>{row.homeLoan}</TableCell>
                                                <TableCell>{row.autoLoan}</TableCell>
                                                <TableCell>{row.goldLoan}</TableCell>
                                                <TableCell>{row.insuranceLoan}</TableCell>
                                                <TableCell>{row.creditCardLoan}</TableCell>
                                                <TableCell>{row.educationLoan}</TableCell>
                                                <TableCell>{row.others}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        }
                    </div>
                </Grid>
                {errorMsg.state && <ErrorSnackbar errorMsg={errorMsg.msg} />}
            </Container>
        </>
    )
}

export default ConnectorReport